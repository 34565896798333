import { BetBarComponent } from 'slotworks/components/betbar/bet-bar-component';

export class SocialBetBarComponent extends BetBarComponent {
  /**
   * Remove balance text.
   * @protected
   * @override
   */
  protected setupTexts(): void {
    super.setupTexts();
    this.layer.getText('balance_value').visible = false;
    this.layer.getText('balance_label').visible = false;
  }
}
