import { Services } from "appworks/services/services";
import { Contract } from "appworks/utils/contracts/contract";
import { Timer } from "appworks/utils/timer";
import { CanvasService } from "../canvas/canvas-service";
import { Flexi } from "./abstract-flexi";

export class FlexiVideo extends Flexi<HTMLVideoElement> {
    public sourceHTML: string;
    public isPlaying: boolean = false;

    public addTarget(target: HTMLVideoElement): void {
        target.addEventListener(VideoEvent.PLAY.getDOMEventString(), this.onPlay);
        target.addEventListener(VideoEvent.PAUSE.getDOMEventString(), this.onPause);
        target.addEventListener(VideoEvent.END.getDOMEventString(), this.onPause);
        this.fixPath(target);
        super.addTarget(target);
    }

    public destroy(): void {
        for (const target of this.targets) {
            if (target instanceof HTMLVideoElement) {
                target.removeEventListener(VideoEvent.PLAY.getDOMEventString(), this.onPlay);
                target.removeEventListener(VideoEvent.PAUSE.getDOMEventString(), this.onPause);
                target.removeEventListener(VideoEvent.END.getDOMEventString(), this.onPause);
            } else {
                throw new Error("Not implemented");
            }
        }

        super.destroy();
    }

    public getCurrentTime(): number {
        if (this.targets[0] instanceof HTMLVideoElement) {
            return this.targets[0].currentTime;
        } else {
            throw new Error("Not implemented");
        }
    }

    public getDuration(): number {
        if (this.targets[0] instanceof HTMLVideoElement) {
            return this.targets[0].duration;
        } else {
            throw new Error("Not implemented");
        }
    }

    public on(event: VideoEvent, callback: Function): void {
        for (const target of this.targets) {
            if (target instanceof HTMLVideoElement) {
                target.addEventListener(event.getDOMEventString(), callback as any);
            } else {
                throw new Error("Not implemented");
            }
        }
    }

    public off(event: VideoEvent, callback: Function): void {
        for (const target of this.targets) {
            if (target instanceof HTMLVideoElement) {
                target.removeEventListener(event.getDOMEventString(), callback as any);
            } else {
                throw new Error("Not implemented");
            }
        }
    }

    /**
     * Plays a video, and resolves when the video begins playing, or if the video can't play
     */
    public buffer() {
        return new Contract<void>((resolve) => {
            for (const target of this.targets) {
                if (target instanceof HTMLVideoElement) {

                    const bufferCheck = Timer.setInterval(() => {
                        if (target.currentTime > 0) {
                            Timer.clearInterval(bufferCheck);
                            resolve();
                        }
                    }, 0);

                    target.setAttribute("playsinline", "true");
                    target.setAttribute("muted", "true");
                    target.play().catch((e) => {
                        Timer.clearInterval(bufferCheck);
                        resolve();
                    });

                } else {
                    throw new Error("Not implemented");
                }
            }
        });
    }

    /**
     * Plays video. Recommended to use playContract if possible, as it handles common errors for you
     */
    public play(from?: number, unloadWhenFinished: boolean = false): void {

        if (from == null && this.getCurrentTime() >= this.getDuration()) {
            from = 0;
        }
        this.seek(from);

        for (const target of this.targets) {
            if (target instanceof HTMLVideoElement) {
                if (target.innerHTML === "" && this.sourceHTML) {
                    target.innerHTML = this.sourceHTML;
                }

                if (target.readyState !== 4) {
                    target.oncanplaythrough = () => {
                        target.onload = null;

                        target.play().catch((e) => {
                            target.dispatchEvent(new Event(VideoEvent.END.getDOMEventString()));
                        });
                    };
                    target.load();
                } else {
                    target.play().catch((e) => {
                        target.dispatchEvent(new Event(VideoEvent.END.getDOMEventString()));
                    });
                }

                target.setAttribute("playsinline", "true");
                target.setAttribute("muted", "true");

                if (unloadWhenFinished) {
                    const unloadVideo = () => {
                        // Delay removal a frame to allow other listener on end to do what they need to
                        Timer.setTimeout(() => {
                            if (target.innerHTML !== "") {
                                this.sourceHTML = target.innerHTML;
                                target.innerHTML = "";
                                target.load();
                            }
                        }, 0);
                        this.off(VideoEvent.END, unloadVideo);
                    };
                    this.on(VideoEvent.END, unloadVideo);
                }
            } else {
                throw new Error("Not implemented");
            }
        }
    }

    /**
     * Plays video and resolves when it either: ends, is paused or fails / errors
     */
    public playContract(from?: number, unloadWhenFinished: boolean = false) {
        return new Contract<void>((resolve) => {

            const complete = () => {
                if (resolve) {
                    this.off(VideoEvent.END, resolve);
                    this.off(VideoEvent.PAUSE, resolve);
                    resolve();
                    resolve = null;
                }
            };

            this.on(VideoEvent.END, complete);
            this.on(VideoEvent.PAUSE, complete);
            try {
                this.play(from, unloadWhenFinished);
            } catch (e) {
                complete();
            }
        });
    }

    public pause(): void {
        for (const target of this.targets) {
            if (target instanceof HTMLVideoElement) {
                target.pause();
            } else {
                throw new Error("Not implemented");
            }
        }
    }

    public seek(time: number): void {
        if (time < 0 || time > this.getDuration()) {
            time = 0;
        }

        for (const target of this.targets) {
            if (target instanceof HTMLVideoElement) {
                target.currentTime = time;
            } else {
                throw new Error("Not implemented");
            }
        }
    }

    public setLoop(loop: boolean) {
        for (const target of this.targets) {
            if (target instanceof HTMLVideoElement) {
                target.loop = loop;
            } else {
                throw new Error("Not implemented");
            }
        }
    }

    public setVisible(visible: boolean) {
        for (const target of this.targets) {
            if (target instanceof HTMLVideoElement) {
                target.classList.toggle("visible", visible);
                target.classList.toggle("hidden", !visible);
            } else {
                throw new Error("Not implemented");
            }
        }
    }

    private onPause = () => {
        this.isPlaying = false;
    }

    private onPlay = () => {
        this.isPlaying = true;
    }

    private fixPath(target: HTMLVideoElement): void {
        if (target instanceof HTMLVideoElement) {
            const idValue: string = target.getAttribute("id");

            for (const extension of ["webm", "mp4"]) {
                const source = document.createElement("source");
                source.src = "video/" + idValue + "@" + Services.get(CanvasService).layoutScale + "x." + extension;
                source.type = "video/" + extension;
                target.appendChild(source);
            }
        }
    }
}

export class VideoEvent {
    public static readonly END = new VideoEvent("ended");
    public static readonly PLAY = new VideoEvent("play");
    public static readonly PAUSE = new VideoEvent("pause");

    constructor(public id: string) {
    }

    public getDOMEventString(): string {
        return this.id;
    }

    public getPIXIEventString(): string {
        throw new Error("Not implemented");
    }
}
