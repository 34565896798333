export class Timer {

    public static time: number = 0;

    /**
     * Updates all timers
     *
     * @param time {number}
     */
    public static update(time: number) {
        this.time = time;

        this.timers.forEach((timer: Timer, key: number) => {
            const onComplete = timer.update(time);

            if (onComplete) {
                onComplete();
            }

            if (timer.complete) {
                this.timers.delete(key);
            }
        });
    }

    /**
     * Calls a function once after a specified number of milliseconds
     *
     * @param callback {Function}
     * @param duration {number}
     */
    public static setTimeout(callback: Function, duration: number): number {
        return this.createTimer(callback, duration, false);
    }

    /**
     * Calls a function repeatedly after a specified number of milliseconds
     *
     * @param callback {Function}
     * @param duration {number}
     */
    public static setInterval(callback: Function, duration: number): number {
        return this.createTimer(callback, duration, true);
    }

    /**
     * Clears a specified timeout. It's callback will not be called.
     *
     * @param id {number}
     */
    public static clearTimeout(id: number) {
        if (this.timers.has(id)) {
            this.timers.delete(id);
        }
    }

    /**
     * Clears a specified interval. It's callback will no longer be called.
     *
     * @param id {number}
     */
    public static clearInterval(id: number) {
        if (this.timers.has(id)) {
            this.timers.delete(id);
        }
    }

    private static timers: Map<number, Timer> = new Map<number, Timer>();

    private static currentId: number = 0;

    private static createTimer(callback: Function, duration: number, repeat: boolean): number {
        this.currentId++;

        this.timers.set(this.currentId, new Timer(this.currentId, repeat, duration, callback));

        return this.currentId;
    }

    public complete: boolean;
    public id: number;

    private duration: number;
    private startTime: number;
    private repeat: boolean;
    private onComplete: Function;

    private constructor(id: number, repeat: boolean, duration: number, onComplete: Function) {
        this.id = id;
        this.repeat = repeat;
        this.duration = duration;
        this.startTime = Timer.time;
        this.onComplete = onComplete;
    }

    private update(time: number) {
        if (time - this.startTime > this.duration) {
            if (this.repeat) {
                this.startTime = time;
            } else {
                this.complete = true;
            }

            return this.onComplete;
        }

        return null;
    }
}
