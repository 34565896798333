import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Point } from "appworks/utils/geom/point";
import { PopScatterResultBuilder } from "integration/builders/results/pop-scatter-result-builder";
import { SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { SlingoRequestSpinState } from "slingo/states/slingo-request-spin-state";

export class PopRequestSpinState extends SlingoRequestSpinState {
    public onEnter(cascadeSkip?: boolean): void {
        PopScatterResultBuilder.scatterWinsAdded = 0;

        this.lastRecord = gameState.getCurrentGame().getCurrentRecord() as SlingoRecord;
        const matches = this.lastRecord.getFirstResultOfType(SlingoReelSpinResult)?.matches;

        // Just in case symbols somehow have failed to dab correctly, we just force them to be dabbed each spin
        if (matches && !this.config.skipRequest) {
            for (let x = 0; x < 5; x++) {
                for (let y = 0; y < 5; y++) {
                    if (!Components.get(SlingoTicketMatrixComponent).isPositionDabbed(new Point(x, y))) {
                        const value = Components.get(SlingoTicketMatrixComponent).getValueFromPosition(x, y);
                        if (matches.find((v) => v.matchedValue === value)) {
                            Components.get(SlingoTicketMatrixComponent).dabSymbol(value, true).execute();
                        }
                    }
                }
            }
        }

        super.onEnter(cascadeSkip);
    }
}