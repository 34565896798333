import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { gameLayers } from "game-layers";
import { SlingoFullHouseState } from "slingo/states/slingo-full-house-state";

export class PopFullHouseState extends SlingoFullHouseState {
    protected showFullHouseAnimation(): Contract<void> {
        return new Sequence([
            () => new Parallel([
                () => gameLayers.CelebrationContent.setScene("fullhouse"),
                () => gameLayers.CelebrationBackground.setScene("fullhouse")
            ]),
            () => Contract.wrap(() => {
                Services.get(SoundService).event(SoundEvent.big_win_tier_id, "fullhouse");
            }),
            () => gameLayers.CelebrationContent.getSpine("full_house").playOnce(),
            () => Contract.getTimeoutContract(1000),
            () => new Parallel([
                () => gameLayers.CelebrationContent.defaultScene(),
                () => gameLayers.CelebrationBackground.defaultScene()
            ])
        ]);
    }
}
