import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { uiFlags, UIFlag } from "appworks/ui/flags/ui-flags";
import { Parallel } from "appworks/utils/contracts/parallel";
import { PopReelMatrixComponent } from "components/pop-reel-matrix-component";
import { SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoJokerResult } from "slingo/model/results/slingo-joker-result";
import { SlingoPickJokerState } from "slingo/states/slingo-pick-joker-state";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";

export class PopPickJokerState extends SlingoPickJokerState {
    protected onPick(value: number) {
        uiFlags.set(UIFlag.SPINNING, true);

        const reelMatrix = Components.get(PopReelMatrixComponent);
        const ticketMatrix = Components.get(SlingoTicketMatrixComponent);

        let wildToPop: number;
        if (this.wasPickSuperWild(value)) {
            // If it's a super wild, just pop first unpopped one we find
            wildToPop = reelMatrix.getBaseGridSymbols().findIndex((symbol: SymbolSubcomponent) => symbol.symbolId === "SJ");
        } else {
            // If it's a wild, pop the wild corresponding to the number picked
            wildToPop = Math.floor((value - 1) / 15);
        }

        new Parallel([
            () => this.sendPickRequest(value),
            () => ticketMatrix.dabSymbol(value),
            () => ticketMatrix.clearHighlights(),
            () => reelMatrix.popSymbol(wildToPop)
        ]).then(() => this.complete());
    }

    protected wasPickSuperWild(value: number): boolean {
        const result = gameState.getCurrentGame().getLatestResultOfType(SlingoJokerResult);
        return result.superJokerCells.indexOf(value) !== -1;
    }
}