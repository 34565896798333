import { Signal } from "signals";

export class KeyInput {
    public onKeyUp: Signal;
    public onKeyHeld: Signal;

    protected keyupListener: (this: Window, ev: KeyboardEvent) => void;
    protected keydownListener: (this: Window, ev: KeyboardEvent) => void;
    protected keyDown: boolean = false;

    constructor() {

        this.onKeyUp = new Signal();
        this.onKeyHeld = new Signal();

        this.keyupListener = (e) => {
            const key = e.key ? e.key : e.which;

            this.keyDown = false;

            this.onKeyUp.dispatch(key, e);
        };

        this.keydownListener = (e) => {
            const key = e.key ? e.key : e.which;

            if (this.keyDown) {
                this.onKeyHeld.dispatch(key, e);
            }

            this.keyDown = true;
        };

        window.addEventListener("keyup", this.keyupListener);
        window.addEventListener("keydown", this.keydownListener);
    }

    public destroy() {

        window.removeEventListener("keyup", this.keyupListener);
        window.removeEventListener("keydown", this.keydownListener);

        this.onKeyUp.dispose();
        this.onKeyHeld.dispose();
    }
}
