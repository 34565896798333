import { SlingoReelSpinResultBuilder } from "slingo/integration/builders/results/slingo-reel-spin-result-builder";
import { SlingoResponse } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";

export class PopReelSpinResultBuilder extends SlingoReelSpinResultBuilder {
    public build(record: SlingoRecord, data: SlingoResponse): SlingoReelSpinResult[] {
        const result = new SlingoReelSpinResult();

        result.patterns = null; // not used and will be incorrect

        result.symbols = [...data.spinResult.reelSequence.symbols];
        result.symbols.length = 5;

        result.matches = [];
        for (const match of data.spinResult.matchedNumbers) {
            if (result.symbols.indexOf(match.toString()) !== -1) {
                result.matches.push({
                    matchedValue: match,
                    reelIndex: result.symbols.indexOf(match.toString())
                });
            }
        }

        return [result];
    }
}
