import { gameState } from "appworks/model/game-state";
import { SlingoCascadeResult } from "slingo/model/results/slingo-cascade-result";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoRecoveryState } from "slingo/states/slingo-recovery-state";

export class PopRecoveryState extends SlingoRecoveryState {
    protected getPreviouslyMatchedNumbers(): number[] {
        const matched = super.getPreviouslyMatchedNumbers();

        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord() as SlingoRecord;
        const cascades = record.getResultsOfType(SlingoCascadeResult);

        return matched.filter(value => {
            const valueMatchedInACascade = cascades.find((cascade) =>
                cascade.matches.find((match) => match.matchedValue === value)
            );
            return !valueMatchedInACascade;
        })
    }
}