import { Record } from "appworks/model/gameplay/records/record";
import { Result } from "appworks/model/gameplay/records/results/result";
import { TrailIncrementResult } from "slotworks/model/gameplay/records/results/trail-increment-result";

export function trailIncrementResultDisplay(record: Record, result: Result) {
    const container = document.createElement("div");

    const trail = (result as TrailIncrementResult);

    container.innerHTML = `Trail increment ${trail.id}:  ${trail.increment}`;

    return container;
}
