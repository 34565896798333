import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { slotDefinition } from "slotworks/model/slot-definition";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { CurrencyService } from "../currency/currency-service";

declare const __VERSION__: string;
declare const __GAMENAME__: string;

class TranslationKeyReplacer {

    protected stringValueMap: Map<string, () => number | string>;

    public constructor() {
        this.stringValueMap = new Map();
        this.stringValueMap.set("totalstake", () => this.returnTotalStake());
        this.stringValueMap.set("totallines", () => this.returnTotalLines());
        this.stringValueMap.set("totalwin", () => this.returnTotalWin());
        this.stringValueMap.set("version", () => this.returnVersionNumber());
        this.stringValueMap.set("gamename", () => this.returnGameName());
    }

    public lookup(toLookup: string) {
        return this.stringValueMap.has(toLookup);
    }

    public get(toGet: string): string | number {
        return this.stringValueMap.get(toGet)();
    }

    protected returnTotalStake(): string {
        return Services.get(CurrencyService).format(Services.get(SlotBetService).getTotalStake());
    }

    protected returnTotalLines(): number {
        return slotDefinition.lines.values.length;
    }

    protected returnTotalWin(): string {
        if (gameState.getCurrentGame().hasRecords()) {
            return Services.get(CurrencyService).format(gameState.getCurrentGame().getTotalWin());
        }
    }

    protected returnVersionNumber() {
        return __VERSION__;
    }

    protected returnGameName() {
        return __GAMENAME__;
    }
}

export const translationKeyReplacer = new TranslationKeyReplacer();
