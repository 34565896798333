import { AbstractComponent } from "appworks/components/abstract-component";
import { Components } from "appworks/components/components";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Layers } from "appworks/graphics/layers/layers";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { CenterPivot, PIXIElement } from "appworks/graphics/pixi/group";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { fadeIn } from "appworks/utils/animation/fade";
import { pulse, scaleIn } from "appworks/utils/animation/scale";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Point } from "appworks/utils/geom/point";
import { Easing } from "appworks/utils/tween";
import { Signal } from "signals";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { slingoModel } from "slingo/model/slingo-model";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";
import { SlingoCelebrationComponent } from "./slingo-celebration-component";

export enum SlingoHighlightType {
    JOKER = "joker",
    SUPER_JOKER = "superjoker",
    MATCHED = "matched",
}

export type SlingoTicketMatrixComponentConfig = {
    parallelCelebration?: boolean;
};

const defaultSlingoTicketMatrixComponentConfig: Partial<SlingoTicketMatrixComponentConfig> = {
    parallelCelebration: true,
};

export class SlingoTicketMatrixComponent extends AbstractComponent {
    public layer: Layers = Layers.get("TicketMatrix");
    public highlightLayer: Layers = Layers.get("TicketMatrixBacking");

    public onDab: Signal = new Signal(); // symbolValue:number, position:Point

    protected config: SlingoTicketMatrixComponentConfig;
    protected gridData: number[][];
    protected winlines: Map<string, boolean> = new Map();
    protected numbers: PIXIElement[][] = [];
    protected dabs: PIXIElement[][] = [];
    protected highlights: PIXIElement[][] = [];

    constructor(config: SlingoTicketMatrixComponentConfig = defaultSlingoTicketMatrixComponentConfig) {
        super();
        this.config = { ...defaultSlingoTicketMatrixComponentConfig, ...config };
    }

    // The number of dabs to happen in 1 spin, used for a progressive sound which gets higher for each dab in a spin (including wilds)
    protected streak: number = 1;

    public setGrid(grid: number[][]) {
        this.reset();

        this.gridData = grid;
        this.populateWinlines();

        grid.forEach((col: number[], x: number) => {
            this.numbers[x] = [];
            this.dabs[x] = [];
            this.highlights[x] = [];

            col.forEach((value: number, y: number) => {
                const position = this.layer.getPosition(`symbol_${x}_${y}`);

                const sprite = this.getNumberSprite(value);
                this.layer.add(sprite);
                sprite.setDualPosition(position);

                this.numbers[x][y] = sprite;
            });
        });
    }

    /**
     * @param symbolValue
     * @param instant
     * @param streak        For how many dabs have happened this spin, used for sounds
     */
    public dabSymbol(symbolValue: number, instant: boolean = false): Contract {
        const xyPos = this.getSymbolPositionFromValue(symbolValue);

        const oldDab = this.dabs[xyPos.x][xyPos.y];
        if (oldDab) {
            oldDab.destroy();
            delete this.dabs[xyPos.x][xyPos.y];
        }

        const dabSprite = this.getDabSprite();
        this.layer.add(dabSprite);
        this.dabs[xyPos.x][xyPos.y] = dabSprite;

        const position = this.getDabPosition(xyPos);
        if (dabSprite instanceof SpineContainer) {
            dabSprite.landscape.setPosition(position.landscape);
            dabSprite.portrait.setPosition(position.portrait);
        } else {
            dabSprite.landscape.set(position.landscape);
            dabSprite.portrait.set(position.portrait);
        }

        if (!instant) {
            Services.get(SoundService).customEvent(SlingoSoundEvent.dab);
            Services.get(SoundService).event(SlingoSoundEvent.dab_N as any, this.streak.toString());
            this.streak++;
        }

        this.onDab.dispatch(symbolValue, xyPos);

        return this.getDabAnimationContract(dabSprite, instant);
    }

    // Call at the start of a spin to reset the streak of how many dabs have happened in 1 spin
    public resetStreak() {
        this.streak = 1;
    }

    public highlightSymbol(symbolValue: number, highlightType: SlingoHighlightType, onClick?: () => void): Contract {
        const pos = this.getSymbolPositionFromValue(symbolValue);

        const oldHighlight = this.highlights[pos.x][pos.y];
        if (oldHighlight) {
            oldHighlight.destroy();
            delete this.highlights[pos.x][pos.y];
        }

        const highlight = this.getHighlightSprite(highlightType);
        this.highlightLayer.add(highlight);
        this.highlights[pos.x][pos.y] = highlight;

        highlight.setDualPosition(this.getHighlightPosition(pos));

        if (onClick) {
            highlight.interactive = true;
            highlight.buttonMode = true;

            highlight.once(ButtonEvent.CLICK.getPIXIEventString(), onClick);
            highlight.once(ButtonEvent.CLICK.getPIXIEventString(), () => {
                this.highlights.forEach(col =>
                    col.forEach(hl => {
                        if (hl) {
                            hl.interactive = false;
                        }
                    })
                );
            });
        }

        return this.getHighlightAnimationContract(highlight, highlightType);
    }

    public clearHighlights(): Contract {
        this.highlights.forEach((col, x) =>
            col.forEach((hl, y) => {
                hl.destroy();
                delete this.highlights[x][y];
            })
        );

        return Contract.empty();
    }

    public checkWinlines(showCelebration: boolean = true): Contract {
        const newMatches: string[] = [];
        this.winlines.forEach((matched, pattern) => {
            if (!matched && this.checkWinline(pattern)) {
                newMatches.push(pattern);
                this.winlines.set(pattern, true);
            }
        });

        if (showCelebration && newMatches.length) {
            const contractType = this.config.parallelCelebration ? Parallel : Sequence;

            return new contractType([
                () => new Sequence([...newMatches.map((pattern, index) => () => this.animateLine(pattern, index))]),
                () => Components.get(SlingoCelebrationComponent).showCelebration(newMatches.length),
            ]);
        } else {
            return Contract.empty();
        }
    }

    /** Assumes that every symbol is already dabbed */
    public fullHouseAnim(): Contract {
        const patterns = this.getFullHouseAnimationPatterns();
        const patternsContracts: Array<() => Contract> = [
            () =>
                Contract.wrap(() => {
                    Services.get(SoundService).event((SlingoSoundEvent as any).full_house_animation);
                }),
        ];

        for (const pattern of patterns) {
            patternsContracts.push(() => this.getDabAnimPatternContracts(pattern));
        }

        return new Sequence(patternsContracts);
    }

    public isValueDabbed(value: number): boolean {
        const pos = this.getSymbolPositionFromValue(value);
        return this.isPositionDabbed(pos);
    }

    public isPositionDabbed(pos: Point): boolean {
        if (!this.dabs || !this.dabs[pos.x]) {
            return false;
        }
        return Boolean(this.dabs[pos.x][pos.y]);
    }

    public getValueFromPosition(x: number, y: number) {
        return this.gridData[x][y];
    }

    protected getDabAnimPatternContracts(pattern: number[][], subAnimDuration = 125, spacingMs = 65) {
        const patternContract: Array<Array<() => Contract>> = [];
        for (let y = 0; y < pattern.length; y++) {
            for (let x = 0; x < pattern[y].length; x++) {
                const dab = this.dabs[x][y];
                if (patternContract[pattern[x][y]]) {
                    patternContract[pattern[x][y]].push(() => pulse(dab, { x: 1.3, y: 1.3 }, subAnimDuration, Easing.Circular.InOut));
                } else {
                    patternContract[pattern[x][y]] = [() => pulse(dab, { x: 1.3, y: 1.3 }, subAnimDuration, Easing.Circular.InOut)];
                }
            }
        }
        return new Parallel(patternContract.map((subAnims, i) => () => Contract.getDelayedContract(spacingMs * i, () => new Parallel(subAnims))));
    }

    protected getSymbolPositionFromValue(value: number): Point | null {
        const x = this.gridData.findIndex(column => column.indexOf(value) !== -1);

        if (x === -1) {
            return null;
        }

        return new Point(x, this.gridData[x].lastIndexOf(value));
    }

    protected getDabSprite(): PIXIElement {
        // can be overridden if using animations, spines etc.
        const graphicsService = Services.get(GraphicsService);
        return graphicsService.createSprite("dab");
    }

    protected getDabPosition(pos: Point): DualPosition {
        return this.layer.getPosition(`symbol_${pos.x}_${pos.y}`);
    }

    protected getDabAnimationContract(dab: PIXIElement, instant: boolean): Contract {
        CenterPivot(dab);

        if (instant) {
            return Contract.empty();
        }

        dab.landscape.scale.set(1.5, 1.5);
        dab.portrait.scale.set(1.5, 1.5);

        return new Parallel([() => scaleIn(dab, 250, Easing.Back.Out), () => fadeIn(dab, 150)]);
    }

    protected getHighlightSprite(highlightType: SlingoHighlightType) {
        return Services.get(GraphicsService).createSprite(`highlight_${highlightType}`);
    }

    protected getHighlightPosition(pos: Point) {
        return this.layer.getPosition(`symbol_${pos.x}_${pos.y}`);
    }

    protected getHighlightAnimationContract(highlight: PIXIElement, highlightType: SlingoHighlightType) {
        return fadeIn(highlight, 250);
    }

    protected animateLine(pattern: string, index?: number): Contract {
        const values = this.getValuesForWinlinePattern(pattern);
        const positions = values.map(val => this.getSymbolPositionFromValue(val));

        const isVertical = positions.every(pos => pos.x === positions[0].x);
        positions.sort((a, b) => (isVertical ? a.y - b.y : a.x - b.x));

        const delay = 75;
        const contracts = positions.map((pos: Point, index: number) => {
            return () =>
                Contract.getDelayedContract(delay * index, () => {
                    const dab = this.dabs[pos.x][pos.y];
                    if (!dab) {
                        return Contract.empty();
                    }
                    this.layer.add(dab); // bring to top so it doesn't pulse under other dabs
                    return pulse(dab, { x: 1.2, y: 1.2 }, 150, Easing.Cubic.InOut);
                });
        });

        contracts.push(() =>
            Contract.wrap(() => {
                Services.get(SoundService).event((SlingoSoundEvent as any).slingo_animate_line);
                if (index !== undefined) {
                    Services.get(SoundService).event((SlingoSoundEvent as any).slingo_animate_line_N, index.toString());
                }
            })
        );

        return new Parallel(contracts);
    }

    protected reset() {
        this.gridData = null;
        this.winlines.clear();

        this.numbers.forEach(elements => elements.forEach(element => element.destroy()));
        this.numbers = [];

        this.dabs.forEach(elements => elements.forEach(element => element.destroy()));
        this.dabs = [];

        this.highlights.forEach(elements => elements.forEach(element => element.destroy()));
        this.highlights = [];
    }

    protected populateWinlines() {
        slingoModel.read().payoutConfig.patterns.forEach(pattern => {
            this.winlines.set(pattern, false);
        });
    }

    protected checkWinline(pattern: string): boolean {
        const values = this.getValuesForWinlinePattern(pattern);

        return values.every(val => {
            const pos = this.getSymbolPositionFromValue(val);
            return this.dabs[pos.x][pos.y];
        });
    }

    protected getValuesForWinlinePattern(pattern: string): number[] {
        const { rawTicketData } = gameState.getCurrentGame().getCurrentRecord() as SlingoRecord;
        const patternBoolArray = pattern.split("").map(char => Boolean(parseInt(char)));
        const lineValues: number[] = [];

        patternBoolArray.forEach((value, valueIndex) => {
            if (value) {
                lineValues.push(rawTicketData[valueIndex]);
            }
        });

        return lineValues;
    }

    protected getNumberSprite(value: number): Sprite {
        return Services.get(GraphicsService).createSprite("numbers/" + value);
    }

    protected getFullHouseAnimationPatterns(): number[][][] {
        return [
            [
                [0, 1, 2, 3, 4],
                [1, 2, 3, 4, 5],
                [2, 3, 4, 5, 6],
                [3, 4, 5, 6, 7],
                [4, 5, 6, 7, 8],
            ],
            [
                [8, 7, 6, 5, 4],
                [7, 6, 5, 4, 3],
                [6, 5, 4, 3, 2],
                [5, 4, 3, 2, 1],
                [4, 3, 2, 1, 0],
            ],
            [
                [0, 15, 14, 13, 12],
                [1, 16, 23, 22, 11],
                [2, 17, 24, 21, 10],
                [3, 18, 19, 20, 9],
                [4, 5, 6, 7, 8],
            ],
            [
                [24, 9, 10, 11, 12],
                [23, 8, 1, 2, 13],
                [22, 7, 0, 3, 14],
                [21, 6, 5, 4, 15],
                [20, 19, 18, 17, 16],
            ],
            [
                [0, 0, 0, 0, 0],
                [0, 1, 1, 1, 0],
                [0, 1, 2, 1, 0],
                [0, 1, 1, 1, 0],
                [0, 0, 0, 0, 0],
            ],
            [
                [2, 2, 2, 2, 2],
                [2, 1, 1, 1, 2],
                [2, 1, 0, 1, 2],
                [2, 1, 1, 1, 2],
                [2, 2, 2, 2, 2],
            ],
        ];
    }
}
