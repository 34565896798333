import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { Layers } from "appworks/graphics/layers/layers";
import { uiFlags, UIMenuFlag } from "appworks/ui/flags/ui-flags";
import { UIFlagState } from "./flags/ui-flag-state";

export class UIMenuButton {
    protected button: FlexiButton;
    protected enableState: UIFlagState;

    constructor(private layer: Layers, private name: string, flag: UIMenuFlag, offFlags?: UIMenuFlag[], enableState?: UIFlagState) {
        this.enableState = enableState;

        uiFlags.onFlagsUpdated.add(() => this.checkButtonEnabled());
        this.checkButtonEnabled();

        this.layer.onSceneEnter.add((scene: string) => {
            if (scene !== "default") {
                this.button = this.layer.getFlexiButton(this.name);

                if (this.button) {
                    this.checkButtonEnabled();

                    this.button.on(ButtonEvent.CLICK, () => {
                        uiFlags.menu(flag);

                        if (offFlags) {
                            offFlags.forEach((offFlag) => {
                                uiFlags.menu(offFlag, false);
                            });
                        }
                    });
                }
            }
        });
    }

    protected checkButtonEnabled() {
        if (this.enableState && this.button) {
            this.button.setEnabled(this.enableState.test());
        }
    }
}
