import { AnticipationSupplement } from "slotworks/model/gameplay/supplements/anticipation-supplement";
import { DataProcessorSupplement } from "slotworks/model/gameplay/supplements/data-processor-supplement";
import { FreespinSymbolSubstituteSupplement } from "slotworks/model/gameplay/supplements/freespin-symbol-substitute-supplement";
import { GridOnlySupplement } from "slotworks/model/gameplay/supplements/grid-only-supplement";

export class DataProcessorSupplementCollection {
    private dataProcessorSupplements: DataProcessorSupplement[] = [
        new FreespinSymbolSubstituteSupplement(),
        new AnticipationSupplement(),
        new GridOnlySupplement()
    ];

    public addAfterStandard(dataProcessorSupplement: DataProcessorSupplement) {
        this.dataProcessorSupplements.push(dataProcessorSupplement);
    }

    public addBeforeStandard(dataProcessorSupplement: DataProcessorSupplement) {
        this.dataProcessorSupplements.unshift(dataProcessorSupplement);
    }

    public remove(dataProcessorSupplement: DataProcessorSupplement) {
        const supplementIndex = this.dataProcessorSupplements.indexOf(dataProcessorSupplement);
        if (supplementIndex > -1) {
            this.dataProcessorSupplements.splice(supplementIndex, 1);
        } else {
            throw new Error("Attempting to remove a data process supplement which does not exist");
        }
    }

    public get() {
        return this.dataProcessorSupplements;
    }

    public getSupplement<T extends DataProcessorSupplement>(type: { new(...args: any[]): T }): T {
        return this.dataProcessorSupplements.find((processor) => processor instanceof type) as T;
    }
}
