import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { gameState } from "appworks/model/game-state";
import { Record } from "appworks/model/gameplay/records/record";
import { Services } from "appworks/services/services";
import { clearElement } from "appworks/utils/dom-utils";
import { freespinRecordDisplay } from "./record-displays/freespin-record-display";
import { pickMatchRecordDisplay } from "./record-displays/pick-match-record-display";
import { pickOneRecordDisplay } from "./record-displays/pick-one-record-display";
import { spinRecordDisplay } from "./record-displays/spin-record-display";

export type RecordDisplayFunction = (record: Record) => HTMLDivElement;

export class RecordDisplay {

    private element: HTMLDivElement;
    private displayFunctionMap: Map<string, RecordDisplayFunction> = new Map();

    constructor(customDisplayFunctions: Map<string, RecordDisplayFunction>) {
        this.displayFunctionMap.set("TumbleRecord", spinRecordDisplay);
        this.displayFunctionMap.set("SpinRecord", spinRecordDisplay);
        this.displayFunctionMap.set("FreespinRecord", freespinRecordDisplay);
        this.displayFunctionMap.set("PickMatchRecord", pickMatchRecordDisplay);
        this.displayFunctionMap.set("PickOneRecord", pickOneRecordDisplay);

        customDisplayFunctions.forEach((value, key) => {
            this.displayFunctionMap.set(key, value);
        });

        this.draw();
    }

    public show(record: Record) {
        clearElement(this.element);

        const infoElement = document.createElement("div");
        this.element.appendChild(infoElement);

        infoElement.innerHTML = "<br>Record type: " + record.id;
        if (gameState.getCurrentGame().id) {
            infoElement.innerHTML += `(<span style="user-select: all !important">${gameState.getCurrentGame().id}</span>)`;
        }
        infoElement.innerHTML += "<br>Record bet: " + record.wager.toString();
        infoElement.innerHTML += "<br>Record win: " + record.cashWon.toString();
        infoElement.innerHTML += "<br>Total bet: " + gameState.getCurrentGame().getTotalWagered();
        infoElement.innerHTML += "<br>Total win: " + gameState.getCurrentGame().getTotalWin();

        let displayElement: HTMLElement;
        if (this.displayFunctionMap.has(record.id)) {
            displayElement = this.displayFunctionMap.get(record.id)(record);
        } else {
            displayElement = document.createElement("div");
            displayElement.innerHTML = "No display implementation";
        }

        this.element.appendChild(displayElement);
    }

    public clear() {
        clearElement(this.element);
        const displayElement = document.createElement("div");
        displayElement.innerHTML = "";
        this.element.appendChild(displayElement);
    }

    public toggle() {
        if (this.element.parentElement) {
            Services.get(CanvasService).rootNode.removeChild(this.element);
        } else {
            Services.get(CanvasService).rootNode.appendChild(this.element);
        }
    }

    private draw() {
        this.element = document.createElement("div");
        this.element.id = "integrationDebugger_recordDisplay";

        this.element.style.boxSizing = "border-box";
        this.element.style.position = "fixed";
        this.element.style.top = "0";
        this.element.style.left = "20vw";
        this.element.style.zIndex = "900";
        this.element.style.width = "60vw";
        this.element.style.height = "50vh";
        this.element.style.paddingTop = "1vh";
        this.element.style.paddingLeft = "3vw";
        this.element.style.overflowY = "scroll";

        this.element.style.backgroundColor = "#222";
        this.element.style.border = "solid 1px white";

        this.element.style.color = "#fff";
        this.element.style.fontSize = "20px";

        Services.get(CanvasService).rootNode.appendChild(this.element);
    }
}
