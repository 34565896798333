import { Layers } from "appworks/graphics/layers/layers";
import { Container } from "appworks/graphics/pixi/container";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { Position } from "appworks/graphics/pixi/position";
import { Sprite } from "appworks/graphics/pixi/sprite";

/**
 * This module is intended to provide information enabling the positioning of
 * objects, relative to potentially numerous other layers in the PSD.
 *
 * I.e: Aligning images to symbols, which have a mryiad of potential related
 * layers, any of which could act as the symbol's defined border.
 *
 * Simple usecases will only need to utilise the `set` function which will
 * automatically repsition your target sprite.
 */

/**
 * Returns a dual position which has a ratio for it's width and height.
 */
export function getOffsetPosition(layer: Layers, masterPosition: string, positions: string[] | string) {

    const masterRect = layer.getPosition(masterPosition);
    const staticRect = getFirstValidPosition(layer, positions);
    const offsetRect = new DualPosition(new Position(), new Position());

    if (staticRect) {
        offsetRect.landscape.x = staticRect.landscape.x - masterRect.landscape.x;
        offsetRect.landscape.y = staticRect.landscape.y - masterRect.landscape.y;
        offsetRect.landscape.width = staticRect.landscape.width / masterRect.landscape.width;
        offsetRect.landscape.height = staticRect.landscape.height / masterRect.landscape.height;
        offsetRect.portrait.x = staticRect.portrait.x - masterRect.portrait.x;
        offsetRect.portrait.y = staticRect.portrait.y - masterRect.portrait.y;
        offsetRect.portrait.width = staticRect.portrait.width / masterRect.portrait.width;
        offsetRect.portrait.height = staticRect.portrait.height / masterRect.portrait.height;
    }

    return offsetRect;
}

/**
 * Iterates thought the list of positions and uses the first one that exists as
 * a refernce for the position of the passed sprite.
 *
 * @method offsetPosition
 */
export function offsetPosition(sprite: Sprite| Container, layer: Layers, masterPosition: string, positions: string[] | string) {

    const offsetRect = getOffsetPosition(layer, masterPosition, positions);

    sprite.landscape.x += offsetRect.landscape.x;
    sprite.landscape.y += offsetRect.landscape.y;
    sprite.landscape.width *= offsetRect.landscape.width;
    sprite.landscape.height *= offsetRect.landscape.height;
    sprite.portrait.x += offsetRect.portrait.x;
    sprite.portrait.y += offsetRect.portrait.y;
    sprite.portrait.width *= offsetRect.portrait.width;
    sprite.portrait.height *= offsetRect.portrait.height;
}

/**
 * finds the first position that exists and returns it.
 *
 * @method getFirstValidPosition
 */
export function getFirstValidPosition(layer: Layers, positions: string[] | string): DualPosition {
    if (!Array.isArray(positions)) {
        positions = [positions];
    }

    const validPosition = positions.find((positionName) => typeof layer.getPosition(positionName) !== "undefined");
    return layer.getPosition(validPosition);
}
