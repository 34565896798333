import { Gameplay } from "appworks/model/gameplay/gameplay";
import { InitRequestPayload } from "appworks/model/gameplay/requests/init-request-payload";
import { RequestBuilder } from "appworks/server/builders/request-builder";
import { AxiosRequestConfig } from "axios";

export class GMRInitRequestBuilder implements RequestBuilder {
    public filter(data: any): boolean {
        return data instanceof InitRequestPayload;
    }

    public build(gameplay: Gameplay, data: any) {
        return {
            method: "get",
            data: {}
        } as AxiosRequestConfig;
    }

}
