import { Layers } from "appworks/graphics/layers/layers";
import { Contract } from "appworks/utils/contracts/contract";
import { Point } from "appworks/utils/geom/point";
import { Timer } from "appworks/utils/timer";
import { clone } from "lodash";
import { Signal } from "signals";
import { ReelSubcomponent } from "slotworks/components/matrix/reel/reel-subcomponent";
import { AbstractReelTransition } from "slotworks/components/matrix/reel/transition-behaviours/abstract-reel-transition";
import { AbstractSymbolBehaviour, DefaultSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/abstract-symbol-behaviour";
import { SymbolComponentType, SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";
import { AnticipationResult } from "slotworks/model/gameplay/records/results/anticipation-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { slotDefinition } from "slotworks/model/slot-definition";
import { AbstractMatrixComponent } from "./abstract-matrix-component";
import { SpinReelTransition } from "slotworks/components/matrix/reel/transition-behaviours/spin/spin-reel-transition";

export class MatrixComponent<T extends SymbolSubcomponent = SymbolSubcomponent> extends AbstractMatrixComponent {
    public readonly startSignal: Signal;
    public readonly stopSignal: Signal;
    public readonly landSignal: Signal;
    public readonly skipSignal: Signal;
    // Dispatched when the first anticipation reel starts
    public readonly onAnticipationStart: Signal = new Signal();
    // Dispatched for each reel as it begins to anticipate
    public readonly onAnticipationReel: Signal = new Signal();
    // Dispatched once when anticipation ends for good
    public readonly onAnticipationEnd: Signal = new Signal();
    // z-sort symbols based on game-config zIndex. Will ignore other items on matrixLayer
    public zSortEnabled: boolean = true;
    // By default symbols z-sort right-to-left, bottom-to-top
    public zSortLeftToRight: boolean = false;
    public zSortTopToBottom: boolean = false;
    public stickyLayer: Layers;
    public lockedReels: number[][] = [];
    public hideSymbolsBehindStickySymbols: boolean = true;
    public matrixLayer: Layers;
    public animationLayer: Layers;
    public heldReels: number[] = [];

    protected defaultSymbolBehaviourGenerators: DefaultSymbolBehaviour[] = [];
    protected reelTransition: AbstractReelTransition;
    protected reels: ReelSubcomponent<T>[];
    protected stuckSymbols: Map<string, T> = new Map<string, T>();
    /**
     * Array of symbols used only for transitions (ie reel spins)
     */
    protected hiddenSymbols: T[];
    protected defaultStops: number[];
    protected defaultReelset: string[][];
    protected currentStops: number[];
    protected currentReelset: string[][];
    protected updateTimer: number;

    constructor(
        grid: number[],
        stops: number[],
        reelset: string[][],
        matrixLayer?: Layers,
        animationLayer?: Layers,
        public readonly symbolType: SymbolComponentType<T> = SymbolSubcomponent as any
    ) {
        super(grid, stops, reelset, matrixLayer, animationLayer, symbolType);

        this.matrixLayer = matrixLayer || Layers.get("MatrixContent");
        this.animationLayer = animationLayer || Layers.get("SymbolAnimations");

        this.startSignal = new Signal();
        this.stopSignal = new Signal();
        this.landSignal = new Signal();
        this.skipSignal = new Signal();
        this.onAnticipationStart = new Signal();
        this.onAnticipationEnd = new Signal();

        this.change("default", grid, stops, reelset);

        this.matrixLayer.enableBoundsMask(true);

        this.updateTimer = Timer.setInterval(() => this.update(), 0);

        this.matrixLayer.hide();
    }

    public init() {
        this.matrixLayer.show();
    }

    public addDefaultSymbolBehaviour(behaviourGenerator: (symbol: T) => AbstractSymbolBehaviour, validSymbols?: string[], invalidSymbols?: string[]) {
        const defaultSymbolBehaviour = { behaviourGenerator, validSymbols, invalidSymbols };
        this.defaultSymbolBehaviourGenerators.push(defaultSymbolBehaviour);

        const allSymbols = [];
        this.reels.forEach((reel) => allSymbols.push(...reel.getSymbols()));
        for (const symbol of allSymbols) {
            const behaviour = behaviourGenerator(symbol);
            behaviour.validSymbols = defaultSymbolBehaviour.validSymbols;
            behaviour.invalidSymbols = defaultSymbolBehaviour.invalidSymbols;

            symbol.addBehaviour(behaviour);
            symbol.refresh();
        }
    }

    public reset() {
        this.jump(this.currentStops, this.currentReelset);
        this.postProcessReels();
    }

    // Force an update outside of the game loop tick
    public flush() {
        this.postProcessReels();
    }

    public getTransition<T extends AbstractReelTransition = SpinReelTransition>() : T {
        return this.reelTransition as T;
    }

    public setTransition(reelTransition: AbstractReelTransition) {
        if (this.reelTransition) {
            this.reelTransition.destroy();
        }
        this.reelTransition = reelTransition;
        this.reelTransition.init(this, this.reels, this.defaultStops, this.defaultReelset);
        this.reelTransition.onReelLand.add(this.onReelLand);
        this.reelTransition.onReelComplete.add(this.onReelComplete);
        this.reelTransition.onAnticipationStart.add((reelIndex: number) => {
            this.onAnticipationStart.dispatch(reelIndex);
        });
        this.reelTransition.onAnticipationReel.add((reelIndex: number) => {
            this.onAnticipationReel.dispatch(reelIndex);
        });
        this.reelTransition.onAnticipationEnd.add((reelIndex: number) => {
            this.onAnticipationEnd.dispatch(reelIndex);
        });
    }

    public startTransition(jumpStart: boolean = false, quickSpin: boolean = false): Contract<void> {
        for (const reel of this.reels) {
            if (!this.heldReels || this.heldReels.indexOf(reel.index) === -1) {
                reel.spin().execute();
            }
        }

        this.startSignal.dispatch();

        this.resetSymbols();

        return this.reelTransition.start(null, jumpStart, quickSpin);
    }

    public stopTransition(spinRecord: SpinRecord, quickSpin: boolean = false): Contract<void> {
        return new Contract<void>((resolve) => {
            this.currentStops = spinRecord.stops;
            this.currentReelset = spinRecord.reelset ?? this.currentReelset ?? this.defaultReelset;

            const anticipations: AnticipationResult[] = spinRecord.getResultsOfType(AnticipationResult);
            const anticipationResult: AnticipationResult = anticipations[0];

            for (let reel = 0; reel < this.reels.length; reel++) {
                if (anticipationResult && anticipationResult.landSymbols) {
                    this.reels[reel].validLandSymbols = anticipationResult.landSymbols[reel];
                }
            }

            this.reelTransition.stop(spinRecord, quickSpin).then(() => {
                this.postProcessReels();
                resolve(null);
                this.stopSignal.dispatch();
            });
        });
    }

    public skipTransition(spinRecord: SpinRecord): void {
        this.skipSignal.dispatch();

        this.currentStops = spinRecord.stops;
        this.currentReelset = spinRecord.reelset ?? this.currentReelset ?? this.defaultReelset;

        const anticipations: AnticipationResult[] = spinRecord.getResultsOfType(AnticipationResult);
        const anticipation: AnticipationResult = anticipations[0];
        if (anticipation) {
            for (let reel = 0; reel < this.reels.length; reel++) {
                this.reels[reel].validLandSymbols = anticipation.landSymbols[reel];
            }
        }

        this.reelTransition.skip(spinRecord);
    }

    public jumpToGrid(grid: string[][]) {
        const stops = [];
        const reelset: string[][] = [];
        for (let reelIndex = 0; reelIndex < grid.length; reelIndex++) {
            const reel = grid[reelIndex];
            stops.push(0);
            reelset.push([...reel, ...this.currentReelset[reelIndex]]);
        }

        this.jump(stops, reelset);
    }

    public jump(stops: number[], reelset: string[][]) {
        this.currentStops = stops;
        this.currentReelset = reelset;

        this.reelTransition.jump(stops, reelset);

        for (let i = 0; i < stops.length; i++) {
            this.reels[i].jump(stops[i], reelset[i]);
        }

        this.postProcessReels();
    }

    public getReels(): ReelSubcomponent<T>[] {
        return this.reels;
    }

    /**
     * Gets a symbol, (0 indexed)
     * For example getSymbol(4,0) will return the top right symbol in a 5x3 slot
     */
    public getSymbol(x: number, y: number): T {
        if (y < 0 || !this.reels[x] || !this.reels[x].getSymbolAt(y)) {
            // Symbol isn't in "standard" grid, so search hidden symbols
            for (const hiddenSymbol of this.hiddenSymbols) {
                if (hiddenSymbol.gridPosition.x === x && hiddenSymbol.gridPosition.y === y) {
                    return hiddenSymbol;
                }
            }

            return null;
        }
        return this.reels[x].getSymbolAt(y);
    }

    public getSymbolsFromPositions(positions: Point[]): T[] {
        const symbols = [];

        for (const position of positions) {
            const symbol = this.getSymbol(position.x, position.y);
            symbols.push(symbol);
        }

        return symbols;
    }

    /**
     * Returns ALL symbols. Hidden, natural, added sticky - literally all
     */
    public getAllSymbols() {
        return this.getGridSymbols().concat(this.getHiddenSymbols());
    }

    /**
     * Gets all grid symbols, including added sticky symbols
     */
    public getGridSymbols(): T[] {
        const symbols: T[] = this.getBaseGridSymbols();

        if (this.stuckSymbols) {
            this.stuckSymbols.forEach((stuckSymbol) => {
                symbols.push(stuckSymbol);
            });
        }

        return symbols;
    }

    /**
     * Gets this symbols which are not considered part of the win area
     */
    public getHiddenSymbols() {
        return this.hiddenSymbols;
    }

    /**
     * Gets only the symbols on the natural grid, excluding things such as sticky symbols
     */
    public getBaseGridSymbols(): T[] {
        let symbols: T[] = [];

        for (const reel of this.reels) {
            symbols = symbols.concat(reel.getSymbols());
        }

        return symbols;
    }

    public resetSymbolPositions() {
        for (const reel of this.reels) {
            reel.resetSymbolPositions();
        }

        this.postProcessReels();
    }

    public addHiddenSymbol(symbol: T) {
        if (this.hiddenSymbols.indexOf(symbol) === -1) {
            this.hiddenSymbols.push(symbol);
            this.reels[symbol.gridPosition.x].addHiddenSymbol(symbol);
            this.setDefaultSymbolBehaviours(symbol);
        }
    }

    public resetSymbols() {

        for (const symbol of this.getAllSymbols()) {
            symbol.setVisible(true);
            symbol.static();
        }

        this.postProcessReels();
    }

    public addStuckSymbol(symbol: T) {
        return this.addStuckSymbolById(symbol.gridPosition, symbol.symbolId);
    }

    public addStuckSymbolById(gridPosition: Point, symbolId: string) {
        const id = `symbol_${gridPosition.x}_${gridPosition.y}`;

        if (this.stuckSymbols.has(id)) {
            const existingSticky = this.stuckSymbols.get(id);
            existingSticky.destroy();
        }

        const stuckSymbol = new this.symbolType(gridPosition.clone(), this.matrixLayer, this.animationLayer);
        if (stuckSymbol.symbolExists(symbolId + "_stuck")) {
            stuckSymbol.setSymbol(symbolId + "_stuck");
        } else {
            stuckSymbol.setSymbol(symbolId);
        }

        const positionRect = this.matrixLayer.getPosition(id);

        stuckSymbol.setTransform(positionRect);
        stuckSymbol.setLayer(this.stickyLayer || this.animationLayer);
        stuckSymbol.lockLayer();
        stuckSymbol.updateTransform();

        this.setDefaultSymbolBehaviours(stuckSymbol);

        this.stuckSymbols.set(id, stuckSymbol);

        this.postProcessReels();

        return stuckSymbol;
    }

    public getStuckSymbol(gridPosition: Point) {
        const id = `symbol_${gridPosition.x}_${gridPosition.y}`;

        return this.stuckSymbols.get(id);
    }

    public getStuckSymbols() {
        return this.stuckSymbols;
    }

    public removeStuckSymbol(gridPosition: Point) {
        const stickySymbol = this.getStuckSymbol(gridPosition);
        if (stickySymbol) {
            const id = `symbol_${gridPosition.x}_${gridPosition.y}`;

            stickySymbol.destroy();
            this.stuckSymbols.delete(id);

            this.resetSymbols();
        }
    }

    /**
     * replaceBase (default false) if true, set the symbol id of the symbol under the sticky symbol to the sticky symbol id
     */
    public clearStuckSymbols(replaceBase: boolean = false) {
        this.stuckSymbols.forEach((symbol: T) => {
            if (replaceBase) {
                const baseSymbol = this.getSymbol(symbol.gridPosition.x, symbol.gridPosition.y);
                baseSymbol.setSymbol(symbol.symbolId);
            }
            symbol.destroy();
        });

        this.stuckSymbols.clear();
        this.resetSymbols();
    }

    public hold(reelIndexes?: number[]) {
        this.heldReels = reelIndexes;
        this.reelTransition.hold(reelIndexes);
    }

    public change(scene: string, grid: number[], stops?: number[], reelset?: string[][]) {

        this.matrixLayer.setScene(scene, null, true).execute();

        if (stops) {
            this.defaultStops = this.currentStops = stops;
        } else if (this.currentStops) {
            this.defaultStops = this.currentStops;
        }
        if (reelset) {
            this.defaultReelset = this.currentReelset = reelset;
        } else if (this.currentReelset) {
            this.defaultReelset = this.currentReelset;
        }

        // If resizing, keep existing symbols
        for (let x = 0; x < grid.length; x++) {
            if (this.reels && x < this.reels.length) {
                const sizeDiff = grid[x] - this.reels[x].size;
                this.defaultStops[x] -= sizeDiff;
                if (this.defaultStops[x] < 0) {
                    this.defaultStops[x] += this.defaultReelset[x].length;
                }
                if (this.defaultStops[x] >= this.defaultReelset[x].length) {
                    this.defaultStops[x] -= this.defaultReelset[x].length;
                }
            }
        }

        this.clearHiddenSymbols();

        // Create reels
        this.reels = [];

        for (let x = 0; x < grid.length; x++) {
            const reelstrip = this.defaultReelset[x];
            const defaultStop: number = this.defaultStops[x];
            const reel = new ReelSubcomponent(x, grid[x], reelstrip, defaultStop, this.matrixLayer, this.animationLayer, this.symbolType);
            this.reels.push(reel);
        }

        // Update transitions if they have already been set
        if (this.reelTransition) {
            this.reelTransition.init(this, this.reels, this.defaultStops, this.defaultReelset);
        }

        // Add default symbol behaviours if they exist
        for (const symbol of this.getAllSymbols()) {
            this.setDefaultSymbolBehaviours(symbol);
        }

        this.postProcessReels();
    }

    public setCustomReelset(reelset: string[][]) {
        this.getTransition().setCustomReelset(reelset);
    }

    public resetReelsetToDefault() {
        this.getTransition().resetReelsetToDefault();
    }

    public getDefaultReelset() {
        return this.defaultReelset;
    }

    public getCurrentReelset(): string[][] {
        return this.currentReelset;
    }

    public getCurrentStops() {
        return clone(this.currentStops);
    }

    public destroy() {
        this.startSignal.removeAll();
        this.stopSignal.removeAll();
        this.landSignal.removeAll();
        this.onAnticipationStart.removeAll();
        this.onAnticipationEnd.removeAll();
        this.clearStuckSymbols();
        this.clearHiddenSymbols();
        this.hiddenSymbols = null;
        this.reelTransition = null;
        this.reels = null;
        this.defaultStops = null;
        this.defaultReelset = null;
        Timer.clearInterval(this.updateTimer);
    }

    protected clearHiddenSymbols() {
        if (this.reels) {
            this.reels.forEach((reel) => reel.clearHiddenSymbols());
        }
        this.hiddenSymbols = [];
    }

    protected onReelLand = (reelIndex: number) => {
        const reel = this.reels[reelIndex];
        reel.land().execute();

        this.landSignal.dispatch(reelIndex);

        this.postProcessReels();
    }

    protected onReelComplete = (reelIndex: number) => {
        const reel = this.reels[reelIndex];
        reel.complete().execute();
    }

    protected update() {
        this.reelTransition.update();

        this.postProcessReels();

        this.reelTransition.postUpdate();
    }

    protected postProcessReels() {

        this.clearSymbolProcessing();

        this.processLockedReels();
        this.processStackedSymbols();
        this.processWideSymbols();
        this.processStickyWilds();
        this.processHiddenReels();

        if (this.zSortEnabled) {
            this.zSort();
        }

        this.getAllSymbols().forEach((symbol) => {
            symbol.updateTransform();
        });
    }

    protected clearSymbolProcessing() {
        this.getAllSymbols().forEach((symbol) => {
            symbol.offsetStack(0);
            symbol.setIsStackPart(false);
            symbol.setIsWidePart(false);
            symbol.setProxy(null);
        });
    }

    protected processLockedReels() {
        this.lockedReels.forEach((lockedReels) => {
            const masterReelIndex = lockedReels[0];
            const slaveReelIndexes = [...lockedReels];
            slaveReelIndexes.shift();

            slaveReelIndexes.forEach((x) => {
                const reel = this.reels[x];
                for (let y = -2; y < reel.size + 1; y++) {

                    const copySymbol = this.getSymbol(x, y);
                    if (!copySymbol) {
                        continue;
                    }
                    const masterSymbol = this.getSymbol(masterReelIndex, y);

                    copySymbol.setSymbol(masterSymbol.symbolId);

                    copySymbol.getTransform().landscape.y = masterSymbol.getTransform().landscape.y;
                    copySymbol.getTransform().portrait.y = masterSymbol.getTransform().portrait.y;
                }
            });
        });
    }

    protected processStackedSymbols() {
        for (let x = 0; x < this.reels.length; x++) {
            const reel = this.reels[x];
            let topOfStackWithinGrid: T | null = null;
            for (let y = -2; y < reel.size + 1; y++) {
                const symbol = this.getSymbol(x, y);
                if (!symbol) {
                    continue;
                }
                const symbolDef = slotDefinition.getSymbolDefinition(symbol.symbolId);
                // Found the top of a stack
                if (symbolDef.height > 1) {
                    if (y >= 0) {
                        topOfStackWithinGrid = symbol;
                    }
                    const topOfStack = y;
                    const symbolsInStack = [symbol];
                    // Check if it's the true top or if it's only half in view
                    let visibleSize = 1;
                    for (let rowBelow = y + 1; rowBelow < Math.min(reel.size + 1, y + symbolDef.height); rowBelow++) {
                        const symbolBelow = this.getSymbol(x, rowBelow);
                        if (!symbolBelow) {
                            continue;
                        }
                        if (symbolBelow.symbolId === symbol.symbolId) {
                            // Found more stack
                            visibleSize++;
                            symbolsInStack.push(symbolBelow);
                            // Hide stack piece and skip processing it
                            symbolBelow.setIsStackPart(true);
                            // If the top of the stack is above the visible area, we need to keep a reference of the top of the visible area to proxy behaviours to
                            if (!topOfStackWithinGrid && rowBelow >= 0) {
                                topOfStackWithinGrid = symbolBelow;
                            }
                            y++;
                        } else {
                            break;
                        }
                    }
                    // If it's not cut off at the bottom, it means it's cut off at the top, so we offset
                    if (topOfStack + visibleSize < reel.size) {
                        // If it's not cut off at the bottom, it means it's cut off at the top, so we offset
                        symbol.offsetStack(symbolDef.height - visibleSize);
                        // Also offset top of stack within visible grid used for symbol behaviours
                        if (topOfStackWithinGrid && topOfStackWithinGrid !== symbol) {
                            topOfStackWithinGrid.offsetStack(symbolDef.height - visibleSize - topOfStack);
                        }
                    }
                    symbolsInStack.forEach((symbolInStack) => {
                        if (symbolInStack !== topOfStackWithinGrid) {
                            symbolInStack.setProxy(topOfStackWithinGrid);
                        }
                    });
                }
                // Sticky symbols
                this.stuckSymbols.forEach((stuckSymbol: T) => {
                    if (stuckSymbol.gridPosition.x === symbol.gridPosition.x && stuckSymbol.gridPosition.y === symbol.gridPosition.y) {
                        symbol.setVisible(false);
                        symbol.setProxy(stuckSymbol);
                    }
                });
                if (!reel.getVisible()) {
                    symbol.setVisible(false);
                }
                symbol.updateTransform();
            }
        }
    }

    protected processWideSymbols() {
        for (let y = -2; y < this.reels[0].size + 1; y++) {
            for (let x = 0; x < this.reels.length; x++) {
                const symbol = this.getSymbol(x, y);
                if (!symbol) {
                    continue;
                }
                const symbolDef = slotDefinition.getSymbolDefinition(symbol.symbolId);

                if (symbolDef.width > 1) {
                    let wideWidth = symbolDef.width - 1;
                    while (wideWidth > 0) {
                        wideWidth--;
                        x++;
                        const wideSymbolPart = this.getSymbol(x, y);
                        if (wideSymbolPart) {
                            wideSymbolPart.setIsWidePart(true);
                            wideSymbolPart.setSymbol(symbolDef.name);
                        }
                    }
                }
            }
        }
    }

    protected processStickyWilds() {
        for (let x = 0; x < this.reels.length; x++) {
            const reel = this.reels[x];

            for (let y = -2; y < reel.size + 1; y++) {
                const symbol = this.getSymbol(x, y);
                if (!symbol) {
                    continue;
                }

                // Sticky symbols
                this.stuckSymbols.forEach((stuckSymbol: T) => {
                    if (stuckSymbol.gridPosition.x === symbol.gridPosition.x && stuckSymbol.gridPosition.y === symbol.gridPosition.y) {
                        symbol.setVisible(!this.hideSymbolsBehindStickySymbols);
                        symbol.setProxy(stuckSymbol);
                    }
                });
            }
        }
    }

    protected processHiddenReels() {
        for (let x = 0; x < this.reels.length; x++) {
            const reel = this.reels[x];
            for (let y = -2; y < reel.size + 1; y++) {
                const symbol = this.getSymbol(x, y);
                if (!reel.getVisible()) {
                    symbol.setVisible(false);
                }
            }
        }
    }

    protected zSort() {
        const orderedSymbols: T[][] = [];

        const allSymbols: T[] = [];

        let reels = [...this.reels];

        // Get symbols sorted according to reel and row, as specified
        if (this.zSortLeftToRight) {
            reels = reels.reverse();
        }

        reels.forEach((reel) => {
            let reelSymbols = [...this.getHiddenSymbols(), ...reel.getSymbols()];
            if (this.zSortTopToBottom) {
                reelSymbols = reelSymbols.reverse();
            }
            reelSymbols.forEach((symbol) => {
                allSymbols.push(symbol);
            });
        });

        // Create z-index array
        allSymbols.forEach((symbol) => {
            const zIndex = symbol.symbolDefinition.zIndex || 0;
            if (!orderedSymbols[zIndex]) {
                orderedSymbols[zIndex] = [];
            }

            orderedSymbols[zIndex].push(symbol);
        });

        // Order symbols by z-index array
        orderedSymbols.forEach((symbols) => {
            if (symbols && symbols.length) {
                symbols.forEach((symbol) => {
                    symbol.moveToTop();
                });
            }
        });
    }

    protected setDefaultSymbolBehaviours(symbol: T) {
        for (const defaultSymbolBehaviour of this.defaultSymbolBehaviourGenerators) {
            const behaviour = defaultSymbolBehaviour.behaviourGenerator(symbol);
            behaviour.validSymbols = defaultSymbolBehaviour.validSymbols;
            behaviour.invalidSymbols = defaultSymbolBehaviour.invalidSymbols;

            symbol.addBehaviour(behaviour);
            symbol.refresh();
        }
    }
}
