import { Layers } from "appworks/graphics/layers/layers";
import { uiFlags, UIMenuFlag } from "appworks/ui/flags/ui-flags";
import { SignalBinding } from "signals";

export class UIToggle {
    protected toggleBinding: SignalBinding;
    protected flagBinding: SignalBinding;

    constructor(private layer: Layers, private name: string, flag: UIMenuFlag, offFlags?: UIMenuFlag[]) {
        this.layer.onSceneEnter.add((scene: string) => {
            if (scene !== "default") {
                const toggle = this.layer.getToggle(this.name);

                if (toggle) {
                    if (this.toggleBinding) {
                        this.toggleBinding.detach();
                    }

                    if (this.flagBinding) {
                        this.flagBinding.detach();
                    }

                    this.flagBinding = uiFlags.onFlagsUpdated.add(() => {
                        toggle.setChecked(!uiFlags.has(flag));
                    });

                    this.toggleBinding = toggle.onChanged.add((toggledOn: boolean) => {
                        uiFlags.menu(flag, toggledOn);

                        if (offFlags) {
                            offFlags.forEach((offFlag) => {
                                uiFlags.menu(offFlag, false);
                            });
                        }
                    });
                }
            }
        });
    }
}
