import { Layers } from "appworks/graphics/layers/layers";
import { SkipAreaControl } from "appworks/ui/controls/standard/shape/skip-area-control";
import { ControlFactory } from "../control-factory";

export class SkipAreaControlFactory implements ControlFactory {
    public filter(layer: Layers) {
        if (layer.getShape("skipArea")) {
            return true;
        }

        return false;
    }

    public build(layer: Layers) {
        return [new SkipAreaControl(layer.getShape("skipArea"))];
    }
}
