import { Point } from "appworks/utils/geom/point";
import { BitmapText as PIXIBitmapText, Renderer } from "pixi.js";
import { RenderOrientation } from "./render-orientation";
import { TextPosition } from "./text-position";

export class BitmapText extends PIXIBitmapText {

    // TODO: V6 - Probably enable this by default. It keeps bitmap text from jiggling when coutning up fast
    public static ENABLE_STEADYING: boolean = false;

    // These currency codes have full stops or other common bitmap font characters in, which would mean that
    // these codes show up incorrectly (due to half characters being missing).
    // These should be removed entirely if found.
    //                                             ISK, PEN, CHF, CHF, LRD, RUB, LBP, OMR, QAR, SYP
    public static mixedCurrencyCodes: string[] = ["kr.", "S/. ", "SFr.", "Fr.", "L$", "р.", "ل.ل", "ر.ق", "ر.ع.", "£S"];

    public landscape: TextPosition = new TextPosition();
    public portrait: TextPosition = new TextPosition();

    public autoShrink: boolean = true;

    public boundsWidth: number;
    public boundsHeight: number;

    private lastPosition: TextPosition;

    private scaling: boolean;

    private steadyWidth: number = 0;

    constructor(text: string, protected style: {
        fontName: string;
        fontSize?: number;
        align?: string;
        tint?: number;
        letterSpacing?: number;
        maxWidth?: number;
    }) {
        super(text, style);
    }

    public clone(): BitmapText {
        const text = new BitmapText(this.text, this.style);

        text.name = this.name; // TODO 6.x: remove this line, it can screw up and cause slotworks to grab the incorrent reference if there are multiple objects with the same name

        text.landscape = this.landscape.clone();
        text.portrait = this.portrait.clone();

        return text;
    }

    public setVisible(visible: boolean): void {
        this.visible = visible;
    }

    public setText(text: string): void {
        this.text = text;
    }

    public setAutoResize(autoresize: boolean): void {
        this.autoShrink = autoresize;
    }

    public updateTransform() {
        const position = this.getOrientationPosition();

        if (!position.equals(this.lastPosition)) {
            this.fontSize = position.fontSize;
        }

        if (!position.equals(this.lastPosition) || this.dirty) {
            this.lastPosition = position.clone();
            this.resizeBounds(position.width, position.height, this.fontSize);
            this.updateText();
            this.move(position.x, position.y, position.scale, position.align);

            super.scale.x = position.scale.x;
            super.scale.y = position.scale.y;
        }

        super.updateTransform();
    }

    public _render(renderer: Renderer) {
        if (this.getOrientationPosition().unavailable) {
            return;
        }

        return super._render(renderer);
    }

    public move(x: number, y: number, scale: { x: number, y: number }, align: string) {
        this.x = x;
        this.y = y;

        let anchor;

        // Alignment
        if (align === "center") {
            anchor = new Point(0.5, 0.5);
        } else if (align === "right") {
            anchor = new Point(1, 0.5);
        } else {
            anchor = new Point(0, 0.5);
        }

        // If the size hasn't changed by much, don't bother realigning it as it will appear as jiggling text when counting up a value
        if (BitmapText.ENABLE_STEADYING) {
            if (Math.abs(this.steadyWidth - this.textWidth) > this.fontSize * 0.5) {
                this.steadyWidth = this.textWidth;
            }
            this.x += (this.boundsWidth - this.steadyWidth * scale.x) * anchor.x;
        } else {
            this.x += (this.boundsWidth - this.textWidth * scale.x) * anchor.x;
        }
        this.y += (this.boundsHeight - this.textHeight * scale.y) * anchor.y;
    }

    public resizeBounds(width: number, height: number, fontSize: number) {
        this.boundsWidth = width;
        this.boundsHeight = height;

        this.fontSize = fontSize;
    }

    public render(renderer: Renderer) {
        if (this.scaling) {
            return;
        }

        this.removeMixedCurrencies();

        super.render(renderer);

        if (this.autoShrink) {
            this.scaleToFit();
        }
    }

    public forceAutoResize() {
        if (this.autoShrink) {
            this.scaleToFit();
        }
    }

    private scaleToFit() {
        this.scaling = true;
        const position = this.getOrientationPosition();
        this.fontSize = position.fontSize;
        while (this.textWidth > this.boundsWidth && this.fontSize > 1) {
            this.fontSize--;
            super.updateText();
        }

        if (position.fontSize !== this.fontSize) {
            this.move(position.x, position.y, position.scale, position.align);
        }

        this.scaling = false;
    }

    // TODO: instead of defining which characters to strip, would be better if we read from the BitmapFont .xml to know exactly which characters are supported,
    // then remove every other character, as unsupported characters still get rendered as white-space which messes with alignment
    private removeMixedCurrencies() {
        // const font = this.font as { name?: string; size?: number; };
        // const fontName = font.name;

        // const data = BitmapText.fonts[fontName];

        // let hasAllCharacters: boolean = true;

        // let charCode;
        // let charData;
        // for (let i: number = 0; i < this.text.length; i++) {
        //     charCode = this.text.charCodeAt(i);
        //     charData = data.chars[charCode];

        //     if (!charData) {
        //         hasAllCharacters = false;
        //     }
        // }

        // if (!hasAllCharacters) {
        //     BitmapText.mixedCurrencyCodes.forEach((currencyCode: string) => {
        //         if (this.text.indexOf(currencyCode) > -1) {
        //             this.text = this.text.replace(currencyCode, "");
        //         }
        //     });
        // }
    }

    private getOrientationPosition() {
        return RenderOrientation.isLandscape() ? this.landscape : this.portrait;
    }
}
