import { PreloaderComponent } from "appworks/components/preloader/preloader-component";
import { FileLocation } from "appworks/config/asset-config-schema";
import { Layers } from "appworks/graphics/layers/layers";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { fadeOut } from "appworks/utils/animation/fade";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";

export class GMRPreloaderComponent extends PreloaderComponent {
    protected anim: SpineContainer;

    public start(files: FileLocation[]): void {
        if (Layers.get("Background")?.hasScene("preloader")) {
            Layers.get("Background").setScene("preloader").execute();
            Layers.get("Background").show();
        }

        const menuLayer = Layers.get("Menus");
        if (menuLayer?.hasScene("preloader")) {
            menuLayer.show();
            menuLayer.setScene("preloader").then(() => {
                this.anim = menuLayer.getSpine("preloader");
            });
        }
    }

    public loadComplete(): Contract<any> | Sequence<void> {
        if (!this.anim) {
            return Contract.getDelayedContract(250, () => this.loadComplete());
        }

        return new Contract((resolve) => {
            if (this.anim.isPlaying()) {
                this.anim.onComplete.addOnce(() => {
                    this.onComplete.dispatch();
                    this.destroy();
                    resolve();
                });
            } else {
                this.destroy();
                resolve();
            }
        });
    }

    public destroy(): void {
        const menuLayer = Layers.get("Menus");
        fadeOut(menuLayer.container, 250).then(() => {
            menuLayer.defaultScene().execute();
        });
    }
}