import { Result } from "appworks/model/gameplay/records/results/result";
import { LineResult } from "slotworks/model/gameplay/records/results/line-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { symbolWinResultDisplay } from "./scatter-result-display";

export function lineResultDisplay(record: SpinRecord, result: Result) {
    const scatterResult = symbolWinResultDisplay(record, result);

    scatterResult.innerHTML = "Line " + (result as LineResult).line + "<br>" + scatterResult.innerHTML;
    scatterResult.style.height = "15vh";

    return scatterResult;
}
