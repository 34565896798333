import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoJokerResult } from "slingo/model/results/slingo-joker-result";

export const symbolColors = {
    unmatched: "#666",
    matched: "#1c5",
    joker: "#dc1",
    superJoker: "#d12"
};

export function SlingoRecordDisplay(record: SlingoRecord) {
    const gameProgressResult = record.getFirstResultOfType(SlingoGameProgressResult);

    const containerElement = document.createElement("div");
    containerElement.style.width = "100%";
    containerElement.style.height = "80%";

    // Current State
    containerElement.innerHTML += "State: " + record.state;

    // Colour key
    const key = document.createElement("div");
    key.innerHTML = "Colours:";
    for (const clr of Object.keys(symbolColors)) {
        key.innerHTML += ` <span style=color:${symbolColors[clr]}>${clr}</span>`;
    }
    containerElement.appendChild(key);

    // Main ticket grid
    const grid = document.createElement("table");
    grid.style.width = "70%";
    grid.style.height = "70%";
    grid.style.marginTop = "10px";
    grid.style.marginBottom = "10px";
    grid.style.marginLeft = "auto";
    grid.style.marginRight = "auto";
    containerElement.appendChild(grid);

    for (let y = 0; y < record.ticketGrid[0].length; y++) {
        const row = document.createElement("tr");
        grid.appendChild(row);

        for (let x = 0; x < record.ticketGrid.length; x++) {
            const value = record.ticketGrid[x][y];

            const symbol = document.createElement("td");
            symbol.style.border = "solid 1px #fff";
            symbol.style.textAlign = "center";
            symbol.style.verticalAlign = "middle";
            symbol.style.backgroundColor = symbolColors.unmatched;
            symbol.innerText = record.ticketGrid[x][y].toString();
            row.appendChild(symbol);

            const jokerResult = record.getFirstResultOfType(SlingoJokerResult);
            if (jokerResult) {
                if (jokerResult.jokerCells.indexOf(value) !== -1) {
                    symbol.style.backgroundColor = symbolColors.joker;
                } else if (jokerResult.superJokerCells.indexOf(value) !== -1) {
                    symbol.style.backgroundColor = symbolColors.superJoker;
                }
            }
            if (gameProgressResult && gameProgressResult.matchedNumbers.indexOf(value) !== -1) {
                symbol.style.backgroundColor = symbolColors.matched;
            }
        }
    }

    return containerElement;
}
