import { Connector } from "appworks/server/connectors/connector";
import { Contract } from "appworks/utils/contracts/contract";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Signal } from "signals";

export class HTTPConnector implements Connector {

    public static SPIN_TIMEOUT: number = 0;

    public withCredentials: boolean = false;
    public defaultHeaders: Map<string, string | number> = new Map<string, string | number>([
        ["Content-Type", "application/json"]
    ] as any);

    protected errorSignal: Signal = new Signal();

    protected forceData: any;

    public sendRequest(request: any): Contract<{}> {
        axios.defaults.timeout = HTTPConnector.SPIN_TIMEOUT;
        const axiosRequest = request as AxiosRequestConfig;
        axiosRequest.withCredentials = this.withCredentials;

        if (!axiosRequest.headers) {
            axiosRequest.headers = {} as any;
        }


        this.defaultHeaders.forEach((value: string | number, index: string) => {
            // Specifically undefined as if null is specified, we want to omit the default header
            if (axiosRequest.headers[index] === undefined) {
                axiosRequest.headers[index] = value;
            }
        });

        return new Contract((resolve) => {
            axios.request(axiosRequest)
                .then((response) => {
                    this.processResponse(response, resolve);
                })
                .catch((error) => {
                    this.processError(error, resolve);
                });
        });
    }

    public onError(method: (message: string, fatal: boolean) => void) {
        this.errorSignal.add(method);
    }

    public close(): void {
        return null;
    }

    public setForce(forceData: any) {
        this.forceData = forceData;
    }

    protected processResponse(response: AxiosResponse, resolve: (value?: any) => void) {
        resolve(response);
    }

    protected processError(error: AxiosError, resolve: (value?: any) => void) {
        if (error.message === "Network Error" || error.message.indexOf("Request failed") > -1) {
            this.errorSignal.dispatch("err.con", true);
            resolve();
        } else {
            throw error;
        }
    }
}

export const httpConnector = new HTTPConnector();
