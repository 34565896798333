import { Record } from "appworks/model/gameplay/records/record";
import { wrapIndex } from "appworks/utils/collection-utils";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { DataProcessorSupplement } from "slotworks/model/gameplay/supplements/data-processor-supplement";
import { slotDefinition } from "slotworks/model/slot-definition";

export class GridOnlySupplement implements DataProcessorSupplement {

    public active: boolean = true;

    // illegalHiddenSymbols: Optional array of symbols which may never appear above the result (for example in tumblers where an oversized symbol should not be visible above the result as it isn't guarenteed it will drop in)
    constructor(public illegalHiddenSymbols?: string[]) {
    }

    /**
     * Checks to see if a results has a grid and no stops, and if so creates a reelset with stops
     *
     * @param record {Record}
     */

    public process(record: Record) {
        if (!this.active) {
            return;
        }

        if (record && record instanceof SpinRecord) {
            let stops = record.stops;
            let reelset = record.reelset;
            const grid = record.grid;

            if (!stops && grid && reelset) {
                stops = [];
                reelset = [...reelset];

                for (let col = 0; col < grid.length; col++) {
                    let r = 0;

                    const topResultSymbol = slotDefinition.getSymbolDefinition(grid[col][0]);
                    const bottomResultSymbol = slotDefinition.getSymbolDefinition(grid[col][grid[col].length - 1]);

                    // Ensures the result isn't spliced into the middle of a stack symbol
                    const betweenStack = () => {
                        const symbolAbove = slotDefinition.getSymbolDefinition(wrapIndex(r - 1, reelset[col]));
                        const symbolBelow = slotDefinition.getSymbolDefinition(wrapIndex(r, reelset[col]));

                        return (symbolAbove.height > 1) && (symbolBelow.height > 1) && (symbolBelow.id === symbolAbove.id);
                    };

                    // Ensures the result isn't connected to another stack
                    const connectingStacks = () => {
                        const symbolAbove = slotDefinition.getSymbolDefinition(wrapIndex(r - 1, reelset[col]));
                        const symbolBelow = slotDefinition.getSymbolDefinition(wrapIndex(r, reelset[col]));

                        return (symbolAbove.height > 1 && topResultSymbol.id === symbolAbove.id) || (symbolBelow.height > 1 && bottomResultSymbol.id === symbolBelow.id);
                    };

                    // Ensures the top "hidden symbol" is not illegal
                    const invalidTophiddenSymbol = () => {
                        const symbolAbove = slotDefinition.getSymbolDefinition(wrapIndex(r - 1, reelset[col]));
                        return this.illegalHiddenSymbols && this.illegalHiddenSymbols.indexOf(symbolAbove.id) > -1;
                    }

                    do {
                        r = Math.floor(Math.random() * reelset[col].length);
                        // console.log("MS:", col + 1, "RESULT INSERT", r);
                    } while (betweenStack() || connectingStacks() || invalidTophiddenSymbol());

                    reelset[col] = [...reelset[col]];
                    reelset[col].splice(r, 0, ...grid[col]);

                    // console.log("MS:", col + 1, "RESULT REELSET UNEDITED", reelset[col], r);

                    // Check for stack at the top of result
                    if (topResultSymbol.height > 1) {
                        // See how big the stack is
                        let stackSize = 1;
                        for (let y = 1; y < topResultSymbol.height; y++) {
                            const nextSymbol = slotDefinition.getSymbolDefinition(grid[col][y]);
                            if (nextSymbol.id === topResultSymbol.id) {
                                stackSize++;
                            } else {
                                break;
                            }
                        }

                        // If the stack needs finishing, add in rest of the stack above result
                        let unfinishedStack = topResultSymbol.height - stackSize;
                        while (unfinishedStack > 0) {
                            unfinishedStack--;
                            reelset[col].splice(r, 0, topResultSymbol.id);
                            r++;
                        }

                        // console.log("MS:", col + 1, "CONTINUED WILDS", reelset[col], r);
                    }

                    stops.push(r);
                }

                record.stops = stops;
                record.reelset = reelset;
            }
        }
    }
}
