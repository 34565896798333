import { AbstractComponent } from "appworks/components/abstract-component";
import { ComponentFactory } from "appworks/components/factories/component-factory";

export class GenericComponentFactory<T extends new(...args: Array<unknown>) => AbstractComponent> implements ComponentFactory {
    protected type: T;
    protected args: ConstructorParameters<T>;

    constructor(type: T, ...args: ConstructorParameters<T>) {
        this.type = type;
        this.args = args;
    }

    public build(): AbstractComponent {
        return new this.type(...this.args);
    }
}
