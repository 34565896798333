import { Orientation } from "appworks/graphics/canvas/orientation";
import { Container as PIXIContainer, Renderer } from "pixi.js";
import { DualPosition } from "./dual-position";
import { Position } from "./position";
import { RenderOrientation } from "./render-orientation";

export class Container extends PIXIContainer {

    public dualPivot: DualPosition;
    public dualPosition: DualPosition = new DualPosition();

    private lastPosition: Position;

    public updateTransform() {
        const position = this.getOrientationPosition();

        if (this.dualPivot) {
            const orientationPivot = this.getOrientationPivot();
            this.pivot.set(orientationPivot.x, orientationPivot.y);
        }

        if (!position.equals(this.lastPosition)) {
            this.lastPosition = position.clone();
            (this.transform as any).position.x = position.x;
            (this.transform as any).position.y = position.y;

            if ((position.width > 0 && position.height > 0)) {
                super.width = position.width * position.scale.x;
                super.height = position.height * position.scale.y;
            } else if (position.scale.x !== 1 || position.scale.y !== 1) {
                this.transform.scale.set(position.scale.x, position.scale.y); // TODO: kill this for v6
            }
        }

        super.updateTransform();
    }

    public render(renderer: Renderer) {
        if (this.getOrientationPosition().unavailable) {
            return;
        }

        return super.render(renderer);
    }

    public getPosition(orientation: Orientation) {
        return this.dualPosition.get(orientation);
    }

    public setDualTransform(dualPosition: DualPosition) {
        this.dualPosition = dualPosition.clone();
    }

    get landscape() {
        return this.dualPosition.landscape;
    }

    set landscape(value) {
        this.dualPosition.landscape = value.clone();
    }

    get portrait() {
        return this.dualPosition.portrait;
    }

    set portrait(value) {
        this.dualPosition.portrait = value.clone();
    }

    // @ts-ignore
    get x() {
        throw new Error("Do not change y manually, please use landscape.y or portrait.y");
        return this.position.x;
    }

    set x(value) {
        throw new Error("Do not change x manually, please use landscape.x or portrait.x");
    }

    // @ts-ignore
    get y() {
        throw new Error("Do not change y manually, please use landscape.y or portrait.y");
        return this.position.y;
    }

    set y(value) {
        throw new Error("Do not change y manually, please use landscape.y or portrait.y");
    }

    // @ts-ignore
    get width() {
        throw new Error("Do not read width manually, please use landscape.width or portrait.width");
        return super.width;
    }

    set width(value) {
        throw new Error("Do not change width manually, please use landscape.width or portrait.width");
    }

    // @ts-ignore
    get height() {
        throw new Error("Do not read height manually, please use landscape.height or portrait.height");
        return super.width;
    }

    set height(value) {
        throw new Error("Do not change height manually, please use landscape.height or portrait.height");
    }

    protected getOrientationPosition() {
        return RenderOrientation.isLandscape() ? this.landscape : this.portrait;
    }

    protected getOrientationPivot() {
        return RenderOrientation.isLandscape() ? this.dualPivot.landscape : this.dualPivot.portrait;
    }

}
