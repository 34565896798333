import { Layers } from "appworks/graphics/layers/layers";
import { AbstractSceneTransition } from "appworks/graphics/layers/scene-transitions/abstract-scene-transition";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Text } from "appworks/graphics/pixi/text";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Easing, Tween } from "appworks/utils/tween";

export interface SpineBigWinI18NAnimationConfig {
    delayIn: number;
    durationIn: number;
    delayOut: number;
    durationOut: number;
}
export class SpineBigWinTransition extends AbstractSceneTransition {

    constructor(
        protected layer: Layers,
        protected spineName: string,
        protected animationPrefix: string,
        protected i18nAnimationConfig: SpineBigWinI18NAnimationConfig = {
            delayIn: 500,
            durationIn: 500,
            delayOut: 0,
            durationOut: 500
        },
        protected animationNames: {
            in: string,
            loop: string,
            out: string
        } = {
                in: "_in",
                loop: "_loop",
                out: "_out"
            },
        protected internationalFallback: boolean = true
    ) {
        super();
    }

    public in(): Contract<void> {
        const spine = this.getSpine();

        return this.cancelGroup.parallel<void>([
            () => this.zoomInternationalTextIn(),
            () => this.cancelGroup.contract((resolve) => {
                spine.playOnce(`${this.animationPrefix}${this.animationNames.in}`).then(() => {
                    spine.play(`${this.animationPrefix}${this.animationNames.loop}`);
                    resolve();
                });
            })
        ]);
    }

    public out(): Contract<void> {
        const spine = this.getSpine();
        return this.cancelGroup.parallel<void>([
            () => spine.playOnce(`${this.animationPrefix}${this.animationNames.out}`),
            () => this.zoomInternationalTextOut()
        ]);
    }

    protected zoomInternationalTextIn() {
        const text = this.layer.getText("international_text");
        const textOut = this.layer.getText("international_text_out");

        if (this.isEnglish()) {
            if (text) {
                text.visible = false;
            }
            if (textOut) {
                textOut.visible = false;
            }
            return Contract.empty();
        } else {
            let zoomIn;
            if (text) {
                zoomIn = () => this.zoom(text, this.i18nAnimationConfig.durationIn, textOut ? 0 : this.i18nAnimationConfig.delayIn);
            } else {
                zoomIn = () => Contract.empty();
            }
            
            let zoomOut;
            if (textOut) {
                text.scale.x = text.scale.y = 0;
                zoomOut = () => this.zoom(textOut, this.i18nAnimationConfig.durationOut, 0, true);
            } else {
                zoomOut = () => Contract.empty();
            }

            return this.cancelGroup.sequence([
                zoomOut,
                zoomIn
            ]);
        }
    }

    protected zoomInternationalTextOut() {
        const text = this.layer.getText("international_text");

        if (this.isEnglish() || text == null) {
            return Contract.empty();
        } else {
            return this.zoom(text, this.i18nAnimationConfig.durationOut, this.i18nAnimationConfig.delayOut, true);
        }
    }

    protected zoom(text: Text, duration: number, delay: number, out: boolean = false) {

        const ease = out ? Easing.Back.In : Easing.Back.Out;

        const targetScale = (out ? 0 : 1);

        const tween = new Tween(text.scale)
            .to({ x: targetScale, y: targetScale }, duration)
            .delay(delay)
            .easing(ease);

        return this.cancelGroup.tweenContract(tween);
    }

    protected getSpine(): SpineContainer {
        const internationalSpine = this.layer.getSpine(this.spineName + "_international");
        const spine = this.layer.getSpine(this.spineName);

        if (!this.isEnglish() && internationalSpine != null) {
            return internationalSpine;
        }

        if (!spine) {
            throw new Error(`Spine ${this.spineName} not found in layer ${this.layer.id}:${this.layer.getCurrentScene().name}`);
        }

        return spine;
    }

    protected isEnglish() {
        return Services.get(TranslationsService).playingInEnglish() || !this.internationalFallback;
    }
}
