import { Result } from "appworks/model/gameplay/records/results/result";
import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { gridDisplay } from "../general-displays/grid-display";

export function symbolWinResultDisplay(record: SpinRecord, result: Result) {
    const container = document.createElement("div");
    container.style.width = "10vw";


    const reelDisplay = gridDisplay(record.grid, record.overlayGrid);

    const symbols = reelDisplay.querySelectorAll(`[id*=symbol_]`);
    [].forEach.call(symbols, (symbol: HTMLElement) => {
        symbol.style.opacity = ".1";
    });

    (result as SymbolWinResult).positions.forEach((position) => {
        const symbol = reelDisplay.querySelector(`#symbol_${position.x}_${position.y}`) as HTMLElement;
        symbol.style.opacity = "1";
    });

    if ((result as SymbolWinResult).multipliers) {
        container.innerHTML = "Multipliers: " + (result as SymbolWinResult).multipliers.join(",") + "<br>";
    }

    container.appendChild(reelDisplay);

    container.onmouseover = () => {
        resetRecordDisplayBorders(record);
        (result as SymbolWinResult).positions.forEach((position) => {
            const recordDisplaySymbol = document.getElementById(`symbol_${position.x}_${position.y}`);
            if (recordDisplaySymbol) {
                recordDisplaySymbol.style.backgroundColor = "red";
            }
        });
    }

    container.onmouseout = () => {
        resetRecordDisplayBorders(record);
    }

    return container;
}

function resetRecordDisplayBorders(record) {
    if (record.grid) {
        for (let x = 0; x < record.grid.length; x++) {
            for (let y = 0; y < record.grid[x].length; y++) {
                const recordDisplaySymbol = document.getElementById(`symbol_${x}_${y}`);
                if (recordDisplaySymbol) {
                    recordDisplaySymbol.style.backgroundColor = null;
                }
            }
        }
    }
}