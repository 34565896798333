import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Record } from "appworks/model/gameplay/records/record";
import { Result } from "appworks/model/gameplay/records/results/result";
import { Services } from "appworks/services/services";
import { clearElement } from "appworks/utils/dom-utils";
import { logger } from "appworks/utils/logger";
import { clusterResultDisplay } from "./result-displays/cluster-result-display";
import { freespinWinResultDisplay } from "./result-displays/freespin-win-result-display";
import { lineResultDisplay } from "./result-displays/line-result-display";
import { reelModifierResultDisplay } from "./result-displays/reel-modifier-result-display";
import { respinResultDisplay } from "./result-displays/respin-result-display";
import { symbolWinResultDisplay } from "./result-displays/scatter-result-display";
import { trailIncrementResultDisplay } from "./result-displays/trail-increment-result-display";
import { trailResultDisplay } from "./result-displays/trail-result-display";
import { waysResultDisplay } from "./result-displays/ways-result-display";
import { TextOnlyDisplay } from "./result-displays/text-only-display";

export type ResultDisplayFunction = (record: Record, result: Result) => HTMLDivElement;

export class ResultDisplay {

    private element: HTMLDivElement;
    private displayFunctionMap: Map<string, ResultDisplayFunction> = new Map();

    constructor(customDisplayFunctions: Map<string, ResultDisplayFunction>) {

        this.displayFunctionMap.set("TrailIncrementResult", trailIncrementResultDisplay);
        this.displayFunctionMap.set("TrailResult", trailResultDisplay);
        this.displayFunctionMap.set("LineResult", lineResultDisplay);
        this.displayFunctionMap.set("WaysResult", waysResultDisplay);
        this.displayFunctionMap.set("SymbolWinResult", symbolWinResultDisplay);
        this.displayFunctionMap.set("ScatterResult", symbolWinResultDisplay);
        this.displayFunctionMap.set("FreespinWinResult", freespinWinResultDisplay);
        this.displayFunctionMap.set("ReelModifierResult", reelModifierResultDisplay);
        this.displayFunctionMap.set("ClusterResult", clusterResultDisplay);
        this.displayFunctionMap.set("RespinResult", respinResultDisplay);

        customDisplayFunctions.forEach((value, key) => {
            this.displayFunctionMap.set(key, value);
        });

        this.draw();
    }

    public show(record: Record) {
        clearElement(this.element);

        const recordTable = document.createElement("table");
        const titleRow = document.createElement("tr");

        titleRow.style.backgroundColor = "#444";
        titleRow.style.fontWeight = "bold";
        titleRow.style.textAlign = "center";

        recordTable.style.width = "50vw";

        recordTable.appendChild(titleRow);

        titleRow.appendChild(this.createCell("index"));
        titleRow.appendChild(this.createCell("resultType"));
        titleRow.appendChild(this.createCell("cashWon"));
        titleRow.appendChild(this.createCell("linkedResult"));
        titleRow.appendChild(this.createCell("display"));

        record.results.forEach((result, index) => {
            const resultRow = document.createElement("tr");
            recordTable.appendChild(resultRow);

            resultRow.appendChild(this.createCell(index.toString()));

            const mainCell = this.createCell(result.resultType);
            mainCell.onclick = () => logger.info(result);
            mainCell.style.cursor = "pointer";
            mainCell.style.pointerEvents = "all";
            resultRow.appendChild(mainCell);

            resultRow.appendChild(this.createCell(result.cashWon.toString()));
            if (result.linkedResult) {
                resultRow.appendChild(this.createCell(record.results.indexOf(result.linkedResult).toString()));
            } else {
                resultRow.appendChild(this.createCell());
            }

            resultRow.appendChild(this.displayResult(record, result));
        });

        this.element.appendChild(recordTable);
    }

    public clear() {
        clearElement(this.element);
    }

    public toggle() {
        if (this.element.parentElement) {
            Services.get(CanvasService).rootNode.removeChild(this.element);
        } else {
            Services.get(CanvasService).rootNode.appendChild(this.element);
        }
    }

    private displayResult(record: Record, result: Result) {
        const displayCell = this.createCell();

        if (this.displayFunctionMap.has(result.resultType)) {
            displayCell.appendChild(this.displayFunctionMap.get(result.resultType)(record, result));
        } else {
            displayCell.innerHTML = JSON.stringify(result);
        }

        return displayCell;
    }

    private createCell(contents: string = "") {
        const cell = document.createElement("td");

        cell.style.border = "solid 1px #fff";

        cell.innerHTML = contents;

        return cell;
    }

    private draw() {
        this.element = document.createElement("div");
        this.element.id = "integrationDebugger_resultDisplay";

        this.element.style.boxSizing = "border-box";
        this.element.style.position = "fixed";
        this.element.style.top = "50vh";
        this.element.style.left = "20vw";
        this.element.style.zIndex = "900";
        this.element.style.width = "60vw";
        this.element.style.height = "50vh";
        this.element.style.paddingTop = "5vh";
        this.element.style.paddingLeft = "3vw";

        this.element.style.pointerEvents = "all";
        this.element.style.overflowY = "scroll";

        this.element.style.backgroundColor = "#222";
        this.element.style.border = "solid 1px white";

        this.element.style.color = "#fff";
        this.element.style.fontSize = "20px";

        Services.get(CanvasService).rootNode.appendChild(this.element);
    }
}
