import { FlexiSlider } from "appworks/graphics/elements/flexi-slider";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { SliderControl } from "appworks/ui/controls/standard/slider/slider-control";
import { ValueList } from "appworks/utils/value-list";

export class VolumeSliderControl extends SliderControl {

    protected sliderValues: ValueList<number>;
    protected onModelUpdateCallback: Function;
    protected addedZeroValue: boolean = false;

    constructor(slider: FlexiSlider, label: FlexiText) {
        super(slider, label);

        this.slider.setup(0, 100, 10);

        this.slider.setValue(Services.get(SoundService).getVolume() * 100);
    }

    protected onChange(index: number): void {
        Services.get(SoundService).setVolume(index / 100);
    }
}
