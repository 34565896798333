import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { slotModel } from "slotworks/model/slot-model";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { RadioControl } from "./radio-control";

export class StakeListRadioControl extends RadioControl {
    constructor(
        protected buttons: FlexiButton[],
        protected labels: FlexiText[],
        protected leftArrow?: FlexiButton,
        protected rightArrow?: FlexiButton
    ) {
        super(buttons, labels, leftArrow, rightArrow);

        this.setFormatMethod((value: number) => {
            return Services.get(CurrencyService).format(value);
        });

        this.setValues(
            Services.get(SlotBetService).getTotalStakeValues(),
            Services.get(SlotBetService).getTotalStake()
        );
    }

    protected onChange(index: number, value: number): void {
        super.onChange(index, value);

        const betModel = slotModel.read().bet;

        if (betModel.creditSizes.currentValue > value) {
            Services.get(SoundService).event(SoundEvent.stake_radio_select_lower);
        } else if (betModel.creditSizes.currentValue < value) {
            Services.get(SoundService).event(SoundEvent.stake_radio_select_higher);
        }

        betModel.creditSizes.currentValue = value;
        slotModel.write({ bet: betModel });
    }
}