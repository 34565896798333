import { ButtonElement } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { ButtonControl } from "appworks/ui/controls/standard/button/button-control";
import { ControlFactory } from "../control-factory";

export class UIButtonControlFactory implements ControlFactory {
    public filter(layer: Layers) {
        const elements = layer.allElements;
        for (const p in elements) {
            if (elements.hasOwnProperty(p) && elements[p] instanceof ButtonElement && p.indexOf("ui_") > -1) {
                return true;
            }
        }

        return false;
    }

    public build(layer: Layers) {
        const elements = layer.allElements;

        const controls = [];

        for (const p in elements) {
            if (elements.hasOwnProperty(p) && elements[p] instanceof ButtonElement && p.indexOf("ui_") > -1) {
                controls.push(new ButtonControl(elements[p]));
            }
        }

        return controls;
    }
}
