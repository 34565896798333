import { gameLoop } from "appworks/core/game-loop";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { RandomRangeInt } from "appworks/utils/math/random";
import { Timer } from "appworks/utils/timer";
import { Signal, SignalBinding } from "signals";
import { CanvasService } from "../canvas/canvas-service";
import { Orientation } from "../canvas/orientation";
import { GraphicsService } from "../graphics-service";
import { SpineService } from "../spine/spine-service";
import { Container } from "./container";
import { DualPosition } from "./dual-position";
import { Position } from "./position";
import { Spine, IAnimation, IEvent, ITrackEntry } from "pixi-spine";
import { Point } from "appworks/utils/geom/point";

export class SpineContainer extends Container {
    // TODO default true in V6, leave toggleable though in case of issues
    public static AUTO_UPDATE_SPEED: boolean = false;
    
    public onStart: Signal = new Signal();
    public onComplete: Signal = new Signal();
    public onEvent: Signal = new Signal();
    public skin: string;
    public spines: { landscape?: Spine & PIXI.Container; portrait?: Spine & PIXI.Container } = {};

    private attachments: Map<string, string> = new Map();
    private spineId: string;
    private playing: boolean = false;
    private looping: boolean = false;
    private playContract?: Contract<void>;
    private animation?: IAnimation;
    private spinePosition: DualPosition;
    private playTimeout?: number;
    private timeScale: number = 1;
    private initialSpineSetup: boolean;
    private spineVisible: boolean = false;
    private onOrientationChangeBinding: SignalBinding;
    private onSpeedChangeBinding: SignalBinding;
    private spineAnchor: Point = new Point(1, 1); // 1,1 is needed to match PSD positioning, only change if you're moving the spine around

    constructor(spineId: string, position: DualPosition) {
        super();

        this.spineId = spineId;
        this.name = spineId + "_" + RandomRangeInt(0, 100);
        this.skin = "default";
        this.spinePosition = position;
        this.initialSpineSetup = true;
        this.spineVisible = false;

        this.onOrientationChangeBinding = Services.get(
            CanvasService
        ).onOrientationChange.add(() => this.updateSpineVisibility());

        if (SpineContainer.AUTO_UPDATE_SPEED) {
            this.onSpeedChangeBinding = gameLoop.onSpeedChange.add(() => {
                this.setSpeed(this.timeScale);
            });
        }
    }

    public updateTransform(): void {
        super.updateTransform();
        this.setupSpine();
    }

    public getDuration(
        animation?: number | string | IAnimation,
        timeScale = 1
    ) {
        animation = this.getAnimation(animation);
        const duration = (animation.duration * 1000) / timeScale;

        return duration;
    }

    public playOnce(
        animation?: number | string | IAnimation,
        stopAtEnd: boolean = false,
        timeScale = 1,
        track = 0
    ): Contract<void> {
        animation = this.getAnimation(animation);

        if (this.playing) {
            this.stop(true, track);
        }

        this.animation = animation;
        this.timeScale = timeScale;

        const duration = (animation.duration * 1000) / timeScale;

        const contract: Contract<void> = new Contract((resolve, cancel) => {
            this.playTimeout = Timer.setTimeout(() => {
                if (this.playTimeout) {
                    Timer.clearTimeout(this.playTimeout);
                    this.playTimeout = undefined;
                }
                this.playContract = undefined;
                if (stopAtEnd) {
                    this.stop(true, track);
                }
                this.playing = false;
                this.onComplete.dispatch();
                resolve();
            }, duration);

            cancel(() => {
                if (this.playTimeout) {
                    Timer.clearTimeout(this.playTimeout);
                    this.playTimeout = undefined;
                }
                if (!this._destroyed && stopAtEnd) {
                    this.stop(true, track);
                }
            });

            this.setupPlay(false, timeScale, track);
            this.onStart.dispatch();
        });

        this.playContract = contract;
        return this.playContract;
    }

    public play(
        animation?: number | string | IAnimation,
        timeScale = 1,
        track = 0
    ): void {
        // TODO: Refactor to use almost identical code block on playOnce
        animation = this.getAnimation(animation);

        this.onStart.dispatch();

        if (this.playing) {
            if (this.animation.name === animation.name) {
                return;
            } else {
                this.stop(true, track);
            }
        }

        this.animation = animation;
        this.timeScale = timeScale;

        const duration = (animation.duration * 1000) / timeScale;

        this.playTimeout = Timer.setTimeout(() => {
            this.stop(true, track);
            this.play(animation, timeScale, track);
            this.onComplete.dispatch();
        }, duration);

        this.setupPlay(true, timeScale, track);
    }

    public stop(hide: boolean = true, track = 0) {
        if (this.playTimeout) {
            Timer.clearTimeout(this.playTimeout);
            this.playTimeout = undefined;
        }

        if (hide) {
            this.spineVisible = false;
            this.updateSpineVisibility();
        }

        if (this.playing) {
            for (const orientation of [Orientation.LANDSCAPE, Orientation.PORTRAIT]) {
                if (this.spines.hasOwnProperty(orientation) && this.spines[orientation].state) {
                    const spine = this.spines[orientation];
                    if ( spine.state.tracks[track]) {
                        spine.state.tracks[track].trackTime = 0;
                    }
                    spine.state.clearListeners();
                    spine.state.setEmptyAnimation(track, 0);
                    this.playing = false;
                    this.looping = false;
                    this.animation = undefined;
                    if (this.playContract) {
                        this.playContract.forceResolve();
                        this.playContract = undefined;
                    }
                }
            }
        }
    }

    public setSpeed(speed: number): void {
        for (const key in this.spines) {
            if (this.spines.hasOwnProperty(key)) {
                this.spines[key].state.timeScale = gameLoop.speed * speed;
            }
        }
    }

    public hasAnimation(anim: string): boolean {
        return Boolean(
            this.getFirstSpine().spineData.animations.find(
                animation => animation.name === anim
            )
        );
    }

    public getAnimations(): IAnimation[] {
        return this.getFirstSpine().spineData.animations;
    }

    public getAnimationIndex(): number {
        return this.getAnimations().indexOf(this.animation);
    }

    public isLooping(): boolean {
        return this.looping;
    }

    public isPlaying(): boolean {
        return this.playing;
    }

    public setupSpine() {
        if (this.initialSpineSetup) {
            this.createSpine(Orientation.LANDSCAPE, this.spinePosition.landscape);
            this.createSpine(Orientation.PORTRAIT, this.spinePosition.portrait);

            this.updateSpineVisibility();

            this.initialSpineSetup = false;
        }
    }

    public getSpinePosition(): DualPosition {
        return this.spinePosition;
    }

    public setSlotVisible(slotKey: string, visible: boolean) {
        for (const key in this.spines) {
            if (this.spines.hasOwnProperty(key)) {
                const slot = this.spines[key].skeleton.findSlot(slotKey);
                if (slot) {
                    for (const spriteName in slot.sprites) {
                        if (slot.sprites[spriteName]) {
                            const sprite = slot.sprites[spriteName];
                            sprite.visible = visible;
                        }
                    }
                }
            }
        }
    }

    public setAttachment(slot: string, attachment: string) {
        this.attachments.set(slot, attachment);

        this.updateAttachments();
    }

    public getSlot(slot: string) {
        const landscapeIndex = this.spines[
            Orientation.LANDSCAPE
        ]?.skeleton.slots.findIndex(x => x.data.name === slot);
        const portraitIndex = this.spines[
            Orientation.PORTRAIT
        ]?.skeleton.slots.findIndex(x => x.data.name === slot);
        return {
            containers: {
                landscape: this.spines[Orientation.LANDSCAPE]?.slotContainers[
                    landscapeIndex
                ],
                portrait: this.spines[Orientation.PORTRAIT]?.slotContainers[
                    portraitIndex
                ]
            },
            slots: {
                landscape: this.spines[Orientation.LANDSCAPE]?.skeleton.slots[
                    landscapeIndex
                ],
                portrait: this.spines[Orientation.PORTRAIT]?.skeleton.slots[
                    portraitIndex
                ]
            }
        };
    }

    public setSkin(skin: string) {
        this.skin = skin;
        this.spines.landscape?.skeleton.setSkinByName(skin);
        this.spines.portrait?.skeleton.setSkinByName(skin);
    }

    public destroy(options?: {
        children?: boolean;
        texture?: boolean;
        baseTexture?: boolean;
    }): void {
        super.destroy(options);

        if (this.onOrientationChangeBinding) {
            this.onOrientationChangeBinding.detach();
            this.onOrientationChangeBinding = undefined;
        }

        if (this.onSpeedChangeBinding) {
            this.onSpeedChangeBinding.detach();
            this.onSpeedChangeBinding = undefined;
        }
    }

    public setSpineAnchor(anchor: { x: number, y: number }) {
        this.spineAnchor.set(anchor.x, anchor.y);

        const landscapeSpine = this.spines.landscape;
        const portraitSpine = this.spines.portrait;
        if (landscapeSpine) {
            landscapeSpine.pivot.x = landscapeSpine.getBounds().x * anchor.x;
            landscapeSpine.pivot.y = landscapeSpine.getBounds().y * anchor.y;
        }
        if (portraitSpine) {
            portraitSpine.pivot.x = portraitSpine.getBounds().x * anchor.x;
            portraitSpine.pivot.y = portraitSpine.getBounds().y * anchor.y;
        }
    }

    protected getAnimation(animation?: number | string | IAnimation) {
        if (!animation) {
            animation = this.getAnimations()[0];
        }

        switch (typeof animation) {
            case "string":
                animation = this.getAnimations().find(
                    x => x.name === animation
                );
                break;
            case "number":
                if (animation >= 0 && animation < this.getAnimations().length) {
                    animation = this.getAnimations()[animation];
                } else {
                    throw new Error(
                        `Invalid animation index given for ${this.name} (${animation})`
                    );
                }
                break;
        }

        return animation;
    }

    private createSpine(orientation: Orientation, position: Position) {
        if (!this.spines[orientation] && !position.unavailable) {
            const spine = Services.get(GraphicsService).createSpine(
                this.spineId
            );
            this.spines[orientation] = spine;

            spine.update(0);
            let bounds = spine.getBounds();

            // sometimes spines have no width and height on default skeleton, so temporarily play the anim to get the correct bounds
            if (bounds.width === 0 && bounds.height === 0) {
                const animName = spine.spineData.animations[0].name;
                spine.state.setAnimation(0, animName, true);
                // jump into the anim because the bounds can still be 0,0 at frame 0
                // WARNING: spine bounds are not consistent when at a time beyond 0, so it's recommended to use 0 for this if possible
                // See https://epicindustries.atlassian.net/wiki/spaces/AW/pages/586809359/Spine for details
                spine.state.tracks[0].trackTime = SpineService.REFERENCE_TIME;

                spine.update(0);
                bounds = spine.getBounds();
            }

            spine.pivot.x = bounds.x * this.spineAnchor.x;
            spine.pivot.y = bounds.y * this.spineAnchor.y;

            this.addChild(spine);

            if (position.width && position.height) {
                spine.width = position.width;
                spine.height = position.height;
            }

            spine.state.setEmptyAnimation(0, 0);
        }
    }

    private setupPlay(looping: boolean, timeScale = 1, track = 0) {
        for (const orientation of [Orientation.LANDSCAPE, Orientation.PORTRAIT]) {
            if (this.spines.hasOwnProperty(orientation)) {
                const spine = this.spines[orientation];
                spine.stateData.defaultMix = 0;
                spine.state.setEmptyAnimation(track, 0);
                spine.skeleton.setToSetupPose();
                spine.skeleton.setSkinByName(this.skin);

                spine.state.setAnimation(
                    track,
                    this.animation.name,
                    false
                );
                spine.state.tracks[track].trackTime = 0;
                spine.state.timeScale = gameLoop.speed * timeScale;
                spine.state.clearListeners();
                spine.state.addListener({
                    event: (entry, event) => this.onSpineEvent(entry, event)
                });

                this.playing = true;
                this.looping = looping;
                this.spineVisible = true;

                this.updateSpineVisibility();
            }
        }

        this.updateAttachments();
    }

    private updateAttachments() {
        for (const key in this.spines) {
            if (this.spines.hasOwnProperty(key)) {
                this.attachments.forEach((attachment: string, slot: string) => {
                    if (this.spines[key].skeleton.findSlot(slot)) {
                        this.spines[key].skeleton.setAttachment(
                            slot,
                            attachment
                        );
                    }
                });
            }
        }
    }

    private onSpineEvent(entry: ITrackEntry, event: IEvent) {
        if (event?.data?.name) {
            Services.get(SoundService).event(
                SoundEvent.spine_NAME,
                event.data.name
            );
        }

        this.onEvent.dispatch(entry, event);
    }

    private updateSpineVisibility(): void {
        for (const key in this.spines) {
            if (this.spines.hasOwnProperty(key)) {
                const spine = this.spines[key] as Spine & PIXI.Container;
                if (this.spineVisible && key === Services.get(CanvasService).orientation) {
                    spine.alpha = 1;
                    spine.renderable = true;
                } else {
                    spine.alpha = 0;
                    spine.renderable = false;
                }
            }
        }
    }

    private getFirstSpine() {
        return (
            this.spines[Orientation.LANDSCAPE] ||
            this.spines[Orientation.PORTRAIT]
        );
    }
}
