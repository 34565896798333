import { Record } from "appworks/model/gameplay/records/record";
import { Result } from "appworks/model/gameplay/records/results/result";
import { FreespinWinResult } from "slotworks/model/gameplay/records/results/freespin-win-result";

export function freespinWinResultDisplay(record: Record, result: Result) {
    const container = document.createElement("div");

    container.innerHTML = "Freespins won: " + (result as FreespinWinResult).freespinsWon;

    return container;
}
