import { InitCommand } from "appworks/commands/comms/init-command";
import { Components } from "appworks/components/components";
import { HeaderComponent } from "appworks/components/header/header-component";
import { PreloaderComponent } from "appworks/components/preloader/preloader-component";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { LoaderService } from "appworks/loader/loader-service";
import { gameState } from "appworks/model/game-state";
import { Model } from "appworks/model/model";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { UI } from "appworks/ui/ui";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Timer } from "appworks/utils/timer";

export interface InitConfig {
    // Number of milliseconds before dispatching game inactive sound event
    gameInactiveTime: number;
}
export class InitState extends State {

    protected config: InitConfig = {
        gameInactiveTime: 5000
    };

    protected inactiveTimer: number;

    constructor(config?: Partial<InitConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }
    }

    public onEnter() {
        this.setupGameInactiveTimer();

        new Sequence([
            () => this.sendInitRequest(),
            () => Components.get(PreloaderComponent).loadComplete(),
            () => this.showOnBoard(),
            () => this.gameLoaded(),
            () => this.startGame()
        ]).then(() => this.complete());
    }

    public onExit() {
        const fireSoundEvents = () => {
            const inFreespins = gameState.getCurrentGame()?.getCurrentRecord() && gameState.getCurrentGame()?.inFreespins();
            if (inFreespins) {
                Services.get(SoundService).event(SoundEvent.enter_freespins);
            } else {
                Services.get(SoundService).event(SoundEvent.enter_basegame);
            }
        };
        Services.get(LoaderService).onSoundUnlocked.add(fireSoundEvents);
        if (!Services.get(LoaderService).soundIsUnlocked) {
            Services.get(LoaderService).onSoundUnlocked.dispatch();
        } else {
            fireSoundEvents();
        }

        super.onExit();
    }

    protected startGame() {
        Services.get(SoundService).event(SoundEvent.start_game);

        uiFlags.set(UIFlag.GAME_STARTED, true);

        Layers.jumpToDefaultScene();
        Layers.showAll();
        Components.init();
        UI.start();

        Services.get(TransactionService).setBalance(gameState.getCurrentGame().startBalance);

        Components.get(HeaderComponent)?.setMode(Model.read().settings.playMode);

        gameState.getCurrentGame().setToLatestRecord();

        return this.enableSound();
    }

    /**
     * If an onboard is presetn ("Onboard" layer with "onboard" scene):
     * Shows onboard and will wait for continue button click (or screen if no button present)
     * Shows background "onboard" scene or "default" scene if present
     */
    protected showOnBoard() {
        const onboardLayer = Layers.get("Onboard");
        const backgroundLayer = Layers.get("Background");
        const canShow: boolean = Model.read().settings.showIntro;

        if (onboardLayer?.hasScene("onboard") && canShow) {
            if (backgroundLayer && backgroundLayer.hasAnyScene(["onboard", "default"])) {
                backgroundLayer.show();
                backgroundLayer.setScene(backgroundLayer.getFirstValidScene(["onboard", "default"])).execute();
            }

            onboardLayer.show();
            onboardLayer.setScene("onboard").execute();

            return new Contract((resolve) => {

                const hideOnboard = () => {
                    Services.get(CanvasService).rootNode.removeEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), hideOnboard);
                    continueButton?.off(ButtonEvent.CLICK, hideOnboard);
                    dontShowToggle?.onChanged.removeAll();

                    // Wait for sound unlock before continuing
                    const onSoundUnlocked = () => {
                        if (backgroundLayer) {
                            backgroundLayer.defaultScene().execute();
                        }

                        onboardLayer.defaultScene().then(() => {
                            onboardLayer.hide();
                            resolve();
                        });
                    };

                    if (Services.get(LoaderService).soundIsUnlocked() || !Services.get(LoaderService).loadSoundsInPreloader) {
                        onSoundUnlocked();
                    } else {
                        Services.get(LoaderService).onSoundUnlocked.addOnce(onSoundUnlocked);
                        Timer.setTimeout(() => Services.get(LoaderService).unlockSound(), 500);
                    }
                };

                const continueButton = onboardLayer.getButton("continue");
                if (continueButton) {
                    continueButton.on(ButtonEvent.CLICK, hideOnboard);
                } else {
                    Services.get(CanvasService).rootNode.addEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), hideOnboard);
                }

                const dontShowToggle = onboardLayer.getToggle("dont_show_toggle");
                if (dontShowToggle) {
                    dontShowToggle.setChecked(Model.read().settings.showIntro);
                    dontShowToggle.onChanged.add((on: boolean) => {
                        const settings = Model.read().settings;
                        settings.showIntro = !on;
                        Model.write({ settings });
                    });
                }
            });
        } else {
            return Contract.empty();
        }
    }

    protected enableSound() {
        if (!Services.get(SoundService).getMuted()) {
            Services.get(SoundService).unmute();
        }

        return Contract.empty();
    }

    protected setupGameInactiveTimer() {
        uiFlags.onFlagsUpdated.add((flags: number) => {
            if ((flags & UIFlag.IDLE) === UIFlag.IDLE) {
                this.startInactiveTimer();
            } else {
                this.cancelInactiveTimer();
            }
        });
    }

    protected gameLoaded(): Contract<void> {
        Services.get(LoaderService).unlockSound();

        return Contract.empty();
    }

    protected startInactiveTimer() {
        if (!this.inactiveTimer) {
            this.cancelInactiveTimer();
            this.inactiveTimer = Timer.setTimeout(() => Services.get(SoundService).customEvent(SoundEvent.game_inactive), this.config.gameInactiveTime);
        }
    }

    protected cancelInactiveTimer() {
        Timer.clearTimeout(this.inactiveTimer);
        this.inactiveTimer = null;
    }

    protected sendInitRequest(): Contract<void> {
        return InitCommand();
    }
}
