import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { Services } from "appworks/services/services";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { formatMoneyAsCurrency } from "appworks/utils/string-formatters";
import { slotModel } from "slotworks/model/slot-model";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { StepperControl } from "./stepper-control";

export class TotalStakeStepperControl extends StepperControl {
    constructor(protected downButton: FlexiButton, protected upButton: FlexiButton, protected valueText?: FlexiText, protected disableWhenFreeBets: boolean = true) {
        super(downButton, upButton, valueText);

        slotModel.onUpdate.add(() => this.updateValues());

        this.stepper.formatFunction = formatMoneyAsCurrency;
        this.stepper.onUpdate.add(() => this.setTotalStake());

        this.updateValues();
    }

    public setEnabled(enabled: boolean) {

        if (uiFlags.has(UIFlag.FREE_BETS) && this.disableWhenFreeBets) {
            enabled = false;
        }

        super.setEnabled(enabled);
    }

    protected updateValues() {
        this.stepper.setValues(Services.get(SlotBetService).getTotalStakeValues());
        this.stepper.setSelectedValue(Services.get(SlotBetService).getTotalStake());
    }

    protected setTotalStake() {
        slotModel.write({ bet: Services.get(SlotBetService).getCreditsForTotalStake(this.stepper.getSelectedValue(), false) });
    }
}
