import { FPSMeter } from 'fpsmeter';

/**
 * FPS meter service
 * Currently dont have a way to hook into the game-loop tick. FPS will always be 0
 *
 * @class FPSMeterService
 * @typedef {FPSMeterService}
 */
export class FPSMeterService {
  public meter: any;
  public showFPS: boolean;

  /**
   * Add fps meter
   *
   * @public
   */
  public init() {
    this.showFPS = this.checkShowFPS();
    if (this.showFPS) {
      this.meter = new FPSMeter(null, {
        heat: 1,
        graph: 1,
        maxFps: 60,
        bottom: '5%',
        top: 'auto',
        history: 30,
        decimals: 0,
        smoothing: 2,
      });
    }
  }

  /**
   * Check if fpsmeter should be enabled from url
   * Could use a rewrite with a url parser
   *
   * @private
   * @returns {boolean}
   */
  private checkShowFPS(): boolean {
    let pageUrl: string = window.location.href.split('?')[1];
    let value: boolean = false;
    if (pageUrl) {
      pageUrl = pageUrl.replace(/#[^?]+/, '');
      let urlVariables: string[] = pageUrl.split('&');
      for (let i = 0; i < urlVariables.length; i++) {
        let parameter = urlVariables[i].split('=');
        let parameterName: string = parameter[0].toLowerCase();
        if (parameterName == 'showfps') {
          value = decodeURIComponent(parameter[1].replace(/\+/g, '%20')).toLowerCase() == 'true';
        }
      }
    }
    return value;
  }
}
