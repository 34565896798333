import { Gameplay } from "appworks/model/gameplay/gameplay";
import { ForceRequestPayload } from "appworks/model/gameplay/requests/force-request-payload";
import { RequestBuilder } from "appworks/server/builders/request-builder";
import { AxiosRequestConfig } from "axios";
import { GamingRealms } from "gaming-realms/gaming-realms";

export class GMRForceRequestBuilder implements RequestBuilder {

    public filter(payload: any): boolean {
        return payload instanceof ForceRequestPayload;
    }

    public build(gameplay: Gameplay, payload: ForceRequestPayload) {
        const userId = GamingRealms.wrapperConfig.getLogin().getUserId();
        const gameId = GamingRealms.wrapperConfig.getOperatorParameters().getGameId();
        const urlParams = `?userId=${userId}&gameId=${gameId}`;

        return {
            method: "post",
            baseURL: "https://eks.rgs-eu-sta.gamingrealms.net",
            url: "/rng/forced-rng" + urlParams,
            data: (payload.data as any)?.stops || payload.data
        } as AxiosRequestConfig;
    }
}
