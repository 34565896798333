import { gameState } from "appworks/model/game-state";
import { arrayOfValues } from "appworks/utils/collection-utils";
import { SlingoSpinType } from "slingo/integration/slingo-schema";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";

export function GetCurrentSlingoSpinType(getNext?: boolean): SlingoSpinType {
    /**
     * The server sends us a spin type, but it works on a last-in first-out basis
     * i.e. you win a free spin and it is immediately consumed. But we want to present free spins as last-in last-out,
     * i.e. they are saved until after the standard spins, so we need to fudge it, track it ourselves and ignore the server
     */

    const gameplay = gameState.getCurrentGame();
    const currentRecord = gameplay?.getCurrentRecord();
    const gameProgress = currentRecord?.getFirstResultOfType(SlingoGameProgressResult);

    if (!gameProgress) {
        return null;
    }

    // First build a queue of all spins, then work out where in that queue we are
    const spinQueue: SlingoSpinType[] = [];
    spinQueue.push(...arrayOfValues(gameProgress.standardSpins, SlingoSpinType.STANDARD));
    spinQueue.push(...arrayOfValues(gameProgress.freeSpins, SlingoSpinType.FREESPIN));
    spinQueue.push(...arrayOfValues(gameProgress.purchaseSpins, SlingoSpinType.PURCHASE));

    const totalSpinsUsed = gameProgress.standardSpinsUsed + gameProgress.freeSpinsUsed + gameProgress.purchaseSpinsUsed;
    const spinIndex = Math.max(0, totalSpinsUsed - (getNext ? 0 : 1));
    return spinQueue[spinIndex];
}
