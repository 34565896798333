import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { CloseRequestPayload } from "appworks/model/gameplay/requests/close-request-payload";
import { InitRequestPayload } from "appworks/model/gameplay/requests/init-request-payload";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoRecordDisplay } from "slingo/debug/display/record/slingo-record-display";
import { SlingoGameProgressResultDisplay } from "slingo/debug/display/results/slingo-game-progress-result-display";
import { SlingoJokerResultDisplay } from "slingo/debug/display/results/slingo-joker-result-display";
import { SlingoReelSpinResultDisplay } from "slingo/debug/display/results/slingo-reel-spin-result-display";
import { SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoPurchaseEntryRequestPayload } from "slingo/model/requests/slingo-purchase-entry-request-payload";
import { slingoModel } from "slingo/model/slingo-model";
import { IntegrationDebugger } from "slotworks/debug/integration-debugger/integration-debugger";
import { PickRequestPayload } from "slotworks/model/gameplay/requests/pick-request-payload";
import { SpinRequestPayload } from "slotworks/model/gameplay/requests/spin-request-payload";

export function SlingoDebugSetup() {
    (window as any).slingoModel = slingoModel;
    (window as any).GamingRealms = GamingRealms;

    IntegrationDebugger.USE_DEFAULT_BUTTONS = false;

    IntegrationDebugger.addRequest("Init", InitRequestPayload);
    IntegrationDebugger.addRequest("Purchase Entry", SlingoPurchaseEntryRequestPayload);
    IntegrationDebugger.addRequest("Spin", SpinRequestPayload);
    IntegrationDebugger.addRequest("Choose Cell", PickRequestPayload, ["pick"]);
    IntegrationDebugger.addRequest("Collect", CloseRequestPayload);

    IntegrationDebugger.addButtonEnabledCondition("Init", (request, rec) => {
        const record = rec ?? gameState.getCurrentGame().getLatestRecord();
        return !record || (record as SlingoRecord).state === SlingoState.COMPLETE;
    });
    IntegrationDebugger.addButtonEnabledCondition("Purchase Entry", (request, rec) => {
        const record = rec ?? gameState.getCurrentGame().getLatestRecord();
        return record && (record as SlingoRecord).state === SlingoState.PURCHASE_ENTRY;
    });
    IntegrationDebugger.addButtonEnabledCondition("Spin", (request, rec) => {
        const record = rec ?? gameState.getCurrentGame().getLatestRecord();
        return record && [SlingoState.STANDARD_SPIN, SlingoState.PURCHASE_SPIN, SlingoState.FREE_SPIN].indexOf((record as SlingoRecord).state) !== -1;
    });
    IntegrationDebugger.addButtonEnabledCondition("Choose Cell", (request, rec) => {
        const record = rec ?? gameState.getCurrentGame().getLatestRecord();
        return record && (record as SlingoRecord).state === SlingoState.CHOOSE_CELL;
    });
    IntegrationDebugger.addButtonEnabledCondition("Collect", (request, rec) => {
        const record = rec ?? gameState.getCurrentGame().getLatestRecord();
        return record && (record as SlingoRecord).state === SlingoState.PURCHASE_SPIN;
    });

    IntegrationDebugger.addRecordDisplayFunction("SlingoRecord", SlingoRecordDisplay);
    IntegrationDebugger.addResultDisplayFunction("SlingoJokerResult", SlingoJokerResultDisplay);
    IntegrationDebugger.addResultDisplayFunction("SlingoReelSpinResult", SlingoReelSpinResultDisplay);
    IntegrationDebugger.addResultDisplayFunction("SlingoGameProgressResult", SlingoGameProgressResultDisplay);

    (window as any).fullHouse = () => {
        const ticketMatrixComponent = Components.get(SlingoTicketMatrixComponent);
        for (const col of (ticketMatrixComponent as any).gridData) {
            for (const row of col) {
                ticketMatrixComponent.dabSymbol(row).execute();
            }
        }
    }

    (window as any).fullHouseAnim = () => {
        const ticketMatrixComponent = Components.get(SlingoTicketMatrixComponent);
        ticketMatrixComponent.fullHouseAnim().execute();
    }
}
