import { gameState } from "appworks/model/game-state";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoStakeToSpinResult } from "slingo/model/results/slingo-stake-to-spin-result";

export class SlingoStakeToSpinWonDecision extends Decision {
    public evaluate(): Exit {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const stakeToSpinResult = record.getFirstResultOfType(SlingoStakeToSpinResult);

        if (!stakeToSpinResult) {
            return Exit.False;
        }

        if (stakeToSpinResult.rng.isWin) {
            return Exit.True;
        }

        return Exit.False;
    }
}