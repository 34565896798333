import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { AlertComponent } from "slotworks/components/alert/alert-component";

export class RecoverState extends State {
    public onEnter() {

        uiFlags.set(UIFlag.GAME_IN_PROGRESS, true);

        Services.get(TransactionService).setBalance(gameState.getCurrentGame().balance);

        Components.get(AlertComponent).recover().then(() => this.complete());
    }
}
