import { Service } from "./service";

export class Services {
    public static init() {
        this.services.forEach((service) => service.init());
    }

    public static register(service: Service) {
        Services.services.push(service);
    }

    public static deregister(type: { new(...args: any[]): Service }) {
        const index = Services.services.findIndex((value) => value instanceof type);
        if (index !== -1) {
            Services.services.splice(index, 1);
        }
    }

    public static get<T extends Service>(type: { new(...args: any[]): T }): T {
        return Services.services.find((value) => value instanceof type) as T;
    }

    private static services: Service[] = [];
}
