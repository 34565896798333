import { AnimationService } from "appworks/graphics/animation/animation-service";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Orientation } from "appworks/graphics/canvas/orientation";
import { ParticleService } from "appworks/graphics/particles/particle-service";
import { RenderOrientation } from "appworks/graphics/pixi/render-orientation";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { AddWindowFocusChangeListener } from "appworks/utils/browser-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Timer } from "appworks/utils/timer";
import * as TweenJS from "appworks/utils/tween";
import * as Logger from "js-logger";
import { Container, Graphics, Point, Renderer, RenderTexture, Sprite } from "pixi.js";
import { Signal } from "signals";
import Stats = require("stats.js");

export interface AdditionalRenderer {
    renderer: Renderer;
    stage: PIXI.Container;
}
class GameLoop {

    public fakeFps: number = 60;
    public speed: number = 1; // TODO should this be private/protected if we have getter and setter functions?
    public currentFPS: number;

    public onPaused: Signal = new Signal();
    public onUnpaused: Signal = new Signal();
    public onSpeedChange: Signal = new Signal();

    private stats: Stats;
    private statsPosition: Point = new Point(0, 0);
    private statsParticlesPanel: Stats.Panel;

    private adjustedTime: number = null;
    private lastRealTime: number = null;
    private lastScaledTime: number = null;
    private pauseOffset: number = 0;

    private pauseReasons: Map<string, boolean> = new Map<string, boolean>();
    private autoPaused: boolean;

    private forcedFrameDrops: number = 0;
    private frameSkipping: boolean = true;

    private frameRate: number = 60;
    private desiredMSBetweenFrames: number = 1000 / this.frameRate;

    // Dual layout debugging
    private dualMode: boolean = false;
    private landscapeRender: RenderTexture;
    private portraitRender: RenderTexture;
    private dualStage: Container;

    // Keeps track of whether the game window is focused / visible
    private focus: boolean = true;

    private debuggingSpeed: boolean;

    private additionalRenderers: AdditionalRenderer[] = [];

    public init() {
        AddWindowFocusChangeListener((focus: boolean) => { this.changeFocus(focus); });

        (TweenJS as any).getTime = () => {
            return this.lastRealTime;
        };
        (TweenJS as any).getAdjustedTime = () => {
            return this.adjustedTime;
        };

        if (this.dualMode) {
            this.setupDualMode();
        }

        return new Contract<void>((resolve) => {
            requestAnimationFrame((time?: number) => {
                this.render(time);
                this.tick();

                resolve(null);
            });
        });
    }

    public toggleStats() {
        if (this.stats) {
            this.hideStats();
        } else {
            this.showStats();
        }
    }

    public showStats() {
        if (!this.stats) {
            this.stats = new Stats();
            this.statsParticlesPanel = this.stats.addPanel(new Stats.Panel("Particles", "#fff", "#000"));
            this.stats.showPanel(0);
            this.stats.dom.id = "stats";
            document.body.appendChild(this.stats.dom);
        }
    }

    public hideStats() {
        if (this.stats) {
            this.stats.end();
            if (this.stats.dom.parentElement) {
                document.body.removeChild(this.stats.dom);
            }
            this.stats = null;
        }
    }

    /**
     * Sets a manual pause on the game. If called without arguments, pause will toggle
     *
     * @param paused {boolean}
     */
    public setPaused(id: string, paused: boolean = null) {
        if (this.pauseReasons.get(id) === paused) {
            return;
        }

        if (paused === null) {
            if (this.pauseReasons.has(id)) {
                this.pauseReasons.set(id, !this.pauseReasons.get(id));
            } else {
                this.pauseReasons.set(id, true);
            }
        } else {
            this.pauseReasons.set(id, paused);
        }

        const isPaused = this.getPaused();

        if (!isPaused) {
            this.autoPaused = true;
        }

        this.changeFocus(this.focus);

        if (isPaused) {
            this.onPaused.dispatch(id);
        } else {
            this.onUnpaused.dispatch(id);
        }

        this.log(`${isPaused ? "paused" : "unpaused"} by ${id}`);
    }

    public setSpeed(speed: number) {
        if (!this.debuggingSpeed) {
            this.speed = speed;
            this.desiredMSBetweenFrames = 1000 / this.frameRate * this.speed;
            this.onSpeedChange.dispatch(speed);
        }
    }

    // Sets the game speed and latches it on until it's set back to 1
    public setDebugSpeed(speed: number) {
        this.debuggingSpeed = true;
        this.speed = speed;
        this.desiredMSBetweenFrames = 1000 / this.frameRate * this.speed;
        if (this.speed === 1) {
            this.debuggingSpeed = false;
        }
        this.onSpeedChange.dispatch(speed);
    }

    public getSpeed() {
        return this.speed;
    }

    public getPaused(id?: string): boolean {
        if (id) {
            return this.pauseReasons.has(id) && this.pauseReasons.get(id);
        } else {
            const values = this.pauseReasons.values();
            let current = values.next();

            while (!current.done) {
                if (current.value) {
                    return true;
                }
                current = values.next();
            }
            return false;
        }
    }

    public addAdditionalRenderer(additionalRenderer: AdditionalRenderer) {
        this.additionalRenderers.push(additionalRenderer);
    }

    public nextFrame() {
        this.render(this.lastRealTime + (1000/60), 1);
    }

    private changeFocus(focus: boolean) {
        const paused = this.getPaused();
        this.focus = focus;
        if (!focus || paused) {
            this.autoPaused = true;
            if (Services.get(SoundService)) {
                Services.get(SoundService).unfocus();
            }
        } else if (!paused) {
            if (Services.get(SoundService)) {
                Services.get(SoundService).focus();
            }
        }
    }

    private render(realTime: number, speed = this.speed) {
        const deltaTime = realTime - this.lastRealTime;
        this.lastRealTime = realTime;
        this.currentFPS = (1000 / (realTime - this.lastRealTime));

        let scaledTime = this.lastScaledTime + deltaTime * speed;

        if (!this.frameSkipping) {
            if (scaledTime - this.lastScaledTime > this.desiredMSBetweenFrames) {
                scaledTime = this.lastScaledTime + this.desiredMSBetweenFrames;
            }
        }

        this.forcedFrameDrops++;

        if (!this.fakeFps || this.forcedFrameDrops >= (this.frameRate / this.fakeFps)) {
            this.forcedFrameDrops = 0;

            if (this.stats) {
                this.stats.dom.style.left = this.statsPosition.x + "px";
                this.stats.dom.style.top = this.statsPosition.y + "px";
                this.stats.begin();
            }

            if (this.adjustedTime === null) {
                this.adjustedTime = scaledTime;
            }

            const paused = this.getPaused();

            if (this.autoPaused || paused) {
                if (this.lastScaledTime === null) {
                    this.lastScaledTime = scaledTime;
                }
                this.pauseOffset += scaledTime - this.lastScaledTime;
                this.autoPaused = false;
            }

            if (!this.autoPaused && !paused) {
                this.adjustedTime = scaledTime - this.pauseOffset;

                Services.get(AnimationService).update(this.adjustedTime);

                Timer.update(this.adjustedTime);
                Services.get(ParticleService).update(this.adjustedTime);
            }

            TweenJS.update();

            if (this.dualMode) {
                Services.get(CanvasService).stage.portrait.x = 0;
                Services.get(CanvasService).stage.portrait.y = 0;
                Services.get(CanvasService).stage.landscape.x = 0;
                Services.get(CanvasService).stage.landscape.y = 0;
                RenderOrientation.orientation = Orientation.LANDSCAPE;
                Services.get(CanvasService).renderer.render(Services.get(CanvasService).stage, this.landscapeRender);
                RenderOrientation.orientation = Orientation.PORTRAIT;
                Services.get(CanvasService).renderer.render(Services.get(CanvasService).stage, this.portraitRender);

                Services.get(CanvasService).renderer.render(this.dualStage);
            } else {
                Services.get(CanvasService).renderer.render(Services.get(CanvasService).stage);
            }

            this.additionalRenderers.forEach((additionalRenderer) => {
                additionalRenderer.renderer.render(additionalRenderer.stage);
            });

            this.lastScaledTime = scaledTime;

            if (this.stats) {
                const emitters = Services.get(ParticleService).getAll();
                const maxParticles = emitters.map((emitter) => emitter.maxParticles).reduce((total, curr) => total + curr, 0);
                const totalParticles = emitters.map((emitter) => emitter.particleCount).reduce((total, curr) => total + curr, 0);
                this.statsParticlesPanel.update(totalParticles, maxParticles);
                this.stats.end();
            }
        }
    }

    private tick() {
        requestAnimationFrame((t: number) => {
            this.render(t);
            this.tick();
        });
    }

    private setupDualMode() {
        const landscapeViewport = Services.get(CanvasService).viewport.landscape;
        const portraitViewport = Services.get(CanvasService).viewport.portrait;

        this.landscapeRender = RenderTexture.create({
            width: landscapeViewport.width,
            height: landscapeViewport.height,
            resolution: Services.get(CanvasService).resolution
        });
        this.portraitRender = RenderTexture.create({
            width: landscapeViewport.height,
            height: landscapeViewport.width,
            resolution: Services.get(CanvasService).resolution
        });

        const landscapeSprite = new Sprite(this.landscapeRender);
        const portraitSprite = new Sprite(this.portraitRender);

        portraitSprite.width = portraitViewport.width * landscapeViewport.height / portraitViewport.height;
        portraitSprite.height = landscapeViewport.height;

        landscapeSprite.x = portraitSprite.width;
        landscapeSprite.width = landscapeViewport.width - portraitSprite.width;
        landscapeSprite.height = landscapeViewport.height * (landscapeSprite.width / landscapeViewport.width);
        landscapeSprite.y = (landscapeViewport.height - landscapeSprite.height) * 0.5;

        const guideBoxes = new Graphics();
        guideBoxes.lineStyle(2, 0x000000, 1);
        guideBoxes.drawRect(landscapeSprite.x, landscapeSprite.y, landscapeSprite.width, landscapeSprite.height);
        guideBoxes.drawRect(portraitSprite.x, portraitSprite.y, portraitSprite.width, portraitSprite.height);

        this.dualStage = new Container();
        this.dualStage.addChild(guideBoxes);
        this.dualStage.addChild(landscapeSprite);
        this.dualStage.addChild(portraitSprite);
    }

    /**
     * Log a message with colored label
     *
     * @param message {string}
     */
    private log(message: string) {
        Logger.info("%c Game Loop ", "background: #f77bed; color: #fff", message);
    }
}

export const gameLoop = new GameLoop();
