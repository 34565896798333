import { gameState } from "appworks/model/game-state";
import { Result } from "appworks/model/gameplay/records/results/result";
import { ResultBuilder } from "appworks/server/builders/result-builder";
import { Services } from "appworks/services/services";
import { SlingoCompletionReason, SlingoResponse, SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";
import { slingoModel } from "slingo/model/slingo-model";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";

export class SlingoLadderResultBuilder implements ResultBuilder {
    public filter(data: SlingoResponse, record?: SlingoRecord): boolean {
        return Boolean(data.gameProgress);
    }

    public build(record: SlingoRecord, data: SlingoResponse, additionalData?: any): Result[] {
        const result = new SlingoLadderResult();

        result.total = data.gameProgress.matchedPatterns;

        if (data.spinResult?.patternWin) {
            result.increase = data.spinResult.patternWin.numberOfPatterns;
            result.otherPrizes = data.spinResult.patternWin["awards"];

            const stake = Services.get(SlotBetService).getTotalStake();

            const ladderPayouts = slingoModel.read().payoutConfig.patternPayouts;
            const currentTotalWin = ladderPayouts[result.total - 1] ?? 0;
            const previousTotalWin = ladderPayouts[result.total - 1 - result.increase] ?? 0;         

            const win = (currentTotalWin - previousTotalWin) * (stake / 100);

            /**
             * Because wins are sent cumulatively on picks until next spin, we only add
             * values to the last one, and do nothing if state is CHOOSE_CELL.
             * 
             * Duplicate win will also be sent on collect, so check for that too.
             */
            if (record.state !== SlingoState.CHOOSE_CELL &&
                data.gameProgress.completionReason !== SlingoCompletionReason.COLLECTED) {
                result.cashWon = win;
            }
        }

        return [result];
    }
}