import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { AbstractControl } from "../../abstract-control";

export class ButtonControl extends AbstractControl {
    protected onClickCallback: Function;

    constructor(protected button: FlexiButton, visible?: boolean) {
        super();
        this.onClickCallback = () => this.onClick();
        this.button.on(ButtonEvent.CLICK, this.onClickCallback);

        if (visible !== undefined) {
            this.setVisible(visible);
        }
    }

    public destroy(): void {
        this.button.off(ButtonEvent.CLICK, this.onClickCallback);
    }

    public setEnabled(enabled: boolean) {

        this.button.setEnabled(enabled);

        super.setEnabled(enabled);
    }

    public setVisible(visible: boolean) {
        this.button.setVisible(visible);
    }

    protected onClick(): void {
        // Do nothing
    }
}
