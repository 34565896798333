import { gameState } from "appworks/model/game-state";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { gridDisplay } from "../general-displays/grid-display";

export function freespinRecordDisplay(record: SpinRecord) {
    const containerElement = document.createElement("div");
    containerElement.style.width = "100%";
    containerElement.style.height = "80%";

    if (record.grid) {
        const gridElement = gridDisplay(record.grid, record.overlayGrid);
        containerElement.appendChild(gridElement);
    }

    const dataElement = document.createElement("div");
    if (record.reelsetId) {
        dataElement.innerHTML += `Reelset: ${record.reelsetId}<br>`;
    }

    if (record.stops) {
        dataElement.innerHTML += `Stops: ${record.stops.join(",")}<br>`;
    }

    const currentFreespin = gameState.getCurrentGame().getFreespinsPlayed(record, record.parent);
    const totalFreespins = gameState.getCurrentGame().getTotalFreespinsWon(record, record.parent);
    dataElement.innerHTML += "Freespins: " + currentFreespin + " of " + totalFreespins + "<br>";
    dataElement.innerHTML += "Total win: " + gameState.getCurrentGame().getTotalWin();

    containerElement.appendChild(dataElement);

    return containerElement;
}
