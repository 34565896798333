import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { ToggleElement } from "appworks/graphics/elements/toggle-element";
import { Layers } from "appworks/graphics/layers/layers";
import { Text } from "appworks/graphics/pixi/text";
import { uiFlags } from "appworks/ui/flags/ui-flags";
import { ValueList } from "appworks/utils/value-list";
import { slingoModel } from "slingo/model/slingo-model";
import { SlingoResponsibleGamingModel } from "slingo/model/slingo-responsible-gaming-model";
import { Services } from "appworks/services/services";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";

export class SlingoResponsibleGamingMenuControl extends AbstractControl {
    protected localModel: SlingoResponsibleGamingModel;

    protected tglsExtraSpins: ToggleElement[];
    protected tglsMaxSpinPrice: ToggleElement[];
    protected tglsMaxTotalStake: ToggleElement[];
    protected tglsMaxTotalLoss: ToggleElement[];

    protected lblsExtraSpins: Text[];
    protected lblsMaxSpinPrice: Text[];
    protected lblsMaxTotalStake: Text[];
    protected lblsMaxTotalLoss: Text[];

    constructor(protected layer: Layers) {
        super();

        // Make frame block clicks
        layer.getSprite("frame").interactive = true;

        this.localModel = slingoModel.read().responsibleGaming;

        const btnSave = this.layer.getButton("save");
        const btnCancel = this.layer.getButton("cancel");

        this.tglsExtraSpins = [];
        this.tglsMaxSpinPrice = [];
        this.tglsMaxTotalStake = [];
        this.tglsMaxTotalLoss = [];

        this.lblsExtraSpins = [];
        this.lblsMaxSpinPrice = [];
        this.lblsMaxTotalStake = [];
        this.lblsMaxTotalLoss = [];

        // Get buttons
        let i = 0;
        let btnFound = true;
        while (btnFound) {
            btnFound = false;

            const extraSpinToggle = this.layer.getToggle("extra_spins_" + i);
            const extraSpinLabel = this.layer.getText("extra_spins_" + i);
            if (extraSpinToggle) {
                this.tglsExtraSpins.push(extraSpinToggle);
            }
            if (extraSpinLabel) {
                this.lblsExtraSpins.push(extraSpinLabel);
            }

            const maxSpinPriceToggle = this.layer.getToggle("spin_price_" + i);
            const maxSpinPriceLabel = this.layer.getText("spin_price_" + i);
            if (maxSpinPriceToggle) {
                this.tglsMaxSpinPrice.push(maxSpinPriceToggle);
            }
            if (maxSpinPriceLabel) {
                this.lblsMaxSpinPrice.push(maxSpinPriceLabel);
            }

            const maxTotalStakeToggle = this.layer.getToggle("total_stake_" + i);
            const maxTotalStakeLabel = this.layer.getText("total_stake_" + i);
            if (maxTotalStakeToggle) {
                this.tglsMaxTotalStake.push(maxTotalStakeToggle);
            }
            if (maxTotalStakeLabel) {
                this.lblsMaxTotalStake.push(maxTotalStakeLabel);
            }

            const maxTotalLossToggle = this.layer.getToggle("total_loss_" + i);
            const maxTotalLossLabel = this.layer.getText("total_loss_" + i);
            if (maxTotalLossToggle) {
                this.tglsMaxTotalLoss.push(maxTotalLossToggle);
            }
            if (maxTotalLossLabel) {
                this.lblsMaxTotalLoss.push(maxTotalLossLabel);
            }

            if (extraSpinToggle || maxSpinPriceToggle || maxTotalStakeToggle || maxTotalLossToggle) {
                btnFound = true;
                i++;
            }
        }

        // Setup labels
        this.tglsExtraSpins.forEach((tgl, index) => {
            const lblText = this.localModel.extraSpins.values[index].toString();
            tgl.getButtonElement("on")?.setLabelText(lblText);
            tgl.getButtonElement("off")?.setLabelText(lblText);
            if (this.lblsExtraSpins[index]) this.lblsExtraSpins[index].text = lblText;
        });
        this.tglsMaxSpinPrice.forEach((tgl, index) => {
            const lblText = "x" + this.localModel.maxSpinPrice.values[index].toString();
            tgl.getButtonElement("on")?.setLabelText(lblText);
            tgl.getButtonElement("off")?.setLabelText(lblText);
            if (this.lblsMaxSpinPrice[index]) this.lblsMaxSpinPrice[index].text = lblText;
        });
        this.tglsMaxTotalStake.forEach((tgl, index) => {
            const lblText = "x" + this.localModel.maxTotalStake.values[index].toString();
            tgl.getButtonElement("on")?.setLabelText(lblText);
            tgl.getButtonElement("off")?.setLabelText(lblText);
            if (this.lblsMaxTotalStake[index]) this.lblsMaxTotalStake[index].text = lblText;
        });
        this.tglsMaxTotalLoss.forEach((tgl, index) => {
            const lblText = "x" + this.localModel.maxTotalLoss.values[index].toString();
            tgl.getButtonElement("on")?.setLabelText(lblText);
            tgl.getButtonElement("off")?.setLabelText(lblText);
            if (this.lblsMaxTotalLoss[index]) this.lblsMaxTotalLoss[index].text = lblText;
        });

        // Setup buttons
        this.updateToggles();
        this.updateTitles();
        this.tglsExtraSpins.forEach((toggle, index) => {
            toggle.onChanged.add(() => {
                this.onToggleChange(this.localModel.extraSpins, index);
            });
        });
        this.tglsMaxSpinPrice.forEach((toggle, index) => {
            toggle.onChanged.add(() => {
                this.onToggleChange(this.localModel.maxSpinPrice, index);
            });
        });
        this.tglsMaxTotalStake.forEach((toggle, index) => {
            toggle.onChanged.add(() => {
                this.onToggleChange(this.localModel.maxTotalStake, index);
            });
        });
        this.tglsMaxTotalLoss.forEach((toggle, index) => {
            toggle.onChanged.add(() => {
                this.onToggleChange(this.localModel.maxTotalLoss, index);
            });
        });
        btnCancel.once(ButtonEvent.CLICK.getPIXIEventString(), () => uiFlags.closeMenus());
        btnSave.once(ButtonEvent.CLICK.getPIXIEventString(), () => this.saveOptions());
    }

    protected onToggleChange(valueList: ValueList<number>, index: number) {
        const newValue = valueList.values[index];

        if (newValue === valueList.currentValue) {
            valueList.currentValue = undefined;
        } else {
            valueList.currentValue = newValue;
        }

        this.updateToggles();
        this.updateTitles();
    }

    protected updateToggles() {
        this.tglsExtraSpins.forEach((toggle, index) => {
            toggle.setChecked(index === this.localModel.extraSpins.values.lastIndexOf(this.localModel.extraSpins.currentValue));
        });
        this.tglsMaxSpinPrice.forEach((toggle, index) => {
            toggle.setChecked(index === this.localModel.maxSpinPrice.values.lastIndexOf(this.localModel.maxSpinPrice.currentValue));
        });
        this.tglsMaxTotalStake.forEach((toggle, index) => {
            toggle.setChecked(index === this.localModel.maxTotalStake.values.lastIndexOf(this.localModel.maxTotalStake.currentValue));
        });
        this.tglsMaxTotalLoss.forEach((toggle, index) => {
            toggle.setChecked(index === this.localModel.maxTotalLoss.values.lastIndexOf(this.localModel.maxTotalLoss.currentValue));
        });
    }

    protected updateTitles() {
        const currency = Services.get(CurrencyService);
        const stake = Services.get(SlotBetService).getTotalStake();

        const titleExtraSpins = this.layer.getText("title_play_controls_spins");
        const titleMaxSpinPrice = this.layer.getText("title_play_controls_spin_price");
        const titleTotalStake = this.layer.getText("title_play_controls_stake");
        const titleTotalLoss = this.layer.getText("title_play_controls_loss");

        for (const txt of [titleExtraSpins, titleMaxSpinPrice, titleTotalStake, titleTotalLoss]) {
            // clear values in titles
            const valueStartIndex = txt.text.indexOf(" (");
            if (valueStartIndex !== -1) {
                txt.text = txt.text.substring(0, valueStartIndex);
            }
        }

        if (this.localModel.extraSpins.currentValue !== undefined) {
            titleExtraSpins.text += ` (${this.localModel.extraSpins.currentValue})`;
        }
        if (this.localModel.maxSpinPrice.currentValue !== undefined) {
            titleMaxSpinPrice.text += ` (${currency.format(stake * this.localModel.maxSpinPrice.currentValue)})`;
        }
        if (this.localModel.maxTotalStake.currentValue !== undefined) {
            titleTotalStake.text += ` (${currency.format(stake * this.localModel.maxTotalStake.currentValue)})`;
        }
        if (this.localModel.maxTotalLoss.currentValue !== undefined) {
            titleTotalLoss.text += ` (${currency.format(stake * this.localModel.maxTotalLoss.currentValue)})`;
        }
    }

    protected saveOptions() {
        slingoModel.write({ responsibleGaming: this.localModel });
        uiFlags.closeMenus();
    }
}
