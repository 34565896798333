import { Record } from "appworks/model/gameplay/records/record";
import { SlingoState } from "../../integration/slingo-schema";
import { GMRAction } from "gaming-realms/integration/gmr-schema";

export class SlingoRecord extends Record {
    public id: string = "SlingoRecord";

    public ticketId: number;
    public rawTicketData: number[];
    public ticketGrid: number[][];
    public state: SlingoState;
    public actions: GMRAction[];
}
