import { Components } from "appworks/components/components";
import { PaginationComponent, PaginationMode } from "appworks/components/ui/pagination-component";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { LoaderService } from "appworks/loader/loader-service";
import { gameState } from "appworks/model/game-state";
import { InitRequestPayload } from "appworks/model/gameplay/requests/init-request-payload";
import { commsManager } from "appworks/server/comms-manager";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { uiFlags, UIFlag } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { asPercent, precisionRound, roundToMultiple } from "appworks/utils/math/number";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { SlingoCoinHopperComponent } from "slingo/components/slingo-coin-hopper-component";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { slingoModel } from "slingo/model/slingo-model";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { InitState } from "slotworks/state-machine/standard/states/init-state";

export class SlingoInitState extends InitState {
    public onExit(): void {
        super.onExit();

        this.configureRules();

        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord() as SlingoRecord;
        Components.get(SlingoTicketMatrixComponent).setGrid(record.ticketGrid);

        Components.get(MatrixComponent).jumpToGrid([["blank"], ["blank"], ["blank"], ["blank"], ["blank"]]);

        Components.get(SlingoSpinsCounterComponent).setValue(slingoModel.read().gameConfig.standardSpins, false).execute();

        Components.get(SlingoCoinHopperComponent)?.set(slingoModel.read().gameConfig.standardSpins, 0, 0);

        if (record.state === SlingoState.PURCHASE_ENTRY) {
            GamingRealms.setWrapperGameState(so.GameState.PURCHASE_ENTRY);
        }
    }

    protected sendInitRequest(): Contract<void> {
        if (GamingRealms.wrapperConfig?.getLogin()) {
            return new Sequence([
                () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, true)),
                () => commsManager.request(new InitRequestPayload()),
                () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, false)),
                () => Contract.wrap(() => gameState.getCurrentGame().setToLatestRecord()),
                () => Contract.wrap(() => {
                    // this is telling the wrapper we're showing the onboard, but for freerounds sequencing
                    // needs to happen right after we've passed data to the wrapper (which happens when the init record builds),
                    // showOnboard is too late due to PreloaderComponent.loadComplete() call in between
                    GamingRealms.wrapperInstance.updateAction(so.GameAction.RESPONSIBLE_GAMING_OPEN);
                })
            ]);
        } else {
            return Contract.never();
        }
    }

    protected showOnBoard(): Contract<any> {
        return new Contract<void>((resolve) => {
            const onboardLayer = Layers.get("Onboard");
            onboardLayer.setScene("onboard").execute();
            onboardLayer.show();

            const backgroundLayer = Layers.get("Background");
            if (backgroundLayer && backgroundLayer.hasScene("onboard")) {
                backgroundLayer.setScene("onboard").execute();
                backgroundLayer.show();
            }

            const paginationComponent = this.setupOnboardPagination();

            const loadBarBacking = onboardLayer.getSprite("bar_backing");
            const loadBar = onboardLayer.getSprite("load_bar");
            const mask = onboardLayer.getSprite("load_bar_mask") || onboardLayer.getShape("load_bar_mask");
            loadBar.mask = mask;

            const targetWidth = { landscape: mask.landscape.width, portrait: mask.portrait.width };
            mask.landscape.width = mask.portrait.width = 0;

            const onProgress = (stage, progress) => {
                mask.landscape.width = targetWidth.landscape * (progress / 100);
                mask.portrait.width = targetWidth.portrait * (progress / 100);
            }

            const continueButton = onboardLayer.getButton("continue");
            continueButton.setVisible(false);
            continueButton.on(ButtonEvent.CLICK, () => {
                if (paginationComponent) { paginationComponent.hide(); }
                if (backgroundLayer && backgroundLayer.currentSceneIs("onboard")) {
                    backgroundLayer.defaultScene().execute();
                }
                onboardLayer.defaultScene().then(resolve);
                GamingRealms.wrapperInstance.updateAction(so.GameAction.RESPONSIBLE_GAMING_CLOSE);
            });

            const loader = Services.get(LoaderService);
            loader.loadStage(3, onProgress).then(() => {
                uiFlags.set(UIFlag.GAME_STARTED, true);
                continueButton.setVisible(true);

                loadBarBacking.visible = false;
                loadBar.visible = false;
            });
        });
    }

    protected setupOnboardPagination(): PaginationComponent {
        const contentLayer = Layers.get("OnboardPages");

        if (contentLayer) {
            contentLayer.jumpToScene("page_1");
            contentLayer.show();

            const component = new PaginationComponent({
                contentLayerName: "OnboardPages",
                uiLayerName: "Onboard",
                mode: PaginationMode.PAGIFY
            });
            component.autoScroll(5000);
            component.show();

            return component;
        }
    }

    protected configureRules() {
        const rulesFrame = document.getElementById("rules_iframe") as HTMLIFrameElement;
        const rulesDoc = rulesFrame?.contentWindow.document;

        // Set initial spins value
        rulesDoc.body.innerHTML = rulesDoc.body.innerHTML.replace(
            "{game.initial.spins.param.amount}",
            slingoModel.read().gameConfig.standardSpins.toString()
        );

        // Any information in the rules page regarding UK play controls needs to be hidden if non-GB
        const isGB = GamingRealms.getLocale().toLowerCase().includes("gb");
        const playControlsElements = rulesDoc.getElementsByClassName("play_controls");
        for (let i = 0; i < playControlsElements.length; i++) {
            const item = playControlsElements.item(i) as HTMLElement;
            item.style.display = isGB ? "inherit" : "none";
        }

        // We need to add the correct value to the line about autocompleting abandoned games,
        // or hide the section altogether if disabled
        // But this can't be populated in history since the wrapper is unavailable
        if (!GamingRealms.isHistory()) {
            const autoCompleteDiv = rulesDoc.getElementById("div_autocomplete");
            const autoCompleteEnabled = GamingRealms.wrapperConfig.getOperatorConfig().isAutoCompleteEnabled();
            if (autoCompleteDiv) {
                autoCompleteDiv.style.display = autoCompleteEnabled ? "inherit" : "none";
                autoCompleteDiv.innerHTML = autoCompleteDiv.innerHTML.replace("{autocomplete_value}",
                    GamingRealms.wrapperConfig.getOperatorConfig().getAutoCompleteInterval().toString());
                autoCompleteDiv.innerHTML = autoCompleteDiv.innerHTML.replace("{param.amount}",
                    GamingRealms.wrapperConfig.getOperatorConfig().getAutoCompleteInterval().toString());
            }
        }

        // Hide You Could Win section if disabled
        const youCouldWinDiv = rulesDoc.getElementById("div_youcouldwin");
        const youCouldWinEnabled = GamingRealms.wrapperConfig.getOperatorConfig().isYouCouldWinEnabled();
        if (youCouldWinDiv) {
            youCouldWinDiv.style.display = youCouldWinEnabled ? "inherit" : "none";
        }

        // Set RTP values
        const game = GamingRealms.wrapperConfig.getGame();
        const rtp = game.getRtp();
        rulesDoc.body.innerHTML = rulesDoc.body.innerHTML.replace("{Base.RTP}", asPercent(parseFloat(rtp.base)));
        rulesDoc.body.innerHTML = rulesDoc.body.innerHTML.replace("{ExtraSpin.RTP}", asPercent(parseFloat(rtp.extra_spins)));

        // Super Spin Wheel
        const slingoConfig = slingoModel.read();
        if (!slingoConfig.gameConfig.stakeToSpinEnabled) {
            const superSpinWheelDiv = rulesDoc.getElementById("super_spin_wheel");
            if (superSpinWheelDiv) { superSpinWheelDiv.style.display = "none"; }
        } else {
            const stakeToSpinMin = Services.get(CurrencyService).format(slingoConfig.stakeConfig.maximumStake, false);
            const stakeToSpinMaxSpins = slingoConfig.gameConfig.maximumStakeToSpinAttempts;

            rulesDoc.body.innerHTML = rulesDoc.body.innerHTML.replace("{ssw.stakeToSpinMin}", stakeToSpinMin.toString());
            rulesDoc.body.innerHTML = rulesDoc.body.innerHTML.replace("{ssw.stakeToSpinAmount}", stakeToSpinMin.toString());
            rulesDoc.body.innerHTML = rulesDoc.body.innerHTML.replace("{ssw.maxSpins}", stakeToSpinMaxSpins.toString());

            if (stakeToSpinMaxSpins <= 0) {
                const maxSpinsDiv = rulesDoc.getElementById("super_spin_wheel_max_spins");
                if (maxSpinsDiv) { maxSpinsDiv.style.display = "none"; }
            }
        }
    }
}
