import { gameState } from "appworks/model/game-state";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";
import { SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";

export class SlingoSpinPurchasedDecision extends Decision {
    public evaluate(): Exit {
        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord() as SlingoRecord;

        if (record.state === SlingoState.COMPLETE) {
            return Exit.False;
        }

        return Exit.True;
    }
}