import { Flexi } from "appworks/graphics/elements/abstract-flexi";
import { BitmapText } from "appworks/graphics/pixi/bitmap-text";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { HTMLText } from "../pixi/html-text";
import { Text } from "../pixi/text";

export class FlexiText extends Flexi<Text | HTMLElement | BitmapText | HTMLText> {
    private autoResize: boolean = false;
    private visible: boolean = true;

    public setText(text: string) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.textContent = text;
                target.innerHTML = Services.get(TranslationsService).convertTags(target.textContent);

                if (this.autoResize) {
                    this.resizeText(target);
                }
            } else {
                target.text = text;
            }
        }
    }

    public setAutoResize(autoresize: boolean) {
        this.autoResize = autoresize;

        if (this.autoResize) {
            window.addEventListener("resize", this.resizeAllTargets);
        } else {
            window.removeEventListener("resize", this.resizeAllTargets);
        }

        this.resizeAllTargets();
    }

    public setAlpha(alpha: number) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.style.opacity = alpha.toString();
            } else {
                target.alpha = alpha;
            }
        }
    }

    public getAlpha() {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                return Number(target.style.opacity);
            } else {
                return target.alpha;
            }
        }
    }

    public setVisible(visible: boolean) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle(visible ? "hidden" : "visible", false);
                target.classList.toggle(visible ? "visible" : "hidden", true);
            } else {
                target.visible = visible;
            }
        }

        this.visible = visible;
    }

    public getVisible(): boolean {
        return this.visible;
    }

    public setScale(scale: number): void {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.style.scale = scale.toString();
            } else {
                target.scale.set(scale, scale);
            }
        }
    }

    private resizeAllTargets = () => {
        if (this.autoResize) {
            for (const target of this.targets) {
                if (target instanceof HTMLElement) {
                    this.resizeText(target);
                }
            }
        }
    }

    private resizeText(element: HTMLElement): void {
        element.style.setProperty("font-size", "100%");

        let fontSize = 100;

        while (
            ((element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight) ||
            (element.scrollWidth > element.offsetWidth || element.scrollHeight > element.offsetHeight)) &&
            fontSize > 5
            ) {
            fontSize *= .95;
            element.style.setProperty("font-size", fontSize + "%");
        }
    }
}
