import { AbstractComponent } from "appworks/components/abstract-component";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { Layers } from "appworks/graphics/layers/layers";
import { Result } from "appworks/model/gameplay/records/results/result";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { Contract } from "appworks/utils/contracts/contract";
import { FreespinWinResult } from "slotworks/model/gameplay/records/results/freespin-win-result";
import { LineResult } from "slotworks/model/gameplay/records/results/line-result";
import { WaysResult } from "slotworks/model/gameplay/records/results/ways-result";

export enum FooterMessageKey {
    DEFAULT = "fot.new",
    NO_WIN = "fot.no",
    SPIN_START = "fot.pla",
    FREESPIN_START = "fot.fpl",
    GAME_WIN = "fot.gam",
    LINE_WIN = "fot.lin",
    WAY_WIN = "fot.way",
    WAYS_WIN = "fot.ways",
    WAY_TRIGGER = "fot.tri",
    WAYS_TRIGGER = "fot.trs",
    RETRIGGER = "fot.ret",
    FREESPINS = "fot.fre"
}

export interface FooterComponentConfig {
    layer?: Layers;
    // Optional; pass this through to currency format whenever it is used in this component
    forceCreditMode?: boolean;
}

export class FooterComponent extends AbstractComponent {
    public hideTotalWin: boolean = false;

    protected layer: Layers;

    protected textValue: string = "";
    protected text: FlexiText;

    protected config?: FooterComponentConfig = {
    }

    constructor(config?: Partial<FooterComponentConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }

        this.layer = this.config.layer || Layers.get("Footer");
        this.layer.onSceneEnter.add(() => this.refresh());

        this.layer.defaultScene().execute();

        this.refresh();

        this.showDefaultText();
    }

    public showNoWin() {
        if (Services.get(TranslationsService).exists(FooterMessageKey.NO_WIN)) {
            this.setText(Services.get(TranslationsService).get(FooterMessageKey.NO_WIN));
        } else {
            this.clear();
        }
    }

    public showSpinStart(betValue: number) {
        this.setText(Services.get(TranslationsService).get(FooterMessageKey.SPIN_START, { bet: Services.get(CurrencyService).format(betValue, false, this.getForceCreditMode()) }));
    }

    public showFreespinStart() {
        const id = FooterMessageKey.FREESPIN_START;
        const message = Services.get(TranslationsService).get(id);

        if (id !== message) {
            this.setText(message);
        } else {
            this.clear();
        }
    }

    public showGameWin(winValue: number) {
        if (!this.hideTotalWin) {
            this.setText(Services.get(TranslationsService).get(FooterMessageKey.GAME_WIN, { win: Services.get(CurrencyService).format(winValue, false, this.getForceCreditMode()) }));
        }
        return Contract.empty();
    }

    public showLineWin(result: LineResult, symbol?: string) {
        const win = Services.get(CurrencyService).format(result.cashWon, false, this.getForceCreditMode());
        this.setText(Services.get(TranslationsService).get(FooterMessageKey.LINE_WIN, { line: result.line, linewin: win, win, matches: result.matches, symbol }));
        return Contract.empty();
    }

    public showWaysWin(result: WaysResult, symbol: string) {
        const winData = {
            payout_per_symbols: Services.get(CurrencyService).format(result.perWayPayout, false, this.getForceCreditMode()),
            number_of_ways: result.ways,
            total_payout: Services.get(CurrencyService).format(result.cashWon, false, this.getForceCreditMode()),
            triggered: 0
        };

        if (result.linkedResult instanceof FreespinWinResult) {
            winData.triggered = result.linkedResult.freespinsWon;
        }

        let message = result.ways > 1 ? FooterMessageKey.WAYS_WIN : FooterMessageKey.WAY_WIN;

        if (winData.triggered > 0) {
            message = result.ways > 1 ? FooterMessageKey.WAYS_TRIGGER : FooterMessageKey.WAY_TRIGGER;
        }

        this.setText(Services.get(TranslationsService).get(message, winData));
    }

    public showMiscWin(textId: string, result: Result) {
        this.setText(Services.get(TranslationsService).get(textId));
    }

    public showFreeSpinsTriggered(freespinsWon?: number) {
        const id = FooterMessageKey.FREESPINS;
        const message = Services.get(TranslationsService).get(id, { freespins: freespinsWon });

        if (id !== message) {
            this.setText(message);
        }
    }

    public showFreeSpinsRetriggered(freespinsWon?: number) {
        const id = FooterMessageKey.RETRIGGER;
        const message = Services.get(TranslationsService).get(id, { freespins: freespinsWon, SPINS: freespinsWon });

        if (id !== message) {
            this.setText(message);
        }
    }

    public showCustomMessage(message: string) {
        this.setText(message);
    }

    public showBigWinMessage(tier: number) {
        // Custom implementation
    }

    public showDefaultText() {
        this.setText(Services.get(TranslationsService).get(FooterMessageKey.DEFAULT));
    }

    public clear() {
        this.setText("");
    }

    public getForceCreditMode(): boolean {
        return this.config.forceCreditMode;
    }

    protected setText(text: string) {
        this.textValue = text;
        if (this.text) {
            this.text.setText(text);
        }
    }

    protected refresh() {
        this.text = this.layer.getFlexiText("value");
        this.setText(this.textValue);
    }
}
