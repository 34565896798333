import { Contract } from 'appworks/utils/contracts/contract';
import { Sequence } from 'appworks/utils/contracts/sequence';
import { gameLayers } from 'game-layers';
import { GamingRealms } from 'gaming-realms/gaming-realms';
import { slingoModel } from 'slingo/model/slingo-model';
import { UIFlag, uiFlags } from 'appworks/ui/flags/ui-flags';
import { Services } from 'appworks/services/services';
import { SlotBetService } from 'slotworks/services/bet/slot-bet-service';
import { ValueList } from 'appworks/utils/value-list';
import { ClientController } from 'client-controller';
import { PopInitState } from 'state-machine/states/pop-init-state';

/**
 * Social init state, handles client bet stops
 *
 * @class SocialInitState
 * @typedef {SocialInitState}
 * @extends {PYLInitState}
 */
export class SocialInitState extends PopInitState {
  private clientBetsHandled = false;
  private betsTimeout = 20000;

  /**
   * Send game ready, set bet stakes timeout
   *
   * @public
   * @override
   */
  public onEnter(): void {
    this.setupGameInactiveTimer();
    new Sequence([
      () => this.sendInitRequest(),
      () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, true)),
      () => Contract.wrap(() => ClientController.getInstance().sendGameReady(this)),
    ]).execute();
    setTimeout(() => {
      if (!this.clientBetsHandled) {
        this.enter();
      }
    }, this.betsTimeout);
  }

  /**
   * Enter game sequence
   *
   * @private
   */
  private enter(): void {
    new Sequence([
      () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, false)),
      () => this.showOnBoard(),
      () => this.gameLoaded(),
      () => this.startGame(),
    ]).then(() => this.complete());
  }

  /**
   * Inject bet stakes into model
   *
   * @private
   */
  private handleBets(): void {
    const modelCopy = slingoModel.read();
    modelCopy.stakeConfig.amounts = ClientController.getInstance().betStakes.map(
      stake => stake * 100,
    );
    slingoModel.write(modelCopy);
    const stakeConfig = slingoModel.read().stakeConfig;

    Services.get(SlotBetService).setDefaults(
      new ValueList<number>(
        stakeConfig.amounts,
        stakeConfig.amounts[stakeConfig.defaultIndex],
        stakeConfig.lastStake || stakeConfig.amounts[stakeConfig.defaultIndex],
      ),
      new ValueList<number>([1], 1, 1),
    );
    this.clientBetsHandled = true;
  }

  /**
   * Inject bet stakes and enter game
   *
   * @public
   */
  public clientBetsReceived(): void {
    this.handleBets();
    this.enter();
  }
}
