import { ForceList } from "slotworks/debug/menu/force-list";

export abstract class DebugTab {
    protected name: string;
    protected resultList?: ForceList;

    constructor(name: string, resultList?: ForceList) {
        this.name = name;
        this.resultList = resultList;
    }

    public getName(): string {
        return this.name;
    }

    public abstract render(root: HTMLElement, hide: Function): void;

    protected addResultButton(root: HTMLElement, hide: Function, id: string, label?: string) {
        const btn = document.createElement("button");

        btn.style.fontSize = "1vmax";
        btn.style.margin = "1vw 1vh";
        btn.style.borderRadius = "10px";
        btn.style.width = "17.3vmax";
        btn.style.height = "10vmax";
        btn.style.backgroundColor = "#ffffff";
        btn.style.outline = "none";
        btn.style.cursor = "pointer";
        btn.innerText = label || id;

        btn.onclick = () => {
            this.resultList.force(id).execute();
            hide();
        };

        root.appendChild(btn);

        return btn;
    }

    protected addSeparator(root: HTMLElement) {
        root.appendChild(document.createElement("hr"));
    }
}
