import { stateMachine } from "appworks/state-machine/state-machine";
import { SlingoFreeSpinsRemainingDecision } from "slingo/states/decisions/slingo-free-spins-remaining-decision";
import { SlingoGameInProgressDecision } from "slingo/states/decisions/slingo-game-in-progress-decision";
import { SlingoHasCascadesDecision } from "slingo/states/decisions/slingo-has-cascades-decision";
import { SlingoHasJokerDecision } from "slingo/states/decisions/slingo-has-joker-decision";
import { SlingoRecoveryIsBeforeFirstSpinDecision } from "slingo/states/decisions/slingo-recovery-is-before-first-spin-decision";
import { SlingoSpinPurchasedDecision } from "slingo/states/decisions/slingo-spin-purchased-decision";
import { SlingoStakeToSpinWonDecision } from "slingo/states/decisions/slingo-stake-to-spin-won-decision";
import { SlingoStandardSpinsRemainingDecision } from "slingo/states/decisions/slingo-standard-spins-remaining-decision";
import { SlingoTicketCompletedDecision } from "slingo/states/decisions/slingo-ticket-completed-decision";
import { SlingoBigWinState } from "slingo/states/slingo-big-win-state";
import { SlingoGameOverState } from "slingo/states/slingo-game-over-state";
import { SlingoPurchaseEntryState } from "slingo/states/slingo-purchase-entry-state";
import { IsRecoveryDecision } from "slotworks/state-machine/standard/decisions/is-recovery-decision";
import { PopCascadeState } from "state-machine/states/pop-cascade-state";
import { PopFullHouseState } from "state-machine/states/pop-full-house-state";
import { PopIdleState } from "state-machine/states/pop-idle-state";
import { PopInitState } from "state-machine/states/pop-init-state";
import { PopPickJokerState } from "state-machine/states/pop-pick-joker-state";
import { PopPurchaseSpinOrCollectState } from "state-machine/states/pop-purchase-spin-or-collect-state";
import { PopRecoveryState } from "state-machine/states/pop-recovery-state";
import { PopRequestSpinState } from "state-machine/states/pop-request-spin-state";
import { PopScatterWinState } from "state-machine/states/pop-scatter-win-state";
import { PopSpinState } from "state-machine/states/pop-spin-state";

// State diagram: https://drive.google.com/file/d/1__bTaNQ8AlppWy0wn_CkDIQqn07BG1Dr/view?usp=sharing

export function PopStateSetup() {
    // States
    const initState = new PopInitState();
    const idleState = new PopIdleState();
    const recoveryState = new PopRecoveryState();
    const purchaseEntryState = new SlingoPurchaseEntryState();
    const requestSpinState = new PopRequestSpinState();
    const stakeToSpinRequestSpinState = new PopRequestSpinState({ skipRequest: true });
    const spinState = new PopSpinState();
    const pickJokerState = new PopPickJokerState();
    const purchaseOrCollectState = new PopPurchaseSpinOrCollectState({ hideSpinButtonWhenPurchaseButtonVisible: true });
    const gameOverState = new SlingoGameOverState();
    const bigWinState = new SlingoBigWinState();
    const fullHouseState = new PopFullHouseState();
    const cascadeState = new PopCascadeState();
    const scatterWinState = new PopScatterWinState();

    // Decisions
    const isRecoveryDecision = new IsRecoveryDecision();
    const gameInProgressDecision = new SlingoGameInProgressDecision();
    const hasJokerDecision = new SlingoHasJokerDecision();
    const standardSpinsRemainingDecision = new SlingoStandardSpinsRemainingDecision();
    const freeSpinsRemainingDecision = new SlingoFreeSpinsRemainingDecision();
    const ticketCompletedDecision = new SlingoTicketCompletedDecision();
    const spinPurchasedDecision = new SlingoSpinPurchasedDecision();
    const recoveryBeforeFirstSpinDecision = new SlingoRecoveryIsBeforeFirstSpinDecision();
    const hasCascadesDecision = new SlingoHasCascadesDecision();
    const isStakeToSpinWonDecision = new SlingoStakeToSpinWonDecision();

    stateMachine.addStates([
        { state: initState, entryPoint: true, links: { complete: isRecoveryDecision } },
        { state: recoveryState, links: { complete: recoveryBeforeFirstSpinDecision } },

        { state: idleState, links: { complete: gameInProgressDecision } },
        { state: purchaseEntryState, links: { complete: requestSpinState, error: idleState } },
        { state: requestSpinState, links: { complete: spinState, error: purchaseOrCollectState } },
        { state: stakeToSpinRequestSpinState, links: { complete: spinState } },
        { state: spinState, links: { complete: scatterWinState } },
        { state: scatterWinState, links: { complete: ticketCompletedDecision } },
        { state: pickJokerState, links: { complete: ticketCompletedDecision } },
        { state: purchaseOrCollectState, links: { complete: isStakeToSpinWonDecision } },
        { state: bigWinState, links: { complete: gameOverState } },
        { state: gameOverState, links: { complete: idleState } },
        { state: fullHouseState, links: { complete: bigWinState } },
        { state: cascadeState, links: { complete: scatterWinState } }
    ]);

    stateMachine.addDecisions([
        { decision: isRecoveryDecision, links: { true: recoveryState, false: idleState } },
        { decision: recoveryBeforeFirstSpinDecision, links: { true: requestSpinState, false: spinState } },
        { decision: gameInProgressDecision, links: { true: requestSpinState, false: purchaseEntryState } },
        { decision: hasJokerDecision, links: { true: pickJokerState, false: standardSpinsRemainingDecision } },
        { decision: standardSpinsRemainingDecision, links: { true: requestSpinState, false: freeSpinsRemainingDecision } },
        { decision: freeSpinsRemainingDecision, links: { true: requestSpinState, false: purchaseOrCollectState } },
        { decision: spinPurchasedDecision, links: { true: requestSpinState, false: bigWinState } },
        { decision: ticketCompletedDecision, links: { true: fullHouseState, false: hasCascadesDecision } },
        { decision: hasCascadesDecision, links: { true: cascadeState, false: hasJokerDecision } },
        { decision: isStakeToSpinWonDecision, links: { true: stakeToSpinRequestSpinState, false: spinPurchasedDecision } },
    ]);
}
