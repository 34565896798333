import { Gameplay } from "appworks/model/gameplay/gameplay";
import { RequestBuilder } from "appworks/server/builders/request-builder";
import { AxiosRequestConfig } from "axios";
import { SpinRequestPayload } from "slotworks/model/gameplay/requests/spin-request-payload";

export class SlingoSpinRequestBuilder implements RequestBuilder {
    public filter(data: any): boolean {
        return data instanceof SpinRequestPayload;
    }

    public build(gameplay: Gameplay, data: any) {
        return {
            method: "put",
            url: "/actions/SPIN",
            data: {}
        } as AxiosRequestConfig;
    }

}
