import { AbstractComponent } from "appworks/components/abstract-component";
import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { Layers } from "appworks/graphics/layers/layers";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { Point } from "appworks/utils/geom/point";
import { logger } from "appworks/utils/logger";
import * as TweenJS from "appworks/utils/tween";
import { DisplayObject, Filter, Graphics } from "pixi.js";

export interface LinesComponentConfig {
    // Duration in ms to mask in the line (default 0 = no masking)
    maskSwipeDuration?: number;
    /**
     * It is common to reuse a line graphic for inverted / mirrors lines (flips on x or y axis)
     * To flip lines, set it's index (0 indexed) on this map and the axis or axes you want to flip
     * e.g. lineFlips.set(2, new Point(1, 0)); // Flips line 2 on the x axis
     * Line sprites only, this is redundant for runtime drawn lines as no flipping would be required
     */
    lineFlips?: Map<number, Point>;
}

export class LinesComponent extends AbstractComponent {

    // TODO: V5 DisplayObject should not be used, as it has no dual position
    protected lines: DisplayObject[];
    protected highlightLines: DisplayObject[];
    protected pips: DisplayObject[][];
    protected masks: DisplayObject[];

    protected config: LinesComponentConfig;

    constructor(protected lineDefinitions: Point[][], config?: LinesComponentConfig) {
        super();

        this.lines = [];
        this.highlightLines = [];
        this.pips = [];
        this.masks = [];

        if (!config) {
            this.config = {
                maskSwipeDuration: 0
            };
        } else {
            this.config = config;
        }
    }

    public init() {
        this.draw(this.lineDefinitions);
    }

    public draw(lineDefinitions: Point[][]) {
        if (this.lines && this.lines.length) {
            for (const line of this.lines) {
                if (line instanceof Graphics) {
                    line.clear();
                }

                Layers.get("Paylines").remove(line);
            }
        }

        this.lines = [];
        this.highlightLines = [];

        for (let lineIndex = 0; lineIndex < lineDefinitions.length; lineIndex++) {
            const lineDefinition = lineDefinitions[lineIndex];

            let line: Graphics | Sprite;
            let highlightLine: Graphics | Sprite;

            const lineSprite =
                Layers.get("Paylines").getSprite("line_" + (lineIndex + 1)) ??
                Layers.get("Paylines").getAnimatedSprite("line_" + (lineIndex + 1));
            let highlightLineSprite =
                Layers.get("Paylines").getSprite("highlight_line_" + (lineIndex + 1)) ??
                Layers.get("Paylines").getAnimatedSprite("highlight_line_" + (lineIndex + 1));

            if (!highlightLineSprite) {
                highlightLineSprite = lineSprite;
            }

            if (lineSprite) {
                // Graphical lines (assetworks)
                line = lineSprite;
                highlightLine = highlightLineSprite;

                if (this.config.lineFlips?.has(lineIndex)) {
                    this.flip(lineIndex, line);
                    if (line !== highlightLine) {
                        this.flip(lineIndex, highlightLine);
                    }
                }
            } else {
                // Draw them ourselves
                line = this.drawLine(lineDefinition, 10, 0xff0000, 0.2);
                this.drawLine(lineDefinition, 8, 0xff0000, 0.4, line);
                this.drawLine(lineDefinition, 6, 0xff0000, 0.6, line);
                this.drawLine(lineDefinition, 2, 0xffffff, 1, line);

                highlightLine = line;
            }

            line.visible = false;
            highlightLine.visible = false;

            this.lines.push(line);
            this.highlightLines.push(highlightLine);

            Layers.get("Paylines").add(line);
            Layers.get("Paylines").add(highlightLine);
        }

        this.drawPips(lineDefinitions);
    }

    public win(id: number, reverse: boolean = false) {
        const line = this.lines[id - 1];

        this.show(line, reverse);
        this.showPips(id);
    }

    public highlight(id: number, reverse: boolean = false) {
        const line = this.highlightLines[id - 1];
        this.show(line, reverse);
    }

    public hideAllLines() {
        for (const line of this.lines) {
            line.visible = false;
        }
        for (const line of this.highlightLines) {
            line.visible = false;
        }

        this.pips.forEach((pipSet) => {
            pipSet.forEach((pip) => {
                pip.visible = false;
            });
        });

        Services.get(SoundService).event(SoundEvent.win_line_hide);

        this.clearMasks();
    }

    public tint(id: number, color: number) {
        const line = this.lines[id - 1];
        if (line instanceof Sprite) {
            line.tint = color;
        }

        const highlightLine = this.highlightLines[id - 1];
        if (highlightLine instanceof Sprite) {
            highlightLine.tint = color;
        }
    }

    public filter(id: number, filters: Filter[]) {
        this.lines[id - 1].filters = this.highlightLines[id - 1].filters = null;
        this.lines[id - 1].filters = this.highlightLines[id - 1].filters = filters;
    }

    protected show(line: DisplayObject, reverse: boolean = false) {
        line.visible = true;

        Services.get(SoundService).event(SoundEvent.win_line_show);

        if (line instanceof AnimatedSprite) {
            line.reverse = reverse;
            line.gotoAndPlay();
            line.onComplete.addOnce(() => {
                Services.get(SoundService).event(SoundEvent.win_line_hide);
            });
        }

        if (line instanceof Sprite) {
            this.maskLine(line);
        }
    }

    protected maskLine(line: Sprite, easing: (k: number) => number = TweenJS.Easing.Linear.None) {
        if (!this.config.maskSwipeDuration) {
            return;
        }

        const sourceMask = Layers.get("Paylines").getPosition("mask");

        if (!sourceMask) {
            logger.warn("No line mask found");
            return;
        }

        const mask = new SmartShape();

        this.masks.push(mask);

        mask.shapeFillColor = 0xff0000;
        mask.shapeFillAlpha = 1;
        mask.landscape = sourceMask.landscape;
        mask.portrait = sourceMask.portrait;

        mask.scale.x = 0;

        new TweenJS.Tween(mask.scale)
            .to({ x: 1 }, this.config.maskSwipeDuration)
            .easing(easing)
            .start();

        line.mask = mask;
        Layers.get("Paylines").add(mask);
    }

    protected clearMasks() {
        this.masks.forEach((mask) => {
            if (mask) {
                TweenJS.removeAll(mask.scale);
                mask.destroy();
            }
        });

        this.masks = [];
    }

    protected showPips(id: number) {
        for (const pips of this.pips) {
            for (const pip of pips) {
                pip.visible = true;
            }
        }

        const lineIndex = id - 1;
        if (lineIndex < this.pips.length) {
            const activePips = this.pips[lineIndex];

            for (const activePip of activePips) {
                if (activePip instanceof AnimatedSprite) {
                    activePip.gotoAndPlay();

                    Layers.get("SymbolAnimations").add(activePip);
                }
            }
        }
    }

    protected flip(id: number, line: Sprite) {
        const flip = this.config.lineFlips?.get(id);
        if (flip) {
            if (flip.x) {
                line.scale.x = -line.scale.x;
            }
            if (flip.y) {
                line.scale.y = -line.scale.y;
                line.y += line.height;
            }
        }
    }

    protected drawLine(lineDefinition: Point[], weight: number, color: number, alpha: number, graphics?: Graphics): Graphics {
        if (!graphics) {
            graphics = new Graphics();
        }

        graphics.lineStyle(weight, color, alpha);

        const firstPosition = lineDefinition[0];
        const firstSymbolPosition = Layers.get("MatrixContent").getPosition("symbol_" + firstPosition.x + "_" + firstPosition.y).landscape;
        graphics.moveTo(firstSymbolPosition.x, firstSymbolPosition.y + firstSymbolPosition.height * 0.5);

        for (const point of lineDefinition) {
            const x = point.x;
            const y = point.y;

            const symbolPosition = Layers.get("MatrixContent").getPosition(`symbol_${x}_${y}`).landscape;

            graphics.lineTo(symbolPosition.x + symbolPosition.width * 0.5, symbolPosition.y + symbolPosition.height * 0.5);
        }

        const lastPosition = lineDefinition[lineDefinition.length - 1];
        const lastSymbolPosition = Layers.get("MatrixContent").getPosition("symbol_" + lastPosition.x + "_" + lastPosition.y).landscape;
        graphics.lineTo(lastSymbolPosition.x + lastSymbolPosition.width, lastSymbolPosition.y + lastSymbolPosition.height * 0.5);

        return graphics;
    }

    protected drawPips(lineDefinitions: Point[][]) {

        this.pips = [];

        for (let lineIndex = 0; lineIndex < lineDefinitions.length; lineIndex++) {
            const pipLeft = Layers.get("Paylines").getAnimatedSprite(`line_${lineIndex + 1}_number_left`);
            const pipRight = Layers.get("Paylines").getAnimatedSprite(`line_${lineIndex + 1}_number_right`);
            const pips = [];

            if (pipLeft) {
                pipLeft.visible = false;
                pips.push(pipLeft);
            }
            if (pipRight) {
                pipRight.visible = false;
                pips.push(pipRight);
            }

            this.pips.push(pips);
        }
    }
}
