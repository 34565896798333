import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Layers } from "appworks/graphics/layers/layers";
import { Services } from "appworks/services/services";
import { KeyInput } from "appworks/utils/key-input";
import { KeyboardCode } from "appworks/utils/keyboard";

export class LayerDebugger {
    private groupElement: HTMLDivElement;

    constructor() {
        const keyInput = new KeyInput();
        keyInput.onKeyUp.add((key: string, e: KeyboardEvent) => {
            if (e.shiftKey) {
                if (key === KeyboardCode.L) {
                    this.toggle();
                }
            }
        });
    }

    public start() {
        this.draw();
        this.updateLayers();
        Layers.onEnterScene.add(() => this.updateLayers());
    }

    public toggle() {
        if (!this.groupElement) {
            this.start();
        } else {

            if (this.groupElement.parentElement) {
                Services.get(CanvasService).rootNode.removeChild(this.groupElement);
            } else {
                Services.get(CanvasService).rootNode.appendChild(this.groupElement);
            }
        }
    }

    private draw() {
        this.groupElement = document.createElement("div");
        this.groupElement.id = "LayerDebugger-groups";

        this.groupElement.style.position = "fixed";
        this.groupElement.style.top = "0";
        this.groupElement.style.right = "5vw";
        this.groupElement.style.zIndex = "900";
        this.groupElement.style.height = "94vh";
        this.groupElement.style.width = "30vw";
        this.groupElement.style.marginTop = "3vh";
        this.groupElement.style.paddingLeft = "3vw";
        this.groupElement.style.pointerEvents = "all";
        this.groupElement.style.overflow = "auto";

        this.groupElement.style.backgroundColor = "rgba(50,50,50,.5)";
        this.groupElement.style.border = "solid 1px white";

        this.groupElement.style.color = "#fff";
        this.groupElement.style.fontSize = "20px";

        Services.get(CanvasService).rootNode.appendChild(this.groupElement);
    }

    private updateLayers() {
        while (this.groupElement.firstChild) {
            this.groupElement.removeChild(this.groupElement.firstChild);
        }

        let i = 0;

        const names: string[] = [];

        Layers.getContainer().children.forEach((child) => {
            names.push(child.name);
        });

        names.reverse();

        names.forEach((name) => {

            const label = document.createElement("div");

            const toggle = document.createElement("input");
            toggle.style.webkitTransform = "scale(1.5)";
            toggle.style.transform = "scale(1.5)";
            toggle.setAttribute("type", "checkbox");

            const layer = Layers.get(name);
            if (layer) {
                toggle.setAttribute("id", `checkbox-${name}`);
                toggle.setAttribute("checked", layer.container.visible.toString());
                toggle.addEventListener("change", (event) => this.onChangeLayerVisibility(event, layer));
            } else {
                toggle.setAttribute("disabled", "true");
            }
            label.appendChild(toggle);

            const layerNameSpan = document.createElement("span");
            layerNameSpan.innerHTML = ` ${i}: ${name} `;
            layerNameSpan.style.color = "white";
            label.appendChild(layerNameSpan);

            const sceneNameSpan = document.createElement("span");
            sceneNameSpan.innerHTML = `(${layer.getCurrentScene() ? layer.getCurrentScene().name : null})`;
            sceneNameSpan.style.color = "#aaa";
            label.appendChild(sceneNameSpan);

            this.groupElement.appendChild(label);

            i++;
        });
    }

    private onChangeLayerVisibility(event: any, layer: Layers): void {
        if (event.target.checked) {
            layer.show();
        } else {
            layer.hide();
        }
    }
}
