import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { Timer } from "appworks/utils/timer";
import { AbstractControl } from "../../abstract-control";

export class TimeControl extends AbstractControl {

    protected interval: number;

    constructor(protected label: FlexiText) {
        super();
        this.interval = Timer.setInterval(() => this.updateText(), 1000);
        this.updateText();
    }

    public destroy() {
        Timer.clearInterval(this.interval);
    }

    protected getTime() {
        const today = new Date();
        const hours = today.getHours();
        const minutes = today.getMinutes();

        return this.leadZeroes(hours) + ":" + this.leadZeroes(minutes);
    }

    protected updateText(){
        this.label.setText(this.getTime());
    }

    protected leadZeroes(n: number) {
        if (n < 10) {
            return "0" + n.toString();
        } else {
            return n.toString();
        }
    }
}