import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { SlingoJokerResult } from "./slingo-joker-result";

export class SlingoCascadeResult extends SlingoReelSpinResult {
    public resultType: string = "SlingoCascadeResult";
    public played: boolean;

    public jokerResult: SlingoJokerResult;
    // Array of booleans to mark symbols as new ones which have dropped in, or ones that remain from previous spins
    public newSymbols: boolean[];
}
