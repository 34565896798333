import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Tween } from "appworks/utils/tween";
import { Contract } from "../contracts/contract";
import { TweenContract } from "../contracts/tween-contract";
import { Point } from "../geom/point";
import { Easing } from "appworks/utils/tween";

export interface DualTransformable {
    landscape: Transformable;
    portrait: Transformable;
}

export interface Transformable {
    x: number;
    y: number;
    width: number;
    height: number;
    scale: Point;
}

export function dualTransform<A extends DualTransformable, B extends DualTransformable>(a: A, b: B, duration = 1000, easing = Easing.Linear.None): Contract {
    return new Parallel([
        () => transform(a.landscape, b.landscape, duration, easing),
        () => transform(a.portrait, b.portrait, duration, easing)
    ]);
}

export function transform<T extends Transformable>(a: T, b: T, duration = 1000, easing = Easing.Linear.None): Contract {
    const tween = new Tween(a).to(b, duration).easing(easing);
    return TweenContract.wrapCancellableTween(tween);
}