import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { FlexiSprite } from "appworks/graphics/elements/flexi-sprite";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";

enum SOUND_STATE {
    SOUND_ON,
    NO_MUSIC,
    SOUND_OFF
}

export class SoundToggleComponent {

    protected onButton: FlexiButton;
    protected offButton: FlexiButton;
    protected noMusicButton: FlexiButton;

    protected loadingSprite: FlexiSprite;

    protected soundState: SOUND_STATE;

    constructor(onButton: FlexiButton, offButton: FlexiButton, loadingSprite?: FlexiSprite, noMusicButton?: FlexiButton) {
        this.onButton = onButton;
        this.offButton = offButton;
        this.noMusicButton = noMusicButton;

        this.onButton.on(ButtonEvent.CLICK, this.buttonHandler);
        this.noMusicButton?.on(ButtonEvent.CLICK, this.buttonHandler);
        this.offButton.on(ButtonEvent.CLICK, this.buttonHandler);

        this.loadingSprite = loadingSprite;
        this.loadingSprite?.setVisible(false);

        Services.get(SoundService).onMuteChange.add(this.muteChangeHandler);

        this.soundState = SOUND_STATE.SOUND_ON;
        if (Services.get(SoundService).getMuted()) {
            this.soundState = SOUND_STATE.SOUND_OFF;
        } else if (Services.get(SoundService).getMusicMuted()) {
            this.soundState = SOUND_STATE.NO_MUSIC;
        }
        this.updateSoundButtons();
    }

    public destroy() {
        this.onButton.off(ButtonEvent.CLICK, this.buttonHandler);
        this.noMusicButton?.off(ButtonEvent.CLICK, this.buttonHandler);
        this.offButton.off(ButtonEvent.CLICK, this.buttonHandler);

        Services.get(SoundService).onMuteChange.remove(this.muteChangeHandler);
        Services.get(SoundService).onLoadComplete.remove(this.loadCompleteHandler);
    }

    public setEnabled(enabled: boolean) {
        this.onButton.setEnabled(enabled);
        this.offButton.setEnabled(enabled);
        this.noMusicButton?.setEnabled(enabled);
    }

    public setVisible(visible: boolean) {
        if (visible) {
            this.updateSoundButtons();
        } else {
            this.loadingSprite?.setVisible(false);

            this.onButton.setVisible(false);
            this.onButton.setEnabled(false);

            this.offButton.setVisible(false);
            this.offButton.setEnabled(false);

            this.noMusicButton?.setVisible(false);
            this.noMusicButton?.setEnabled(false);
        }
    }

    protected buttonHandler = () => this.nextState();
    protected muteChangeHandler = (muted) => this.setState(muted ? SOUND_STATE.SOUND_OFF : SOUND_STATE.SOUND_ON);
    protected loadCompleteHandler = () => this.loadComplete();

    protected nextState() {
        switch (this.soundState) {
            case SOUND_STATE.SOUND_ON:
                this.setState(this.noMusicButton ? SOUND_STATE.NO_MUSIC : SOUND_STATE.SOUND_OFF);
                break;

            case SOUND_STATE.NO_MUSIC:
                this.setState(SOUND_STATE.SOUND_OFF);
                break;

            case SOUND_STATE.SOUND_OFF:
                this.setState(SOUND_STATE.SOUND_ON);
                break;

        }
    }

    protected setState(state?: SOUND_STATE) {
        switch (state) {
            case SOUND_STATE.SOUND_ON:
                Services.get(SoundService).setMusicMute(false);
                Services.get(SoundService).unmute();
                break;

            case SOUND_STATE.NO_MUSIC:
                Services.get(SoundService).setMusicMute(true);
                Services.get(SoundService).unmute();
                break;

            case SOUND_STATE.SOUND_OFF:
                Services.get(SoundService).mute();
                break;

        }

        this.soundState = state;
        this.updateSoundButtons();
    }

    protected loadComplete() {
        this.loadingSprite?.setVisible(false);

        Services.get(SoundService).onLoadComplete.remove(this.loadCompleteHandler);
        this.setState(SOUND_STATE.SOUND_ON);
    }

    protected updateSoundButtons() {
        this.setVisible(false);

        switch (this.soundState) {
            case SOUND_STATE.SOUND_ON:
                this.offButton.setVisible(true);
                this.offButton.setEnabled(true);
                break;

            case SOUND_STATE.NO_MUSIC:
                if (this.noMusicButton) {
                    this.noMusicButton.setVisible(true);
                    this.noMusicButton.setEnabled(true);
                    break;
                }
            case SOUND_STATE.SOUND_OFF:
                this.onButton.setVisible(true);
                this.onButton.setEnabled(true);
                break;

        }

        this.waitForSoundsToLoad();
    }

    protected waitForSoundsToLoad() {
        if (Services.get(SoundService).getLoading()) {
            this.onButton.setVisible(false);
            this.onButton.setEnabled(false);
            this.offButton.setVisible(false);
            this.offButton.setEnabled(false);
            this.noMusicButton?.setVisible(false);
            this.noMusicButton?.setEnabled(false);

            this.loadingSprite?.setVisible(true);

            Services.get(SoundService).onLoadComplete.add(this.loadCompleteHandler);
        }
    }

}
