import { gameState } from "appworks/model/game-state";
import { Gameplay } from "appworks/model/gameplay/gameplay";
import { RequestBuilder } from "appworks/server/builders/request-builder";
import { Services } from "appworks/services/services";
import { AxiosRequestConfig } from "axios";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoPurchaseEntryRequestPayload } from "slingo/model/requests/slingo-purchase-entry-request-payload";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { SlingoStakeToSpinRequestPayload } from "slingo/model/requests/slingo-stake-to-spin-request-payload";

export class SlingoStakeToSpinRequestBuilder implements RequestBuilder {
    public filter(data: any): boolean {
        return data instanceof SlingoStakeToSpinRequestPayload;
    }

    public build(gameplay: Gameplay, data: any) {
        return {
            method: "put",
            url: "/actions/STAKE_TO_SPIN",
            data: {}
        } as AxiosRequestConfig;
    }

}
