import { Point } from "appworks/utils/geom/point";
import { Rectangle } from "../../utils/geom/rectangle";

export class Position extends Rectangle {

    public static unavailable() {
        return new Position(0, 0, 0, 0, new Point(1, 1), true);
    }

    public static fromRectangle(rectangle: Rectangle, scale?: { x: number, y: number }) {
        return new Position(
            rectangle.x,
            rectangle.y,
            rectangle.width,
            rectangle.height,
            new Point(scale.x, scale.y)
        );
    }

    constructor(
        public x: number = 0,
        public y: number = 0,
        public width: number = 0,
        public height: number = 0,
        public scale: Point = new Point(1, 1),
        public readonly unavailable: boolean = false
    ) {
        super(x, y, width, height);
    }

    public set(position: Position) : void;
    public set(x?: number, y?: number, width?: number, height?: number) : void;
    public set(x: number | Position = this.x, y = this.y, width = this.width, height = this.height) : void {
        if (typeof x === "number") {
            this.x = x;
            this.y = y;
            this.width = width;
            this.height = height;
        } else {
            this.x = x.x;
            this.y = x.y;
            this.width = x.width;
            this.height = x.height;
        }
    }

    public setScale<T extends { x: number, y: number }>(scale: T) {
        this.scale.x = scale.x;
        this.scale.y = scale.y;
    }

    public lerp(target: Position, t: number): Position {
        return Position.fromRectangle(super.lerp(target, t), this.scale);
    }

    public clone() {
        return new Position(this.x, this.y, this.width, this.height, new Point(this.scale.x, this.scale.y), this.unavailable);
    }

    public coordsToPoint() {
        return new Point(this.x, this.y);
    }

    public equals(position: Position): boolean {
        return position &&
            this.x === position.x &&
            this.y === position.y &&
            this.width === position.width &&
            this.height === position.height &&
            this.scale.x === position.scale.x &&
            this.scale.y === position.scale.y;
    }

    public add(position: Position): Position {
        return Position.fromRectangle(super.add(position), this.scale);
    }

    public subtract(position: Position): Position {
        return Position.fromRectangle(super.subtract(position), this.scale);
    }

    public multiply(position: Position): Position {
        return Position.fromRectangle(super.multiply(position), this.scale);
    }

    public divide(position: Position): Position {
        return Position.fromRectangle(super.divide(position), this.scale);
    }

    public addPosition(position: Position): Position {
        return Position.fromRectangle(super.addPosition(position), this.scale);
    }

    public subtractPosition(position: Position): Position {
        return Position.fromRectangle(super.subtractPosition(position), this.scale);
    }

    public multiplyPosition(position: Position): Position {
        return Position.fromRectangle(super.multiplyPosition(position), this.scale);
    }

    public dividePosition(position: Position): Position {
        return Position.fromRectangle(super.dividePosition(position), this.scale);
    }

    public addSides(position: Position): Position {
        return Position.fromRectangle(super.addSides(position), this.scale);
    }

    public subtractSides(position: Position): Position {
        return Position.fromRectangle(super.subtractSides(position), this.scale);
    }

    public multiplySides(position: Position): Position {
        return Position.fromRectangle(super.multiplySides(position), this.scale);
    }

    public divideSides(position: Position): Position {
        return Position.fromRectangle(super.divideSides(position), this.scale);
    }

    public containsPoint(point: Point | PIXI.Point | Position) {
        return this.x < point.x && this.x + this.width > point.x && this.y < point.y && this.y + this.height > point.y;
    }

    public getCenterPoint(): Point {
        return new Point(this.x + (this.width * 0.5), this.y + (this.height * 0.5));
    }

    public getCenteredPosition(): Position {
        return new Position(this.x + (this.width * 0.5), this.y + (this.height * 0.5), this.width, this.height);
    }
}
