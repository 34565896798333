import { Layers } from "appworks/graphics/layers/layers";
import { TotalStakeStepperControl } from "appworks/ui/controls/standard/stepper/total-stake-stepper-control";
import { ControlFactory } from "../control-factory";

export class TotalStakeStepperControlFactory implements ControlFactory {

    constructor(protected disableWhenFreeBets: boolean = true) { }

    public filter(layer: Layers) {
        if (layer.getFlexiButton("stake_up") &&
            layer.getFlexiButton("stake_down")) {
            return true;
        }

        return false;
    }

    public build(layer: Layers) {
        return [new TotalStakeStepperControl(layer.getFlexiButton("stake_down"), layer.getFlexiButton("stake_up"), layer.getFlexiText("stake_value"), this.disableWhenFreeBets)];
    }
}
