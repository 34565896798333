import { asArray } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Easing, Tween } from "appworks/utils/tween";
import { TweenContract } from "../contracts/tween-contract";

/**
 * fade2 electric boogaloo
 * + fades elements from their current alpha instead of always starting at a fixed alpha
 * + sets visible to true when fading in and false when fading out
 * + returns empty contract if tweening to an alpha value that they're already at
 */

export interface Fadable2 {
    alpha: number;
    visible: boolean;
}

const defaultDuration: number = 500;
const defaultEasing: (k: number) => number = Easing.Linear.None;

export function fadeIn2<T extends Fadable2>(
    targetOrTargets: T | T[], 
    duration = defaultDuration, 
    easing = defaultEasing
): Contract<void> {
    return fade2(targetOrTargets, 1, duration, easing);
}

export function fadeOut2<T extends Fadable2>(
    targetOrTargets: T | T[], 
    duration = defaultDuration, 
    easing = defaultEasing
): Contract<void> {
    return fade2(targetOrTargets, 0, duration, easing);
}

export function fade2<T extends Fadable2>(
    targetOrTargets: T | T[],
    toAlpha: number,
    duration = defaultDuration,
    easing = defaultEasing
): Contract<void> {
    const targets = asArray(targetOrTargets);

    return new Parallel(targets.map((target) => {
        if ((target.alpha === toAlpha && target.visible) || (!target.visible && toAlpha === 0)) {
            return () => Contract.empty();
        }

        const fadeTween = new Tween(target)
            .onStart(() => target.visible = true)
            .to({ alpha: toAlpha }, duration)
            .onComplete(() => {
                target.alpha = toAlpha;
                target.visible = toAlpha > 0;
            })
            .easing(easing);

        return () => TweenContract.wrapCancellableTween(fadeTween);
    }));
}
