import { AbstractBetService } from "appworks/services/bet/abstract-bet-service";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { LocalStorageService } from "appworks/services/storage/local-storage-service";
import { ValueList } from "appworks/utils/value-list";
import { Signal } from "signals";
import { SlotBetModel } from "slotworks/model/bets/slot-bet-model";
import { slotDefinition } from "slotworks/model/slot-definition";
import { slotModel } from "slotworks/model/slot-model";

export class SlotBetService extends AbstractBetService {
    private static SELECTED_LINES: string = "selectedLines";
    private static STAKE_PER_LINE: string = "creditPerLine";
    private static CREDIT_SIZE: string = "creditSize";

    public saveToLocalStorage: boolean = false;

    public onBetChange: Signal = new Signal();

    protected isLoaded: boolean;

    protected betModel: SlotBetModel;
    protected lastModel: SlotBetModel;

    public init(): void {
        this.betModel = slotModel.read().bet;
        this.lastModel = slotModel.read().bet;

        slotModel.onUpdate.add(() => {
            this.betModel = slotModel.read().bet;

            if (this.betModel.selectedLines?.length !== this.lastModel.selectedLines?.length ||
                this.betModel.creditSizes?.currentValue !== this.lastModel.creditSizes?.currentValue ||
                this.betModel.creditsPerLine?.currentValue !== this.lastModel.creditsPerLine?.currentValue) {
                this.lastModel = slotModel.read().bet;
                this.onBetChange.dispatch();
            }

            this.lastModel = slotModel.read().bet;

            this.save();
        });
    }

    public setDefaults(creditSizes: ValueList<number>, creditsPerLine: ValueList<number>, lines?: number) {
        this.betModel.creditSizes = creditSizes;
        this.betModel.creditsPerLine = creditsPerLine;

        this.writeModel();

        if (lines !== undefined) {
            this.setSelectedLines(lines);
        }

        this.writeModel();
    }

    // Sets a fixed cost instead of multiplying bet per line by number of lines
    // If > 0, total bet is calculated as creditSize * creditsPerLine * fixedBetCost
    // If 0, total bet is calculated as creditSize * creditsPerLine * selectedLines
    public setFixedBetCost(value: number) {
        this.betModel.fixedBetCost = value;
        this.writeModel();
    }

    // This is a bet which is deducted from balance, but not used when calculating wins; for example "golden bet" or bonus buy
    public setExtraBet(value: number) {
        this.betModel.extraBet = value;
        this.writeModel();
    }

    // This is a bet which is deducted from balance, but not used when calculating wins; for example "golden bet" or bonus buy
    public getExtraBet() {
        return this.betModel.extraBet;
    }

    public setBetType(value: string) {
        this.betModel.betType = value;
        this.writeModel();
    }

    public getBetType(): string {
        return this.betModel.betType;
    }

    public setAdditionalBet(value: number) {
        this.betModel.additionalBet = value;
        this.writeModel();
    }

    public getFixedBetCost() {
        return this.betModel.fixedBetCost;
    }

    public getAdditionalBet() {
        return this.betModel.additionalBet;
    }

    public getCreditPerLine(): number {
        return this.betModel.creditsPerLine.currentValue;
    }

    public getCreditSize(): number {
        return this.betModel.creditSizes.currentValue;
    }

    // TODO: Deprecated. Lines will be removed in V5 - delete all references to selectedLines throughout slotworks
    public getSelectedLines(): number {
        return this.betModel.selectedLines?.length ?? 1;
    }

    public getTotalCoinBet(): number {
        if (this.betModel.fixedBetCost > 0) {
            return this.getCreditPerLine() * this.betModel.fixedBetCost;
        }
        return this.getCreditPerLine() * this.getSelectedLines();
    }

    public getTotalStake(): number {
        return this.getTotalCoinBet() * this.getCreditSize() + this.getAdditionalBet();
    }

    public load() {
        if (this.saveToLocalStorage) {
            this.setCreditSize(Services.get(LocalStorageService).getNumber(SlotBetService.CREDIT_SIZE));
            this.setCreditPerLine(Services.get(LocalStorageService).getNumber(SlotBetService.STAKE_PER_LINE));
            this.setSelectedLines(Services.get(LocalStorageService).getNumber(SlotBetService.SELECTED_LINES));
        }
        this.isLoaded = true;
    }

    public getSelectedLineIds(): number[] {
        return this.betModel.selectedLines;
    }

    public getTotalStakeValues(): number[] {

        const totalStakeValues = [];

        for (const creditSize of this.betModel.creditSizes.values) {
            for (const stakePerLine of this.betModel.creditsPerLine.values) {
                const stake = Math.round(stakePerLine * creditSize * this.getLineBetMultiplier() * 100) / 100;
                if (totalStakeValues.indexOf(stake) === -1) {
                    totalStakeValues.push(stake);
                }
            }
        }

        // Sort ascending
        totalStakeValues.sort((a, b) => a - b);

        return totalStakeValues;
    }

    // From a given total works out what the credits per line / coin size will be
    public getCreditsForTotalStake(value: number, lockCoinSize: boolean = false) {
        const output = {
            creditSizes: ValueList.copy(this.betModel.creditSizes),
            creditsPerLine: ValueList.copy(this.betModel.creditsPerLine)
        };

        for (const coinSize of output.creditSizes.values) {
            if (lockCoinSize && coinSize !== output.creditSizes.currentValue) {
                continue;
            }
            for (const creditsPerLine of output.creditsPerLine.values) {
                const stake = creditsPerLine * coinSize * this.getLineBetMultiplier();
                // Avoid floating point errors
                if (Math.abs(Math.round(stake * 100 - value * 100)) < 1) {
                    output.creditSizes.currentValue = coinSize;
                    output.creditsPerLine.currentValue = creditsPerLine;
                    return output;
                }
            }
        }

        return output;
    }

    public setSelectedLines(lines: number) {
        const newLines = [];

        for (let i = 1; i <= lines; i++) {
            newLines.push(i);
        }

        this.betModel.selectedLines = newLines;
        slotDefinition.lines.currentValue = slotDefinition.lines.values[lines];

        this.writeModel();
    }

    public setCreditSize(value: number) {
        if (this.betModel.creditSizes.currentValue !== value) {
            this.betModel.creditSizes.currentValue = value;
            Services.get(CurrencyService).creditRatio = value;
            this.writeModel();
        }
    }

    public setCreditPerLine(value: number) {
        if (this.betModel.creditsPerLine.currentValue !== value) {
            this.betModel.creditsPerLine.currentValue = value;
            this.writeModel();
        }
    }

    protected save() {
        if (this.saveToLocalStorage && this.isLoaded) {
            Services.get(LocalStorageService).set(SlotBetService.STAKE_PER_LINE, this.getCreditPerLine());
            Services.get(LocalStorageService).set(SlotBetService.SELECTED_LINES, this.getSelectedLines());
            Services.get(LocalStorageService).set(SlotBetService.CREDIT_SIZE, this.getCreditSize());
        }
    }

    protected writeModel() {
        slotModel.write({ bet: this.betModel });
    }

    protected getLineBetMultiplier() {
        return this.betModel.fixedBetCost > 0 ? this.betModel.fixedBetCost : this.getSelectedLines();
    }
}
