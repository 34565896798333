import { Layers } from "appworks/graphics/layers/layers";
import { Text } from "appworks/graphics/pixi/text";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { slingoModel } from "slingo/model/slingo-model";

declare const __GAMENAME__: string;

export class SlingoGameInstanceIdControl extends AbstractControl {
    public static showGameId = false;

    protected label: Text;

    constructor(protected layer: Layers) {
        super();

        this.label = layer.getText("gameId");

        this.update();
        slingoModel.onUpdate.add(() => this.update());
    }

    protected update() {
        const model = slingoModel.read();
        const gameName = __GAMENAME__;
        const gameInstanceId = model.gameInstanceId;

        let text = "";

        if (SlingoGameInstanceIdControl.showGameId && gameName) {
            text += `${gameName}`;

            if (gameInstanceId) {
                text += "\n";
            }
        }

        if (gameInstanceId) {
            text += Services.get(TranslationsService).get("game_id", { gameId: gameInstanceId });
        }

        this.label.text = text;
    }
}