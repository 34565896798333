import { PreloaderComponent } from 'appworks/components/preloader/preloader-component';
import { FileLocation } from 'appworks/config/asset-config-schema';
import { Layers } from 'appworks/graphics/layers/layers';

/**
 * Social variation of preloader with no spine.
 *
 * @class SocialPreloaderComponent
 * @typedef {SocialPreloaderComponent}
 * @extends {PreloaderComponent}
 */
export class SocialPreloaderComponent extends PreloaderComponent {
  /**
   * Remove preloader spine, or else it will flash prior to reaching load screen.
   * @override
   * @param {FileLocation[]} files
   */
  public start(files: FileLocation[]): void {
    super.start(files);
    const menuLayer = Layers.get('Menus');
    menuLayer.getSpine('preloader').destroy();
  }
}
