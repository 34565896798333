import { Exit } from "appworks/state-machine/data/exit";
import { State } from "appworks/state-machine/states/state";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { StateMachine } from "../state-machine";
import Logger = require("js-logger");
export abstract class SuperState {

    public active: boolean;
    public params: any;

    public subState: State;

    protected stateMachine: StateMachine;

    protected cancelGroup: CancelGroup = new CancelGroup();

    /**
     * Called once on startup
     */
    public setup(stateMachine: StateMachine) {
        if (this.stateMachine) {
            throw new Error("Setup being called twice on state: " + this.constructor.name);
        }
        this.stateMachine = stateMachine;
    }

    /**
     * Call when the state has accepted the sub states request to complete
     */
    public confirmComplete() {
        this.stateMachine.changeState(Exit.Complete, this);
    }

    /**
     * Call when the state has accepted the sub states request to skip
     */
    public confirmSkip() {
        this.stateMachine.changeState(Exit.Skip, this);
    }

    /**
     * Call when the state has accepted the sub states request to skip
     */
    public confirmExit() {
        this.cancelGroup.cancel();
        this.stateMachine.superStateExitConfirmed();
        // this.stateMachine.changeState(Exit.Skip, this);
    }

    /**
     * Call to exit the super state without following the exit path of the current sub state.
     * This is for when the super state itself decides to change state; the sub states are ignored
     */
    public collapse() {
        this.stateMachine.changeState(Exit.Collapse, this);
    }

    /**
     * Call in order to repeat the sub state
     */
    public repeat() {
        this.stateMachine.changeState(Exit.Repeat, this);
    }

    /**
     * Call when something when wrong during the state
     */
    public error() {
        this.cancelGroup.clear();
        this.stateMachine.changeState(Exit.Error, this);
    }

    /**
     * Executed automatically when the state is entered
     */
    public onEnter(cascadeSkip?: boolean) {
        Logger.debug("onEnter not implemented on ", this);
    }

    /**
     * Executed automatically by the state machine when the state is exited (via any means)
     */
    public onExit() {
        Logger.debug("onExit not implemented on ", this);
    }

    /**
     * A sub state has requested to exit via complete
     * Super state can either allow the complete, call for a repeat, or even ignore the complete altogether
     * Useful for repeating sub states while some race handler is resolved in the super state, EG win cycle during rollup
     */
    public onSubStateComplete() {
        this.confirmComplete();
        // this.repeat();
    }

    /**
     * A sub state has requested to exit via skip
     */
    public onSubStateSkip() {
        this.confirmSkip();
    }

    /**
     * An exit link has been reached and the super state must now exit
     */
    public onSuperStateExit() {
        this.confirmExit();
    }

    /**
     * When a sub state errors, the event is bubbled up through the super state, to the state machine
     */
    public onSubStateError() {
        this.error();
    }

}
