import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { SpineService } from "appworks/graphics/spine/spine-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { PopSoundEvents } from "setup/pop-sound-events";
import { AbstractSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/abstract-symbol-behaviour";

export class PopSpineSymbolBehaviour extends AbstractSymbolBehaviour {
    protected spine: SpineContainer;
    protected cancelGroup = new CancelGroup();

    protected symbolId: string;

    public changeSymbol(): void {
        if (this.symbolId !== this.symbol.symbolId) {
            this.symbolId = this.symbol.symbolId;

            this.cancelGroup.skip();
            if (this.spine) {
                this.spine.destroy();
                this.spine = null;
            }

            const spineService = Services.get(SpineService);

            if (spineService.spineData.has(`symbol_${this.symbol.symbolId}`)) {
                this.spine = Services.get(SpineService).createSpineContainer(
                    `symbol_${this.symbol.symbolId}`,
                    this.matrixLayer.getPosition(this.symbol.symbolId)
                );
                this.matrixLayer.add(this.spine);
            }
        }
    }

    public land(): Contract<void> {
        this.cancelGroup.skip();
        if (!this.spine) { return Contract.empty(); }

        const ss = Services.get(SoundService);
        if (["FS", "SJ", "J", "D"].indexOf(this.symbolId) !== -1) {
            // NOTE: The wild/superwild/free spin symbols are revealed within the 'in' animations. To avoid a delay we play the 'idle' sound on event fire.
            this.spine.onEvent.addOnce(() =>
                ss.customEvent(ss.getSoundEventName(PopSoundEvents.symbol_idle, this.symbolId))
            );
        }

        return this.cancelGroup.sequence([
            () => Contract.wrap(() => ss.customEvent(ss.getSoundEventName(PopSoundEvents.whirpool, this.symbolId))),
            () => this.spine.playOnce("in", null, GamingRealms.isArcade() ? 2 : 1),
            () => Contract.wrap(() => this.spine.play("idle"))
        ]);
    }

    public remove(): Contract<void> {
        this.cancelGroup.skip();
        if (!this.spine || !this.spine.hasAnimation("out")) { return Contract.empty(); }

        const ss = Services.get(SoundService);
        return this.cancelGroup.add(
            new Sequence([
                () => Contract.wrap(() => {
                    if (this.symbolId === "SJ" || this.symbolId === "J") {
                        ss.customEvent(PopSoundEvents.vanish_wild);
                    } else if (this.symbolId !== "D" && this.symbolId !== "FS") {
                        ss.customEvent(PopSoundEvents.vanish_number);
                    }
                }),
                () => this.spine.playOnce("out"),
                () => Contract.wrap(() => this.symbol.setSymbol("blank"))
            ])
        );
    }

    public static(): void {
        this.cancelGroup.skip();
        this.spine?.play("idle");
    }

    public updateTransform(): void {
        if (this.spine) {
            const symbol0 = this.matrixLayer.getPosition("symbol_0_0");
            const symbolPos = this.matrixLayer.getPosition(`symbol_${this.symbol.gridPosition.x}_0`);

            const offset = symbolPos.subtractPosition(symbol0);
            offset.landscape.y += (this.symbol.getTransform().landscape.y - symbolPos.landscape.y);
            offset.portrait.y += (this.symbol.getTransform().portrait.y - symbolPos.portrait.y);

            this.spine.dualPosition.setPosition(this.matrixLayer.getPosition(this.symbol.symbolId).addPosition(offset));

            this.spine.alpha = this.symbol.getAlpha();
        }
    }
}
