import { Gameplay } from "appworks/model/gameplay/gameplay";
import { InitRequestPayload } from "appworks/model/gameplay/requests/init-request-payload";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoPurchaseEntryRequestPayload } from "slingo/model/requests/slingo-purchase-entry-request-payload";
import { SlingoResponse } from "slingo/integration/slingo-schema";
import { SlingoRecordBuilder } from "./slingo-record-builder";
import { SlingoStakeToSpinRequestPayload } from "slingo/model/requests/slingo-stake-to-spin-request-payload";
import { builderManager } from "appworks/server/builders/builder-manager";
import { SlingoStakeToSpinResult } from "slingo/model/results/slingo-stake-to-spin-result";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";

export class SlingoStakeToSpinRecordBuilder extends SlingoRecordBuilder {
    public id: string = "SlingoStakeToSpinRecordBuilder";

    public filter(request: InitRequestPayload, response: any): boolean {
        return request instanceof SlingoStakeToSpinRequestPayload;
    }

    protected buildResults(record: SlingoRecord, response: SlingoResponse): void {
        const results = builderManager.buildResults(record, response);

        const stakeToSpinResult = results.find(result => result instanceof SlingoStakeToSpinResult) as SlingoStakeToSpinResult;

        record.results = results;

        if (stakeToSpinResult.rng.isWin) {
            for (const result of record.results) {
                record.cashWon += result.cashWon;
            }
        }
    }
}
