import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { BitmapText } from "appworks/graphics/pixi/bitmap-text";
import { Container } from "appworks/graphics/pixi/container";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { DisplayObject } from "pixi.js";

export function isDualPositionObject(object: DisplayObject): object is DualPositionObject {
    return object instanceof Sprite ||
        object instanceof Container ||
        object instanceof BitmapText ||
        object instanceof Text ||
        object instanceof SmartShape;
}

export type DualPositionObject = Sprite | Container | BitmapText | Text | SmartShape;
