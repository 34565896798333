import { AbstractComponent } from "appworks/components/abstract-component";
import { AbstractBetService } from "appworks/services/bet/abstract-bet-service";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Timer } from "appworks/utils/timer";
import { Tween } from "appworks/utils/tween";
import { gameLayers } from "game-layers";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { slingoModel } from "slingo/model/slingo-model";

export class PopScatterInfoComponent extends AbstractComponent {

    protected timeout: number;

    public init(): void {
        this.updateText();
        Services.get(AbstractBetService).onBetChange.add(() => {
            this.updateText();
        });

        if (GamingRealms.isArcade()) {
            gameLayers.ScatterWin.setScene("info").execute();
            const payouts = this.getPayouts();
            gameLayers.ScatterWin.scene.info.contents.payout_3.text = `${payouts[0]}`;
            gameLayers.ScatterWin.scene.info.contents.payout_4.text = `${payouts[1]}`;
            gameLayers.ScatterWin.scene.info.contents.payout_5.text = `${payouts[2]}`;

            Contract.getTimeoutContract(5000).then(() => {
                gameLayers.ScatterWin.defaultScene().execute();
            });
        }

        uiFlags.onFlagsUpdated.add(() => {
            if (uiFlags.has(UIFlag.GAME_IN_PROGRESS)) {
                gameLayers.ScatterWin.defaultScene().execute();
            }
        });
    }

    protected updateText() {
        const payouts = this.getPayouts();

        const paysText = gameLayers.ScatterInfo.scene.default.contents.pays;
        paysText.text = `3:${payouts[0]}, 4:${payouts[1]}, 5:${payouts[2]}`;
    }

    protected getPayouts() {
        const betMultiplier = Services.get(AbstractBetService).getTotalStake() / 100;
        const model = slingoModel.read();
        const payouts = [];

        for (let i = 3; i < 6; i++) {
            const payout = model.payoutConfig.symbolPayouts[0].multipliers[i - 1] * 100;
            payouts.push(Services.get(CurrencyService).format(payout * betMultiplier, false));
        }

        return payouts;
    }
}