import { Flexi } from "appworks/graphics/elements/abstract-flexi";
import { ButtonElement, ButtonEvent } from "appworks/graphics/elements/button-element";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";

class DOMListenerReference {
    constructor(public target: HTMLElement, public event: string, public method: any) {
    }
}

export class FlexiButton extends Flexi<Sprite | ButtonElement | HTMLElement> {

    private name: string;

    private selected: boolean;
    private enabled: boolean;

    private domListeners: DOMListenerReference[] = [];

    public addTarget(target: Sprite | ButtonElement | HTMLElement) {
        super.addTarget(target);

        if (target instanceof HTMLElement) {
            this.name = target.id;

            target.addEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), this.onDown);
            target.addEventListener(ButtonEvent.POINTER_UP.getDOMEventString(), this.onUp);
            target.addEventListener(ButtonEvent.POINTER_OVER.getDOMEventString(), this.onOver);
            target.addEventListener(ButtonEvent.POINTER_OUT.getDOMEventString(), this.onOut);
            this.hideAllHTMLButtonStates(target);
        }
    }

    public destroy() {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                this.name = target.id;

                target.removeEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), this.onDown);
                target.removeEventListener(ButtonEvent.POINTER_UP.getDOMEventString(), this.onUp);
                target.removeEventListener(ButtonEvent.POINTER_OVER.getDOMEventString(), this.onOver);
                target.removeEventListener(ButtonEvent.POINTER_OUT.getDOMEventString(), this.onOut);

                this.domListeners.forEach((reference) => reference.target.removeEventListener(reference.event, reference.method));
                this.domListeners = [];
            } else if (target instanceof ButtonElement) {
                target.destroy();
            }
        }
    }

    public on(event: ButtonEvent, handler: Function) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                this.domListeners.push(new DOMListenerReference(target, event.getDOMEventString(), handler));
                target.addEventListener(event.getDOMEventString(), handler as any);
            } else if (target instanceof ButtonElement) {
                target.on(event, handler);
            } else {
                target.on(event.getPIXIEventString(), handler);
            }
        }
    }

    public off(event: ButtonEvent, handler: Function) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.removeEventListener(event.getDOMEventString(), handler as any);
            } else if (target instanceof ButtonElement) {
                target.off(event, handler);
            } else {
                target.off(event.getPIXIEventString(), handler);
            }
        }
    }

    public getSelected() {
        return this.selected;
    }

    public setSelected(selected: boolean) {
        this.selected = selected;

        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle(HTMLButtonState.SELECTED, selected);
                if (selected) {
                    this.setHTMLButtonState(target, HTMLButtonState.SELECTED);
                } else {
                    this.setHTMLButtonState(target, HTMLButtonState.UP);
                }
            } else if (target instanceof ButtonElement) {
                target.setSelected(selected);
            } else {
                throw new Error("Not implemented");
            }
        }
    }

    public setVisible(visible: boolean) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle(visible ? "hidden" : "visible", false);
                target.classList.toggle(visible ? "visible" : "hidden", true);
                target.classList.remove(HTMLButtonState.OVER);
                target.classList.remove(HTMLButtonState.DOWN);
            } else if (target instanceof ButtonElement) {
                target.setVisible(visible);
            } else {
                target.visible = visible;
            }
        }
    }

    public setEnabled(enabled: boolean) {

        this.enabled = enabled;

        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle(enabled ? "disabled" : "enabled", false);
                target.classList.toggle(enabled ? "enabled" : "disabled", true);

                this.setHTMLButtonState(target, HTMLButtonState.UP);
            } else if (target instanceof ButtonElement) {
                target.setEnabled(enabled);
            } else {
                target.buttonMode = enabled;
            }
        }
    }

    public setAlpha(alpha: number) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.style.opacity = alpha.toString();
            } else {
                target.alpha = alpha;
            }
        }
    }

    public getAlpha(): number {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                return Number(target.style.opacity);
            } else {
                return target.alpha;
            }
        }
    }

    private onDown = () => {
        Services.get(SoundService).event(SoundEvent.button_press_NAME, this.name);

        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle(HTMLButtonState.DOWN, true);
                this.setHTMLButtonState(target, HTMLButtonState.DOWN);
            }
        }
    }

    private onUp = () => {
        Services.get(SoundService).event(SoundEvent.button_up_NAME, this.name);

        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle(HTMLButtonState.DOWN, false);
                this.setHTMLButtonState(target, HTMLButtonState.UP);
            }
        }
    }

    private onOver = () => {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle(HTMLButtonState.OVER, true);
                this.setHTMLButtonState(target, HTMLButtonState.OVER);
            }
        }
    }

    private onOut = () => {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle(HTMLButtonState.OVER, false);
                target.classList.toggle(HTMLButtonState.DOWN, false);
                this.setHTMLButtonState(target, HTMLButtonState.UP);
            }
        }
    }

    private setHTMLButtonState(element: HTMLElement, state: HTMLButtonState) {

        this.hideAllHTMLButtonStates(element);

        if (this.selected) {
            state = HTMLButtonState.SELECTED;
        } else if (!this.enabled) {
            state = HTMLButtonState.DISABLED;
        }

        if (element.querySelector(`#${state}`)) {
            (element.querySelector(`#${state}`) as HTMLElement).style.display = "block";
        }
    }

    private hideAllHTMLButtonStates(element: HTMLElement) {
        for (const buttonState in HTMLButtonState) {
            if (element.querySelector(`#${buttonState}`)) {
                (element.querySelector(`#${buttonState}`) as HTMLElement).style.display = "none";
            }
        }
    }
}

enum HTMLButtonState {
    UP = "up",
    OVER = "over",
    DOWN = "down",
    DISABLED = "disabled",
    SELECTED = "selected"
}
