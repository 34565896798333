import { gameState } from "appworks/model/game-state";
import { BlastworksClientEvent, ClientController } from "client-controller";
import { SlingoCascadeResult } from "slingo/model/results/slingo-cascade-result";
import { PopCascadeState } from "state-machine/states/pop-cascade-state";

export class SocialCascadeState extends PopCascadeState {

    protected onReelLand(reelIndex: number, match?: { matchedValue: number; reelIndex: number; }): void {
        const result = gameState.getCurrentGame().getLatestResultOfType(SlingoCascadeResult);
        const landedSymbol = result.symbols[reelIndex];

        if(result.newSymbols[reelIndex]) {
            if (landedSymbol === 'FS') {
                ClientController.getInstance().raiseEvent(BlastworksClientEvent.REEL_STOP_FREE_SPIN);
            }
    
            if (landedSymbol === 'J') {
                ClientController.getInstance().raiseEvent(BlastworksClientEvent.REEL_STOP_JOKER);
            }
    
            if (landedSymbol === 'SJ') {
                ClientController.getInstance().raiseEvent(BlastworksClientEvent.REEL_STOP_SUPER_JOKER);
            }
        }
        super.onReelLand(reelIndex, match);
    }
}