import { AbstractComponent } from "appworks/components/abstract-component";
import { ComponentFactory } from "appworks/components/factories/component-factory";
import { BigWinComponent, BigWinTierConfig, CountMode } from "../bigwin/big-win-component";

export class BigWinFactory implements ComponentFactory {

    constructor(
        protected countMode: CountMode = CountMode.LINEAR,
        protected upgrade: boolean = true,
        protected tiers?: BigWinTierConfig[],
        protected skipTier?: boolean
    ) {
        if (!tiers) {
            this.tiers = [
                {
                    threshold: 20,
                    countTime: 4000,
                    waitTime: 4000,
                    scene: "bigWin"
                },
                {
                    threshold: 50,
                    countTime: 5500,
                    waitTime: 4000,
                    scene: "megaWin"
                },
                {
                    threshold: 100,
                    countTime: 9500,
                    waitTime: 4000,
                    scene: "hugeMegaWin"
                }
            ];
        }
    }

    public build(): AbstractComponent {
        const bigWin = new BigWinComponent();
        bigWin.countupMode = this.countMode;
        bigWin.upgrade = this.upgrade;
        bigWin.skipTier = this.skipTier;

        if (this.tiers) {
            for (const tier of this.tiers) {
                bigWin.addWinTier(tier);
            }
        }

        return bigWin;
    }
}
