import { Easing } from "appworks/utils/tween";

const TweenTypes = new Map<string, Function>([
    ["Linear.None", Easing.Linear.None],
    ["Quadratic.In", Easing.Quadratic.In],
    ["Quadratic.Out", Easing.Quadratic.Out],
    ["Quadratic.InOut", Easing.Quadratic.InOut],
    ["Cubic.In", Easing.Cubic.In],
    ["Cubic.Out", Easing.Cubic.Out],
    ["Cubic.InOut", Easing.Cubic.InOut],
    ["Quartic.In", Easing.Quartic.In],
    ["Quartic.Out", Easing.Quartic.Out],
    ["Quartic.InOut", Easing.Quartic.InOut],
    ["Quintic.In", Easing.Quintic.In],
    ["Quintic.Out", Easing.Quintic.Out],
    ["Quintic.InOut", Easing.Quintic.InOut],
    ["Sinusoidal.In", Easing.Sinusoidal.In],
    ["Sinusoidal.Out", Easing.Sinusoidal.Out],
    ["Sinusoidal.InOut", Easing.Sinusoidal.InOut],
    ["Exponential.In", Easing.Exponential.In],
    ["Exponential.Out", Easing.Exponential.Out],
    ["Exponential.InOut", Easing.Exponential.InOut],
    ["Circular.In", Easing.Circular.In],
    ["Circular.Out", Easing.Circular.Out],
    ["Circular.InOut", Easing.Circular.InOut],
    ["Elastic.In", Easing.Elastic.In],
    ["Elastic.Out", Easing.Elastic.Out],
    ["Elastic.InOut", Easing.Elastic.InOut],
    ["Back.In", Easing.Back.In],
    ["Back.Out", Easing.Back.Out],
    ["Back.InOut", Easing.Back.InOut],
    ["Bounce.In", Easing.Bounce.In],
    ["Bounce.Out", Easing.Bounce.Out],
    ["Bounce.InOut", Easing.Bounce.InOut]
] as any);

/**
 * Alows typesafe lookup of easing types.
 *
 * @method easingFactory
 * @param type String The name of the easing function. I.e: "Quadratic.InOut".
 * @returns Function The easing function.
 */
export function EasingFactory(type: string) {
    const easing = TweenTypes.get(type);

    if (typeof easing === "undefined") {
        throw new Error(`Invalid easing type "${type}" requested.`);
    }

    return easing;
}
