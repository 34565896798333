import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Services } from "appworks/services/services";
import { Contract } from "appworks/utils/contracts/contract";
import { Renderer, Texture } from "pixi.js";

class GPUUploader {

    public enabled: boolean = true;

    private queue: Upload[] = [];

    public upload(texture: Texture) {
        if (Services.get(CanvasService).renderer instanceof Renderer && this.enabled) {
            const uploadContract = new Contract<void>((resolve) => {
                this.queue.push({ texture, resolve } as Upload);

                if (this.queue.length === 1) {
                    this.uploadNext();
                }
            });

            return uploadContract;
        } else {
            return Contract.empty();
        }
    }

    private uploadNext() {
        if (this.queue.length) {

            const upload = this.queue.pop();
            const sprite = new Sprite(upload.texture);

            // const ramUsage = Math.floor(((upload.texture.baseTexture.realWidth * upload.texture.baseTexture.realHeight * 4) / (1024 * 1024) * 2) * 100) / 100;
            // logger.log("Predicted ram usage: ", upload.texture.baseTexture.textureCacheIds[0], ramUsage + "mb");

            Services.get(CanvasService).renderer.plugins.prepare.upload(sprite, () => {
                upload.resolve();
                this.uploadNext();
            });
        }
    }

}

interface Upload {
    texture: Texture;
    resolve: () => void;
}

export const gpuUploader = new GPUUploader();
