import { DualPositionConfig } from "appworks/config/asset-config-schema";
import { Orientation } from "appworks/graphics/canvas/orientation";
import { Position } from "./position";

export class DualPosition {

    // Generates a dual position from a position config, or unavailable for orientation which doesn't exist
    public static fromConfig(config: DualPositionConfig) {
        return new DualPosition(
            config.landscape ? new Position(config.landscape.x, config.landscape.y, config.landscape.width, config.landscape.height) : Position.unavailable(),
            config.portrait ? new Position(config.portrait.x, config.portrait.y, config.portrait.width, config.portrait.height) : Position.unavailable()
        );
    }

    constructor(
        public landscape: Position = new Position(),
        public portrait: Position = new Position()
    ) { }

    public setPosition(dualPosition: DualPosition) {
        this.landscape.setPosition(dualPosition.landscape);
        this.portrait.setPosition(dualPosition.portrait);
    }

    public setSize(dualPosition: DualPosition) {
        this.landscape.setSize(dualPosition.landscape);
        this.portrait.setSize(dualPosition.portrait);
    }

    public lerp(target: DualPosition, t: number) {
        return new DualPosition(this.landscape?.lerp(target.landscape, t), this.portrait?.lerp(target.portrait, t));
    }

    public clone() {
        return new DualPosition(this.landscape?.clone(), this.portrait?.clone());
    }

    public add(dualPosition: DualPosition): DualPosition {
        return new DualPosition(
            this.landscape.add(dualPosition.landscape),
            this.portrait.add(dualPosition.portrait)
        );
    }

    public get(orientation: Orientation) {
        return orientation === Orientation.PORTRAIT ? this.portrait : this.landscape;
    }

    public subtract(dualPosition: DualPosition): DualPosition {
        return new DualPosition(
            this.landscape.subtract(dualPosition.landscape),
            this.portrait.subtract(dualPosition.portrait)
        );
    }

    public multiply(dualPosition: DualPosition): DualPosition {
        return new DualPosition(
            this.landscape.multiply(dualPosition.landscape),
            this.portrait.multiply(dualPosition.portrait)
        );
    }

    public divide(dualPosition: DualPosition): DualPosition {
        return new DualPosition(
            this.landscape.divide(dualPosition.landscape),
            this.portrait.divide(dualPosition.portrait)
        );
    }

    public addPosition(dualPosition: DualPosition): DualPosition {
        return new DualPosition(
            this.landscape.addPosition(dualPosition.landscape),
            this.portrait.addPosition(dualPosition.portrait)
        );
    }

    public subtractPosition(dualPosition: DualPosition): DualPosition {
        return new DualPosition(
            this.landscape.subtractPosition(dualPosition.landscape),
            this.portrait.subtractPosition(dualPosition.portrait)
        );
    }

    public multiplyPosition(dualPosition: DualPosition): DualPosition {
        return new DualPosition(
            this.landscape.multiplyPosition(dualPosition.landscape),
            this.portrait.multiplyPosition(dualPosition.portrait)
        );
    }

    public dividePosition(dualPosition: DualPosition): DualPosition {
        return new DualPosition(
            this.landscape.dividePosition(dualPosition.landscape),
            this.portrait.dividePosition(dualPosition.portrait)
        );
    }

    public addSides(dualPosition: DualPosition): DualPosition {
        return new DualPosition(
            this.landscape.addSides(dualPosition.landscape),
            this.portrait.addSides(dualPosition.portrait)
        );
    }

    public subtractSides(dualPosition: DualPosition): DualPosition {
        return new DualPosition(
            this.landscape.subtractSides(dualPosition.landscape),
            this.portrait.subtractSides(dualPosition.portrait)
        );
    }

    public multiplySides(dualPosition: DualPosition): DualPosition {
        return new DualPosition(
            this.landscape.multiplySides(dualPosition.landscape),
            this.portrait.multiplySides(dualPosition.portrait)
        );
    }

    public divideSides(dualPosition: DualPosition): DualPosition {
        return new DualPosition(
            this.landscape.divideSides(dualPosition.landscape),
            this.portrait.divideSides(dualPosition.portrait)
        );
    }

    public getCenterPos() : DualPosition {
        return new DualPosition(
            this.landscape.getCenteredPosition(),
            this.portrait.getCenteredPosition()
        )
    }
}
