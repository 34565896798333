import { Components } from "appworks/components/components";
import { uiFlags, UIMenuFlag } from "appworks/ui/flags/ui-flags";
import { Timer } from "appworks/utils/timer";
import { PopSpinsCounterComponent } from "components/pop-spins-counter-component";
import { SlingoSpinType } from "slingo/integration/slingo-schema";
import { SlingoPurchaseSpinOrCollectState } from "slingo/states/slingo-purchase-spin-or-collect-state";

export class PopPurchaseSpinOrCollectState extends SlingoPurchaseSpinOrCollectState {
    public onEnter(cascadeSkip?: boolean): void {
        super.onEnter();

        if (Components.get(PopSpinsCounterComponent).getSpinType() !== SlingoSpinType.PURCHASE) {
            uiFlags.menu(UIMenuFlag.PAYTABLE, true);
            Timer.setTimeout(() => {
                uiFlags.menu(UIMenuFlag.PAYTABLE, false);
            }, 2000);
        }
    }
}