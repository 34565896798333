import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { arrayOfValues } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { Tween } from "appworks/utils/tween";
import { BubbleSpineState } from "components/pop-bubble-spine-component";
import { PopReelMatrixComponent } from "components/pop-reel-matrix-component";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { ReelSubcomponent } from "slotworks/components/matrix/reel/reel-subcomponent";
import { AbstractReelTransition } from "slotworks/components/matrix/reel/transition-behaviours/abstract-reel-transition";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";

export class PopReelTransition extends AbstractReelTransition {
    protected symbolHeld: boolean[];

    public init(matrix: AbstractMatrixComponent<SymbolSubcomponent>, reels: Array<ReelSubcomponent<SymbolSubcomponent>>, stops: number[], reelset: string[][]): void {
        super.init(matrix, reels, stops, reelset);
        this.clearHolds();
    }

    public start(reelset?: string[][], jumpStart?: boolean, quickSpin?: boolean): Contract<void> {
        const matrix = this.matrix as PopReelMatrixComponent;
        const symbols = matrix.getBaseGridSymbols();

        if (symbols.every((sym) => sym.symbolDefinition.isBlank)) { return Contract.empty(); }

        const duration = 350;
        const offset = 175;

        return new Parallel(symbols.map((symbol, index) => {
            if (this.symbolHeld[index]) { return () => Contract.empty(); }

            const transform = symbol.getTransform();
            symbol.setAlpha(1);

            return () => Contract.getDelayedContract(100 * index,
                () => new Parallel([
                    () => symbol.remove(),
                    () => matrix.getSymbolFadeTweenContract(symbol, 0, duration * 0.75),
                    () => TweenContract.wrapTween(
                        new Tween(transform.landscape)
                            .to({ y: transform.landscape.y + offset }, duration)
                            .easing(Easing.Back.In)
                    ),
                    () => TweenContract.wrapTween(
                        new Tween(transform.portrait)
                            .to({ y: transform.portrait.y + offset }, duration)
                            .easing(Easing.Back.In)
                            .onComplete(() => this.resetSymbolPosition(symbol))
                    )
                ]));
        }));
    }

    public stop(spinRecord: SpinRecord, quickSpin?: boolean): Contract<void> {
        const matrix = this.matrix as PopReelMatrixComponent;
        const symbols = matrix.getBaseGridSymbols();

        const duration = 350;
        const offset = 175;

        return new Parallel(symbols.map((symbol, index) => {
            if (this.symbolHeld[index]) { return () => Contract.empty(); }

            const rawId = spinRecord.grid[index][0];
            const newSymId = isNaN(parseInt(rawId)) ? rawId : `numbers/${rawId}`;
            symbol.setSymbol(newSymId);
            matrix.getBubble(index).setState(BubbleSpineState.IDLE).execute();

            const transform = symbol.getTransform();
            const position = this.matrixLayer.getPosition(`symbol_${symbol.gridPosition.x}_${symbol.gridPosition.y}`);

            symbol.getTransform().landscape.y = position.landscape.y - offset;
            symbol.getTransform().portrait.y = position.portrait.y - offset;

            symbol.setAlpha(0);

            const ss = Services.get(SoundService);
            return () => Contract.getDelayedContract(100 * index,
                () => new Parallel([
                    () => ss.customEventContract(SoundEvent.reel_any_land),
                    () => matrix.getSymbolFadeTweenContract(symbol, 1, duration * 0.75, duration * 0.25),
                    () => TweenContract.wrapTween(
                        new Tween(transform.landscape)
                            .to({ y: position.landscape.y }, duration)
                            .easing(Easing.Back.Out)
                    ),
                    () => TweenContract.wrapTween(
                        new Tween(transform.portrait)
                            .to({ y: position.portrait.y }, duration)
                            .easing(Easing.Back.Out)
                    ),
                    () => Contract.wrap(() => {
                        Timer.setTimeout(() => this.onReelLand.dispatch(index), duration * 0.7);
                    }),
                    () => Contract.getDelayedContract(duration * 0.5, () => symbol.land())
                ]));
        }));
    }

    public clearHolds() {
        this.symbolHeld = arrayOfValues(5, false);
    }

    protected resetSymbolPosition(symbol: SymbolSubcomponent) {
        const position = this.matrixLayer.getPosition(`symbol_${symbol.gridPosition.x}_${symbol.gridPosition.y}`);
        symbol.getTransform().setPosition(position);
    }
}
