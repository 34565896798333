import { ButtonElement } from "appworks/graphics/elements/button-element";
import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { Layers } from "appworks/graphics/layers/layers";
import { Scene } from "appworks/graphics/layers/scene";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { SoundNames } from "sounds-enum";

class GameLayers {
    public CelebrationForeground: Layers & {
        scene: {
             bigwin: Scene & {
                contents: {
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    value?: Text
                };
            };
        };
    };
    public CelebrationContent: Layers & {
        scene: {
             fullhouse: Scene & {
                contents: {
                    full_house?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    full_house?: SpineContainer
                    slingo_spine?: SpineContainer
                    celebrations?: SpineContainer
                    scatter_big?: SpineContainer
                };
            };
             slingo: Scene & {
                contents: {
                    slingo_spine?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             megawin: Scene & {
                contents: {
                    celebrations?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             superbigwin: Scene & {
                contents: {
                    celebrations?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             bigwin: Scene & {
                contents: {
                    celebrations?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             scatterwin: Scene & {
                contents: {
                    celebrations?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             scatterbig: Scene & {
                contents: {
                    scatter_big?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public CelebrationBackground: Layers & {
        scene: {
             fullhouse: Scene & {
                contents: {
                    clickblocker?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    clickblocker?: SmartShape
                    bigWin_coin?: Sprite
                    bigWin_bubble?: Sprite
                };
            };
             bigwin: Scene & {
                contents: {
                    bigWin_coin?: Sprite
                    bigWin_bubble?: Sprite
                    clickblocker?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Menus: Layers & {
        scene: {
             preloader: Scene & {
                contents: {
                    preloader?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    preloader?: SpineContainer
                    sound_effects?: Text
                    background_music?: Text
                    deposit?: ButtonElement
                    ui_help?: ButtonElement
                    ui_play_controls?: ButtonElement
                    lobby?: ButtonElement
                    history?: ButtonElement
                    cover?: SmartShape
                    arrow_back?: ButtonElement
                    arrow_forward?: ButtonElement
                    stake_0?: ButtonElement
                    stake_1?: ButtonElement
                    stake_2?: ButtonElement
                    stake_3?: ButtonElement
                    stake_4?: ButtonElement
                    stake_5?: ButtonElement
                    stake_6?: ButtonElement
                    stake_7?: ButtonElement
                    stake_label_0?: Text
                    stake_label_1?: Text
                    stake_label_2?: Text
                    stake_label_3?: Text
                    stake_label_4?: Text
                    stake_label_5?: Text
                    stake_label_6?: Text
                    stake_label_7?: Text
                    close?: ButtonElement
                    extra_spins_0?: Text
                    extra_spins_1?: Text
                    extra_spins_2?: Text
                    extra_spins_3?: Text
                    extra_spins_4?: Text
                    spin_price_0?: Text
                    spin_price_1?: Text
                    spin_price_2?: Text
                    spin_price_3?: Text
                    spin_price_4?: Text
                    total_stake_0?: Text
                    total_stake_1?: Text
                    total_stake_2?: Text
                    total_stake_3?: Text
                    total_stake_4?: Text
                    total_loss_0?: Text
                    total_loss_1?: Text
                    total_loss_2?: Text
                    total_loss_3?: Text
                    total_loss_4?: Text
                    cancel?: ButtonElement
                    save?: ButtonElement
                    frame?: Sprite
                    title_play_controls_spins?: Text
                    title_play_controls_spin_price?: Text
                    title_play_controls_stake?: Text
                    title_play_controls_loss?: Text
                };
            };
             settings: Scene & {
                contents: {
                    sound_effects?: Text
                    background_music?: Text
                    deposit?: ButtonElement
                    ui_help?: ButtonElement
                    ui_play_controls?: ButtonElement
                    lobby?: ButtonElement
                    history?: ButtonElement
                    cover?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             stake: Scene & {
                contents: {
                    arrow_back?: ButtonElement
                    arrow_forward?: ButtonElement
                    stake_0?: ButtonElement
                    stake_1?: ButtonElement
                    stake_2?: ButtonElement
                    stake_3?: ButtonElement
                    stake_4?: ButtonElement
                    stake_5?: ButtonElement
                    stake_6?: ButtonElement
                    stake_7?: ButtonElement
                    cover?: SmartShape
                    stake_label_0?: Text
                    stake_label_1?: Text
                    stake_label_2?: Text
                    stake_label_3?: Text
                    stake_label_4?: Text
                    stake_label_5?: Text
                    stake_label_6?: Text
                    stake_label_7?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             rules: Scene & {
                contents: {
                    close?: ButtonElement
                    cover?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             play_controls: Scene & {
                contents: {
                    cover?: SmartShape
                    extra_spins_0?: Text
                    extra_spins_1?: Text
                    extra_spins_2?: Text
                    extra_spins_3?: Text
                    extra_spins_4?: Text
                    spin_price_0?: Text
                    spin_price_1?: Text
                    spin_price_2?: Text
                    spin_price_3?: Text
                    spin_price_4?: Text
                    total_stake_0?: Text
                    total_stake_1?: Text
                    total_stake_2?: Text
                    total_stake_3?: Text
                    total_stake_4?: Text
                    total_loss_0?: Text
                    total_loss_1?: Text
                    total_loss_2?: Text
                    total_loss_3?: Text
                    total_loss_4?: Text
                    cancel?: ButtonElement
                    save?: ButtonElement
                    frame?: Sprite
                    title_play_controls_spins?: Text
                    title_play_controls_spin_price?: Text
                    title_play_controls_stake?: Text
                    title_play_controls_loss?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public SuperSpinWheel: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    cta_bg?: Sprite
                    help?: ButtonElement
                    spin?: ButtonElement
                    wheel_portrait?: SpineContainer
                    wheel_landscape?: SpineContainer
                    cta?: Text
                    counter?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    cta_bg?: Sprite
                    help?: ButtonElement
                    spin?: ButtonElement
                    wheel_portrait?: SpineContainer
                    wheel_landscape?: SpineContainer
                    cta?: Text
                    counter?: Text
                };
            };
        };
    };
    public ScatterWin: Layers & {
        scene: {
             info: Scene & {
                contents: {
                    superwild?: Sprite
                    wild?: Sprite
                    back?: Sprite
                    titlle?: Text
                    payout_5?: Text
                    payout_4?: Text
                    payout_3?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    superwild?: Sprite
                    wild?: Sprite
                    back?: Sprite
                    titlle?: Text
                    payout_5?: Text
                    payout_4?: Text
                    payout_3?: Text
                };
            };
        };
    };
    public ScatterInfo: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    wild?: Sprite
                    super_wild?: Sprite
                    back?: Sprite
                    pays?: Text
                    scatter?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    wild?: Sprite
                    super_wild?: Sprite
                    back?: Sprite
                    pays?: Text
                    scatter?: Text
                };
            };
        };
    };
    public MenuButton: Layers & {
        scene: {
             base: Scene & {
                contents: {
                    ui_settings_menu?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    ui_settings_menu?: ButtonElement
                };
            };
        };
    };
    public Footer: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    value?: Text
                };
            };
        };
    };
    public BetBar: Layers & {
        scene: {
             base: Scene & {
                contents: {
                    total_win_bg?: Sprite
                    ui_stake_menu?: ButtonElement
                    top_bar?: SmartShape
                    bottom_bar?: SmartShape
                    total_win_value?: Text
                    total_win_label?: Text
                    gameId?: Text
                    time?: Text
                    balance_label?: Text
                    balance_value?: Text
                    slingo_stake?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    total_win_bg?: Sprite
                    ui_stake_menu?: ButtonElement
                    top_bar?: SmartShape
                    bottom_bar?: SmartShape
                    total_win_value?: Text
                    total_win_label?: Text
                    gameId?: Text
                    time?: Text
                    balance_label?: Text
                    balance_value?: Text
                    slingo_stake?: Text
                };
            };
        };
    };
    public Collect: Layers & {
        scene: {
             collect: Scene & {
                contents: {
                    purchase?: ButtonElement
                    collect?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    purchase?: ButtonElement
                    collect?: ButtonElement
                };
            };
        };
    };
    public SpinCounter: Layers & {
        scene: {
             purchase: Scene & {
                contents: {
                    counter_purchase?: Sprite
                    spins_remaining_bg?: Sprite
                    spins_remaining_value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    counter_purchase?: Sprite
                    spins_remaining_bg?: Sprite
                    spins_remaining_value?: Text
                    counter_freespin?: Sprite
                    counter_standard?: Sprite
                };
            };
             free: Scene & {
                contents: {
                    counter_freespin?: Sprite
                    spins_remaining_bg?: Sprite
                    spins_remaining_value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             default: Scene & {
                contents: {
                    counter_standard?: Sprite
                    spins_remaining_bg?: Sprite
                    spins_remaining_value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public CoinHopper: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    free_spin_flying_coin?: SpineContainer
                    hopper_mask?: Sprite
                    positions: {
                        [key: string]: DualPosition
                        hopper_top?: DualPosition
                        hopper_bottom?: DualPosition
                        hopper_9?: DualPosition
                        hopper_8?: DualPosition
                        hopper_7?: DualPosition
                        hopper_6?: DualPosition
                        hopper_5?: DualPosition
                        hopper_4?: DualPosition
                        hopper_3?: DualPosition
                        hopper_2?: DualPosition
                        hopper_1?: DualPosition
                        hopper_0?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        hopper_top?: DualPosition
                        hopper_bottom?: DualPosition
                        hopper_9?: DualPosition
                        hopper_8?: DualPosition
                        hopper_7?: DualPosition
                        hopper_6?: DualPosition
                        hopper_5?: DualPosition
                        hopper_4?: DualPosition
                        hopper_3?: DualPosition
                        hopper_2?: DualPosition
                        hopper_1?: DualPosition
                        hopper_0?: DualPosition
                    };
                    free_spin_flying_coin?: SpineContainer
                    hopper_mask?: Sprite
                };
            };
        };
    };
    public SpinButton: Layers & {
        scene: {
             base: Scene & {
                contents: {
                    spin?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    spin?: ButtonElement
                };
            };
        };
    };
    public SlingoLadder: Layers & {
        scene: {
             paytable: Scene & {
                contents: {
                    arrow_2?: Sprite
                    arrow_1?: Sprite
                    p_fill_12?: Sprite
                    p_fill_10?: Sprite
                    p_fill_9?: Sprite
                    p_fill_8?: Sprite
                    p_fill_7?: Sprite
                    p_fill_6?: Sprite
                    p_fill_5?: Sprite
                    p_fill_4?: Sprite
                    p_fill_3?: Sprite
                    p_fill_2?: Sprite
                    p_fill_1?: Sprite
                    p_highlight_12?: Sprite
                    p_highlight_10?: Sprite
                    p_highlight_9?: Sprite
                    p_highlight_8?: Sprite
                    p_highlight_7?: Sprite
                    p_highlight_6?: Sprite
                    p_highlight_5?: Sprite
                    p_highlight_4?: Sprite
                    p_highlight_3?: Sprite
                    p_highlight_2?: Sprite
                    p_highlight_1?: Sprite
                    p_ladder_bg?: Sprite
                    extended_fill_12?: Sprite
                    extended_fill_10?: Sprite
                    extended_fill_9?: Sprite
                    extended_fill_8?: Sprite
                    extended_fill_7?: Sprite
                    extended_fill_6?: Sprite
                    extended_fill_5?: Sprite
                    extended_fill_4?: Sprite
                    extended_fill_3?: Sprite
                    extended_fill_2?: Sprite
                    extended_fill_1?: Sprite
                    extended_highlight_12?: Sprite
                    extended_highlight_10?: Sprite
                    extended_highlight_9?: Sprite
                    extended_highlight_8?: Sprite
                    extended_highlight_7?: Sprite
                    extended_highlight_6?: Sprite
                    extended_highlight_5?: Sprite
                    extended_highlight_4?: Sprite
                    extended_highlight_3?: Sprite
                    extended_highlight_2?: Sprite
                    extended_highlight_1?: Sprite
                    extended_12?: Sprite
                    extended_10?: Sprite
                    extended_9?: Sprite
                    extended_8?: Sprite
                    extended_7?: Sprite
                    extended_6?: Sprite
                    extended_5?: Sprite
                    extended_4?: Sprite
                    extended_3?: Sprite
                    extended_2?: Sprite
                    extended_1?: Sprite
                    ui_paytable?: ButtonElement
                    ui_ladder?: ButtonElement
                    fill_12?: Sprite
                    fill_10?: Sprite
                    fill_9?: Sprite
                    fill_8?: Sprite
                    fill_7?: Sprite
                    fill_6?: Sprite
                    fill_5?: Sprite
                    fill_4?: Sprite
                    fill_3?: Sprite
                    fill_2?: Sprite
                    fill_1?: Sprite
                    highlight_12?: Sprite
                    highlight_10?: Sprite
                    highlight_9?: Sprite
                    highlight_8?: Sprite
                    highlight_7?: Sprite
                    highlight_6?: Sprite
                    highlight_5?: Sprite
                    highlight_4?: Sprite
                    highlight_3?: Sprite
                    highlight_2?: Sprite
                    highlight_1?: Sprite
                    ladder_bg?: Sprite
                    cashprize_12?: Text
                    cashprize_10?: Text
                    cashprize_9?: Text
                    cashprize_8?: Text
                    cashprize_7?: Text
                    cashprize_6?: Text
                    cashprize_5?: Text
                    cashprize_4?: Text
                    cashprize_3?: Text
                    cashprize_2?: Text
                    p_label_12?: Text
                    p_label_10?: Text
                    p_label_9?: Text
                    p_label_8?: Text
                    p_label_7?: Text
                    p_label_6?: Text
                    p_label_5?: Text
                    p_label_4?: Text
                    p_label_3?: Text
                    p_label_2?: Text
                    p_label_1?: Text
                    label_12?: Text
                    label_10?: Text
                    label_9?: Text
                    label_8?: Text
                    label_7?: Text
                    label_6?: Text
                    label_5?: Text
                    label_4?: Text
                    label_3?: Text
                    label_2?: Text
                    label_1?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    arrow_2?: Sprite
                    arrow_1?: Sprite
                    p_fill_12?: Sprite
                    p_fill_10?: Sprite
                    p_fill_9?: Sprite
                    p_fill_8?: Sprite
                    p_fill_7?: Sprite
                    p_fill_6?: Sprite
                    p_fill_5?: Sprite
                    p_fill_4?: Sprite
                    p_fill_3?: Sprite
                    p_fill_2?: Sprite
                    p_fill_1?: Sprite
                    p_highlight_12?: Sprite
                    p_highlight_10?: Sprite
                    p_highlight_9?: Sprite
                    p_highlight_8?: Sprite
                    p_highlight_7?: Sprite
                    p_highlight_6?: Sprite
                    p_highlight_5?: Sprite
                    p_highlight_4?: Sprite
                    p_highlight_3?: Sprite
                    p_highlight_2?: Sprite
                    p_highlight_1?: Sprite
                    p_ladder_bg?: Sprite
                    extended_fill_12?: Sprite
                    extended_fill_10?: Sprite
                    extended_fill_9?: Sprite
                    extended_fill_8?: Sprite
                    extended_fill_7?: Sprite
                    extended_fill_6?: Sprite
                    extended_fill_5?: Sprite
                    extended_fill_4?: Sprite
                    extended_fill_3?: Sprite
                    extended_fill_2?: Sprite
                    extended_fill_1?: Sprite
                    extended_highlight_12?: Sprite
                    extended_highlight_10?: Sprite
                    extended_highlight_9?: Sprite
                    extended_highlight_8?: Sprite
                    extended_highlight_7?: Sprite
                    extended_highlight_6?: Sprite
                    extended_highlight_5?: Sprite
                    extended_highlight_4?: Sprite
                    extended_highlight_3?: Sprite
                    extended_highlight_2?: Sprite
                    extended_highlight_1?: Sprite
                    extended_12?: Sprite
                    extended_10?: Sprite
                    extended_9?: Sprite
                    extended_8?: Sprite
                    extended_7?: Sprite
                    extended_6?: Sprite
                    extended_5?: Sprite
                    extended_4?: Sprite
                    extended_3?: Sprite
                    extended_2?: Sprite
                    extended_1?: Sprite
                    ui_paytable?: ButtonElement
                    ui_ladder?: ButtonElement
                    fill_12?: Sprite
                    fill_10?: Sprite
                    fill_9?: Sprite
                    fill_8?: Sprite
                    fill_7?: Sprite
                    fill_6?: Sprite
                    fill_5?: Sprite
                    fill_4?: Sprite
                    fill_3?: Sprite
                    fill_2?: Sprite
                    fill_1?: Sprite
                    highlight_12?: Sprite
                    highlight_10?: Sprite
                    highlight_9?: Sprite
                    highlight_8?: Sprite
                    highlight_7?: Sprite
                    highlight_6?: Sprite
                    highlight_5?: Sprite
                    highlight_4?: Sprite
                    highlight_3?: Sprite
                    highlight_2?: Sprite
                    highlight_1?: Sprite
                    ladder_bg?: Sprite
                    cashprize_12?: Text
                    cashprize_10?: Text
                    cashprize_9?: Text
                    cashprize_8?: Text
                    cashprize_7?: Text
                    cashprize_6?: Text
                    cashprize_5?: Text
                    cashprize_4?: Text
                    cashprize_3?: Text
                    cashprize_2?: Text
                    p_label_12?: Text
                    p_label_10?: Text
                    p_label_9?: Text
                    p_label_8?: Text
                    p_label_7?: Text
                    p_label_6?: Text
                    p_label_5?: Text
                    p_label_4?: Text
                    p_label_3?: Text
                    p_label_2?: Text
                    p_label_1?: Text
                    label_12?: Text
                    label_10?: Text
                    label_9?: Text
                    label_8?: Text
                    label_7?: Text
                    label_6?: Text
                    label_5?: Text
                    label_4?: Text
                    label_3?: Text
                    label_2?: Text
                    label_1?: Text
                };
            };
             base: Scene & {
                contents: {
                    arrow_2?: Sprite
                    arrow_1?: Sprite
                    p_fill_12?: Sprite
                    p_fill_10?: Sprite
                    p_fill_9?: Sprite
                    p_fill_8?: Sprite
                    p_fill_7?: Sprite
                    p_fill_6?: Sprite
                    p_fill_5?: Sprite
                    p_fill_4?: Sprite
                    p_fill_3?: Sprite
                    p_fill_2?: Sprite
                    p_fill_1?: Sprite
                    p_highlight_12?: Sprite
                    p_highlight_10?: Sprite
                    p_highlight_9?: Sprite
                    p_highlight_8?: Sprite
                    p_highlight_7?: Sprite
                    p_highlight_6?: Sprite
                    p_highlight_5?: Sprite
                    p_highlight_4?: Sprite
                    p_highlight_3?: Sprite
                    p_highlight_2?: Sprite
                    p_highlight_1?: Sprite
                    p_ladder_bg?: Sprite
                    ui_paytable?: ButtonElement
                    ui_ladder?: ButtonElement
                    fill_12?: Sprite
                    fill_10?: Sprite
                    fill_9?: Sprite
                    fill_8?: Sprite
                    fill_7?: Sprite
                    fill_6?: Sprite
                    fill_5?: Sprite
                    fill_4?: Sprite
                    fill_3?: Sprite
                    fill_2?: Sprite
                    fill_1?: Sprite
                    highlight_12?: Sprite
                    highlight_10?: Sprite
                    highlight_9?: Sprite
                    highlight_8?: Sprite
                    highlight_7?: Sprite
                    highlight_6?: Sprite
                    highlight_5?: Sprite
                    highlight_4?: Sprite
                    highlight_3?: Sprite
                    highlight_2?: Sprite
                    highlight_1?: Sprite
                    ladder_bg?: Sprite
                    p_label_12?: Text
                    p_label_10?: Text
                    p_label_9?: Text
                    p_label_8?: Text
                    p_label_7?: Text
                    p_label_6?: Text
                    p_label_5?: Text
                    p_label_4?: Text
                    p_label_3?: Text
                    p_label_2?: Text
                    cashprize_12?: Text
                    cashprize_10?: Text
                    cashprize_9?: Text
                    cashprize_8?: Text
                    cashprize_7?: Text
                    cashprize_6?: Text
                    cashprize_5?: Text
                    cashprize_4?: Text
                    cashprize_3?: Text
                    cashprize_2?: Text
                    label_12?: Text
                    label_10?: Text
                    label_9?: Text
                    label_8?: Text
                    label_7?: Text
                    label_6?: Text
                    label_5?: Text
                    label_4?: Text
                    label_3?: Text
                    label_2?: Text
                    label_1?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Logo: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    logo_arcade?: Sprite
                    logo?: Sprite
                    bubbles?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    logo_arcade?: Sprite
                    logo?: Sprite
                    bubbles?: Sprite
                };
            };
        };
    };
    public MatrixContent: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        J?: DualPosition
                        SJ?: DualPosition
                        FS?: DualPosition
                        D?: DualPosition
                        bubble?: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_4_0?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        J?: DualPosition
                        SJ?: DualPosition
                        FS?: DualPosition
                        D?: DualPosition
                        bubble?: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_4_0?: DualPosition
                    };
                };
            };
        };
    };
    public TicketMatrix: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        bubble?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_1_1?: DualPosition
                        symbol_1_2?: DualPosition
                        symbol_1_3?: DualPosition
                        symbol_1_4?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_2_1?: DualPosition
                        symbol_2_2?: DualPosition
                        symbol_2_3?: DualPosition
                        symbol_2_4?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_3_1?: DualPosition
                        symbol_3_2?: DualPosition
                        symbol_3_3?: DualPosition
                        symbol_3_4?: DualPosition
                        symbol_4_0?: DualPosition
                        symbol_4_1?: DualPosition
                        symbol_4_2?: DualPosition
                        symbol_4_3?: DualPosition
                        symbol_4_4?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        bubble?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_1_1?: DualPosition
                        symbol_1_2?: DualPosition
                        symbol_1_3?: DualPosition
                        symbol_1_4?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_2_1?: DualPosition
                        symbol_2_2?: DualPosition
                        symbol_2_3?: DualPosition
                        symbol_2_4?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_3_1?: DualPosition
                        symbol_3_2?: DualPosition
                        symbol_3_3?: DualPosition
                        symbol_3_4?: DualPosition
                        symbol_4_0?: DualPosition
                        symbol_4_1?: DualPosition
                        symbol_4_2?: DualPosition
                        symbol_4_3?: DualPosition
                        symbol_4_4?: DualPosition
                    };
                };
            };
        };
    };
    public TicketMatrixBacking: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    frame?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    frame?: Sprite
                };
            };
        };
    };
    public Background: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    bg_portrait?: Sprite
                    bg_landscape?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    bg_portrait?: Sprite
                    bg_landscape?: Sprite
                    portrait_bg?: Sprite
                    landscape_bg?: Sprite
                };
            };
             onboard: Scene & {
                contents: {
                    portrait_bg?: Sprite
                    landscape_bg?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Onboard: Layers & {
        scene: {
             onboard: Scene & {
                contents: {
                    image_right_arcade?: Sprite
                    image_right?: Sprite
                    image_left?: Sprite
                    logo?: Sprite
                    logo_arcade?: Sprite
                    continue?: ButtonElement
                    load_bar_mask?: SmartShape
                    load_bar?: Sprite
                    bar_backing?: Sprite
                    text_right?: Text
                    text_mid?: Text
                    text_left?: Text
                    legal?: Text
                    version?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    image_right_arcade?: Sprite
                    image_right?: Sprite
                    image_left?: Sprite
                    logo?: Sprite
                    logo_arcade?: Sprite
                    continue?: ButtonElement
                    load_bar_mask?: SmartShape
                    load_bar?: Sprite
                    bar_backing?: Sprite
                    text_right?: Text
                    text_mid?: Text
                    text_left?: Text
                    legal?: Text
                    version?: Text
                };
            };
        };
    };
    public Prompts: Layers & {
        scene: {
             bonusmoney: Scene & {
                contents: {
                    cover?: SmartShape
                    tc?: ButtonElement
                    ok?: ButtonElement
                    frame?: Sprite
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    cover?: SmartShape
                    tc?: ButtonElement
                    ok?: ButtonElement
                    frame?: Sprite
                    title?: Text
                    body?: Text
                    bubbles?: Sprite
                    glow?: Sprite
                    game_over?: Text
                    total_win_value?: Text
                    total_win_title?: Text
                    yes?: ButtonElement
                    no?: ButtonElement
                    continuenooptions?: ButtonElement
                    logout?: ButtonElement
                    history?: ButtonElement
                    continue?: ButtonElement
                };
            };
             gamesummary_nowin: Scene & {
                contents: {
                    bubbles?: Sprite
                    glow?: Sprite
                    cover?: SmartShape
                    game_over?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             gamesummary_win: Scene & {
                contents: {
                    bubbles?: Sprite
                    glow?: Sprite
                    cover?: SmartShape
                    total_win_value?: Text
                    title?: Text
                    total_win_title?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             yes_no: Scene & {
                contents: {
                    cover?: SmartShape
                    yes?: ButtonElement
                    no?: ButtonElement
                    frame?: Sprite
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             realitycheck: Scene & {
                contents: {
                    cover?: SmartShape
                    continuenooptions?: ButtonElement
                    logout?: ButtonElement
                    history?: ButtonElement
                    continue?: ButtonElement
                    frame?: Sprite
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             info: Scene & {
                contents: {
                    cover?: SmartShape
                    continue?: ButtonElement
                    frame?: Sprite
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
}

export enum LayerNames {
    CelebrationForeground = "celebrationforeground",
    CelebrationContent = "celebrationcontent",
    CelebrationBackground = "celebrationbackground",
    Menus = "menus",
    SuperSpinWheel = "superspinwheel",
    ScatterWin = "scatterwin",
    ScatterInfo = "scatterinfo",
    MenuButton = "menubutton",
    Footer = "footer",
    BetBar = "betbar",
    Collect = "collect",
    SpinCounter = "spincounter",
    CoinHopper = "coinhopper",
    SpinButton = "spinbutton",
    SlingoLadder = "slingoladder",
    Logo = "logo",
    MatrixContent = "matrixcontent",
    TicketMatrix = "ticketmatrix",
    TicketMatrixBacking = "ticketmatrixbacking",
    Background = "background",
    Onboard = "onboard",
    Prompts = "prompts"
}

export const gameLayers = new GameLayers();
export const assetworksData = {gameLayers, LayerNames, SoundNames};
