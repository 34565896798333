import { Contract } from "appworks/utils/contracts/contract";
export class Sequence<T = void> extends Contract<T> {
    private resolve: (value: T) => any;
    private childContract: Contract<any>;

    constructor(private methods: Array<(...args) => Contract<any>>) {
        super((resolve) => {
            this.resolve = resolve;
            this.next();
        });
    }

    public cancel() {
        super.cancel();
        if (this.childContract) {
            this.childContract.cancel();
        }
    }

    public forceResolve() {
        super.forceResolve();
        if (this.childContract) {
            this.childContract.cancel();
        }
    }

    private next(result?: any) {
        if (this.isCancelled()) {
            return;
        }
        if (!this.methods || !this.methods.length) {
            this.resolve(result);
            return;
        }
        this.childContract = this.methods.shift()(result);
        this.childContract.then((childResult?: any) => this.next(childResult));
    }
}
