import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { Contract } from "appworks/utils/contracts/contract";
import { Timer } from "appworks/utils/timer";
import * as TweenJS from "appworks/utils/tween";
import { Signal } from "signals";
import { isNullOrUndefined } from "util";
import { Service } from "../service";
import { SoundService } from "../sound/sound-service";

export class TransactionService extends Service {

    public currentWinUpdate: Signal = new Signal();
    public totalWinUpdate: Signal = new Signal();
    public balanceUpdate: Signal = new Signal();

    protected previousBalance: number = -1;

    protected pendingUpdate: number;
    protected currentWin: CurrentWinTicker;
    protected totalWin: WinTicker;

    public init(): void {
        this.currentWin = new CurrentWinTicker();
        this.totalWin = new WinTicker();

        this.currentWin.onUpdate.add((value: number) => this.currentWinUpdate.dispatch(value));
        this.totalWin.onUpdate.add((value: number) => this.totalWinUpdate.dispatch(value));
    }

    public setBalance(value: number) {
        this.balanceUpdate.dispatch(value, this.previousBalance);
        this.previousBalance = value;
    }

    public getBalance(): number {
        return this.previousBalance;
    }

    public setDelayedWinnings(currentWin: number, totalWin?: number, delay: number = 0, tickTime: number = 0): Contract<void> {
        return new Contract<void>((resolve) => {
            Timer.clearTimeout(this.pendingUpdate);

            this.pendingUpdate = Timer.setTimeout(() => {
                this.setWinnings(currentWin, totalWin, tickTime).then(() => resolve(null));
            }, delay);
        });
    }

    public setWinnings(currentWin: number, totalWin?: number, tickTime: number = 0): Contract<void> {
        return new Contract<void>((resolve) => {
            Timer.clearTimeout(this.pendingUpdate);

            if (isNullOrUndefined(currentWin)) {
                currentWin = 0;
            }

            if (totalWin === undefined) {
                totalWin = currentWin;
            }

            this.setWin(this.totalWin, totalWin, tickTime).execute();
            this.setWin(this.currentWin, currentWin, tickTime).then(() => resolve(null));
        });
    }

    public setTotalWin(totalWin: number) {
        this.setWinnings(0, totalWin).execute();
    }

    public addWin(amount: number, tickTime = 0): Contract {
        if (amount <= 0) {
            return Contract.empty();
        }

        Timer.clearTimeout(this.pendingUpdate);

        const newTotal = this.totalWin.totalValue + amount;
        
        return this.setWin(this.totalWin, newTotal, tickTime);
    }

    protected setWin(win: WinTicker, value: number, tickTime: number) {
        return new Contract<void>((resolve) => {
            if (!win.actionNeeded(value, tickTime)) {
                resolve(null);
                return;
            }

            if (win.ticking()) {
                win.stop();
            }

            win.start(value, tickTime).then(() => resolve(null));
        });
    }
}

class WinTicker {
    public onUpdate: Signal = new Signal();
    public tickingValue: number = 0;
    public totalValue: number = 0;
    protected tween: TweenJS.Tween = null;

    public actionNeeded(value: number, tickTime: number) {
        const newIsValid = value !== null && !isNaN(value);
        const newIsSameAsCurrent = value !== this.totalValue;
        const skippingCurrentTickingValue = (tickTime === 0 && this.ticking());
        return newIsValid && (newIsSameAsCurrent || skippingCurrentTickingValue);
    }

    public ticking(): boolean {
        return this.tween !== null;
    }

    public stop() {
        if (this.ticking()) {
            this.tween.stop();
        }
        this.tween = null;
    }

    public start(value: number, tickTime: number): Contract<void> {
        if (tickTime > 0 && value > 0) {
            return this.tickUp(value, tickTime);
        } else {
            return this.go(value);
        }
    }

    public update(value: number, ticking: boolean = false) {
        this.onUpdate.dispatch(value, ticking);
    }

    protected tickUp(value: number, tickTime: number) {

        return new Contract<void>((resolve) => {
            // protect against ticking backwards
            if (value < this.totalValue) {
                this.totalValue = 0;
            }

            this.tickingValue = this.totalValue;
            const tickObject = { t: this.tickingValue };
            this.totalValue = value;

            this.tween = new TweenJS.Tween(tickObject)
                .to({ t: value }, tickTime)
                .onUpdate(() => {
                    this.tickingValue = tickObject.t;
                    this.update(this.tickingValue, true);
                })
                .onComplete(() => {
                    this.update(this.totalValue);
                    this.tickingValue = this.totalValue;
                    this.stop();
                    resolve(null);
                })
                .start();
        });
    }

    protected go(value: number) {
        this.tickingValue = value;
        this.totalValue = value;
        this.update(value);
        return Contract.empty();
    }
}

class CurrentWinTicker extends WinTicker {
    public onUpdate: Signal = new Signal();

    public start(value: number, tickTime: number): Contract<void> {
        if (value > 0 && tickTime > 0) {
            Services.get(SoundService).event(SoundEvent.winnings_countup);
        }

        return super.start(value, tickTime);
    }

    public stop() {
        super.stop();
        Services.get(SoundService).event(SoundEvent.winnings_countup_end);
    }

    public update(value: number) {
        this.onUpdate.dispatch(value);
    }

    protected tickUp(value: number, tickTime: number) {
        // always reset to 0 and count up from there for current win
        this.totalValue = 0;

        return super.tickUp(value, tickTime);
    }
}
