import { Components } from "appworks/components/components";
import { PromptComponent } from "appworks/components/prompt/prompt-component";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Layers } from "appworks/graphics/layers/layers";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Text } from "appworks/graphics/pixi/text";
import { LoaderService } from "appworks/loader/loader-service";
import { builderManager } from "appworks/server/builders/builder-manager";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { FadeTransition } from "appworks/transitions/fade-transition";
import { SpineBigWinTransition } from "appworks/transitions/spine-big-win-transition";
import { UIFlagState } from "appworks/ui/flags/ui-flag-state";
import { UIFlag } from "appworks/ui/flags/ui-flags";
import { UI } from "appworks/ui/ui";
import { PopReelMatrixFactory } from "components/factories/pop-reel-matrix-factory";
import { PopCoinHopperComponent } from "components/pop-coin-hopper-component";
import { PopLadderComponent } from "components/pop-ladder-component";
import { PopScatterInfoComponent } from "components/pop-scatter-info-component";
import { PopSlingoCelebrationComponent } from "components/pop-slingo-celebration-component";
import { PopSlingoTicketMatrixComponent } from "components/pop-slingo-ticket-matrix-component";
import { PopSpinsCounterComponent } from "components/pop-spins-counter-component";
import { LayerNames, gameLayers } from "game-layers";
import { GMRAlertComponent } from "gaming-realms/components/gmr-alert-component";
import { GMRPreloaderComponent } from "gaming-realms/components/gmr-preloader-component";
import { GMRSuperSpinWheelComponent } from "gaming-realms/components/gmr-super-spin-wheel-component";
import { GMRCollectRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-collect-request-builder";
import { GMRForceRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-force-request-builder";
import { GMRInitRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-init-request-builder";
import { PopReelSpinResultBuilder } from "integration/builders/results/pop-reel-spin-result-builder";
import { PopScatterResultBuilder } from "integration/builders/results/pop-scatter-result-builder";
import { SlingoFooterComponent } from "slingo/components/slingo-footer-component";
import { SlingoCollectRecordBuilder } from "slingo/integration/builders/records/slingo-collect-record-builder";
import { SlingoInitRecordBuilder } from "slingo/integration/builders/records/slingo-init-record-builder";
import { SlingoPickRecordBuilder } from "slingo/integration/builders/records/slingo-pick-record-builder";
import { SlingoPurchaseEntryRecordBuilder } from "slingo/integration/builders/records/slingo-purchase-entry-record-builder";
import { SlingoSpinRecordBuilder } from "slingo/integration/builders/records/slingo-spin-record-builder";
import { SlingoStakeToSpinRecordBuilder } from "slingo/integration/builders/records/slingo-stake-to-spin-record-builder";
import { SlingoPickRequestBuilder } from "slingo/integration/builders/requests/slingo-pick-request-builder";
import { SlingoPurchaseEntryRequestBuilder } from "slingo/integration/builders/requests/slingo-purchase-entry-request-builder";
import { SlingoSpinRequestBuilder } from "slingo/integration/builders/requests/slingo-spin-request-builder";
import { SlingoStakeToSpinRequestBuilder } from "slingo/integration/builders/requests/slingo-stake-to-spin-request-builder";
import { SlingoCascadeResultBuilder } from "slingo/integration/builders/results/slingo-cascade-result-builder";
import { SlingoGameProgressResultBuilder } from "slingo/integration/builders/results/slingo-game-progress-result-builder";
import { SlingoJokerResultBuilder } from "slingo/integration/builders/results/slingo-joker-result-builder";
import { SlingoLadderResultBuilder } from "slingo/integration/builders/results/slingo-ladder-result-builder";
import { SlingoStakeToSpinResultBuilder } from "slingo/integration/builders/results/slingo-stake-to-spin-result-builder";
import { SlingoSetup } from "slingo/setup/slingo-setup";
import { BetBarComponent } from "slotworks/components/betbar/bet-bar-component";
import { CountMode } from "slotworks/components/bigwin/big-win-component";
import { CelebrateWinComponent } from "slotworks/components/celebrate-win/celebrate-win-component";
import { BigWinFactory } from "slotworks/components/factories/big-win-factory";
import { GenericComponentFactory } from "slotworks/components/factories/generic-component-factory";
import { PopGameDefinitionSetup } from "./pop-game-definition-setup";
import { PopSoundSetup } from "./pop-sound-setup";
import { PopStateSetup } from "./pop-state-setup";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { commsManager } from "appworks/server/comms-manager";

export class PopSetup extends SlingoSetup {
    public setupCanvas(): void {
        super.setupCanvas();
        Services.get(CanvasService).cssCanvasEnabled = true;

        Text.minPadding = 20;
    }

    public setupI18N(): void {
        super.setupI18N();

        const translations = Services.get(TranslationsService);
        for (let i = 0; i <= 12; i++) {
            const text = `${i} ${translations.get((i === 1) ? "slingo" : "slingos")}`;
            translations.set(`${i}_slingo${i === 1 ? "" : "s"}`, text);
        }
    }

    public setupGameDefinition(): void {
        PopGameDefinitionSetup();
    }

    public setupComponents() {
        SpineContainer.AUTO_UPDATE_SPEED = true;

        Components.register(new GenericComponentFactory(PromptComponent), 0);
        Components.register(new GenericComponentFactory(BetBarComponent));
        Components.register(new GenericComponentFactory(CelebrateWinComponent));
        Components.register(new BigWinFactory(CountMode.LINEAR, false, [
            {
                threshold: 20,
                countTime: 4000,
                waitTime: 4000,
                scene: "bigwin"
            },
            {
                threshold: 50,
                countTime: 5500,
                waitTime: 4000,
                scene: "superbigwin"
            },
            {
                threshold: 100,
                countTime: 9500,
                waitTime: 4000,
                scene: "megawin"
            }
        ]));

        Components.register(new GenericComponentFactory(GMRPreloaderComponent), null);
        Components.register(new GenericComponentFactory(GMRAlertComponent), 0);
        Components.register(new GenericComponentFactory(GMRSuperSpinWheelComponent));

        Components.register(new PopReelMatrixFactory(), 3);

        Components.register(new GenericComponentFactory(SlingoFooterComponent));
        Components.register(new GenericComponentFactory(PopSlingoCelebrationComponent, LayerNames.CelebrationContent));
        Components.register(new GenericComponentFactory(PopSpinsCounterComponent, LayerNames.SpinCounter));
        Components.register(new GenericComponentFactory(PopCoinHopperComponent));
        Components.register(new GenericComponentFactory(PopLadderComponent));
        Components.register(new GenericComponentFactory(PopScatterInfoComponent));

        Components.register(new GenericComponentFactory(PopSlingoTicketMatrixComponent), 3);
    }

    public setupServices(): void {
        super.setupServices();

        Services.get(LoaderService).loadSoundsInPreloader = true;
    }

    public setupStates() {
        PopStateSetup();
    }

    public setupDebug(): void {
        super.setupDebug();

        //commsManager.queueResponses(require("../mocks/superspinwheel-totalwin-bug.json"));
    }

    public setupLayers(): void {
        Layers.setLayerOrderConfig({
            default: [
                LayerNames.Background,
                LayerNames.Onboard,
                LayerNames.TicketMatrixBacking,
                LayerNames.TicketMatrix,
                LayerNames.MatrixContent,
                LayerNames.ScatterWin,
                LayerNames.Logo,
                LayerNames.BetBar,
                LayerNames.MenuButton,
                LayerNames.ScatterInfo,
                LayerNames.SpinButton,
                LayerNames.CoinHopper,
                LayerNames.SpinCounter,
                LayerNames.Footer,
                LayerNames.Collect,
                LayerNames.SlingoLadder,
                LayerNames.SuperSpinWheel,
                LayerNames.CelebrationBackground,
                LayerNames.CelebrationContent,
                LayerNames.CelebrationForeground,
                LayerNames.Prompts,
                LayerNames.Menus
            ]
        }, LayerNames);

        Layers.get("Prompts").onSceneEnter.add(() => {
            const cover = Layers.get("Prompts").getShape("cover");
            if (cover) {
                Layers.get("Prompts").add(cover, false, true);
            }
        });

        Layers.get("Logo").onSceneEnter.add(() => {
            const logo = Layers.get("Logo").getSprite("logo");
            const arcadeLogo = Layers.get("Logo").getSprite("logo_arcade");

            if (logo) {
                logo.visible = !GamingRealms.isArcade();
                arcadeLogo.visible = GamingRealms.isArcade();
            }
        });

        Layers.get("Onboard").onSceneEnter.add(() => {
            const logo = Layers.get("Onboard").getSprite("logo");
            const arcadeLogo = Layers.get("Onboard").getSprite("logo_arcade");
            const imageRight = Layers.get("Onboard").getSprite("image_right");
            const imageRightArcade = Layers.get("Onboard").getSprite("image_right_arcade");

            if (logo) {
                logo.visible = !GamingRealms.isArcade();
                arcadeLogo.visible = GamingRealms.isArcade();
                imageRight.visible = !GamingRealms.isArcade();
                imageRightArcade.visible = GamingRealms.isArcade();

                if (GamingRealms.isArcade()) {
                    const rightText = Layers.get("Onboard").getText("text_right");
                    rightText.text = rightText.text.replace("350", "50");
                }
            }
        });
    }

    public setupComms(): void {
        super.setupComms();

        builderManager.addRequestBuilder(new GMRForceRequestBuilder());
        builderManager.addRequestBuilder(new GMRInitRequestBuilder());
        builderManager.addRequestBuilder(new SlingoPurchaseEntryRequestBuilder());
        builderManager.addRequestBuilder(new SlingoSpinRequestBuilder());
        builderManager.addRequestBuilder(new SlingoPickRequestBuilder());
        builderManager.addRequestBuilder(new GMRCollectRequestBuilder());
        builderManager.addRequestBuilder(new SlingoStakeToSpinRequestBuilder());

        builderManager.addRecordBuilder(new SlingoInitRecordBuilder());
        builderManager.addRecordBuilder(new SlingoPurchaseEntryRecordBuilder());
        builderManager.addRecordBuilder(new SlingoSpinRecordBuilder());
        builderManager.addRecordBuilder(new SlingoPickRecordBuilder());
        builderManager.addRecordBuilder(new SlingoCollectRecordBuilder());
        builderManager.addRecordBuilder(new SlingoStakeToSpinRecordBuilder());

        builderManager.addResultBuilder(new PopReelSpinResultBuilder());
        builderManager.addResultBuilder(new PopScatterResultBuilder());
        builderManager.addResultBuilder(new SlingoCascadeResultBuilder());
        builderManager.addResultBuilder(new SlingoJokerResultBuilder());
        builderManager.addResultBuilder(new SlingoLadderResultBuilder());
        builderManager.addResultBuilder(new SlingoGameProgressResultBuilder());
        builderManager.addResultBuilder(new SlingoStakeToSpinResultBuilder());
    }

    public setupTransitions(): void {
        gameLayers.CelebrationContent.setTransitions("fullhouse", new FadeTransition(gameLayers.CelebrationContent, 500, "out"));
        gameLayers.CelebrationBackground.setTransitions("fullhouse", new FadeTransition(gameLayers.CelebrationBackground, 500));

        gameLayers.CelebrationContent.setTransitions("bigwin", new SpineBigWinTransition(gameLayers.CelebrationContent, "celebrations", "bigwin"));
        gameLayers.CelebrationContent.setTransitions("superbigwin", new SpineBigWinTransition(gameLayers.CelebrationContent, "celebrations", "super_bigwin"));
        gameLayers.CelebrationContent.setTransitions("megawin", new SpineBigWinTransition(gameLayers.CelebrationContent, "celebrations", "megawin"));
    }

    public setupUI(): void {
        UI.layer(Layers.get("BetBar"), [
            {
                scene: "bonus",
                show: new UIFlagState(UIFlag.BONUS),
                enable: UIFlagState.ALWAYS
            }
        ]);

        super.setupUI();
    }

    public setupSound(): void {
        super.setupSound();
        PopSoundSetup();
    }
}
