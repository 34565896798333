import { gameLoop } from "appworks/core/game-loop";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Services } from "appworks/services/services";
import { KeyInput } from "appworks/utils/key-input";
import { KeyboardCode } from "appworks/utils/keyboard";
import { Timer } from "appworks/utils/timer";
import { DebugMenu } from "slotworks/debug/menu/debug-menu";

export function KeyboardSpeedControl() {
    let currentSpeed = 1;

    const keyInput = new KeyInput();

    const displayElement = GenerateDisplay();
    let hideDisplayTimer: number;

    keyInput.onKeyUp.add((key: string, e: KeyboardEvent) => {
        if (!DebugMenu.getVisible()) {
            switch (key) {
                case KeyboardCode.PERIOD:
                    gameLoop.nextFrame();
                    return;
                case KeyboardCode.EQUAL:
                    currentSpeed = 1;
                    break;
                case KeyboardCode.PLUS:
                    if (currentSpeed < 1) {
                        currentSpeed += 0.1;
                    } else {
                        currentSpeed++;
                    }
                    break;
                case KeyboardCode.MINUS:
                    if (currentSpeed <= 1) {
                        currentSpeed -= 0.1;
                    } else {
                        currentSpeed--;
                    }
                    break;
                case KeyboardCode.DIGIT0:
                    currentSpeed = 0;
                    break;
                case KeyboardCode.OPEN_BRACKET:
                    currentSpeed = 100;
                    break;
                case KeyboardCode.DIGIT9:
                    currentSpeed = 20;
                    break;
                case KeyboardCode.DIGIT8:
                    currentSpeed = 0.5;
                    break;
                case KeyboardCode.DIGIT7:
                    currentSpeed = 0.05;
                    break;
            }

            currentSpeed = Math.round(Math.max(0, currentSpeed) * 100) / 100;

            if (currentSpeed !== gameLoop.getSpeed()) {
                gameLoop.setDebugSpeed(currentSpeed);
                displayElement.innerText = `x${currentSpeed}`;
                displayElement.style.opacity = "1";
                Timer.clearTimeout(hideDisplayTimer);
                hideDisplayTimer = Timer.setTimeout(() => {
                    displayElement.style.opacity = "0";
                }, 1000 * currentSpeed);
            }
        }
    });
}

function GenerateDisplay() {
    const element = document.createElement("div");

    element.style.position = "fixed";
    element.style.top = "0";
    element.style.right = "0";
    element.style.zIndex = "1000";

    element.style.fontSize = "10vh";
    element.style.color = "white";
    element.style.fontFamily = "arial";
    element.style.pointerEvents = "none";

    element.style.opacity = "0";
    element.style.transition = "all .25s";

    Services.get(CanvasService).rootNode.appendChild(element);

    return element;
}
