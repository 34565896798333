import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Gameplay } from "appworks/model/gameplay/gameplay";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Point } from "appworks/utils/geom/point";
import { SignalBinding } from "signals";
import { SlingoCoinHopperComponent } from "slingo/components/slingo-coin-hopper-component";
import { SlingoFooterComponent } from "slingo/components/slingo-footer-component";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";
import { SlingoReelHighlightComponent } from "slingo/components/slingo-reel-highlight-component";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { SlingoHighlightType, SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoSpinType } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { slingoModel } from "slingo/model/slingo-model";
import { showAutoCompletedPrompt } from "slingo/util/slingo-show-auto-completion-prompt";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { BonusResult } from "slotworks/model/gameplay/records/results/bonus-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { slotDefinition } from "slotworks/model/slot-definition";

interface SlingoSpinStateConfig {
    slingoMatrixComponentType: { new (...args: any[]): MatrixComponent };
}

export class SlingoSpinState extends State {
    protected config: SlingoSpinStateConfig = {
        slingoMatrixComponentType: MatrixComponent,
    };
    protected landBinding: SignalBinding;

    constructor(config?: Partial<SlingoSpinStateConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }
    }

    public onEnter(cascadeSkip?: boolean): void {
        Components.get(SlingoTicketMatrixComponent).resetStreak();

        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord();
        const spinResult = record.getFirstResultOfType(SlingoReelSpinResult);

        this.cancelGroup
            .sequence([
                () => this.stopReels(spinResult),
                () => this.getDabContracts(),
                () => Components.get(SlingoLadderComponent).stepToLevel(gameplay.getLatestResultOfType(SlingoLadderResult).total),
            ])
            .then(() => this.complete());
    }

    public onExit(): void {
        this.landBinding.detach();
        this.landBinding = null;
        Components.get(SlingoReelHighlightComponent)?.clearHighlights();
        Components.get(SlingoFooterComponent)?.clear();
    }

    public complete(): void {
        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord() as SlingoRecord;
        const progressResult = record.getFirstResultOfType(SlingoGameProgressResult);

        new Sequence([
            () => this.addFreespins(),
            () => this.updateTotalWin(),
            () => this.updateSpinCounter(),
            () => showAutoCompletedPrompt(progressResult.completionReason),
        ]).then(() => super.complete());
    }

    protected stopReels(result: SlingoReelSpinResult): Contract {
        const fakeRecord = new SpinRecord();
        fakeRecord.grid = result.symbols.map(sym => {
            return [isNaN(parseInt(sym)) ? sym : `numbers/${sym}`];
        });
        fakeRecord.reelset = slotDefinition.reelsets.get("slingo");
        Gameplay.dataProcessorSupplements.get().forEach(supplement => supplement.process(fakeRecord));

        const matrix = Components.get(this.config.slingoMatrixComponentType);

        this.landBinding = matrix.landSignal.add((reelIndex: number) =>
            this.onReelLand(
                reelIndex,
                result.matches.find(match => match.reelIndex === reelIndex)
            )
        );

        return matrix.stopTransition(fakeRecord);
    }

    protected onReelLand(reelIndex: number, match?: { matchedValue: number; reelIndex: number }) {
        if (match) {
            const reelSymbol = Components.get(this.config.slingoMatrixComponentType)
                .getBaseGridSymbols()
                .find(symbol => symbol.symbolId.replace("numbers/", "") === match.matchedValue.toString());
            Components.get(SlingoReelHighlightComponent)
                ?.highlightSymbol(reelSymbol.gridPosition, SlingoHighlightType.MATCHED)
                .execute();

            Components.get(SlingoTicketMatrixComponent)
                .highlightSymbol(match.matchedValue, SlingoHighlightType.MATCHED)
                .execute();
        }
    }

    protected addFreespins() {
        const hopper = Components.get(SlingoCoinHopperComponent);
        if (hopper) {
            const sequence: Array<() => Contract> = [];

            Components.get(MatrixComponent)
                .getAllSymbols()
                .forEach((symbol, reelIndex) => {
                    if (symbol.symbolId === "FS") {
                        sequence.push(() => hopper.addCoinFromReels(reelIndex, SlingoSpinType.FREESPIN));
                    }
                });

            return new Sequence(sequence);
        } else {
            return Contract.empty();
        }
    }

    protected updateTotalWin(tickTime: number = 500): Contract {
        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord();

        // Update total win, but exclude wins from bonuses that haven't been played yet
        const bonusResults = record.getResultsOfType(BonusResult);
        let pendingBonusWin = 0;
        bonusResults.forEach(result => (pendingBonusWin += result.played ? 0 : result.cashWon));

        return Services.get(TransactionService).setWinnings(0, gameplay.getTotalWin() - pendingBonusWin, tickTime);
    }

    protected updateSpinCounter(): Contract {
        const progressResult = gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult);

        const rgExtraSpinLimit = slingoModel.read().responsibleGaming.extraSpins.currentValue;
        const numPurchaseSpins = rgExtraSpinLimit ?? progressResult.purchaseSpins;

        Components.get(SlingoCoinHopperComponent)?.set(
            progressResult.standardSpinsRemaining + progressResult.freeSpinsRemaining,
            progressResult.freeSpins,
            progressResult.purchaseSpinsUsed === 0 ? 0 : numPurchaseSpins - progressResult.purchaseSpinsUsed
        );

        let counterValue = 0;
        if (progressResult.purchaseSpinsUsed === 0) {
            counterValue = progressResult.standardSpinsRemaining + progressResult.freeSpinsRemaining;
        } else {
            counterValue = numPurchaseSpins - progressResult.purchaseSpinsUsed + progressResult.freeSpinsRemaining;
        }

        return Components.get(SlingoSpinsCounterComponent).setValue(counterValue);
    }

    protected getDabContracts(): Contract {
        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord();
        const spinResult = record.getFirstResultOfType(SlingoReelSpinResult);
        const ticketComponent = Components.get(SlingoTicketMatrixComponent);

        const dabContracts: Array<() => Contract> = [];

        spinResult.matches.forEach(match => {
            dabContracts.push(
                () =>
                    new Sequence([
                        () => ticketComponent.dabSymbol(match.matchedValue, false),
                        () =>
                            Contract.wrap(() => {
                                Components.get(SlingoReelHighlightComponent)?.clearHighlight(new Point(match.reelIndex, 0));
                            }),
                        () => ticketComponent.checkWinlines(),
                    ])
            );
        });

        return new Sequence(dabContracts);
    }
}
