import { FlexiToggle } from "appworks/graphics/elements/flexi-toggle";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { ToggleControl } from "./toggle-control";

export class BackgroundMusicToggleControl extends ToggleControl {

    constructor(protected button: FlexiToggle) {
        super(button);

        const sound = Services.get(SoundService);
        sound.onMuteChange.add(() => this.updateButtonState());
        sound.onMusicMuteChange.add(() => this.updateButtonState());

        this.updateButtonState();
    }

    protected onChange(value: boolean): void {
        if (value) {
            Services.get(SoundService).unmute();
        }
        Services.get(SoundService).setMusicMute(!value);
    }

    protected updateButtonState() {
        const sound = Services.get(SoundService);
        const globalMute = sound.getMuted();
        const musicMute = sound.getMusicMuted();

        this.button.setValue(!globalMute && !musicMute);
    }
}
