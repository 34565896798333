import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { State } from "appworks/state-machine/states/state";
import { Contract } from "appworks/utils/contracts/contract";
import { Tween } from "appworks/utils/tween";
import { gameLayers } from "game-layers";
import { PopScatterResult } from "model/pop-scatter-result";
import { PopSoundEvents } from "setup/pop-sound-events";
import { SlingoReelMatrixFactory } from "slingo/components/factories/slingo-reel-matrix-factory";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";

export class PopScatterWinState extends State {
    public onEnter(cascadeSkip?: boolean): void {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const results = record.getResultsOfType(PopScatterResult);
        
        let scatterResult;
        let isScatterWin = false;
        results.forEach((result) => {
            if (result) {
                const wildCount = Components.get(MatrixComponent).getAllSymbols().reduce((total, symbol) => total + (symbol.symbolId.indexOf("J") > -1 ? 1 : 0), 0);
                if (wildCount === result.matches) {
                    scatterResult = result;
                    isScatterWin = true;
                }
            }
        });

        if (isScatterWin) {

            // Show celebration scenes
            gameLayers.CelebrationForeground.setScene("bigwin").execute();
            gameLayers.CelebrationBackground.setScene("bigwin").execute();
            const is5oak = scatterResult.matches === 5 ? true : false;
            const scene = is5oak ? "scatterbig" : "scatterwin";
            const spine = is5oak ? "scatter_big" : "celebrations";
            gameLayers.CelebrationContent.setScene(scene).execute();
            // Get sound service
            const ss = Services.get(SoundService);

            // Animate scatter win spine in / idle
            gameLayers.CelebrationContent.getSpine(spine).playOnce("scatter_win_in").then(() => {
                gameLayers.CelebrationContent.getSpine(spine).play("scatter_win_idle");

                // Set win text
                let tweenObj = { value: 0, alpha: 0 };
                new Tween(tweenObj)
                    .to({ value: scatterResult.cashWon, alpha: 1 }, 500)
                    // Play sfx
                    .onStart(() => {
                        ss.event(SoundEvent.winnings_countup);
                    })
                    .onComplete(() => {
                        ss.event(SoundEvent.winnings_countup_end);
                    })
                    // Update text contents and alpha
                    .onUpdate(() => {
                        gameLayers.CelebrationForeground.scene.bigwin.contents.value.alpha = tweenObj.alpha;
                        gameLayers.CelebrationForeground.scene.bigwin.contents.value.text = Services.get(CurrencyService).format(tweenObj.value);
                    })
                    .start();
            });

            // SFX
            if (scatterResult.matches === 3 || scatterResult.matches === 4) {
                ss.customEvent(PopSoundEvents.scatter_low);
            } else if (scatterResult.matches > 4) {
                ss.customEvent(PopSoundEvents.scatter_high);
            }

            // Wait
            Contract.getTimeoutContract(is5oak ? 8000 : 5000).then(() => {
                // Animate scatter win spine out
                gameLayers.CelebrationContent.getSpine(spine).playOnce("scatter_win_out").then(() => {
                    // Reset celebration scenes
                    gameLayers.CelebrationForeground.defaultScene().execute();
                    gameLayers.CelebrationBackground.defaultScene().execute();
                    gameLayers.CelebrationContent.defaultScene().execute();
                    this.complete();
                });
            });
        } else {
            this.complete();
        }
    }
}

