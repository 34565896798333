import { Layers } from "appworks/graphics/layers/layers";
import { SceneTransition } from "appworks/graphics/layers/scene-transitions/scene-transition";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Contract } from "appworks/utils/contracts/contract";

export abstract class AbstractSceneTransition implements SceneTransition {

    protected layer: Layers;
    protected cancelGroup: CancelGroup;

    constructor(layer: Layers = null) {
        this.layer = layer;
        this.cancelGroup = new CancelGroup();
    }

    public in() {
        return Contract.empty();
    }

    public out() {
        return Contract.empty();
    }

    public skipIn() {
        // Skip in
    }

    public skipOut() {
        // Skip out
    }

    public destroy() {
        // Destroy
    }
}
