import { ButtonElement } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { ControlFactory } from "appworks/ui/factories/control-factory";

/**
 * This will take a list of button names, along with whether or not they should be enabled. It will hide any that should not be visible,
 * and bump the rest up to fill the gaps.
 */
export class ButtonOrganiserControlFactory implements ControlFactory {
    constructor(protected buttons: Map<string, boolean>) { };

    public filter(layer: Layers) {
        let allExist = true;

        this.buttons.forEach((enabled, buttonName) => {
            const element = layer.getButton(buttonName);
            if (!element) { allExist = false; }
        });

        return allExist;
    }

    public build(layer: Layers) {
        const elements: ButtonElement[] = [];
        const elementsToShow: ButtonElement[] = [];

        this.buttons.forEach((enabled, buttonName) => {
            const element = layer.getButton(buttonName);

            if (enabled) {
                elementsToShow.push(element);
            } else {
                // TODO V6: This should be using setVisible but flexibutton is dumb
                element.landscape.scale.set(0);
                element.portrait.scale.set(0);
            }

            elements.push(element);
        });

        const elementPositions = elements.map(element => element.dualPosition.clone()).sort((a, b) => a.landscape.y - b.landscape.y);

        elementsToShow.forEach((button, index) => {
            button.landscape.y = elementPositions[index].landscape.y;
            button.portrait.y = elementPositions[index].portrait.y;
        });

        return [];
    }
}
