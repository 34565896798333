import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Services } from "appworks/services/services";
import { slotDefinition } from "slotworks/model/slot-definition";

export function gridDisplay(grid: string[][], overlayGrid: string[][] = [], addImages: boolean = true) {
    const tableElement = document.createElement("table");
    tableElement.style.color = "#fff";

    tableElement.style.width = "80%";
    tableElement.style.height = "80%";
    tableElement.align = "center";

    const reelParentRow = document.createElement("tr");
    tableElement.appendChild(reelParentRow);

    grid.forEach((reel, reelIndex) => {
        // Create a cell for each reel
        const reelParent = document.createElement("td");
        reelParentRow.appendChild(reelParent);

        // Create a table to contain all symbols in
        const reelTable = document.createElement("table");
        reelParent.appendChild(reelTable);

        reelTable.style.width = reelTable.style.height = "100%";

        reel.forEach((symbol, symbolIndex) => {
            const symbolRow = document.createElement("tr");
            reelTable.appendChild(symbolRow);
            const symbolElement = document.createElement("td");
            symbolElement.id = `symbol_${reelIndex}_${symbolIndex}`;
            symbolRow.appendChild(symbolElement);

            symbolElement.style.border = "solid 1px #fff";
            symbolElement.style.textAlign = "center";
            symbolElement.style.verticalAlign = "middle";
            symbolElement.style.webkitTextStroke = "black 1px";
            symbolElement.style.fontFamily = "sans-serif";
            symbolElement.style.fontWeight = "bold";

            // Detect quick hit symbols and display their value
            if (slotDefinition.getSymbolDefinition(symbol).id !== symbol) {
                symbolElement.innerText = symbol.slice(symbol.lastIndexOf("_") + 1, symbol.length);
            } else {
                symbolElement.innerText = " ";
            }
            symbolElement.style.whiteSpace = "pre";

            if (addImages) {
                let overlayPad = "";

                if (overlayGrid && overlayGrid[reelIndex] && overlayGrid[reelIndex][symbolIndex]) {
                    const overlaySymbol = overlayGrid[reelIndex][symbolIndex];

                    symbolElement.style.backgroundImage += "url(" + getSymbolImageData(overlaySymbol) + ")";
                    symbolElement.style.backgroundSize += "50%";
                    symbolElement.style.backgroundPosition += "right bottom";
                    symbolElement.style.backgroundRepeat += "no-repeat";

                    overlayPad = ", ";
                }

                symbolElement.style.backgroundImage += overlayPad + "url(" + getSymbolImageData(symbol) + ")";
                symbolElement.style.backgroundSize += overlayPad + "contain";
                symbolElement.style.backgroundPosition += overlayPad + "center";
                symbolElement.style.backgroundRepeat += overlayPad + "no-repeat";
            }
        });
    });

    return tableElement;
}

const imageDataCache = new Map<string, string>();

export function getSymbolImageData(symbol: string) {
    symbol = slotDefinition.getSymbolDefinition(symbol).id;
    let symbolData;
    if (imageDataCache.has(symbol)) {
        symbolData = imageDataCache.get(symbol);
    } else {
        symbolData = Services.get(CanvasService).renderer.extract.canvas(Services.get(GraphicsService).createSprite(symbol)).toDataURL("image/png");
        imageDataCache.set(symbol, symbolData);
    }

    return symbolData;
}
