import { Sprite } from "appworks/graphics/pixi/sprite";
import { Contract } from "appworks/utils/contracts/contract";
import { Texture } from "pixi.js";
import { Signal, SignalBinding } from "signals";

export class AnimatedSprite extends Sprite {

    public animationSpeed: number = 1;
    public loop: boolean = true;
    public onRangeComplete: Signal = new Signal();
    public onComplete: Signal = new Signal();
    public onFrameChange: Signal = new Signal();
    public playing: boolean = false;
    public currentFrame: number = 0;
    public startFrame: number;
    public endFrame: number;
    public loopFrame: number = 0;
    public reverse: boolean;

    public destroyed: boolean = false;

    private textures: Texture[];
    private deltaTime: number = 0;
    private lastTime: number = null;

    private delayTime: number = 0;
    private playBinding: SignalBinding;

    private frameSprite: Sprite;
    private frameSprites: Sprite[];

    /**
     * If frameSprites is passed in, those sprites will be used as frames instead of changing the texture
     */
    constructor(textures: Texture[], frameSprites?: Sprite[]) {
        super(textures[0]);

        if (frameSprites) {
            this.frameSprites = frameSprites;
            frameSprites.forEach((frameSprite) => {
                frameSprite.visible = false;
                this.addChild(frameSprite);
            });
            this.frameSprite = this.frameSprites[0];
            this.frameSprite.visible = true;
        }

        this.currentFrame = 0;
        this.textures = textures;
    }

    public stop(): void {
        this.delayTime = 0;
        this.playing = false;

        this.clearPlayBinding();
    }

    public play(): void {
        this.playing = true;
    }

    public playRange(from: number, to: number, loop: boolean = false): void {
        this.currentFrame = from;
        this.startFrame = from;
        this.endFrame = to;
        this.loop = loop;
        this.playing = true;
    }

    public playRangeOnce(from: number, to: number): Contract<void> {
        return new Contract<void>((resolve) => {
            this.playRange(from, to);
            this.playBinding = this.onRangeComplete.addOnce(resolve);
        });
    }

    // TODO: change this to frame = 0 so it's more consistent with spine anims
    public playOnce(frame = this.currentFrame): Contract<void> {
        return this.gotoAndPlayOnce(frame);
    }

    public gotoAndStop(frame: number): void {
        this.startFrame = undefined;
        this.endFrame = undefined;
        this.currentFrame = frame;
        this.stop();
        this.updateTexture();
    }

    public gotoAndPlay(frame: number = 0): void {
        this.startFrame = undefined;
        this.endFrame = undefined;
        this.currentFrame = frame;
        this.clearPlayBinding();
        this.play();
        this.updateTexture();
    }

    public gotoAndPlayDelayed(frame: number = 0, delay: number = 0): void {
        this.delayTime = delay;
        this.gotoAndPlay(frame);
    }

    public gotoAndPlayOnce(frame: number = 0): Contract<void> {
        return new Contract<void>((resolve, cancel) => {
            this.loop = false;
            this.gotoAndPlay(frame);
            this.playBinding = this.onComplete.addOnce(resolve);

            cancel(() => {
                if (!this.destroyed) {
                    this.gotoAndStop(0);
                }
                if (this.playBinding) {
                    this.playBinding.detach();
                }
            });
        });
    }

    public update(time: number): void {

        const singnalsToDispatch = [];

        const startingFrame = this.currentFrame;

        if (this.lastTime === null) {
            this.lastTime = time;
        }

        if (this.playing) {
            this.deltaTime += time - this.lastTime;

            if (this.delayTime > 0) {
                this.delayTime -= this.deltaTime;
                if (this.delayTime < 0) {
                    this.deltaTime = -this.delayTime;
                    this.delayTime = 0;
                } else {
                    this.deltaTime = 0;
                }
            }

            if (this.delayTime <= 0) {
                const timePerFrame = (1000 / 60) / this.animationSpeed;

                while (this.deltaTime > timePerFrame) {

                    this.deltaTime -= timePerFrame;
                    this.currentFrame++;

                    if (!isNaN(this.startFrame) && !isNaN(this.endFrame) && this.currentFrame >= this.endFrame) {
                        if (this.loop) {
                            this.currentFrame = this.startFrame;
                        } else {
                            this.currentFrame = this.endFrame;
                            this.playing = false;
                            this.startFrame = undefined;
                            this.endFrame = undefined;
                        }

                        singnalsToDispatch.push(this.onRangeComplete);
                        break;
                    }
                    if (this.currentFrame >= this.totalFrames) {
                        if (this.loop) {
                            this.currentFrame = this.loopFrame;
                        } else {
                            this.currentFrame = this.totalFrames - 1;
                            this.playing = false;
                        }

                        singnalsToDispatch.push(this.onComplete);
                        break;
                    }
                }
            }
        } else {
            this.deltaTime = 0;
        }

        this.lastTime = time;

        const textureChanged = this.updateTexture();

        if (textureChanged) {
            for (let frame = startingFrame + 1; frame <= this.currentFrame; frame++) {
                this.onFrameChange.dispatch(frame);
                if (frame >= this.totalFrames) {
                    frame = 0;
                }
            }
        }

        singnalsToDispatch.forEach((signal: Signal) => signal.dispatch());
    }

    public updateTexture(): boolean {
        const currentFrame = this.reverse ? (this.totalFrames - this.currentFrame - 1) : this.currentFrame;

        if (this.frameSprites && this.frameSprite !== this.frameSprites[currentFrame]) {
            this.frameSprite.visible = false;
            this.frameSprite = this.frameSprites[currentFrame];
            this.frameSprite.visible = true;

            return true;
        } else if (this.texture !== this.textures[currentFrame]) {
            this.texture = this.textures[currentFrame];

            return true;
        }

        return false;
    }

    public destroy(): void {
        if (!this.destroyed) {
            this.destroyed = true;
            this.stop();
            if (this.texture) {
                super.destroy();
            }
        }
    }

    get totalFrames(): number {
        if (this.frameSprites) {
            return this.frameSprites.length;
        }
        return this.textures.length;
    }

    public getAnimationDuration(): number {
        return this.totalFrames / (this.animationSpeed * 60) * 1000;
    }

    private clearPlayBinding(): void {
        if (this.playBinding) {
            this.playBinding.detach();
            this.playBinding = null;
        }
    }
}
