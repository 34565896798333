import { PositionConfig } from "appworks/config/asset-config-schema";
import { ToggleElement } from "appworks/graphics/elements/toggle-element";
import { Layers } from "appworks/graphics/layers/layers";
import { generateButton } from "appworks/graphics/layers/populators/generate-button";

export function generateToggle(id: string, spriteId: string, landscapePosition: PositionConfig, portraitPosition: PositionConfig, layer: Layers) {
    const idParts = id.split("-");
    const buttonId = idParts[0];
    const toggleState: "on" | "off" = idParts[1] as "on" | "off";
    const buttonState = idParts.length > 1 ? idParts[2] : "up";

    let toggle = layer.getToggle(buttonId);

    if (!toggle) {
        toggle = new ToggleElement();
        layer.addToggle(buttonId, toggle);
    }

    const buttonGenString = buttonId + "_" + toggleState + "-" + buttonState;
    generateButton(buttonGenString, spriteId, layer, landscapePosition, portraitPosition);

    const flexiButton = layer.getFlexiButton(buttonId + "_" + toggleState);
    toggle.addButton(flexiButton, toggleState);
}
