import { arrayOfValues } from "appworks/utils/collection-utils";
import { Point } from "appworks/utils/geom/point";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { DataProcessorSupplement } from "slotworks/model/gameplay/supplements/data-processor-supplement";
import { slotDefinition } from "slotworks/model/slot-definition";
import { SymbolDefinition } from "slotworks/model/symbol-definition";
import { AnticipationResult } from "../records/results/anticipation-result";

export class AnticipationSupplement implements DataProcessorSupplement {
    public reelOrder?: number[];

    public active: boolean = true;
    // Disable supplement for these record types
    public excludeRecordTypes: Array<{ new(...args: any[]): any }>;

    protected symbolAnticipations: Map<string, SymbolAnticipateCount>;
    protected reels: string[][];
    protected selectedReelOrder: number[];

    constructor(reelOrder?: number[]) {
        this.reelOrder = reelOrder;
    }

    public process(record: SpinRecord) {

        const definitionsExist = !!slotDefinition.symbolDefinitions;
        const recordHasGrid = record.grid;

        if (!this.active || !definitionsExist || !recordHasGrid) {
            return;
        }

        if (this.excludeRecordTypes) {
            let excluded = false;
            this.excludeRecordTypes.forEach((type) => {
                if (record instanceof type) {
                    excluded = true;
                }
            });
            if (excluded) {
                return;
            }
        }

        const anticipationResult = this.buildAnticipationResult(record);

        record.results.push(anticipationResult);
    }

    protected buildAnticipationResult(record: SpinRecord) {

        this.symbolAnticipations = new Map<string, SymbolAnticipateCount>();
        this.reels = record.grid;
        this.selectedReelOrder = this.getReelOrder(record.grid);

        const anticipationResult: AnticipationResult = new AnticipationResult();

        // Create anticipation count for all symbols
        slotDefinition.symbolDefinitions.forEach((symbol) => {
            this.symbolAnticipations.set(symbol.id, { count: 0, anticipate: false, gap: false, bonusId: symbol.bonusId });
        });

        for (let reelIndex = 0; reelIndex < this.reels.length; reelIndex++) {

            const reel = this.selectedReelOrder[reelIndex];

            anticipationResult.landSymbols[reel] = [];
            anticipationResult.landBonuses[reel] = [];
            anticipationResult.anticipateSymbols[reel] = [];

            // Continue to track symbols which still have a chance of triggering
            this.symbolAnticipations.forEach((symbolAnticipation, symbolId) => {
                if (symbolAnticipation.anticipate && this.hasChanceToWin(slotDefinition.getSymbolDefinition(symbolId), reel, symbolAnticipation)) {
                    anticipationResult.anticipateSymbols[reel].push(symbolId);
                }
            });

            this.reels[reel].forEach((symbolId, row) => {

                const bonusSymbols = this.getSymbolsAndTriggeringWilds(symbolId);

                for (const symbol of bonusSymbols) {
                    const symbolAnticipation = this.symbolAnticipations.get(symbol.id);

                    if (this.isNotValidAnticipationSymbol(symbol, row)) {
                        continue;
                    }

                    // Update count of symbols hit
                    symbolAnticipation.gap = symbolAnticipation.count < reel;

                    if (symbol.ways && symbolAnticipation.gap) {
                        continue;
                    }

                    symbolAnticipation.count++;

                    // Update other symbol anticipation counts which share a bonus ID
                    this.updateRelatedAnticipationCounts(symbol.bonusId, symbolAnticipation);

                    // Only play symbol lands if there is still a chance of winning a bonus
                    if (this.hasChanceToWin(symbol, reelIndex, symbolAnticipation)) {
                        anticipationResult.landSymbols[reel].push(symbolId);
                        anticipationResult.landBonuses[reel].push(symbol.bonusId);
                        anticipationResult.positions.push(new Point(reel, row));
                    } else {
                        // Not possible to win, so stop counting
                        symbolAnticipation.count = 0;
                    }

                    // Check to see if anticipation is triggered yet
                    if (symbolAnticipation.count >= symbol.anticipation) {
                        // Activate the anticipation
                        symbolAnticipation.anticipate = true;
                        anticipationResult.anticipateSymbols[reel].push(symbolId);
                    }
                }
            });

            this.symbolAnticipations.forEach((symbolAnticipation, symbolId) => {
                const symbol = slotDefinition.getSymbolDefinition(symbolId);
                const maxAnticipation = symbol.matchesMax === 0 ? this.reels.length : symbol.matchesMax;
                const minAnticipation = symbol.matchesMin;
                const remainingReels = this.reels.length - reelIndex - 1;
                const possibleRemaining = remainingReels * symbol.possibleMatchesPerReel;

                // Deactivate anticipation when count goes over max, or is no longer possible to achieve the bonus
                if (symbolAnticipation.count >= maxAnticipation || (symbolAnticipation.count + possibleRemaining) < minAnticipation) {
                    symbolAnticipation.anticipate = false;
                }

                // Deactivate anticipation when there are gaps in a ways trigger
                if (symbolAnticipation.gap && symbol.ways) {
                    symbolAnticipation.anticipate = false;
                } else {
                    // Turn gap on which will either be filled or not when moving to next reel
                    symbolAnticipation.gap = true;
                }
            });

            anticipationResult.reelAnticipations[reel + 1] = false;

            // If any anticipations are active, next reel should anticipate
            this.symbolAnticipations.forEach((value: { count: number, anticipate: boolean }, key: string) => {
                const symbolDef = slotDefinition.getSymbolDefinition(key);

                if (value.anticipate) {
                    // If the next strip doesn't contain any of the anticipation worthy symbols, it should not anticipate

                    const nextReelIndex = this.selectedReelOrder[reelIndex + 1];

                    const nextReelStripContainsSymbol = symbolDef.reels.indexOf(nextReelIndex) > -1;
                    if (nextReelStripContainsSymbol) {
                        anticipationResult.reelAnticipations[nextReelIndex] = true;
                    }
                }
            });
        }

        // Second pass for symbols which always anticipate
        for (const symbolDef of slotDefinition.symbolDefinitions) {
            if (symbolDef.anticipation === 0 && symbolDef.matchesMin > 0) {
                for (let reel = 0; reel < this.reels.length; reel++) {
                    const reelStripContainsSymbol = symbolDef.reels.indexOf(reel) > -1;
                    if (reelStripContainsSymbol) {
                        anticipationResult.reelAnticipations[reel] = true;
                    }
                }
            }
        }

        // Last "next reel should anticipate" is irrelevant
        anticipationResult.reelAnticipations.pop();

        return anticipationResult;
    }

    protected getSymbolsAndTriggeringWilds(symbolId: string) {

        const symbol = slotDefinition.getSymbolDefinition(symbolId);
        const bonusSymbols = [symbol];

        if (symbol.wild) {
            for (const newSymbol of slotDefinition.symbolDefinitions) {
                if (newSymbol.bonusId > 0 && newSymbol.includeWilds) {
                    bonusSymbols.push(newSymbol);
                }
            }
        }

        return bonusSymbols;
    }

    protected isNotValidAnticipationSymbol(symbol: SymbolDefinition, row: number) {
        // Skip if symbol has no anticipation, don't track it
        if (symbol.matchesMin === 0) {
            return true;
        }

        // Skip if the symbol is not on a valid row
        if (symbol.validRows && symbol.validRows.indexOf(row) === -1) {
            return true;
        }

        return false;
    }

    protected getReelOrder(reels: string[][]): number[] {
        if (this.reelOrder) {
            return this.reelOrder;
        }

        return arrayOfValues(reels.length);
    }

    protected hasChanceToWin(symbol: SymbolDefinition, reelIndex: number, symbolAnticipation: SymbolAnticipateCount) {
        let validReelsRemaining = (this.reels.length - (reelIndex + 1));
        if (symbol.reels) {
            validReelsRemaining = symbol.reels.filter((value) => this.selectedReelOrder.slice(reelIndex + 1, this.selectedReelOrder.length).indexOf(value) > -1).length;
        }

        return symbolAnticipation.count + (validReelsRemaining * symbol.possibleMatchesPerReel) >= symbol.matchesMin;
    }

    protected updateRelatedAnticipationCounts(bonusId: number, symbolAnticipation: SymbolAnticipateCount) {
        if (!bonusId) {
            return;
        }

        this.symbolAnticipations.forEach((otherSymbolAnticipation) => {
            if (otherSymbolAnticipation !== symbolAnticipation) {
                if (otherSymbolAnticipation.bonusId === symbolAnticipation.bonusId) {
                    otherSymbolAnticipation.count++;
                }
            }
        });
    }
}

interface SymbolAnticipateCount {
    count: number;
    anticipate: boolean;
    gap: boolean;
    bonusId: number;
}
