import { AbstractComponent } from "appworks/components/abstract-component";
import { Components } from "appworks/components/components";
import { PromptComponent } from "appworks/components/prompt/prompt-component";
import { PromptContentSubcomponent } from "appworks/components/prompt/prompt-content-subcomponent";
import { ToggleEvent } from "appworks/graphics/elements/flexi-toggle";
import { Layers } from "appworks/graphics/layers/layers";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { Contract } from "appworks/utils/contracts/contract";
import { logger } from "appworks/utils/logger";
import { Signal } from "signals";

export class AlertComponent extends AbstractComponent {

    public rememberedChoices: Map<string, boolean> = new Map<string, boolean>();
    public onFatal: Signal = new Signal();

    protected initialized

    protected fatal: boolean = false;

    public init() {
        super.init();

        this.initialized = true;
    }

    public isInitialized() {
        return this.initialized;
    }

    public info(messageId: string, titleId?: string): Contract<void> {
        if (this.fatal) {
            return Contract.never();
        }

        let message = this.getMessage(messageId);

        if (message === undefined || message === "" || !message) {
            message = Services.get(TranslationsService).get("General Error");
        }

        const prompt = new PromptContentSubcomponent(["info", "error"], Infinity, Layers.get("Alerts"));
        prompt.setText("body", message);
        if (titleId) { prompt.setText("title", this.getMessage(titleId)); }
        prompt.addContinueButton();

        return Components.get(PromptComponent).showPrompt(prompt);
    }

    /**
     * @param messageId {string}
     * @param uid {string} Unique ID to use to save preference of don't show again checkbox
     */
    public confirm(messageId: string, uid?: string, titleId?: string): Contract<boolean> {
        if (this.fatal) {
            return Contract.never();
        }

        return new Contract<boolean>((resolve) => {
            const confirmUID = "prompt_" + (uid || messageId);

            const rememberedChoice = this.rememberedChoices.get(confirmUID);

            if (rememberedChoice) {
                resolve(false);
                return;
            }

            let message = this.getMessage(messageId);

            if (message === undefined || message === "" || !message) {
                message = Services.get(TranslationsService).get("General Error");
            }

            const prompt = new PromptContentSubcomponent(["confirm", "yes_no"], Infinity, Layers.get("Alerts"));
            prompt.addContinueButton();
            prompt.addButton("no", () => {
                prompt.hide().execute();
                resolve(false);
            });
            prompt.setText("body", message);
            if (titleId) { prompt.setText("title", this.getMessage(titleId)); }
            prompt.addContinueButton();

            Components.get(PromptComponent).showPrompt(prompt).then(() => {
                prompt.hide().execute();
                resolve(true);
            });

            const checkbox = Layers.get("Alerts").getFlexiToggle("checkbox");
            if (checkbox) {
                checkbox.setValue(false);
                checkbox.on(ToggleEvent.CHANGE, () => {
                    this.rememberedChoices.set(confirmUID, checkbox.getValue());
                });
            }
        });

    }

    public error(messageId: string, fatal: boolean = false, titleId?: string): Contract<void> {
        if (this.fatal) {
            return Contract.never();
        }

        if (fatal) {
            this.fatal = true;
            this.onFatal.dispatch();
        }

        let message = this.getMessage(messageId);

        if (message === undefined || message === "" || !message) {
            message = Services.get(TranslationsService).get("General Error");
        }

        if (Layers.get("Alerts") && !Layers.get("Alerts").hasAnyScene(["info", "error"])) {
            logger.error(new Error().stack);
        }

        const prompt = new PromptContentSubcomponent(["info", "error"], Infinity, Layers.get("Alerts"));
        prompt.setText("body", message);
        if (titleId) { prompt.setText("title", this.getMessage(titleId)); }
        prompt.addContinueButton();

        return new Contract<void>((resolve) => {
            Components.get(PromptComponent).showPrompt(prompt).then(() => {
                if (fatal) {
                    location.reload();
                }

                resolve(null);
            });
        });
    }

    public recover(messageID?: string, titleId?: string): Contract<void> {
        const recoveryMessages = ["recovering_game", "rec.spi"];
        if (messageID) {
            recoveryMessages.unshift(messageID);
        }

        messageID = Services.get(TranslationsService).getFirstValid(recoveryMessages);

        return this.info(messageID, titleId);
    }

    protected getMessage(messageId: string) {
        let message;

        try {
            message = Services.get(TranslationsService).get(messageId);
        } catch (e) {
            message = messageId;
        }

        return message;
    }
}
