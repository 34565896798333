import { Layers } from "appworks/graphics/layers/layers";
import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { GameNameLabelControl } from "appworks/ui/controls/standard/text/game-name-label-control";
import { ControlFactory } from "../control-factory";

export class GameNameControlFactory implements ControlFactory {
    public filter(layer: Layers): boolean {
        if (layer.getFlexiText("game_name")) {
            return true;
        }
        return false;
    }

    public build(layer: Layers): AbstractControl[] {
        return [ new GameNameLabelControl(layer.getFlexiText("game_name")) ];
    }
}
