import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";
import { SoundNames } from "sounds-enum";
import { PopSoundEvents } from "./pop-sound-events";

export function PopSoundSetup() {
    const sound = Services.get(SoundService);

    // SFX Launch
    sound.registerPlay(SoundEvent.enter_basegame, { id: SoundNames.sp_board_appears, singleton: true });
    sound.registerPlay(SoundEvent.start_game, { id: SoundNames.sp_launch_game });
    sound.registerStop(SlingoSoundEvent.play_game, { id: SoundNames.sp_launch_game });

    // SFX Reels appear
    sound.registerPlay(SoundEvent.start_spin, { id: SoundNames.sp_reel_appear, singleton: true });
    // Remove reels
    sound.registerPlay(SoundEvent.end_spin, { id: SoundNames.sp_reel_remove, singleton: true });

    // Free spin 'chink' animation
    sound.registerPlay(PopSoundEvents.free_spin, { id: SoundNames.sp_free_spin_chink });

    // Spin click
    sound.registerPlay(SoundEvent.default_button_press, { id: SoundNames.sp_spin_click });

    // SFX Ladder meter
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["1"], { id: SoundNames.sp_level_up_noprize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["2"], { id: SoundNames.sp_level_up_noprize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["3"], { id: SoundNames.sp_level_up_noprize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["4"], { id: SoundNames.sp_level_up_noprize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["5"], { id: SoundNames.sp_level_up_cashprize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["6"], { id: SoundNames.sp_level_up_cashprize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["7"], { id: SoundNames.sp_level_up_cashprize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["8"], { id: SoundNames.sp_level_up_cashprize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["9"], { id: SoundNames.sp_level_up_featureprize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["10"], { id: SoundNames.sp_level_up_featureprize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["11"], { id: SoundNames.sp_level_up_featureprize });

    // Reel 'pop' on dab
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["1"], { id: SoundNames.sp_cascade_in_4 });
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["2"], { id: SoundNames.sp_cascade_in_1 });
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["3"], { id: SoundNames.sp_cascade_in_2 });
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["4"], { id: SoundNames.sp_cascade_in_3 });
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["5"], { id: SoundNames.sp_cascade_in_4 });

    // Whirlpool
    sound.registerPlayArgs(PopSoundEvents.whirpool, ["FS"], { id: SoundNames.sp_anticipation });
    sound.registerPlayArgs(PopSoundEvents.whirpool, ["SJ"], { id: SoundNames.sp_anticipation });
    sound.registerPlayArgs(PopSoundEvents.whirpool, ["J"], { id: SoundNames.sp_anticipation });
    sound.registerPlayArgs(PopSoundEvents.whirpool, ["D"], { id: SoundNames.sp_anticipation });

    // Bet change
    sound.registerPlay(SoundEvent.stake_radio_select_higher, { id: SoundNames.sp_bet_value_increase });
    sound.registerPlay(SoundEvent.stake_radio_select_lower, { id: SoundNames.sp_bet_value_decrease });

    // Spin Start click
    sound.registerPlay(SlingoSoundEvent.purchase_spin, { id: SoundNames.sp_extra_spin_click });

    // Celebrations
    sound.registerPlayArgs(SoundEvent.big_win_tier_id, ["bigwin"], { id: SoundNames.sp_win_big });
    sound.registerPlayArgs(SoundEvent.big_win_tier_id, ["superbigwin"], { id: SoundNames.sp_win_super });
    sound.registerPlayArgs(SoundEvent.big_win_tier_id, ["megawin"], { id: SoundNames.sp_win_mega });
    sound.registerPlayArgs(SoundEvent.big_win_tier_id, ["fullhouse"], { id: SoundNames.sp_win_full_house });
    // Scatters
    sound.registerPlay(PopSoundEvents.scatter_low, { id: SoundNames.sp_win_scatter_low });
    sound.registerPlay(PopSoundEvents.scatter_high, { id: SoundNames.sp_win_scatter_high });

    // Win countups
    sound.registerPlay(SoundEvent.winnings_countup, { id: SoundNames.sp_prize_countup_loop, loop: true });
    sound.registerStop(SoundEvent.winnings_countup_end, { id: SoundNames.sp_prize_countup_loop });
    sound.registerPlay(SoundEvent.big_win_countup_start, { id: SoundNames.sp_prize_countup_loop, loop: true });
    sound.registerStop(SoundEvent.big_win_countup_end, { id: SoundNames.sp_prize_countup_loop });

    // Results panel
    sound.registerPlay(SlingoSoundEvent.result_substake_win, { id: SoundNames.sp_results_substake_win });
    sound.registerPlay(SlingoSoundEvent.result_normal_win, { id: SoundNames.sp_results_normal_win });
    sound.registerPlay(SlingoSoundEvent.result_big_win, { id: SoundNames.sp_results_big_win });
    sound.registerPlay(SlingoSoundEvent.result_full_house_win, { id: SoundNames.sp_results_full_house });

    // Game over
    sound.registerPlay(SlingoSoundEvent.result_no_win, { id: SoundNames.sp_game_over_no_results });

    // Slingo
    sound.registerPlayArgs(SlingoSoundEvent.slingo_N, ["1"], { id: SoundNames.sp_slingo_1 });
    sound.registerPlayArgs(SlingoSoundEvent.slingo_N, ["2"], { id: SoundNames.sp_slingo_2 });
    sound.registerPlayArgs(SlingoSoundEvent.slingo_N, ["3"], { id: SoundNames.sp_slingo_3 });
    sound.registerPlayArgs(SlingoSoundEvent.slingo_N, ["4"], { id: SoundNames.sp_slingo_4 });

    // Free spin added
    sound.registerPlayArgs(PopSoundEvents.symbol_idle, ["FS"], { id: SoundNames.sp_free_spin_added });

    // Wilds
    sound.registerPlayArgs(PopSoundEvents.symbol_idle, ["SJ"], { id: SoundNames.sp_reel_drop_superwild });
    sound.registerPlayArgs(PopSoundEvents.symbol_idle, ["J"], { id: SoundNames.sp_reel_drop_wild });

    // Reels stop
    sound.registerPlay(SoundEvent.reel_any_land, { id: SoundNames.sp_dab_1 });

    // Reel icon removal
    sound.registerPlay(PopSoundEvents.vanish_number, { id: SoundNames.sp_vanish_number });
    sound.registerPlay(PopSoundEvents.vanish_wild, { id: SoundNames.sp_vanish_wild });

    // Theme music
    sound.registerPlay(SlingoSoundEvent.play_game, { id: SoundNames.sp_theme_1_loop, music: true, singleton: true, loop: true });
    sound.registerStop(SlingoSoundEvent.end_game_no_win, { id: SoundNames.sp_theme_1_loop });
    sound.registerStop(SlingoSoundEvent.end_game_win, { id: SoundNames.sp_theme_1_loop });
    // Theme 2
    sound.registerStopArgs(SlingoSoundEvent.ladder_level_up_N, ["8"], { id: SoundNames.sp_theme_1_loop });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["8"], { id: SoundNames.sp_theme_2_loop, music: true, singleton: true, loop: true });
    sound.registerStop(SlingoSoundEvent.end_game_no_win, { id: SoundNames.sp_theme_2_loop });
    sound.registerStop(SlingoSoundEvent.end_game_win, { id: SoundNames.sp_theme_2_loop });

    // Slingo logo
    sound.registerPlay(SlingoSoundEvent.slingo, { id: SoundNames.sp_slingo_logo });

    // Coin (hopper) sfx
    sound.registerPlay(PopSoundEvents.coin_flip, { id: SoundNames.sp_coin_flip });
    sound.registerPlay(PopSoundEvents.coin_insert, { id: SoundNames.sp_coin_insert });
    sound.registerPlay(PopSoundEvents.coin_nudge, { id: SoundNames.sp_coin_nudge });

    // Drain
    sound.registerPlayArgs(PopSoundEvents.symbol_idle, ["D"], { id: SoundNames.sp_sinkplug_waterdrain });
}

