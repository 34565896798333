import { AssetConfigSchema } from "appworks/config/asset-config-schema";
import { gameLoop } from "appworks/core/game-loop";
import { Layers } from "appworks/graphics/layers/layers";
import { Service } from "appworks/services/service";
import { AddWindowFocusChangeListener } from "appworks/utils/browser-utils";
import { nearestUnder } from "appworks/utils/collection-utils";
import { deviceInfo } from "appworks/utils/device-info";
import { Point } from "appworks/utils/geom/point";
import * as Hammer from "hammerjs";
import * as Logger from "js-logger";
import { ENV, Renderer, settings } from "pixi.js";
import { CanvasRenderer } from "pixi.js-legacy";
import { Signal } from "signals";
import { Container } from "../pixi/container";
import { DualPosition } from "../pixi/dual-position";
import { Position } from "../pixi/position";
import { RenderOrientation } from "../pixi/render-orientation";
import { Orientation } from "./orientation";


const iNoBounce = require("inobounce");

declare const __DEBUG__: boolean;

export enum ResolutionName {
    LD = "ld",
    MD = "md",
    HD = "hd"
}

export class CanvasService extends Service {
    public FIT_CANVAS_TO_SCREEN: boolean = false;

    public stage: Container;

    /**
     * Theoretical width of the stage (doesn't include pixel density or css scaling)
     */
    public stageWidth: number;
    /**
     * Theoretical height of the stage (doesn't include pixel density or css scaling)
     */
    public stageHeight: number;

    public viewport: DualPosition;

    /**
     * Pixel density pixi will render at. Shouldn't really need to access this as pixi will handle everything automatically
     */
    public resolution: number;
    public layoutScale: number;
    public resolutionName: ResolutionName;

    public renderer: Renderer | CanvasRenderer;
    public canvas: HTMLCanvasElement;

    /**
     * Enabled scroll prompt overlay for iOS full screen
     */
    public iosScrollPromptEnabled: boolean = true;

    /**
     * Enables full screen api on iPads
     */
    public enableIpadFullScreen: boolean = true;

    /**
     * Set valid orientations for overlay.
     */
    public validOrientations: Orientation[] = [];

    /**
     * For gestures
     */
    public hammer: HammerManager;

    public allowPreloaderRotationPrompt: boolean = false;
    public rotationPromptOnDesktop: boolean = false;

    public headerDOMContainer: HTMLDivElement;
    public footerDOMContainer: HTMLDivElement;
    public portraitDOMContainer: HTMLDivElement;
    public landscapeDOMContainer: HTMLDivElement;

    public extraCanvasContainers: HTMLElement[] = [];

    public scaledContainers: HTMLElement[] = [];

    public orientation: Orientation = Orientation.LANDSCAPE;
    public onOrientationChange: Signal = new Signal();
    public onResize: Signal = new Signal();

    public iosScrollOnlyIfCanvasIsTooBig: boolean = true;
    public iosScrollOnlyLandscape: boolean = false;
    public iosScrollWaitForGameLoad: boolean = true;

    public fullScreenEnabled: boolean = true;

    public rootNode: HTMLElement = document.body;
    public rootRootNode: HTMLElement = document.documentElement;
    public canvasContainer: HTMLElement;

    /** @todo Change this to true this in next beta */
    public cssCanvasEnabled: boolean = false;

    protected fixedPositionBackground = false;
    protected portraitCanvasEnabled: boolean = false;
    protected portraitEnabled: boolean = true;
    protected landscapeScale: number = 1;
    protected portraitScale: number = 1;
    protected landScapeOffset: Point = new Point(0, 0);
    protected portraitOffset: Point = new Point(0, 0);
    protected landscapeAnchor: Point = new Point(0.5, 0.5);
    protected portraitAnchor: Point = new Point(0.5, 0.5);
    protected landscapeBackgroundOffset: Point;
    protected portraitBackgroundOffset: Point;

    protected previousScreenWidth: number;
    protected previousScreenHeight: number;
    protected previousWindowWidth: number;
    protected previousWindowHeight: number;

    protected setupComplete: boolean;
    protected isGameLoaded: boolean;
    protected iosEnableZoom: boolean = false;
    protected iosEnableINoBounce: boolean = true;
    protected iosIsScrolling: boolean = false;

    protected fullScreenPaused = false;
    protected resumeFullScreen = false;

    protected safeArea: DualPosition;

    public init(): void {
        // Do nothing. Handled in setup due to assetConfig requirements.
    }

    /**
     * Creates a pixi renderer (WebGL with canvas fallback), an HTML canvas element and a pixi stage container
     * Uses graphics config to intialize factory methods
     * Starts render loop (requestAnimationFrame)
     */
    // TODO: Reduce cyclomatic complexity
    // tslint:disable-next-line:cyclomatic-complexity
    public setup(assetConfig: AssetConfigSchema) {

        this.setINoBounceEnabled(true);

        // Show the document body
        if (deviceInfo.isIE11()) {
            this.rootNode.style.display = "block";
        } else {
            this.rootNode.style.display = "initial";
        }

        // Delete root elements which aren't this layout
        for (let i = this.rootNode.childNodes.length - 1; i >= 0; i--) {
            const element = this.rootNode.childNodes.item(i) as HTMLElement;
            if ((element.id === "desktop" || element.id === "mobile") && element.id !== deviceInfo.layoutId) {
                this.rootNode.removeChild(element);
            }
        }

        this.headerDOMContainer = document.getElementById("headerDOMContainer") as HTMLDivElement;
        this.footerDOMContainer = document.getElementById("footerDOMContainer") as HTMLDivElement;
        this.portraitDOMContainer = document.getElementById("portrait") as HTMLDivElement;
        this.landscapeDOMContainer = document.getElementById("landscape") as HTMLDivElement;

        const layoutConfig = assetConfig.layouts[deviceInfo.layoutId];
        const layoutSettings = layoutConfig.stage;
        const layoutScales = layoutSettings.scales;
        const nativePixelDensity = layoutSettings.nativePixelRatio;

        // Some devices may only have a 1x pixel density, but still possess enough pixels
        // to render higher scale graphics for this layout
        const width1x = layoutSettings.viewport.landscape.width / nativePixelDensity;
        const deviceWidth = Math.max(screen.width * deviceInfo.pixelDensity, screen.height * deviceInfo.pixelDensity);
        const psuedoPixelRatio = Math.round(deviceWidth / width1x * 100) / 100;

        this.layoutScale = nearestUnder(Math.round(psuedoPixelRatio / nativePixelDensity * 100) / 100, layoutScales);

        // Screens lower than the smallest resolution should use the smallest
        if (this.layoutScale === -1) {
            this.layoutScale = layoutScales[0];
        }

        this.resolution = Math.round(this.layoutScale * nativePixelDensity * 100) / 100;
        this.resolutionName = [ResolutionName.LD, ResolutionName.MD, ResolutionName.HD][layoutScales.indexOf(this.layoutScale)];

        // Always use medium res assets on iOS (so we can remove lowest res assets to reduce size of bundles)
        if (deviceInfo.isAppleDevice()) {
            this.resolution = Math.round(layoutScales[1] * nativePixelDensity * 100) / 100;
            this.resolutionName = ResolutionName.MD;
        }

        // Always use highest res assets on desktop
        if (deviceInfo.isDesktop) {
            this.resolution = Math.round(layoutScales[layoutScales.length - 1] * nativePixelDensity * 100) / 100;
            this.resolutionName = ResolutionName.HD;
        }

        this.log("Resolution: " + this.resolutionName + ": @" + this.resolution + "x" + "\n" +
            "scale: " + this.layoutScale + "\n" +
            "natPixRatio: " + nativePixelDensity + "\n" +
            "nativeWidth: " + layoutSettings.viewport.landscape.width + "\n" +
            "nativeHeight: " + layoutSettings.viewport.landscape.height + "\n" +
            "deviceInfo.pixelDensity: " + deviceInfo.pixelDensity + "\n" +
            "screen.height: " + screen.height + "\n" +
            "screen.width: " + screen.width + "\n" +
            "psuedoPixelRatio: " + psuedoPixelRatio
        );

        this.log("Resolution / atlas scale is @" + this.resolution + "x");

/////////////////////////
////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////
//////////////////

        // Give entire document class to determine pixel density (used for CSS psuedo queries)
        this.rootNode.classList.toggle(this.resolutionName, true);

        // Give entire document class to weed out iOS bugs
        if (deviceInfo.isAppleDevice()) {
            this.rootNode.classList.toggle("iOS", true);
        }

        // Give entire document class to tell it it's an iPhone 5,
        // and requires special treatment (to compensate for 44px dead zone at footer)
        if (deviceInfo.isIphone5()) {
            this.rootNode.classList.toggle("iPhone5", true);
        }

        if (deviceInfo.hasiOSTaskBar()) {
            this.rootNode.classList.toggle("iPhoneTaskBar", true);
        }

        if (deviceInfo.isIpad()) {
            this.rootNode.classList.toggle("iPad", true);
        }

        if (deviceInfo.isiOSiFrame()) {
            this.rootNode.classList.add("ios-iframe");
        }

        if (this.cssCanvasEnabled) {
            this.rootRootNode.classList.add("css-canvas");
        }

        this.rootNode.classList.toggle(deviceInfo.layoutId, true);

        this.viewport = DualPosition.fromConfig(layoutSettings.viewport);

        this.stageWidth = this.viewport.landscape.width;
        this.stageHeight = this.viewport.landscape.height;

        // Pixi uses webgl 1.0 instead of 2.0 for all mobiles, when really it should only use 1.0 on bad devices (aka Apple) - iOS 15+ does support webgl 2.0, but there isn't a good way of detecting it
        if (!deviceInfo.isAppleDevice()) {
            settings.PREFER_ENV = ENV.WEBGL2;
        }

        const rendererOptions = {
            transparent: true,
            resolution: this.resolution / nativePixelDensity,
            roundPixels: false,
            antialias: false,
            legacy: deviceInfo.isAndroid(),
            width: this.stageWidth,
            height: this.stageHeight
        };

        try {
            this.renderer = new Renderer(rendererOptions);
        } catch (e) {
            this.renderer = new CanvasRenderer(rendererOptions);
        }

        this.canvas = (this.renderer as any).view;
        this.canvas.id = "canvas";

        this.canvasContainer = document.querySelector("#canvas-container") as HTMLElement;
        const canvasContainer = this.canvasContainer ?? this.rootNode;

        canvasContainer.appendChild(this.canvas);

        if (this.cssCanvasEnabled) {
            const scaledContainer = document.getElementById("scaled-container") as HTMLElement;
            if (scaledContainer) {
                this.scaledContainers.push(scaledContainer);
            }
        }

        for (const node of document.getElementsByClassName("scaled-container")) {
            this.scaledContainers.push(node as HTMLElement);
        }

        this.stage = new Container();
        this.stage.portrait.x = layoutSettings.viewport.portrait.x;
        this.stage.portrait.y = layoutSettings.viewport.portrait.y;

        if (deviceInfo.isIE11()) {
            window.onresize = () => this.resize();
        }

        window.addEventListener("resize", (event) => this.resize(event));
        window.addEventListener("load", (event) => this.resize(event));
        window.addEventListener("orientationchange", (event) => this.resize(event));

        // Not using slotworks interval as these polls needs to happen regardless of whether the game is paused, thanks to iOS not dispatching correctly
        setInterval(() => this.resizePoll(), 0);
        setInterval(() => this.scrollPrompt(), 0);
        if (this.cssCanvasEnabled) {
            setInterval(() => {
                this.scaledContainers.forEach((element) => {
                    if (deviceInfo.isAppleDevice()) {
                        element.style.left = `0`;
                    }
                    const scale = this.getRelativeCanvasScale(true);
                    element.style.width = `${this.stageWidth}px`;
                    element.style.height = `${this.stageHeight}px`;
                    element.style.left = `${this.canvas.offsetLeft}px`;
                    element.style.top = `${this.canvas.offsetTop}px`;
                    element.style.transform = `scale(${scale.x})`;
                    element.style.transformOrigin = `0 0`;
                    element.classList.add("scaled-container");
                });
            }, 100);
        }

        AddWindowFocusChangeListener(() => this.resize());
        this.resize();

        const resolutionString = this.stageWidth + "x" + this.stageHeight + "@" + this.resolution + "x (" + (this.renderer as any).width + "x" + (this.renderer as any).height + "), ";
        this.log("Canvas created at " + resolutionString);

        // Gesture listeners
        this.hammer = new Hammer.Manager(this.canvas);

//////////////////////////
        if (!__DEBUG__) {
            if ((!deviceInfo.isAppleDevice() || (deviceInfo.isIpad() && this.enableIpadFullScreen)) && (!deviceInfo.isDesktop)) {
                this.canvas.addEventListener("touchend", () => this.requestFullScreen());
                document.onclick = () => this.requestFullScreen();
            }
        }
//////////////////

        // Apple hacks / fixes
        if (deviceInfo.isAppleDevice()) {
            this.iosHacks();
        }

        this.setupComplete = true;
    }

    public setLandscapeScale(scale: number, anchor?: Point, offset?: Point) {
        this.landscapeScale = scale;
        if (anchor) {
            this.landscapeAnchor = anchor;
        }
        if (offset) {
            this.landScapeOffset = offset;
        }
        if (this.setupComplete) { this.resize(); }
    }

    // Sets whether or not canvas should change size and use portrait positions if screen height > width
    public setPortraitCanvasEnabled(enabled: boolean) {
        this.portraitCanvasEnabled = enabled;
    }

    // Sets whether or not to use the DOM portrait container & root css class
    public setPortraitEnabled(enabled: boolean) {
        this.portraitEnabled = enabled;
    }

    public setPortraitScale(scale: number, anchor?: Point, offset?: Point) {
        this.portraitScale = scale;
        if (anchor) {
            this.portraitAnchor = anchor;
        }
        if (offset) {
            this.portraitOffset = offset;
        }
        if (this.setupComplete) { this.resize(); }
    }

    public gameLoaded() {
        this.isGameLoaded = true;
        this.resize();
    }

    public useFixedPositionBackground(landscapeOffset: Point, portraitOffset: Point) {
        this.landscapeBackgroundOffset = landscapeOffset;
        this.portraitBackgroundOffset = portraitOffset;
        this.fixedPositionBackground = true;

        Layers.get("Background").onSceneEnter.add((scene: string) => {
            this.resize();
        });
    }

    public setFullScreenPaused(pause: boolean) {
        this.fullScreenPaused = pause;

        if (this.isFullScreen() && pause) {
            this.resumeFullScreen = true;
            this.exitFullScreen();
        }

        if (this.resumeFullScreen && !pause) {
            this.resumeFullScreen = false;
            this.requestFullScreen();
        }
    }

    public setINoBounceEnabled(enabled: boolean) {
        this.iosEnableINoBounce = enabled;
        if (enabled) {
            iNoBounce.enable();
        } else {
            iNoBounce.disable();
        }
    }

    public getSafeArea(): DualPosition {
        return this.safeArea;
    }

    public getRelativeCanvasScale(maintainAspect = true): Point {
        let scaleX = window.innerWidth / this.stageWidth;
        let scaleY = window.innerHeight / this.stageHeight;

        if (this.canvas.offsetWidth === this.stageWidth) {
            scaleX = 1;
            scaleY = 1;
        }

        if (maintainAspect) {
            const scale = Math.min(scaleX, scaleY);
            scaleX = scale;
            scaleY = scale;
        }

        return new Point(scaleX, scaleY);
    }

    protected resizePoll() {
        if (deviceInfo.getWindowWidth() === this.previousScreenWidth &&
            deviceInfo.getWindowHeight() === this.previousScreenHeight &&
            deviceInfo.getWindow().innerWidth === this.previousWindowWidth &&
            deviceInfo.getWindow().innerHeight === this.previousWindowHeight) {
            return;
        }
        this.previousScreenWidth = deviceInfo.getWindowWidth();
        this.previousScreenHeight = deviceInfo.getWindowHeight();

        this.previousWindowWidth = deviceInfo.getWindow().innerWidth;
        this.previousWindowHeight = deviceInfo.getWindow().innerHeight;

        this.resize();
    }

    protected resize(event?: any) {
        let canvasDOMContainer: HTMLElement;
        let zoomLevel: number;
        let anchor: Point;
        let backgroundOffset: Point;

        const previousOrientation = this.orientation;

        // Set orientation
        if (deviceInfo.getOrientation() === Orientation.LANDSCAPE || !this.portraitEnabled) {
            this.rootNode.classList.toggle("portrait", false);
            this.rootNode.classList.toggle("landscape", true);

            if (this.portraitDOMContainer) {
                this.portraitDOMContainer.style.display = "none";
            }
            if (this.landscapeDOMContainer) {
                this.landscapeDOMContainer.style.display = "block";
            }

            canvasDOMContainer = document.querySelector("#landscape #canvasDOMContainer") as HTMLElement;
            zoomLevel = this.landscapeScale;
            anchor = this.landscapeAnchor;
            backgroundOffset = this.landscapeBackgroundOffset;
            this.updateOrientation(Orientation.LANDSCAPE);
        } else {
            this.rootNode.classList.toggle("portrait", true);
            this.rootNode.classList.toggle("landscape", false);

            if (this.portraitDOMContainer) {
                this.portraitDOMContainer.style.display = "block";
            }
            if (this.landscapeDOMContainer) {
                this.landscapeDOMContainer.style.display = "none";
            }

            canvasDOMContainer = document.querySelector("#portrait #canvasDOMContainer") as HTMLElement;
            zoomLevel = this.portraitScale;
            anchor = this.portraitAnchor;
            backgroundOffset = this.portraitBackgroundOffset;
            this.updateOrientation(Orientation.PORTRAIT);
        }

        if (!canvasDOMContainer) {
            canvasDOMContainer = document.querySelector("#canvasDOMContainer") as HTMLElement;
        }

        if (this.portraitCanvasEnabled) {
            let viewport = this.viewport.landscape;

            if (this.orientation === Orientation.PORTRAIT) {
                viewport = this.viewport.portrait;
            }

            this.stageWidth = viewport.width;
            this.stageHeight = viewport.height;

            this.renderer.resize(this.stageWidth, this.stageHeight);

            if (this.orientation !== previousOrientation) {
                this.onOrientationChange.dispatch(this.orientation);
            }
        } else {
            this.updateOrientation(Orientation.LANDSCAPE);
        }

        const offset = this.orientation === Orientation.PORTRAIT ? this.portraitOffset : this.landScapeOffset;

        // Resize canvas including zoom
        if (!this.cssCanvasEnabled) {
            this.resizeElement(this.canvas, zoomLevel, anchor, offset, this.FIT_CANVAS_TO_SCREEN);

            this.extraCanvasContainers.forEach((element) => {
                this.resizeElement(element, zoomLevel, anchor, offset, true);
            });

            this.scaledContainers.forEach((element) => {
                const scale = this.getCanvasScale(true);
                element.style.width = `${this.stageWidth}px`;
                element.style.height = `${this.stageHeight}px`;
                element.style.transform = `scale(${scale.x})`;
                element.classList.add("scaled-container");
            });
        } else if (this.canvasContainer && !deviceInfo.isDesktop && this.cssCanvasEnabled) {
            if (deviceInfo.isAppleDevice()) {
                this.canvasContainer.style.height = `0px`;
                this.canvas.style.maxHeight = "0";
            }
            setTimeout(() => {
                this.canvasContainer.style.height = `${window.innerHeight}px`;
                this.canvas.style.maxHeight = "100%";
            }, 0);
        }

        if (canvasDOMContainer) {
            this.resizeElement(canvasDOMContainer, zoomLevel, anchor, offset, true);
        }

        if (this.fixedPositionBackground) {
            const backgrounds = Layers.get("Background").getDOMContainers();
            backgrounds.forEach((background: HTMLElement) => {
                background.style.backgroundRepeat = "no-repeat";
                background.style.backgroundPosition = "center center";
                background.style.transformOrigin = "left top";

                backgroundOffset = new Point(backgroundOffset.x + offset.x, backgroundOffset.y + offset.x);
                this.resizeBackground(background, anchor, backgroundOffset);
            });
        }

        if (this.cssCanvasEnabled) {
            const scale = this.orientation === Orientation.LANDSCAPE ? this.landscapeScale : this.portraitScale;
            const anchor = this.orientation === Orientation.LANDSCAPE ? this.landscapeAnchor : this.portraitAnchor;
            const offset = this.orientation === Orientation.LANDSCAPE ? this.landScapeOffset : this.portraitOffset;
            this.canvas.style.transform = `scale(${scale}) translateX(${offset.x * window.innerWidth}px) translateY(${offset.y * window.innerHeight}px)`;
            this.canvas.style.transformOrigin = `${anchor.x * 100}% ${anchor.y * 100}%`;
        }

        this.domContainers(canvasDOMContainer);

        this.rotationPrompt();

        this.setSafeArea();

        this.onResize.dispatch();
    }

    protected domContainers(canvasDOMContainer: HTMLElement) {
        // Header DOM container
        if (canvasDOMContainer && this.headerDOMContainer) {
            this.headerDOMContainer.style.width = deviceInfo.getWindowWidth() + "px";
            this.headerDOMContainer.style.height = parseFloat(canvasDOMContainer.style.marginTop) + "px";
            canvasDOMContainer.style.marginTop = this.headerDOMContainer.style.height;
            if (this.footerDOMContainer) {
                this.footerDOMContainer.style.marginTop = parseFloat(canvasDOMContainer.style.height) + "px";
            }
        }

        // Footer DOM container
        if (canvasDOMContainer && this.footerDOMContainer) {
            this.footerDOMContainer.style.width = deviceInfo.getWindowWidth() + "px";
            this.footerDOMContainer.style.height =
                (deviceInfo.getWindowHeight() - parseFloat(canvasDOMContainer.style.height) - parseFloat(canvasDOMContainer.style.marginTop)) + "px";
            this.footerDOMContainer.style.marginTop = (deviceInfo.getWindowHeight() - parseFloat(this.footerDOMContainer.style.height)) + "px";
        }
    }

    protected getCanvasScale(maintainAspect = true, zoomLevel = 1): Point {
        const elementWidth = this.stageWidth;
        const elementHeight = this.stageHeight;

        const viewPortWidth = deviceInfo.getWindowWidth() * zoomLevel;
        const viewPortHeight = deviceInfo.getWindowHeight() * zoomLevel;

        let scaleX = viewPortWidth / elementWidth;
        let scaleY = viewPortHeight / elementHeight;

        if (maintainAspect) {
            const scale = Math.min(scaleX, scaleY);
            scaleX = scale;
            scaleY = scale;
        }

        return new Point(scaleX, scaleY);
    }

    /**
     * Resizes an element to fit on screen
     *
     * @param element The element to resize
     * @param zoomLevel A custom zoom level to take into account, for manually overflowing the game; i.e. for portrait if the edges need to be cut off
     * @param anchor Where should the game centered when resized (top, middle, bottom etc)
     * @param limitToScreen Once scaled, if there is overflow, should it be cropped or included in the element width and height.
     *                      e.g, canvas and background should, full screen elements such as UI (canvasDOMContainer etc) should not
     * @param maintainAspect
     */
    protected resizeElement(element: HTMLElement, zoomLevel = 1, anchor: Point, offset: Point, limitToScreen = false, maintainAspect = true) {
        const scale = this.cssCanvasEnabled ? this.getRelativeCanvasScale(maintainAspect) : this.getCanvasScale(maintainAspect, zoomLevel);

        let scaledWidth = this.stageWidth * scale.x;
        let scaledHeight = this.stageHeight * scale.y;

        if (limitToScreen) {
            scaledWidth = Math.min(scaledWidth, deviceInfo.getWindowWidth());
            scaledHeight = Math.min(scaledHeight, deviceInfo.getWindowHeight());
        }

        element.style.width = scaledWidth + "px";
        element.style.height = scaledHeight + "px";

        offset = new Point(offset.x * scaledWidth, offset.y * scaledHeight);

        const marginTop = ((deviceInfo.getWindowHeight() - scaledHeight) * anchor.y) + offset.y;
        const marginRight = ((deviceInfo.getWindowWidth() - scaledWidth) * (1 - anchor.x) - offset.x);
        const marginBottom = ((deviceInfo.getWindowHeight() - scaledHeight) * (1 - anchor.y) - offset.y);
        const marginLeft = ((deviceInfo.getWindowWidth() - scaledWidth) * anchor.x) + offset.x;
        const padding = "0";
        const margin = marginTop + "px " + marginRight + "px " + marginBottom + "px " + marginLeft + "px";
        // const fontScale = Math.min(scaledWidth / this.viewport.landscape.width, scaledHeight / this.viewport.landscape.height) * 100;

        element.style.padding = padding;
        element.style.margin = margin;
        element.style.display = "block";
        if (deviceInfo.isiOSiFrame()) {
            element.style.display = "flex";
            element.style.position = "fixed";
            element.style.margin = "0";
        }

        // todo: move this so that it is only applied to canvasdomcontainer
        // Removing because it interferes with any other elements we want to resize.
        // element.style.fontSize = fontScale.toString() + "px";
    }

    protected resizeBackground(background: HTMLElement, anchor: Point, offset: Point): void {
        const viewportWidth = deviceInfo.getWindowWidth();
        const viewportHeight = deviceInfo.getWindowHeight();

        const scale = Math.min(
            viewportWidth / this.stageWidth,
            viewportHeight / this.stageHeight
        );
        background.style.transform = `scale(${scale}, ${scale})`;

        const scaledBounds = background.getBoundingClientRect();
        const top = ((viewportHeight - scaledBounds.height) * anchor.y + offset.y * scale);
        const left = ((viewportWidth - scaledBounds.width) * anchor.x + offset.x * scale);

        background.style.marginTop = `${top}px`;
        background.style.marginLeft = `${left}px`;
    }

    protected setSafeArea(): void {
        const getPosition = (scale: number) => {
            const stageAspect: number = Math.round((this.stageWidth / this.stageHeight) * 100) / 100;
            const deviceAspect: number = Math.min(Math.round(deviceInfo.getScreenAspectRatio() * 100) / 100, Math.round(deviceInfo.getWindowAspectRatio() * 100) / 100);
            const targetAspect: number = Math.round(scale * 100) / 100;

            const scaledWidth: number = this.stageWidth / targetAspect;
            const scaledHeight: number = this.stageHeight / targetAspect;

            const position: Position = new Position(
                (this.stageWidth - scaledWidth) / 2,
                (this.stageHeight - scaledHeight) / 2,
                scaledWidth,
                scaledHeight
            );

            if (deviceAspect > stageAspect) {
                position.width = Math.min((scaledWidth / stageAspect) * deviceAspect, this.stageWidth);
                position.x = (this.stageWidth - position.width) / 2;
            } else if (stageAspect > deviceAspect) {
                position.height = Math.min((scaledHeight / deviceAspect) * stageAspect, this.stageHeight);
                position.y = (this.stageHeight - position.height) / 2;
            }

            return position;
        };

        this.safeArea = new DualPosition(getPosition(this.landscapeScale), getPosition(this.portraitScale));
    }

    protected requestFullScreen() {
        if (this.fullScreenEnabled && !this.fullScreenPaused && !deviceInfo.isDesktop && !deviceInfo.isIpad()) {
            let element: any;
            try {
                element = window.top.document.body;
            } catch (e) {
                element = window.document.body;
            }

            // Supports most browsers and their versions.
            const requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;

            if (requestMethod) {
                // Native full screen.
                try {
                    requestMethod.call(element);
                } catch (e) {
                    // Allow non fatal error
                }
            }
        }
    }

    protected exitFullScreen() {
        if (!deviceInfo.isDesktop) {
            if (this.isFullScreen()) {

                const doc: any = window.document;
                let promise;

                if (doc.exitFullscreen) {
                    promise = doc.exitFullscreen();
                } else if (doc.msExitFullscreen) {
                    promise = doc.msExitFullscreen();
                } else if (doc.mozCancelFullScreen) {
                    promise = doc.mozCancelFullScreen();
                } else if (doc.webkitExitFullscreen) {
                    promise = doc.webkitExitFullscreen();
                }

                if (promise && promise.catch) {
                    promise.catch(() => 0);
                }
            }
        }
    }

    // TODO: Reduce cyclomatic complexity
    // tslint:disable-next-line:cyclomatic-complexity
    protected scrollPrompt() {
        if (this.cssCanvasEnabled) {
            this.newScrollPrompt();
            return;
        }

        if ((deviceInfo.isAppleDevice() && !deviceInfo.isIpad() && !deviceInfo.isiOSChrome() && !deviceInfo.isiOSiFrame()) && this.isGameLoaded) {

            const iOSChromeVisible = deviceInfo.iOSChromeVisible();

            this.rootNode.classList.toggle("iosChromeVisible", iOSChromeVisible);

            if (this.iosScrollPromptEnabled) {

                const scrollPromptDiv = document.getElementById("iosScroll");

                // Scroll to the top of the page if the user isn't scrolling the screen
                if (!this.iosIsScrolling) {
                    if (window && window.scrollTo) {
                        window.scrollTo(0, 0);
                    }

                    let gameFitsOnScreen = false;
                    if (this.orientation === Orientation.PORTRAIT) {
                        // TODO: Be smarter about this. Right now we arbitrarily device if the game is close enough to being as big as it will get,
                        // but ideally we need to reverse engineer Safari's criteria on whether it should add the chrome back
                        const heightFits = Math.abs(parseFloat(this.canvas.style.height) - deviceInfo.getWindowHeight()) < devicePixelRatio;
                        const widthFits = Math.abs(parseFloat(this.canvas.style.width) - deviceInfo.getWindowWidth()) < devicePixelRatio * 10;
                        if (heightFits && widthFits) {
                            gameFitsOnScreen = true;
                        }

                        if (this.iosScrollOnlyLandscape) {
                            gameFitsOnScreen = true;
                        }
                    }

                    const showPrompt = iOSChromeVisible && (this.iosScrollOnlyIfCanvasIsTooBig && !gameFitsOnScreen);

                    if (!showPrompt) {
                        if (deviceInfo.isIphone5()) {
                            document.documentElement.style.height = "101%";
                        }
                        if (scrollPromptDiv) {
                            scrollPromptDiv.classList.toggle("hidden", true);
                        }
                        this.rootNode.classList.toggle("noOverflow", true);
                        this.rootNode.classList.toggle("iosTreadmill", false);
                        if (this.iosEnableINoBounce) {
                            iNoBounce.enable();
                        }
                        if (gameLoop.getPaused("iosScroll")) {
                            gameLoop.setPaused("iosScroll", false);
                        }
                    } else {

                        if (scrollPromptDiv) {
                            scrollPromptDiv.classList.toggle("hidden", false);
                        }
                        this.rootNode.classList.toggle("noOverflow", false);
                        this.rootNode.classList.toggle("iosTreadmill", true);
                        if (this.iosEnableINoBounce) {
                            iNoBounce.disable();
                        }
                        if (!gameLoop.getPaused("iosScroll")) {
                            gameLoop.setPaused("iosScroll", true);
                        }
                    }
                }

                return;
            }
        }

        if (window && window.scrollTo) {
            window.scrollTo(0, 0);
        }
    }

    // tslint:disable-next-line:cyclomatic-complexity
    protected newScrollPrompt() {
        const waitForGameLoad = (this.iosScrollWaitForGameLoad && this.isGameLoaded) || !this.iosScrollWaitForGameLoad;
        if ((deviceInfo.isAppleDevice() && !deviceInfo.isIpad() && !deviceInfo.isiOSChrome() && !deviceInfo.isiOSiFrame() && !deviceInfo.isIphone6()) && waitForGameLoad) {

            const iOSChromeVisible = deviceInfo.iOSChromeVisible();

            this.rootNode.classList.toggle("iosChromeVisible", iOSChromeVisible);

            if (this.iosScrollPromptEnabled) {

                const scrollPromptDiv = document.getElementById("iosScroll");

                // Scroll to the top of the page if the user isn't scrolling the screen
                if (!this.iosIsScrolling) {
                    if (window && window.scrollTo) {
                        window.scrollTo(0, 0);
                    }

                    const addressBarShowing = deviceInfo.iOSChromeVisible();
                    const hasBlackBarsAtSides = this.canvas.clientWidth < this.rootNode.clientWidth;
                    const isLandscape = deviceInfo.getOrientation() === Orientation.LANDSCAPE;
                    const showPrompt = addressBarShowing && isLandscape && hasBlackBarsAtSides;

                    if (!showPrompt) {
                        if (deviceInfo.isIphone5()) {
                            document.documentElement.style.height = "101%";
                        }
                        if (scrollPromptDiv) {
                            scrollPromptDiv.classList.toggle("hidden", true);
                        }
                        if (!this.cssCanvasEnabled) {
                            this.rootNode.classList.toggle("noOverflow", true);
                        }
                        this.rootNode.classList.toggle("iosTreadmill", false);
                        if (this.iosEnableINoBounce) {
                            iNoBounce.enable();
                        }
                        if (gameLoop.getPaused("iosScroll")) {
                            gameLoop.setPaused("iosScroll", false);
                        }
                    } else {

                        if (scrollPromptDiv) {
                            scrollPromptDiv.classList.toggle("hidden", false);
                        }
                        if (!this.cssCanvasEnabled) {
                            this.rootNode.classList.toggle("noOverflow", false);
                        }
                        this.rootNode.classList.toggle("iosTreadmill", true);
                        this.rootRootNode.classList.toggle("iosTreadmill", true);
                        if (this.iosEnableINoBounce) {
                            iNoBounce.disable();
                        }
                        if (!gameLoop.getPaused("iosScroll")) {
                            gameLoop.setPaused("iosScroll", true);
                        }
                    }
                }

                return;
            }
        }

        if (window && window.scrollTo) {
            window.scrollTo(0, 0);
        }
    }

    protected rotationPrompt() {
        const desktopShowPrompt = !deviceInfo.isDesktop || (this.rotationPromptOnDesktop && deviceInfo.isDesktop);

        if (this.isGameLoaded || this.allowPreloaderRotationPrompt) {
            if (this.validOrientations.length > 0 && desktopShowPrompt) {
                const showPrompt: boolean = this.validOrientations.indexOf(deviceInfo.getOrientation()) === -1;
                gameLoop.setPaused("rotation", showPrompt);

                this.rootNode.classList.toggle("rotationPrompt", showPrompt);

                const prompt = document.getElementById("rotationBlock");

                if (prompt && showPrompt && !this.cssCanvasEnabled) {
                    prompt.style.position = "absolute";
                    prompt.style.top = "0";
                    prompt.style.left = "0";
                    prompt.style.width = `${deviceInfo.getWindowWidth()}px`;
                    prompt.style.height = `${deviceInfo.getWindowHeight()}px`;
                }

                if (this.cssCanvasEnabled) {
                    if (prompt && showPrompt && !deviceInfo.isDesktop) {
                        prompt.style.display = "flex";
                    } else if (prompt) {
                        prompt.style.display = "none";
                    }
                }
            }
        }
    }

    protected isFullScreen() {
        const doc: any = document;

        return doc.fullscreenElement !== null || doc.mozFullScreenElement !== null || doc.webkitFullscreenElement !== null || doc.msFullscreenElement !== null;
    }

    protected updateOrientation(orientation: Orientation) {
        this.orientation = orientation;
        RenderOrientation.orientation = orientation;
    }

    // Wacky fixes to work around bugs and deliberate crippling from Apple
    protected iosHacks() {
        // Weird apple screen size bug detailed in GAM-243
        document.body.parentElement.style.width = "1000%";
        setTimeout(() => {
            document.body.parentElement.style.width = "";
        }, 0);

        // iOS hack to force honoring of user-scale
        document.addEventListener("touchmove", (moveEvent: any) => {
            this.iosIsScrolling = true;
            if (!this.iosEnableZoom && moveEvent.scale !== 1) {
                moveEvent.preventDefault();
            }
        }, false);
        // iOS hack to prevent double tap zooming
        let lastTouchEnd = 0;
        document.addEventListener("touchend", (touchEvent: any) => {
            setTimeout(() => {
                this.iosIsScrolling = false;
            }, 500);
            const now = (new Date()).getTime();
            if (now - lastTouchEnd <= 500) {
                touchEvent.preventDefault();
            }
            lastTouchEnd = now;
        }, false);
        document.addEventListener("resize", () => {
            this.iosIsScrolling = false;
        });
    }

    /**
     * Log a message with colored label
     *
     * @param message {string}
     */
    protected log(message: string) {
        Logger.info("%c Canvas Manager ", "background: #f96; color: #fff", message);
    }
}
