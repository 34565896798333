import { CenterPivot } from "appworks/graphics/pixi/group";
import { Position } from "appworks/graphics/pixi/position";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { asArray } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Easing, Tween } from "appworks/utils/tween";
import { TweenContract } from "../contracts/tween-contract";

const defaultDuration: number = 500;
const defaultEasing: (k: number) => number = Easing.Linear.None;

export interface Scalable {
    landscape: Position;
    portrait: Position;
}

export function scaleIn<T extends Scalable>(targetOrTargets: T | T[], duration = defaultDuration, easing = defaultEasing): Contract<void> {
    return scale(targetOrTargets, { x: 1, y: 1 }, duration, easing);
}

export function scaleOut<T extends Scalable>(targetOrTargets: T | T[], duration = defaultDuration, easing = defaultEasing): Contract<void> {
    return scale(targetOrTargets, { x: 0, y: 0 }, duration, easing);
}

export function scale<T extends Scalable>(targetOrTargets: T | T[], toScale: { x: number, y: number }, duration = defaultDuration, easing = defaultEasing): Contract<void> {
    const targets = asArray(targetOrTargets);

    return new Parallel(targets.map((target) => {
        if (target instanceof Sprite) {
            CenterPivot(target);
        }

        const landscapeScaleTween = new Tween(target.landscape.scale).to(toScale, duration).easing(easing);
        const portraitScaleTween = new Tween(target.portrait.scale).to(toScale, duration).easing(easing);

        landscapeScaleTween.onComplete(() => target.landscape.setScale(toScale));
        portraitScaleTween.onComplete(() => target.portrait.setScale(toScale));

        return () => new Parallel([
            () => TweenContract.wrapCancellableTween(landscapeScaleTween),
            () => TweenContract.wrapCancellableTween(portraitScaleTween)
        ]);
    }));
}

export function pulse<T extends Scalable>(targetOrTargets: T | T[], toScale = { x: 1.1, y: 1.1 }, duration = defaultDuration, easing = defaultEasing) {
    const targets = asArray(targetOrTargets);

    return new Parallel(targets.map((target) => {
        if (target instanceof Sprite) { CenterPivot(target); }

        const landscapeScale = {
            x: target.landscape.scale.x * toScale.x,
            y: target.landscape.scale.y * toScale.y
        };
        const portraitScale = {
            x: target.portrait.scale.x * toScale.x,
            y: target.portrait.scale.y * toScale.y
        };

        const landscapeScaleTween = new Tween(target.landscape.scale).to(landscapeScale, duration).easing(easing).repeat(1).yoyo(true);
        const portraitScaleTween = new Tween(target.portrait.scale).to(portraitScale, duration).easing(easing).repeat(1).yoyo(true);

        return () => new Parallel([
            () => TweenContract.wrapCancellableTween(landscapeScaleTween),
            () => TweenContract.wrapCancellableTween(portraitScaleTween)
        ]);
    }));
}