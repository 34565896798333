import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { Tween } from "appworks/utils/tween";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";
import { BubbleSpineState, PopBubbleSpineComponent } from "./pop-bubble-spine-component";

export class PopReelMatrixComponent extends MatrixComponent {
    protected bubbles: PopBubbleSpineComponent[] = [];

    public init(): void {
        super.init();

        this.matrixLayer.enableBoundsMask(false);

        const bubblePos = this.matrixLayer.getPosition("bubble");
        const symbol00Pos = this.matrixLayer.getPosition("symbol_0_0");
        for (let x = 0; x < 5; x++) {
            const bubble = new PopBubbleSpineComponent(this.matrixLayer, bubblePos);
            this.bubbles[x] = bubble;

            const symbolPosition = this.matrixLayer.getPosition(`symbol_${x}_0`);
            const offset = symbolPosition.subtractPosition(symbol00Pos);
            bubble.setPosition(bubble.getPosition().addPosition(offset));

            bubble.setState(BubbleSpineState.IDLE).execute();
        }
    }

    public getBubble(x: number) { return this.bubbles[x]; }
    public getBubbles() { return this.bubbles; }

    public popSymbol(x: number): Contract {
        const symbol = this.getSymbol(x, 0);

        if (symbol.symbolId.includes("numbers/")) {
            return new Parallel([
                () => this.bubbles[x].setState(BubbleSpineState.POP),
                () => this.getSymbolFadeTweenContract(this.getSymbol(x, 0), 0, 300, 300)
            ]);
        } else {
            return symbol.remove();
        }
    }

    public getSymbolFadeTweenContract(symbol: SymbolSubcomponent, targetAlpha: number, time: number = 500, delay: number = 0): Contract {
        const dataObj = { alpha: symbol.getAlpha() };
        const tween = new Tween(dataObj)
            .delay(delay)
            .to({ alpha: targetAlpha }, time)
            .onUpdate(() => symbol.setAlpha(dataObj.alpha));

        return TweenContract.wrapTween(tween);
    }

    protected update(): void {
        super.update();
        this.updateBubbles();
    }

    protected updateBubbles() {
        const bubblePos = this.matrixLayer.getPosition("bubble");
        const symbol00Pos = this.matrixLayer.getPosition("symbol_0_0");
        const offset = bubblePos.subtractPosition(symbol00Pos);

        this.bubbles.forEach((bubble: PopBubbleSpineComponent, x: number) => {
            const symbol = this.getSymbol(x, 0);
            const symbolTransform = symbol.getTransform();

            if (symbol.symbolId.includes("number")) {
                bubble.setAlpha(symbol.getAlpha());

                const pos = bubble.getPosition();
                pos.landscape.y = symbolTransform.landscape.y + offset.landscape.y;
                pos.portrait.y = symbolTransform.portrait.y + offset.portrait.y;
                bubble.setPosition(pos);
            } else {
                bubble.setAlpha(0);
            }
        });
    }
}
