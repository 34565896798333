import { gameState } from "appworks/model/game-state";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";
import { SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";

export class SlingoTicketCompletedDecision extends Decision {
    public evaluate(): Exit {
        const gameplay = gameState.getCurrentGame();
        const family = gameplay.getFamily(gameplay.getRootRecord(), gameplay.getCurrentRecord());

        let latestSlingoRecord: SlingoRecord;
        family.forEach(record => {
            if (record instanceof SlingoRecord) {
                latestSlingoRecord = record;
            }
        });

        return latestSlingoRecord.state === SlingoState.COMPLETE ? Exit.True : Exit.False;
    }
}
