import { Gameplay } from "appworks/model/gameplay/gameplay";
import { Signal } from "signals";
import { BonusResult } from "slotworks/model/gameplay/records/results/bonus-result";
import { FreespinWinResult } from "slotworks/model/gameplay/records/results/freespin-win-result";
import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";

class GameState {

    public onNewGame = new Signal();
    public autoDeductStake: boolean = true;

    private gameplay: Gameplay;

    /**
     * The wrapper can force new balance, typically as a result of the user depositing funds. This will be used as the
     * starting balance for the user's next spin.
     */
    private wrapperOverrideBalance: number = null;

    // TODO: V6 - this should throw if lastChild / hasChildren has been set up incorrectly and a game isn't actually "complete"
    public newGame(stake: number = 0) {
        const balance = this.getBalance();

        this.gameplay = new Gameplay();
        this.gameplay.startBalance = this.gameplay.balance = balance;

        if (this.autoDeductStake && stake && balance >= stake) {
            this.gameplay.startBalance -= stake;
            if (this.gameplay.startBalance < 0) {
                this.gameplay.startBalance = 0;
            }
        }

        this.onNewGame.dispatch();
    }

    public setGameplay(gameplay: Gameplay) {
        this.gameplay = gameplay;
    }

    public setWrapperOverrideBalance(newBalance: number) {
        this.wrapperOverrideBalance = newBalance;
    }

    public getCurrentGame() {
        return this.gameplay || null;
    }

    public currentRecordHasSymbolWinResults() {
        return this.gameplay && this.gameplay.getCurrentRecord() && this.gameplay.getCurrentRecord().getResultsOfType(SymbolWinResult).length > 0;
    }

    public currentRecordHasFreespinWinResults() {
        return this.gameplay && this.gameplay.getCurrentRecord() && this.gameplay.getCurrentRecord().getResultsOfType(FreespinWinResult).length > 0;
    }

    public currentRecordHasBonusWinResults() {
        return this.gameplay && this.gameplay.getCurrentRecord() && this.gameplay.getCurrentRecord().getResultsOfType(BonusResult).length > 0;
    }

    private getBalance() {

        if (this.wrapperOverrideBalance) {
            const balance = this.wrapperOverrideBalance;
            this.wrapperOverrideBalance = null;
            return balance;
        }

        if (this.gameplay) {
            return this.gameplay.balance;
        }

        return null;
    }
}

export const gameState = new GameState();
