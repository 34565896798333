import { parseJSONMap } from "appworks/utils/json-utils";
import { slotModel } from "./slot-model";

export interface SymbolConfig {
    /** Symbol ID (should match static symbol asset name) */
    id: string;
    /** A verbose name used for debugging */
    name?: string;
    /** A list of aliases which this symbol can also be known by */
    aliases?: string[];
    wild?: boolean;
    /** Whether to include wild symbols towards a bonus match count */
    includeWilds?: boolean;
    scatter?: boolean;
    ways?: boolean;
    /** bonusIds above 0 denote a bonus symbol, different values can be used to identify different types. */
    bonusId?: number;
    /** Z-sort position of this symbol */
    zIndex?: number;
    /** Number of symbols that must be hit before anticipation starts */
    anticipation?: number;
    /** Minimum matches to payout (used for bonus land sounds mostly) */
    matchesMin?: number;
    /** Maximum matches possible (used to cancel anticipation when max has been hit) */
    matchesMax?: number;
    /**
     * Maximum bonus symbol matches per reel
     * Used for calculating anticipation etc on games where you can get more than 1 bonus symbol per reel
     * @default 1
     */
    possibleMatchesPerReel?: number;
    /** Reels this symbol appears on. Used to determine whether to bother anticipating or making land sounds */
    reels?: number[];
    /** If set, contains a list of rows which this symbol will pay on */
    validRows?: number[];
    /** The width of a wide symbol which takes up more than 1 reel */
    width?: number;
    /** The height of a stacked symbol which takes up more than 1 row */
    height?: number;
    /** Upon landing this symbol should automatically stick */
    autoStick?: boolean;
    /** @todo replace any type with SymbolsPayouts */
    payouts?: any;
    /**
     * For mixed payout wins, where any of the following symbols count towards the mixed payout
     * Must include 1 or more of these to count as a win
     */
    mixed?: string[];
    /** When a symbol multiplies the total win it's part of (i.e 2x wild) */
    multiplier?: number;
    /** Some transitions might not want to waste time animating a blank symbol for example */
    isBlank?: boolean;
    /** If true, slotworks will generate a blurred texture for this symbol on startup and add it to the texture cache */
    blur?: boolean;
    /** If blur is true, how much should the symbols blur by? */
    blurStrength?: number;
}

export type SymbolsPayouts = Record<string | number, SymbolProfiles>;
export type SymbolProfiles = Record<PayoutProfileId, SymbolPayouts>;
export type SymbolPayouts = Record<number, SymbolPayout>;
export type SymbolPayout = number | RespinsPayout;
export type RespinsPayout = `${number}respin`;
export type PayoutProfileId = "default" | `rtp_${number}`;

export class SymbolDefinition implements SymbolConfig {
    public index: number;
    public id: string;
    public name: string;
    public aliases: string[];
    public wild: boolean;
    public includeWilds: boolean;
    public scatter: boolean;
    public ways: boolean;
    public bonusId: number;
    public zIndex: number;
    public anticipation: number;
    public matchesMin: number;
    public matchesMax: number;
    public possibleMatchesPerReel: number;
    public reels: number[];
    public validRows: number[];
    public height: number;
    public width: number;
    public autoStick: boolean;
    public payouts: Map<string, number>;
    public multiplier: number;
    public isBlank: boolean;
    public mixed: string[];
    public blur: boolean;
    public blurStrength: number;

    /** @todo replace any type with SymbolsPayouts */
    protected payoutConfig: any;

    constructor(symbolConfig: SymbolConfig, index: number) {
        this.index = index;
        this.id = symbolConfig.id;
        this.name = symbolConfig.name || symbolConfig.id;
        this.aliases = symbolConfig.aliases || [];
        this.wild = symbolConfig.wild;
        this.scatter = symbolConfig.scatter;
        this.ways = symbolConfig.ways;
        this.includeWilds = symbolConfig.includeWilds;
        this.bonusId = symbolConfig.bonusId || 0;
        this.zIndex = symbolConfig.zIndex;
        this.anticipation = symbolConfig.anticipation;
        this.matchesMin = symbolConfig.matchesMin;
        this.matchesMax = symbolConfig.matchesMax;
        this.possibleMatchesPerReel = symbolConfig.possibleMatchesPerReel || 1;
        this.reels = symbolConfig.reels;
        this.validRows = symbolConfig.validRows;
        this.height = symbolConfig.height || 1;
        this.width = symbolConfig.width || 1;
        this.autoStick = symbolConfig.autoStick;
        this.mixed = symbolConfig.mixed;
        this.multiplier = symbolConfig.multiplier;
        this.isBlank = symbolConfig.isBlank;
        this.blur = symbolConfig.blur ?? false;
        this.blurStrength = symbolConfig.blurStrength ?? 200;

        this.payoutConfig = symbolConfig.payouts;

        this.updatePayouts();

        const slotModelUpdateSignal = slotModel.onUpdate.add(() => {
            this.updatePayouts();

            if (slotModel.read().regulations.rtp) {
                slotModelUpdateSignal.detach();
            }
        });
    }

    public isBonusSymbol() {
        return this.bonusId > 0;
    }

    public updatePayouts() {
        if (this.payoutConfig) {
            const rtp = slotModel.read().regulations.rtp;

            let payouts = this.payoutConfig[`rtp_${rtp}`];

            if (!payouts) { payouts = this.payoutConfig["default"]; }
            if (!payouts) { payouts = this.payoutConfig; }

            this.payouts = parseJSONMap<number>(payouts);
        }
    }
}
