import { Layers } from "appworks/graphics/layers/layers";
import { Container } from "appworks/graphics/pixi/container";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Result } from "appworks/model/gameplay/records/results/result";
import { Contract } from "appworks/utils/contracts/contract";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";

export class AbstractSymbolBehaviour {
    public behaviourName: string;
    public validSymbols: string[];
    public invalidSymbols: string[];
    public behaviourSprite: Sprite | Container;

    protected symbol: SymbolSubcomponent;
    protected matrixLayer: Layers;
    protected animationLayer: Layers;

    constructor(symbol: SymbolSubcomponent) {
        this.symbol = symbol;

        this.matrixLayer = this.symbol.getMatrixLayer();
        this.animationLayer = this.symbol.getAnimationLayer();
    }

    public static(): void {
        if (this.behaviourSprite) {
            this.behaviourSprite.visible = false;
        }
    }

    public idle(): void {
        // Idle symbol behaviour
    }

    public land(): Contract<void> {
        return Contract.empty();
    }

    public anticipateLand(): Contract<void> {
        return Contract.empty();
    }

    public win(result?: Result): Contract<void> {
        return Contract.empty();
    }

    public cycle(result?: Result): Contract<void> {
        return this.win(result);
    }

    public highlight(result?: Result): Contract<void> {
        return Contract.empty();
    }

    public highlightDim(result?: Result): Contract<void> {
        return Contract.empty();
    }

    public winDim(result?: Result): Contract<void> {
        return Contract.empty();
    }

    public cycleDim(result?: Result): Contract<void> {
        return Contract.empty();
    }

    public expand(x: number = 0, y: number = 0): Contract<void> {
        return Contract.empty();
    }

    public remove(x: number = 0, y: number = 0): Contract<void> {
        return Contract.empty();
    }

    public anticipate(): Contract<void> {
        return Contract.empty();
    }

    public stick(): Contract<void> {
        return Contract.empty();
    }

    public changeSymbol(): void {
        // Symbol changed into new symbol
    }

    public updateTransform(): void {
        // Reposition "behaviourSprite" (sprite which should match the static sprite's position and size)
        if (this.behaviourSprite) {
            const symbolTransform = this.symbol.getTransform();

            const globalPosition = this.matrixLayer.localToGlobal(symbolTransform);
            const localPosition = this.animationLayer.globalToLocal(globalPosition);

            this.behaviourSprite.landscape = localPosition.landscape.clone();
            this.behaviourSprite.portrait = localPosition.portrait.clone();
        }
    }

    public setAnimationLayer(layer: Layers) {
        this.animationLayer = layer;
    }
}

export interface DefaultSymbolBehaviour {
    behaviourGenerator: (symbol: SymbolSubcomponent) => AbstractSymbolBehaviour;
    validSymbols?: string[];
    invalidSymbols?: string[];
}
