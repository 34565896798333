import { SoundToggleComponent } from "appworks/components/sound/sound-toggle-component";
import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { FlexiSprite } from "appworks/graphics/elements/flexi-sprite";
import { AbstractControl } from "../../abstract-control";

export class SoundToggleControl extends AbstractControl {
    protected soundToggle: SoundToggleComponent;

    constructor(protected on: FlexiButton, protected off: FlexiButton, protected loading: FlexiSprite, protected noMusic: FlexiButton) {
        super();

        this.soundToggle = new SoundToggleComponent(on, off, loading, noMusic);
    }

    public setEnabled(enabled: boolean) {
        // Never disable
    }
}
