import { LoaderService } from "appworks/loader/loader-service";
import { Services } from "appworks/services/services";
import { logger } from "appworks/utils/logger";
import { SignalBinding } from "signals";
import { AbstractComponent } from "./abstract-component";
import { ComponentFactory } from "./factories/component-factory";

export class Components {

    public static init() {
        this.constructed = true;
        this.components.forEach((component) => component.init());
    }

    /**
     * @param factory
     * @param requiredStage Stage required to load before component initialises. If null, will initialise immediately
     */
    public static register(factory: ComponentFactory, requiredStage: number = 1) {

        const addComponent = () => Components.components.push(factory.build());

        if (requiredStage !== null) {
            const binding = Services.get(LoaderService).onStageLoad.add((stage: number) => {
                if (stage === requiredStage) {
                    this.constructed = true;
                    binding.detach();
                    addComponent();
                }
            });

            // adding bindings to list to enable their removal.
            this.factoryBindings.set(factory, binding);

        } else {
            this.constructed = true;
            addComponent();
        }
    }

    public static deregister(factory: ComponentFactory) {
        if (this.constructed) {
            throw new Error("Cannot deregister components after any component construction has taken place.");
        }

        const binding = this.factoryBindings.get(factory);

        if (!binding) {
            throw new Error("Could not find requested component factory.");
        }

        binding.detach();
    }

    /**
     * Deregisters all factories which pass instanceof factoryType
     */
    public static deregisterType(factoryType: new () => ComponentFactory) {
        this.factoryBindings.forEach((signal, factory) => {
            if (factory instanceof factoryType) {
                this.deregister(factory);
            }
        });
    }

    // TODO: change any[] to unknown[]. using unknown preserves type safety for components with generics
    public static get<T extends AbstractComponent>(type: { new(...args: any[]): T }): T {
        const foundComponents = Components.components.filter((value) => value instanceof type) as T[];
        if (foundComponents.length > 1) {
            logger.debug(`Found more than one component of type ${type.name}`);
        }
        return foundComponents[0];
    }

    // TODO: change any[] to unknown[]. using unknown preserves type safety for components with generics
    public static getAll<T extends AbstractComponent>(type: { new(...args: any[]): T }): T[] {
        return Components.components.filter((value) => value instanceof type) as T[];
    }

    protected static constructed: boolean = false;
    protected static factoryBindings = new Map<ComponentFactory, SignalBinding>();

    private static components: AbstractComponent[] = [];
}
