/**
 * Defines possible exit types for a node
 */
export const enum Exit {
    Complete = "Complete",
    Skip = "Skip",
    Error = "Error",
    True = "True",
    False = "False",
    Repeat = "Repeat",
    Custom = "Custom",
    Collapse = "Collapse"
}

/**
 * Converts a string to it's corresponding exit type from Exit enum
 * @param str string
 *
 * @return Exit
 */
export const GetExitFromString = (str: string) => {
    const key = str.toLowerCase();

    if (key === "complete") {
        return Exit.Complete;
    } else if (key === "skip") {
        return Exit.Skip;
    } else if (key === "error") {
        return Exit.Error;
    } else if (key === "true") {
        return Exit.True;
    } else if (key === "false") {
        return Exit.False;
    } else if (key === "repeat") {
        return Exit.Repeat;
    } else if (key === "collapse") {
        return Exit.Collapse;
    } else {
        return str;
    }
};
