import { ValueList } from "appworks/utils/value-list";

export class AutoplayModel {

    public enabled = true;

    public autoplays: ValueList<number> = new ValueList();
    public singleWinLimit: ValueList<number> = new ValueList();
    public totalWinLimit: ValueList<number> = new ValueList();
    public lossLimit: ValueList<number> = new ValueList();

    public stopOnFeatureWin: boolean = true;
    public stopOnAnyWin: boolean = false;

    public runningWinLoss: number = 0;

    public startConfirmed: boolean;
    public lossLimitRequired: boolean = false;

    public firstAutoplay: boolean = false;

    public customSpinCountSelected: boolean = false;
    public customLossLimitSelected: boolean = false;
    public customSingleWinLimitSelected: boolean = false;

    public initialAutoplays: number = 0;

    constructor() {
        this.autoplays.currentValue = 0;
        this.singleWinLimit.currentValue = 0;
        this.totalWinLimit.currentValue = 0;
        this.lossLimit.currentValue = 0;
    }
}
