import { Result } from "appworks/model/gameplay/records/results/result";
import { BonusResult } from "slotworks/model/gameplay/records/results/bonus-result";
import { FreespinWinResult } from "slotworks/model/gameplay/records/results/freespin-win-result";

// TODO: rename this to AbstractRecord so it doesn't clash with TS Record type
export abstract class Record {

    /** ID for debugging purposes */
    public id: string = "Record";

    public cashWon: number = 0;

    public wager: number = 0;

    public results: Result[] = [];

    public parent: Record;
    public children: Record[] = [];
    public currentChildIndex: number = -1;

    public hasChildren: boolean = false;
    public lastChild: boolean = false;

    public gamble: boolean;
    public roundId: number;
    public gambleStake: number;

    // Optional timestamp for completion
    public completionDate: string;

    public getResultsOfType<T extends Result>(resultClass: { new(...args: any[]): T }): T[] {
        const toReturn: T[] = [];

        for (const result of this.results) {
            if (result instanceof resultClass) {
                toReturn.push(result as T);
            }
        }

        return toReturn;
    }

    public getFirstResultOfType<T extends Result>(resultClass: { new(...args: any[]): T }): T {
        const results = this.getResultsOfType(resultClass);

        if (results.length > 0) {
            return results[0];
        }

        return;
    }

    public hasResultOfType<T extends Result>(resultClass: { new(...args: any[]): T }): boolean {
        return this.getResultsOfType(resultClass).length > 0;
    }

    public isBonusTriggered(id?: string): boolean {
        for (const result of this.results) {
            if (result instanceof BonusResult) {
                const matchesId = id == null || result.id === id;
                if (matchesId && !result.played) {
                    return true;
                }
            }
        }

        return false;
    }

    public setBonusPlayed(id: string, played: boolean = true) {
        for (const result of this.results) {
            if (result instanceof BonusResult) {
                if (result.id === id) {
                    result.played = played;
                    break;
                }
            }
        }
    }

    public hasUnplayedBonuses() {
        let bonuses = false;

        for (const result of this.results) {
            if (result instanceof BonusResult) {
                bonuses = true;
                if (result.played) {
                    return false;
                }
            }
        }

        return bonuses;
    }

    /**
     * Finds all FreespinWinResult results and returns the total freespinsWon across them all
     */
    public getFreespinsWon() {
        let totalFreespinsWon = 0;

        for (const freespinWinResult of this.getResultsOfType(FreespinWinResult)) {
            totalFreespinsWon += freespinWinResult.freespinsWon;
        }

        return totalFreespinsWon;
    }

    /**
     * Adds a child record to the bottom most branch, not necessarily as a direct child of this record
     */
    public addChildRecord(record: Record): boolean {
        let added = false;

        if (this.hasChildren && this.children.length > 0) {
            let index = 0;
            do {
                added = this.children[index].addChildRecord(record);
                index++;
            } while (!added && index < this.children.length);
        }

        if (!added && this.hasChildren && !this.isBranchComplete()) {
            record.parent = this;
            this.children.push(record);
            added = true;
        }

        return added;
    }

    public nextChild() {
        return this.children[++this.currentChildIndex];
    }

    public isBranchComplete() {
        let branchComplete = false;

        if (!this.hasChildren) {
            branchComplete = true;
        } else if (this.children.length > 0) {
            const lastChild = this.children[this.children.length - 1];
            if (lastChild.lastChild && lastChild.isBranchComplete()) {
                branchComplete = true;
            }
        }

        return branchComplete;
    }

    public hasMoreChildren() {
        return this.children.length - 1 > this.currentChildIndex;
    }

    public getHighestCashWinResult() {
        let highest: Result;

        this.results.forEach((result) => {
            if (!highest || result.cashWon > highest.cashWon) {
                highest = result;
            }
        });

        return highest;
    }
    public getPrevRecord(): Record | undefined {
        return this.parent?.children[this.parent.children.indexOf(this) - 1];
    }
}
