import { Contract } from "appworks/utils/contracts/contract";
import { gameLayers } from "game-layers";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";
import { slingoModel } from "slingo/model/slingo-model";

export class PopLadderComponent extends SlingoLadderComponent {
    public init(): void {
        super.init();
        this.setMultiplierLabels();

        // Hide bottom prize in arcade only
        gameLayers.SlingoLadder.onSceneEnter.add((scene:string) => {
            const arrow = gameLayers.SlingoLadder.getSprite("arrow_2");
            const value = gameLayers.SlingoLadder.getText("cashprize_2");
            const label = gameLayers.SlingoLadder.getText("p_label_2");
            if (arrow) {
                arrow.visible = !GamingRealms.isArcade();
            }
            if (value) {
                value.visible = GamingRealms.isArcade();
            }
            if (label && scene !== "paytable") {
                label.visible = GamingRealms.isArcade();
            }
        });

        for (let i = 0; i <= SlingoLadderComponent.MAX_SLINGOS; i++) {
            const extendedFill = this.layer.getSprite("extended_fill_" + i);
            if (extendedFill) { this.fillSprites.get(i).push(extendedFill); }

            const extendedHighlight = this.layer.getSprite("extended_highlight_" + i);
            if (extendedHighlight) { this.highlightSprites.get(i).push(extendedHighlight); }
        }

        this.setValue(this.currentLevel).execute();
    }

    protected setMultiplierLabels() {
        if (this.layer.currentSceneIs("base")) {
            // In portrait shrunken state, the labels should show the win multipliers
            const payouts = slingoModel.read().payoutConfig.patternPayouts;
            for (let i = 1; i <= 12; i++) {
                const text = this.layer.getText("p_label_" + i);
                if (text) { text.text = `x${payouts[i - 1] / 100}`; }
            }
        }
    }
}