import { Components } from "appworks/components/components";
import { PopScatterInfoComponent } from "components/pop-scatter-info-component";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { slingoModel } from "slingo/model/slingo-model";
import { SlingoInitState } from "slingo/states/slingo-init-state";

export class PopInitState extends SlingoInitState {
    protected configureRules() {
        super.configureRules();

        const rulesFrame = document.getElementById("rules_iframe") as HTMLIFrameElement;
        const rulesDoc = rulesFrame?.contentWindow.document;

        const model = slingoModel.read();
        const payouts = [];

        for (let i = 3; i < 6; i++) {
            const payout = model.payoutConfig.symbolPayouts[0].multipliers[i - 1];
            payouts.push(payout);
        }

        // Swap logo
        if (GamingRealms.isArcade()) {
            (rulesDoc.getElementById("rules_logo") as HTMLImageElement).src = "./images/logo_arcade.png";
        }

        // Set initial spins value
        for (let i = 0; i < 3; i++) {
            rulesDoc.body.innerHTML = rulesDoc.body.innerHTML.replace(`{scatterx${i + 3}}`, payouts[i]);
        }
    }
}