import { Point } from "appworks/utils/geom/point";
import { Position } from "./position";

export class TextPosition extends Position {

    public static unavailable() {
        return new TextPosition(0, 0, 0, 0, new Point(1, 1), 1, null, true);
    }

    constructor(
        public x: number = 0,
        public y: number = 0,
        public width: number = 0,
        public height: number = 0,
        public scale: Point = new Point(1, 1),
        public fontSize: number = 1,
        public align: string = "",
        unavailable: boolean = false
    ) {
        super(x, y, width, height, scale, unavailable);
    }

    public clone() {
        return new TextPosition(this.x, this.y, this.width, this.height, new Point(this.scale.x, this.scale.y), this.fontSize, this.align, this.unavailable);
    }

    public toPosition() {
        return new Position(this.x, this.y, this.width, this.height, new Point(this.scale.x, this.scale.y), this.unavailable);
    }

    public equals(position: TextPosition): boolean {
        return super.equals(position) &&
            this.fontSize === position.fontSize &&
            this.align === position.align;
    }
}
