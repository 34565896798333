import { ResultBuilder } from "appworks/server/builders/result-builder";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { SlingoResponse } from "slingo/integration/slingo-schema";
import { SlingoStakeToSpinResult } from "slingo/model/results/slingo-stake-to-spin-result";
import { contains, lastInArray } from "appworks/utils/collection-utils";
import { GMRAction } from "gaming-realms/integration/gmr-schema";

export class SlingoStakeToSpinResultBuilder implements ResultBuilder {
    public filter(data: SlingoResponse, record?: SlingoRecord): boolean {
        return Boolean(data.spinResult?.nextStakeToSpin || data.spinResult?.stakeToSpin) && 
        !((data.state === "COMPLETE" && contains(data.actions, GMRAction.PURCHASE)) && (data.gameProgress.completionReason !== "MAXIMUM_TOTAL_STAKE_LIMIT" && data.gameProgress.completionReason !== "MAXIMUM_STAKE_LIMIT")) ;
    }

    public build(record: SlingoRecord, data: SlingoResponse): SlingoStakeToSpinResult[] {
        const result = new SlingoStakeToSpinResult();

        const stakeToSpinData = lastInArray(data.spinResult.nextStakeToSpin) ?? data.spinResult.stakeToSpin;

        result.rng = stakeToSpinData.feature.rng;

        record.wager = Math.round(stakeToSpinData.stake * 100);

        return [result];
    }
}
