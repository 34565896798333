import Logger = require("js-logger");
import { Service } from "../service";

let ls: any | undefined;
try {
    ls = require("local-storage");
} catch (e) {
    Logger.error("Failed to access local storage.");
}

declare const __GAMENAME__: string;
declare const __VERSION__: string;

export class LocalStorageService extends Service {
    public init(): void {
        //
    }

    public set(key: string, value: string | number | boolean) {
        if (value !== undefined) {
            try {
                ls.set(this.getUniqueKey(key), value);
            } catch (e) {
                Logger.error("Failed to save to local storage.");
            }
        }
    }

    public getBoolean(key: string): boolean {
        return this.get(key) as boolean;

    }

    public getString(key: string): string {
        return this.get(key) as string;
    }

    public getNumber(key: string): number {
        return this.get(key) as number;
    }

    protected getUniqueKey(key: string) {
        if (typeof __VERSION__ !== "undefined") {
            key = __VERSION__ + "_" + key;
        }
        if (typeof __GAMENAME__ !== "undefined") {
            key = __GAMENAME__ + "_" + key;
        }

        return key;
    }

    protected get(key: string): string | boolean | number {
        try {
            return ls.get(this.getUniqueKey(key));
        } catch (e) {
            Logger.error("Failed to load from local storage.");
            return undefined;
        }
    }
}
