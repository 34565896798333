import { gameState } from 'appworks/model/game-state';
import { SlingoPurchaseEntryState } from 'slingo/states/slingo-purchase-entry-state';
import { BlastworksClientEvent, ClientController } from 'client-controller';

/**
 * Social purchase entry state, send start game message
 *
 * @class SocialPurchaseEntryState
 * @typedef {SocialPurchaseEntryState}
 * @extends {SlingoPurchaseEntryState}
 */
export class SocialPurchaseEntryState extends SlingoPurchaseEntryState {
  /**
   * Send start game message
   *
   * @public
   * @override
   */
  public complete(): void {
    const gameplay = gameState.getCurrentGame();
    gameplay.setToLatestRecord();

    // If this is zero then there must be an error
    // i.e. account limits, insufficient funds
    if (gameplay.getTotalWagered() !== 0) {
      ClientController.getInstance().raiseEvent(BlastworksClientEvent.START_GAME);
    }
    super.complete();
  }
}
