import { PopReelMatrixComponent } from "components/pop-reel-matrix-component";
import { SlingoReelMatrixFactory } from "slingo/components/factories/slingo-reel-matrix-factory";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";
import { slotDefinition } from "slotworks/model/slot-definition";
import { PopReelTransition } from "../reel-transitions/pop-reel-transition";
import { PopSpineSymbolBehaviour } from "components/symbol-behaviours/pop-spine-symbol-behaviour";
import { gameLayers } from "game-layers";

export class PopReelMatrixFactory extends SlingoReelMatrixFactory {
    protected createMatrix(): AbstractMatrixComponent<SymbolSubcomponent> {
        return new PopReelMatrixComponent(
            slotDefinition.matrixGrid,
            this.defaultStops,
            slotDefinition.reelsets.get(this.defaultReelset),
            gameLayers.MatrixContent,
            gameLayers.MatrixContent
        );
    }

    protected createTransition(matrix: AbstractMatrixComponent<SymbolSubcomponent>): void {
        matrix.setTransition(new PopReelTransition());
    }

    protected addSymbolBehaviours(matrix: AbstractMatrixComponent<SymbolSubcomponent>): void {
        super.addSymbolBehaviours(matrix);
        matrix.addDefaultSymbolBehaviour(((symbol) => new PopSpineSymbolBehaviour(symbol)), ["D", "J", "SJ", "FS"]);
    }
}
