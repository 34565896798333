import { BlastworksClientEvent, ClientController } from 'client-controller';
import { PopPurchaseSpinOrCollectState } from 'state-machine/states/pop-purchase-spin-or-collect-state';

/**
 * Social purchase or collect state to send purchase state message
 *
 * @class SocialPurchaseSpinOrCollectState
 * @typedef {SocialPurchaseSpinOrCollectState}
 * @extends {PYLPurchaseSpinOrCollectState}
 */
export class SocialPurchaseSpinOrCollectState extends PopPurchaseSpinOrCollectState {
  /**
   * Send purchase spin message
   *
   * @public
   * @override
   */
  public onEnter(): void {
    ClientController.getInstance().raiseEvent(BlastworksClientEvent.PURCHASE_SPIN);
    super.onEnter();
  }
}
