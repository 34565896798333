import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { asArray, lastInArray } from "appworks/utils/collection-utils";
import { Easing } from "appworks/utils/tween";
import { Tween } from "appworks/utils/tween";
import { SetRequired } from "appworks/utils/types";
import { Signal } from "signals";

/**
 * Wrapper around tweens which takes options as an object and returns a promise
*/

export type TweenOptions<T> = {
    target: T;
    to: { [K in keyof T]?: T[K] | Array<T[K]> };
    duration: number;
    repeat: number;
    yoyo: boolean;
    delay: number;
    easing: (k: number) => number;
    interpolation: (v: number[], k: number) => number;
    onStart: () => void;
    onUpdate: () => void;
    onComplete: () => void;
    onStop: () => void;
    onLoop: () => void;
    /** Stops the tween where it is */
    stopSignal?: Signal;
    /** Stops the tween and then sets it to its final `to` state */
    cancelSignal?: Signal;
    /** If the target object is already at the given `to` object, the tween will resolve immediately. Defaults to true */
    resolveImmediatelyIfSame?: boolean;
}

const defaultTweenOptions: Partial<TweenOptions<unknown>> = {
    duration: 1000,
    resolveImmediatelyIfSame: true
}

export async function tween2<T>(options: SetRequired<Partial<TweenOptions<T>>, "target" | "to">) {
    options = { ...defaultTweenOptions, ...options };

    return new Promise<void>((resolve) => {
        if (options.resolveImmediatelyIfSame) {
            let same = true;
            for (const key in options.to) {
                const toValue = lastInArray(asArray(options.to[key]));
                if (options.target[key] !== toValue) {
                    same = false;
                    break;
                }
            }
            if (same) {
                if (options.onComplete) {
                    options.onComplete();
                }
                if (options.onStop) {
                    options.onStop();
                }
                resolve();
                return;
            }
        }

        const _tween = new Tween(options.target).to(options.to, options.duration);

        if (options.easing) _tween.easing(options.easing);
        if (options.repeat) _tween.repeat(options.repeat);
        if (options.yoyo) _tween.yoyo(options.yoyo);
        if (options.delay) _tween.delay(options.delay);
        if (options.interpolation) _tween.interpolation(options.interpolation);
        if (options.onStart) _tween.onStart(options.onStart);
        if (options.onUpdate) _tween.onUpdate(options.onUpdate);
        if (options.onStop) _tween.onStop(options.onStop);
        if (options.onLoop) _tween.onLoop(options.onLoop);
        
        _tween.onComplete(() => {
            if (options.onComplete) {
                options.onComplete();
            }
            resolve();
        });

        if (options.stopSignal) {
            options.stopSignal.add(() => {
                _tween.stop();
                resolve();
            });
        }

        if (options.cancelSignal) {
            options.cancelSignal.add(() => {
                _tween.stop();
                _tween.end();
                resolve();
            });
        }

        _tween.start();
    });
}