import { Layers } from "appworks/graphics/layers/layers";
import { Services } from "appworks/services/services";
import { GameJuriInfoControl } from "appworks/ui/controls/standard/misc/game-juri-info-control";
import { Jurisdiction, JurisdictionService } from "slotworks/services/jurisdiction/jurisdiction-service";
import { ControlFactory } from "../control-factory";

export class GameJuriInfoControlFactory implements ControlFactory {
    public filter(layer: Layers) {
        if (
            !layer.getText("line_1") ||
            !layer.getText("line_2")
        ) {
            return false;
        }

        if (Services.get(JurisdictionService).getJurisdiction() !== Jurisdiction.Germany) {
            Layers.get("Jurisdiction")?.hide();
            return false;
        }

        return true;
    }

    public build(layer: Layers) {
        return [new GameJuriInfoControl(
            layer.getText("line_1"),
            layer.getText("line_2"),
            layer.getText("line_3"),
            layer.getText("line_4")
        )];
    }
}
