import { FlexiSlider, SliderEvent } from "appworks/graphics/elements/flexi-slider";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { Signal } from "signals";

export class SliderComponent {
    public formatFunction: (value: number) => string;
    public onChange: Signal;
    public onInput: Signal;

    protected minLabel: FlexiText;
    protected maxLabel: FlexiText;

    constructor(protected slider: FlexiSlider, protected label: FlexiText) {
        this.onChange = new Signal();
        this.onInput = new Signal();

        this.slider.setEnabled(true);

        this.slider.on(SliderEvent.INPUT, this.inputHandler);
        this.slider.on(SliderEvent.CHANGE, this.changeHandler);
    }

    public setup(min: number, max: number, step: number): void {
        if (this.minLabel) {
            this.minLabel.setText(this.format(min));
        }

        if (this.maxLabel) {
            this.maxLabel.setText(this.format(max));
        }

        this.slider.setup(min, max, step);
    }

    public setValue(value: number): void {
        this.slider.setValue(value);
        this.updateLabel();
    }

    public destroy(): void {
        if (this.slider) {
            this.slider.off(SliderEvent.INPUT, this.inputHandler);
            this.slider.off(SliderEvent.CHANGE, this.changeHandler);
            this.slider.destroy();
            this.slider = null;

            this.onInput.dispose();
            this.onChange.dispose();
            this.onInput = null;
            this.onChange = null;
        }
    }

    public setLabels(minLabel: FlexiText, maxLabel: FlexiText): void {
        this.minLabel = minLabel;
        this.maxLabel = maxLabel;
    }

    public setVisible(visible: boolean): void {
        this.slider.setVisible(visible);
        if (this.label) {
            this.label.setVisible(visible);
        }
        if (this.minLabel) {
            this.minLabel.setVisible(visible);
        }
        if (this.maxLabel) {
            this.maxLabel.setVisible(visible);
        }
    }

    public setEnabled(enabled: boolean): void {
        this.slider.setEnabled(enabled);
    }

    protected inputHandler = (value: number) => {
        if (this.onInput) {
            this.updateLabel();
            this.onInput.dispatch(value);
        }
    }

    protected changeHandler = (value: number) => {
        if (this.onChange) {
            this.updateLabel();
            this.onChange.dispatch(value);
        }
    }

    protected updateLabel(): void {
        if (this.label) {
            const value = this.slider.getValue();
            const text = this.format(value);

            this.label.setText(text);
        }
    }

    protected format(value: number): string {
        if (this.formatFunction) {
            return this.formatFunction(value);
        } else {
            return value.toString();
        }
    }
}
