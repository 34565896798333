import { Timer } from "appworks/utils/timer";
import * as VisibilityJS from "visibilityjs";

export abstract class WindowFocusManager {

    public static visible: boolean = true;

    public callbacks: Array<(focused: boolean) => void> = [];

    constructor() {
        const onChange = () => {
            this.setVisible(!VisibilityJS.hidden());
        };

        VisibilityJS.change(onChange);

        Timer.setInterval(() => {
            if (VisibilityJS.hidden()) {
                onChange();
            }
        }, 0);

        document.addEventListener("visibilitychange", onChange, false);
        window.addEventListener("focus", onChange);
        window.addEventListener("blur", onChange);
    }

    private setVisible(visible: boolean) {
        if (WindowFocusManager.visible !== visible) {
            WindowFocusManager.visible = visible;

            for (const callback of this.callbacks) {
                callback(visible);
            }
        }
    }
}

class WindowFocusManagerSingleton extends WindowFocusManager { }

const windowFocusManager = new WindowFocusManagerSingleton();

/**
 * Detects when the browser window has lost or gained focus and calls the callback with this information
 *
 * @param callback {(visible:boolean) => void}
 */
export function AddWindowFocusChangeListener(callback: (focused: boolean) => void) {
    windowFocusManager.callbacks.push(callback);
}

export function RemoveWindowFocusChangeListener(callback: (focused: boolean) => void) {
    while (windowFocusManager.callbacks.indexOf(callback) > -1) {
        windowFocusManager.callbacks.splice(windowFocusManager.callbacks.indexOf(callback), 1);
    }
}

/**
 * Appends a random sequence to a URL to prevent cached versions from loading.
 * i.e. "google.com" becomes "google.com?hvn3o9"
 */
export function appendCacheBustStringToUrl(url: string): string {
    return url + "?" + Math.random().toString(36).substring(2, 8);
}
