import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { SlingoSpinType } from "slingo/integration/slingo-schema";

export class PopSpinsCounterComponent extends SlingoSpinsCounterComponent {
    protected spinType: SlingoSpinType;

    public getSpinType() {
        return this.spinType;
    }

    protected getText(): void {
        super.getText();
        this.text.alpha = 0.7;
    }
}
