export const PopSoundEvents = {
    free_spin: "sp_free_spin_chink",
    whirpool: "sp_in_symbol_{0}",
    symbol_idle: "sp_idle_symbol_{0}",
    vanish_number: "sp_vanish_number",
    vanish_wild: "sp_vanish_wild",
    scatter_low: "sp_scatter_win_low",
    scatter_high: "sp_scatter_win_high",
    coin_flip: "sp_coin_flip",
    coin_insert: "sp_coin_insert",
    coin_nudge: "sp_coin_nudge"
} as const;

