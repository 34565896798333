import { AbstractComponent } from "appworks/components/abstract-component";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { SpineService } from "appworks/graphics/spine/spine-service";
import { Services } from "appworks/services/services";
import { arrayOfValues } from "appworks/utils/collection-utils";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Contract } from "appworks/utils/contracts/contract";
import { RandomFromArray, RandomRangeFloat } from "appworks/utils/math/random";

export enum BubbleSpineState {
    IDLE,
    MATCH,
    WILD,
    SUPERWILD,
    DAB,
    DAB_INSTANT,
    POP,
    PULSE
}

export class PopBubbleSpineComponent extends AbstractComponent {
    protected spine: SpineContainer;
    protected state: BubbleSpineState;
    protected idleSpeed: number = RandomRangeFloat(0.6, 1);

    protected cancelGroup = new CancelGroup();

    constructor(protected layer: Layers, defaultPosition: DualPosition) {
        super();

        const spineService = Services.get(SpineService);

        this.spine = spineService.createSpineContainer("bubble", defaultPosition);
        this.spine.buttonMode = true;
        this.layer.add(this.spine, false, true);

        this.spine.onComplete.add(() => this.onSpineComplete());
    }

    public setPosition(dualPosition: DualPosition) { this.spine.dualPosition.setPosition(dualPosition); }
    public getPosition() { return this.spine.dualPosition; }

    public setState(state: BubbleSpineState): Contract {
        const oldState = this.state;
        this.state = state;

        this.cancelGroup.cancel();

        switch (state) {
            case BubbleSpineState.IDLE:
                this.onSpineComplete();
                return Contract.empty();

            case BubbleSpineState.MATCH:
                this.spine.play("dab_idle", this.idleSpeed);
                return Contract.empty();

            case BubbleSpineState.WILD:
                return this.cancelGroup.sequence([
                    () => this.spine.playOnce("wild_in"),
                    () => Contract.wrap(() => this.spine.play("wild_idle", this.idleSpeed))
                ]);

            case BubbleSpineState.SUPERWILD:
                return this.cancelGroup.sequence([
                    () => this.spine.playOnce("super_wild_in"),
                    () => Contract.wrap(() => this.spine.play("super_wild_idle", this.idleSpeed))
                ]);

            case BubbleSpineState.DAB:
                this.layer.add(this.spine); // bring to front
                if (oldState === BubbleSpineState.SUPERWILD) {
                    return this.cancelGroup.add(this.spine.playOnce("super_wild_out"));
                }
                return this.cancelGroup.add(this.spine.playOnce("wild_out", null, 2));

            case BubbleSpineState.DAB_INSTANT:
                this.layer.add(this.spine); // bring to front
                this.state = BubbleSpineState.DAB;
                this.spine.playOnce("wild_out", false, 100).execute();
                return Contract.empty();

            case BubbleSpineState.POP:
                return this.cancelGroup.add(this.spine.playOnce("DAB_reel_pop"));

            case BubbleSpineState.PULSE:
                return this.cancelGroup.add(this.spine.playOnce("Dab_shines"));
        }
    }
    public getState() { return this.state; }

    public setAlpha(alpha: number) { this.spine.alpha = alpha; }
    public getAlpha() { return this.spine.alpha; }

    public destroy() {
        this.spine.destroy();
    }

    protected onSpineComplete() {
        if (this.state === BubbleSpineState.IDLE) {
            const idleAnims = [...arrayOfValues(25, "bubble_idle"), "bubble_idle_sparkly", "bubble_idle_sparkly2"];
            this.spine.playOnce(RandomFromArray(idleAnims), false, this.idleSpeed).execute();
        }
    }
}
