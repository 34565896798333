export class DOMLayer {

    public static hasDOMScene(scene: string, layer: string): boolean {
        return document.querySelectorAll("#" + layer + " #" + scene).length > 0;
    }

    public static setDOMScene(scene: string, layer: string, active: boolean) {
        const domElements = document.querySelectorAll("#" + layer + " #" + scene);
        for (let i = 0; i < domElements.length; i++) {
            const domElement = domElements.item(i) as HTMLElement;
            domElement.classList.toggle(active ? "inactiveScene" : "activeScene", false);
            domElement.classList.toggle(active ? "activeScene" : "inactiveScene", true);
        }
    }

    public static setVisibleDOMLayer(layer: string, visible: boolean) {
        const domElements = document.querySelectorAll("#" + layer);
        for (let i = 0; i < domElements.length; i++) {
            const domElement = domElements.item(i) as HTMLElement;
            domElement.classList.toggle(visible ? "visible" : "hidden", true);
            domElement.classList.toggle(visible ? "hidden" : "visible", false);
        }
    }
}
