import { Services } from "appworks/services/services";
import { contains } from "appworks/utils/collection-utils";
import { Signal } from "signals";
import { slotModel } from "slotworks/model/slot-model";
import { Service } from "../../../appworks/services/service";
import { SlotBetService } from "../bet/slot-bet-service";

export enum Jurisdiction {
    UK = "UK",
    Malta = "Malta",
    Sweden = "Sweden",
    Italy = "Italy",
    Denmark = "Denmark",
    Spain = "Spain",
    Czechia = "Czechia",
    Alderney = "Alderney",
    Norway = "Norway",
    Romania = "Romania",
    Latvia = "Latvia",
    Germany = "Germany",
    Greece = "Greece",
    Quebec = "Quebec"
}

export class JurisdictionService extends Service {

    public netPositionUpdate: Signal = new Signal();

    public init(): void {
        //
    }

    public setJurisdiction(jurisdiction: Jurisdiction) {
        slotModel.write({ regulations: { jurisdiction } });

        if (Boolean(jurisdiction)) {
            this.setJurisdictionDefaults(jurisdiction);
        }
    }

    public getJurisdiction(): Jurisdiction | null {
        return slotModel.read().regulations.jurisdiction;
    }

    public setAutoplayEnabled(enabled: boolean) {
        slotModel.write({ regulations: { autoplayAllowed: enabled } });
    }

    public getAutoplayEnabled(): boolean {
        return slotModel.read().regulations.autoplayAllowed;
    }

    public setBuyBonusEnabled(enabled: boolean) {
        slotModel.write({ regulations: { buyBonusAllowed: enabled } });
    }

    public getBuyBonusEnabled(): boolean {
        return slotModel.read().regulations.buyBonusAllowed;
    }

    public setUnlimitedAutoplayEnabled(enabled: boolean) {
        slotModel.write({ regulations: { unlimitedAutoplaySpins: enabled } });
    }

    public isUnlimitedAutoplayEnabled(): boolean {
        return slotModel.read().regulations.unlimitedAutoplaySpins;
    }

    // Suggested autoplay options for this jurisdiction, with the last in array being the max allowed
    public setAutoplayLimits(value: number[]) {
        slotModel.write({ regulations: { autoplayLimits: value.concat() } });
    }

    // Suggested autoplay options for this jurisdiction, with the last in array being the max allowed
    public getAutoplayLimits() {
        return slotModel.read().regulations.autoplayLimits;
    }

    public setMinimumSpinTimes(minSpinTimes: Array<{ minBet: number, delayMs: number }>) {
        slotModel.write({ regulations: { minSpinTimes: minSpinTimes.sort((a, b) => b.minBet - a.minBet) } });
    }

    public getMinimumSpinTimes(): Array<{ minBet: number, delayMs: number }> {
        return slotModel.read().regulations.minSpinTimes;
    }

    public getMinimumSpinTime(): number {
        const stake = Services.get(SlotBetService).getTotalStake();
        const delays = this.getMinimumSpinTimes();
        const delay = delays.find((delayObject) => stake >= delayObject.minBet);

        if (delay) {
            return delay.delayMs;
        } else {
            return 0;
        }
    }

    public setSpinSkippingEnabled(enabled: boolean) {
        slotModel.write({ regulations: { skipAllowed: enabled } });
    }

    public getSpinSkippingEnabled(): boolean {
        return slotModel.read().regulations.skipAllowed;
    }

    public setQuickSpinEnabled(enabled: boolean) {
        slotModel.write({ regulations: { turboAllowed: enabled } });
    }

    public getQuickSpinEnabled(): boolean {
        return slotModel.read().regulations.turboAllowed;
    }

    public setMinPreloaderTime(timeMs: number) {
        slotModel.write({ regulations: { minPreloaderTime: timeMs } });
    }

    public getMinPreloaderTime(): number {
        return slotModel.read().regulations.minPreloaderTime;
    }

    public setPreloaderMessageKey(key: string) {
        slotModel.write({ regulations: { preloaderMessageKey: key } });
    }

    public getPreloaderMessageKey(): string {
        return slotModel.read().regulations.preloaderMessageKey;
    }

    public shouldStopAutoplayOnFeature(): boolean {
        const jurisdiction = this.getJurisdiction();
        return jurisdiction === Jurisdiction.UK || jurisdiction === Jurisdiction.Alderney || jurisdiction === Jurisdiction.Quebec;
    }

    public setElapsedTimeEnabled(enabled: boolean) {
        slotModel.write({ regulations: { sessionTimerEnabled: enabled } });
    }

    public getElapsedTimeEnabled(): boolean {
        return slotModel.read().regulations.sessionTimerEnabled;
    }

    public setNetPositionEnabled(enabled: boolean) {
        slotModel.write({ regulations: { sessionTimerEnabled: enabled } });
    }

    public getNetPositionEnabled(): boolean {
        return slotModel.read().regulations.sessionTimerEnabled;
    }

    public setCelebrateSubWinsEnabled(enabled: boolean) {
        slotModel.write({ regulations: { celebrateSubWins: enabled } });
    }

    public getCelebrateSubWinsEnabled(): boolean {
        return slotModel.read().regulations.celebrateSubWins;
    }

    public betStaked(stake: number): void {
        const netPositionValue = slotModel.read().regulations.netPosition - stake;
        slotModel.write({ regulations: { netPosition: netPositionValue } });
        this.netPositionUpdate.dispatch(netPositionValue);
    }

    public winningsReceived(winnings: number): void {
        const netPositionValue = slotModel.read().regulations.netPosition + winnings;
        slotModel.write({ regulations: { netPosition: netPositionValue } });
        this.netPositionUpdate.dispatch(netPositionValue);
    }

    public setJurisdictionDefaults(jurisdiction: Jurisdiction) {
        const unlimitedAutoplaySpins = [
            Jurisdiction.Malta,
            Jurisdiction.Latvia
        ];

        const noSpinSkippingJuris = [
            Jurisdiction.Denmark,
            Jurisdiction.Spain,
            Jurisdiction.Sweden,
            Jurisdiction.Latvia,
            Jurisdiction.Norway,
            Jurisdiction.UK,
            Jurisdiction.Alderney,
            Jurisdiction.Germany,
            Jurisdiction.Quebec
        ];

        const noQuickSpinJuris = [
            Jurisdiction.Denmark,
            Jurisdiction.Spain,
            Jurisdiction.Sweden,
            Jurisdiction.Latvia,
            Jurisdiction.Norway,
            Jurisdiction.UK,
            Jurisdiction.Alderney,
            Jurisdiction.Germany,
            Jurisdiction.Quebec
        ];

        const minSpinTime = this.getMinimumSpinTimes();
        switch (jurisdiction) {
            case Jurisdiction.Denmark:
            case Jurisdiction.Spain:
            case Jurisdiction.Sweden:
            case Jurisdiction.Latvia:
            case Jurisdiction.Norway:
                minSpinTime[0].delayMs = 3500;
                break;
            case Jurisdiction.UK:
            case Jurisdiction.Alderney:
            case Jurisdiction.Greece:
            case Jurisdiction.Quebec:
                minSpinTime[0].delayMs = 3000;
                break;
            case Jurisdiction.Germany:
                minSpinTime[0].delayMs = 5000;
                break;
        }

        let minPreloaderTime = this.getMinPreloaderTime();
        switch (jurisdiction) {
            case Jurisdiction.Alderney:
                minPreloaderTime = 5000;
                break;
        }

        const unlimitedAutoSpinsAllowed = !contains(unlimitedAutoplaySpins, jurisdiction);
        const spinSkippingAllowed = !contains(noSpinSkippingJuris, jurisdiction);
        const quickSpinAllowed = !contains(noQuickSpinJuris, jurisdiction);

        this.setUnlimitedAutoplayEnabled(unlimitedAutoSpinsAllowed);
        this.setSpinSkippingEnabled(spinSkippingAllowed);
        this.setQuickSpinEnabled(quickSpinAllowed);
        this.setMinimumSpinTimes(minSpinTime);
        this.setMinPreloaderTime(minPreloaderTime);
    }
}
