import { Services } from "appworks/services/services";
import TaggedText from "pixi-tagged-text";
import { Align, FontStyle, ImageSource, ImageSourceMap, ParagraphToken, TaggedTextOptions, TextStyleExtended } from "pixi-tagged-text/dist/types";
import { Renderer, TextStyle, Texture } from "pixi.js";
import { Orientation } from "../canvas/orientation";
import { GraphicsService } from "../graphics-service";
import { DualPosition } from "./dual-position";
import { RenderOrientation } from "./render-orientation";
import { TextPosition } from "./text-position";

export enum HTMLTextVAlign {
    MIDDLE,
    TOP,
    BOTTOM
}
export class HTMLText extends TaggedText {

    public static addStaticImages() {
        const graphicsService = Services.get(GraphicsService);
        const imageNames: string[] = graphicsService.listStaticImageNames();
        imageNames.forEach((imageName) => {
            HTMLText.setImage(imageName, graphicsService.createSprite(imageName));
        });
    }

    public static minFontSize: number = 8;

    public static setTagStyling(tag: string, style: TextStyleExtended): void {
        this.styling[tag] = style;
    }

    public static getTextStyling(tag: string): TextStyleExtended | undefined {
        return this.styling[tag];
    }

    public static setImage(tag: string, image: ImageSource): void {
        this.imgMap[tag] = image;
    }

    protected static styling: { [key: string]: TextStyleExtended } = {};
    protected static imgMap: ImageSourceMap = {};

    public landscape: TextPosition = new TextPosition();
    public portrait: TextPosition = new TextPosition();

    private lastText: string;
    private valign: HTMLTextVAlign = HTMLTextVAlign.MIDDLE;

    constructor(text?: string, style: Partial<TextStyle> = { align: "left", fontStyle: "normal" }, options?: TaggedTextOptions, texture?: Texture, valign: HTMLTextVAlign = HTMLTextVAlign.MIDDLE) {
        super(text, {
            default: {
                ...style,
                align: style.align as Align,
                valign: "middle",
                fontStyle: style.fontStyle as FontStyle
            }, ...HTMLText.styling
        }, { ...options, imgMap: HTMLText.imgMap }, texture);
        this.valign = valign;
    }

    public updateTransform() {
        const position = this.getOrientationPosition();

        // @ts-ignore
        super.x = position.x;
        let verticleOffset = 0;
        if (this.valign == HTMLTextVAlign.MIDDLE) {
            verticleOffset = ((position.height - this.textContainer.height) / 2);
        } else if (this.valign === HTMLTextVAlign.BOTTOM) {
            verticleOffset = position.height - this.textContainer.height;
        }
        // @ts-ignore
        super.y = position.y + verticleOffset;

        super.updateTransform();

        if (this.defaultStyle.wordWrapWidth !== position.width) {
            this.defaultStyle.wordWrapWidth = position.width;
            this.forceTextRefresh();
        }
    }

    public _render(renderer: Renderer) {
        if (this.getOrientationPosition().unavailable) {
            return;
        }

        return super._render(renderer);
    }

    public getPosition(orientation: Orientation) {
        return orientation === Orientation.PORTRAIT ? this.portrait : this.landscape;
    }

    public setDualPosition(dualPosition: DualPosition) {
        this.landscape.x = dualPosition.landscape.x;
        this.landscape.y = dualPosition.landscape.y;
        this.landscape.width = dualPosition.landscape.width;
        this.landscape.height = dualPosition.landscape.height;
        this.portrait.x = dualPosition.portrait.x;
        this.portrait.y = dualPosition.portrait.y;
        this.portrait.width = dualPosition.portrait.width;
        this.portrait.height = dualPosition.portrait.height;
    }

    public update(skipDraw?: boolean): ParagraphToken {

        // this.iOSFix(); // Removed as of PIXI 5.3.2 - Left commented out for now in case of false positive

        if (this.text !== this.lastText) {
            this.lastText = this.text;
            this.autoResize();
        }

        return super.update(skipDraw);
    }

    /**
     * This only exists as a way to force `updateText` to call `autoResize`.
     *
     * Useful when resizing text position's width & height programatically.
     */
    public forceAutoResize() {
        this.autoResize();
        super.update(false);
    }

    /**
     * **Warning**: This has cross references to filters, replace them if you don't wish to share them with the cloned text
     */
    public clone() {
        const cloned = new HTMLText(this.text);
        cloned.defaultStyle = this.defaultStyle;
        cloned.tagStyles = this.tagStyles;
        cloned.landscape = this.landscape.clone();
        cloned.portrait = this.portrait.clone();
        cloned.filters = [...this.filters];
        cloned.valign = this.valign;

        return cloned;
    }

    protected forceTextRefresh() {
        const text = this.text;
        this.lastText = "";
        this.setText("", true);
        this.setText(text, false);
    }

    protected autoResize() {
        const position = this.getOrientationPosition();

        if (position) {
            // Size adjustment is only applied to "default". Other styles adjust automatically relative to the default.
            this.tagStyles["default"].wordWrapWidth = position.width;
            this.tagStyles["default"].fontSize = position.fontSize;

            if (this.tagStyles["default"].fontSize > HTMLText.minFontSize) {
                super.update(false);
                // Binary search for a font size that fits
                let maxSize = this.tagStyles["default"].fontSize;
                let minSize = HTMLText.minFontSize;
                let size = maxSize;
                while (maxSize > minSize) {
                    // Check for fit
                    const tooBig = this.textContainer.width > position.width || this.textContainer.height > position.height;

                    // If it fits first time, break
                    if (size === maxSize && !tooBig) {
                        break;
                    }

                    if (tooBig) {
                        maxSize = size - 1;
                    } else {
                        minSize = size;
                    }

                    // Try new size halfway between what we know fits, and what we know doesn't
                    size = Math.ceil((maxSize - minSize) / 2) + minSize;
                    this.tagStyles["default"].fontSize = size;

                    super.update(false);
                }
            }
        }
    }

    // @ts-ignore
    get x() {
        throw new Error("Do not change y manually, please use landscape.y or portrait.y");
    }

    set x(value: number) {
        throw new Error("Do not change x manually, please use landscape.x or portrait.x");
    }

    // @ts-ignore
    get y() {
        throw new Error("Do not change y manually, please use landscape.y or portrait.y");
    }

    set y(value: number) {
        throw new Error("Do not change y manually, please use landscape.y or portrait.y");
    }

    // @ts-ignore
    get width() {
        throw new Error("Do not read width manually, please use landscape.width or portrait.width");
    }

    set width(value: number) {
        throw new Error("Do not change width manually, please use landscape.width or portrait.width");
    }

    // @ts-ignore
    get height() {
        throw new Error("Do not read height manually, please use landscape.height or portrait.height");
    }

    set height(value: number) {
        throw new Error("Do not change height manually, please use landscape.height or portrait.height");
    }

    private getOrientationPosition() {
        return RenderOrientation.isLandscape() ? this.landscape : this.portrait;
    }
}
