import { BevelFilter } from "@pixi/filter-bevel";
import { GlowFilter } from "@pixi/filter-glow";
import { OutlineFilter } from "@pixi/filter-outline";
import { SceneConfig, TextConfig } from "appworks/config/asset-config-schema";
import { ButtonState } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { BitmapText } from "appworks/graphics/pixi/bitmap-text";
import { HTMLText, HTMLTextVAlign } from "appworks/graphics/pixi/html-text";
import { Text } from "appworks/graphics/pixi/text";
import { TextPosition } from "appworks/graphics/pixi/text-position";
import { Model } from "appworks/model/model";
import { Services } from "appworks/services/services";
import { FontSubstitute, TranslationsService } from "appworks/services/translations/translations-service";
import { contains } from "appworks/utils/collection-utils";
import { Filter, LINE_JOIN, TextStyle } from "pixi.js";
import { Scene } from "../scene";

// TODO: rename file to match class name
export class TextPopulator {
    public static autoSetPadding: boolean = false;
    public static safetyPadding: number = 0;

    // Map of safety paddings to add for any text field with a certain font
    public static fontPaddings: Map<string, number> = new Map();

    private fontFallbacks: Map<string, string> = new Map();
    private defaultFallback: string;

    // Add websafe fonts here as you need them
    // https://www.w3.org/Style/Examples/007/fonts.en.html
    private websafeFonts: Map<string, string> = new Map([
        ["arialmt-regular", "arial"]
    ] as any);

    /**
     * List fallbacks for each font (lower case, no spaces), and/or a default which all fonts will fall back to as a last resort
     * @param fontFallbacks Map<string, string>
     * @param defaultFallback string
     */
    public setFontFallbacks(fontFallbacks: Map<string, string>, defaultFallback?: string) {
        this.fontFallbacks = fontFallbacks;
        this.defaultFallback = defaultFallback;
    }

    // TODO: Reduce cyclomatic complexity
    // tslint:disable-next-line:cyclomatic-complexity
    public populateText(scene: Scene, layer: Layers) {
        const layerScene = scene.name === "default" ? layer.id : `${layer.id}-${scene.name}`;
        const sprites = scene.config.sprites;
        const texts = scene.config.text;

        // Populate text as designed in asset works
        if (texts) {
            for (const id in texts) {
                if (texts.hasOwnProperty(id)) {

                    let textConfig = texts[id].landscape;
                    if (!textConfig || !textConfig.content) {
                        textConfig = texts[id].portrait;
                    }
                    const idParts = id.split("-");

                    // Skip if this text is baked for current language
                    if (idParts[0] === "i18n") {
                        const i18nSpriteName = `${layerScene}/${id}-${Services.get(TranslationsService).languageCode.slice(0, 2)}`;
                        if (sprites[i18nSpriteName]) {
                            continue;
                        }
                    }

                    let text: BitmapText | Text | HTMLText;

                    if (textConfig.fontVariant === "bmp") {
                        let content = textConfig.content;
                        const i18nRegex = /\[(.*?)\]/g;

                        if (i18nRegex.test(content)) {
                            content = content.replace(/\u0003/g, "\n").replace(i18nRegex, (codeFull, code) => {
                                const translation = Services.get(TranslationsService).get(code);
                                if (translation) {
                                    return translation;
                                }
                                return codeFull;
                            });
                        }

                        // Bitmap font
                        text = new BitmapText(content, {
                            fontSize: textConfig.size,
                            fontName: textConfig.fontFamily,
                            align: textConfig.align
                        });

                    } else {
                        // Substitute fonts
                        let fontFamily = textConfig.fontFamily + "-" + textConfig.fontVariant;
                        let fontWeight = "normal";

                        const fontSubstitutes = Services.get(TranslationsService).getFontSubstitutes();
                        if (fontSubstitutes.has(fontFamily)) {
                            const substitutes: FontSubstitute[] = fontSubstitutes.get(fontFamily);
                            substitutes.forEach((substitute: FontSubstitute, index: number) => {
                                const language = Model.read().settings.language;
                                if (!substitute.targetInstances || (substitute.targetInstances && contains(substitute.targetInstances, id))) {
                                    if (substitute.includedLanguages && substitute.includedLanguages.some((lang) => language.includes(lang))) {
                                        fontFamily = substitute.font;
                                    }
                                    if (substitute.excludedLanguages && !substitute.excludedLanguages.some((lang) => language.includes(lang))) {
                                        fontFamily = substitute.font;
                                    }
                                    if (!substitute.excludedLanguages && !substitute.includedLanguages) {
                                        fontFamily = substitute.font;
                                    }

                                    fontWeight = substitute.weight ?? fontWeight;
                                }
                            });
                        }

                        // List fonts and fallbacks
                        const fonts = [fontFamily];
                        if (this.fontFallbacks.has(fontFamily)) {
                            fonts.push(this.fontFallbacks.get(fontFamily));
                        }
                        if (this.defaultFallback) {
                            fonts.push(this.defaultFallback);
                        }
                        if (this.websafeFonts.has(fontFamily)) {
                            fonts.push(this.websafeFonts.get(fontFamily));
                        }

                        // Regular text
                        const style = new TextStyle({
                            fontFamily: fonts,
                            fontWeight,
                            fontSize: textConfig.size,
                            fill: textConfig.color,
                            wordWrap: true,
                            wordWrapWidth: textConfig.width,
                            breakWords: Services.get(TranslationsService).breakWords,
                            trim: false,
                            align: textConfig.align
                        });

                        // Tracking / Kerning / Letter Spacing
                        if (textConfig.letterSpacing) {
                            style.letterSpacing = textConfig.letterSpacing;
                        }

                        style.padding = TextPopulator.safetyPadding;

                        // Pad specific fonts
                        if (TextPopulator.fontPaddings && fonts.find((font) => TextPopulator.fontPaddings.has(font))) {
                            const font = fonts.find((font) => TextPopulator.fontPaddings.has(font));
                            style.padding = TextPopulator.fontPaddings.get(font);
                        }

                        // Stroke
                        if (textConfig.strokeThickness) {
                            style.stroke = textConfig.stroke;
                            style.strokeThickness = textConfig.strokeThickness * 2;
                            style.lineJoin = LINE_JOIN.ROUND.toString();
                        }

                        // Gradient fill
                        if (textConfig.fill) {
                            style.fill = textConfig.fill;
                            style.fillGradientStops = textConfig.fillGradientStops;
                            style.fillGradientType = textConfig.fillGradientType;
                        }

                        // Drop shadow
                        if (textConfig.dropShadowBlur !== undefined) {
                            style.dropShadow = true;
                            style.dropShadowAlpha = textConfig.dropShadowAlpha;
                            style.dropShadowAngle = (180 - textConfig.dropShadowAngle) * Math.PI / 180;
                            style.dropShadowBlur = textConfig.dropShadowBlur / 2;
                            style.dropShadowColor = textConfig.dropShadowColor;
                            style.dropShadowDistance = 1 + textConfig.dropShadowDistance;

                            if (TextPopulator.autoSetPadding) {
                                style.padding = (textConfig.dropShadowBlur + textConfig.dropShadowDistance) * 0.5;
                            }
                        }

                        // Content and localisation
                        let content = textConfig.content;

                        const i18nRegex = /\[(.*?)\]/g;

                        if (i18nRegex.test(content)) {
                            content = content.replace(/\u0003/g, "\n").replace(i18nRegex, (codeFull, code) => {
                                const translation = this.processTranslation(code);
                                if (translation) {
                                    return translation;
                                }
                                return codeFull;
                            });
                        }

                        if (content.indexOf("#") > -1) {
                            content = "";
                        }

                        const filters: Filter[] = [];

                        if (textConfig.innerShadow) {
                            const innerShadow = textConfig.innerShadow;
                            const color = this.colourStringToHex(innerShadow.color);
                            const bevel = new BevelFilter({
                                rotation: innerShadow.rotation,
                                thickness: innerShadow.thickness * 2,
                                lightColor: 0xfffff,
                                lightAlpha: 0,
                                shadowColor: color,
                                shadowAlpha: innerShadow.alpha
                            });
                            filters.push(bevel);
                        }

                        if (textConfig.outerGlow) {
                            const outerGlow = textConfig.outerGlow;
                            const glow = new GlowFilter({
                                distance: outerGlow.blur * 0.5,
                                outerStrength: Math.max(1,
                                    outerGlow.spread),
                                innerStrength: 0,
                                color: this.colourStringToHex(outerGlow.color),
                                quality: 1
                            });
                            filters.push(glow);
                        }

                        if (filters.length > 0 && textConfig.strokeThickness > 0) {
                            style.stroke = 0;
                            style.strokeThickness = 0;

                            const colour = this.colourStringToHex(textConfig.stroke);
                            const stroke = new OutlineFilter(textConfig.strokeThickness * 2, colour);

                            // Luke W recently changed filter ordering to keep strokes inside any glows or shadows being applied.
                            filters.unshift(stroke);
                        }

                        if (idParts[0] === "html") {
                            // Passing in a style object to be cloned breaks HTMLText, so manually copy the "private" params
                            const htmlCompatibleStyleObj = {};
                            for (const styleProp in style) {
                                if (styleProp.indexOf("_") > -1) {
                                    htmlCompatibleStyleObj[styleProp.replace("_", "")] = style[styleProp];
                                }
                            }

                            let valign = HTMLTextVAlign.MIDDLE;
                            if (idParts[2]) {
                                if (idParts[2] === "middle") {
                                    valign = HTMLTextVAlign.MIDDLE;
                                } else if (idParts[2] === "top") {
                                    valign = HTMLTextVAlign.TOP;
                                } else if (idParts[2] === "bottom") {
                                    valign = HTMLTextVAlign.BOTTOM;
                                }
                            }
                            text = new HTMLText(content, htmlCompatibleStyleObj as any, null, null, valign)
                        } else {
                            text = new Text(content, style);
                        }

                        text.filters = filters;
                    }

                    text.name = idParts.length > 1 ? idParts[1] : id;

                    text.landscape = this.setPosition(scene.config, text, text.landscape, texts[id].landscape);
                    text.portrait = this.setPosition(scene.config, text, text.portrait, texts[id].portrait);

                    // If only one orientation has a specified font size, use that for both
                    if (text.landscape && !text.landscape.fontSize) {
                        text.landscape.fontSize = text.portrait.fontSize;
                    }
                    if (text.portrait && !text.portrait.fontSize) {
                        text.portrait.fontSize = text.landscape.fontSize;
                    }

                    // If only one orientation has a specified alignment, use that for both
                    if (text.landscape && !text.landscape.align) {
                        text.landscape.align = text.portrait.align;
                    }
                    if (text.portrait && !text.portrait.align) {
                        text.portrait.align = text.landscape.align;
                    }

                    // Convert i18n text to a sprite
                    if (idParts[0] === "lbl") {
                        this.processLabel(layer, text, idParts[1], idParts[2]);
                    } else if (idParts[0] === "tgl") {
                        this.processLabel(layer, text, idParts[1], idParts[3], idParts[2]);
                    } else {
                        layer.add(text, false, false, text.name);
                    }

                    if (text instanceof HTMLText) {
                        text.forceAutoResize();
                    }

                }
            }
        }
    }

    private setPosition(sceneConfig: SceneConfig, target: any, position: TextPosition, textConfig: TextConfig): TextPosition {
        if (!textConfig) {
            return TextPosition.unavailable();
        }

        position.x = textConfig.x;
        position.y = textConfig.y;
        position.width = textConfig.width;
        position.height = textConfig.height;
        position.fontSize = textConfig.size;
        position.align = textConfig.align;

        return position;
    }

    private processTranslation(translationID: string): string {
        let translation = Services.get(TranslationsService).get(translationID);

        const pTagsToStrip = /(?:<p>)|(?:<\/p>(?!.|\s*<\/p>))/gim;
        const pTagsToReplace = /<\/p>/gi;

        translation = translation.replace(pTagsToStrip, "");
        translation = translation.replace(pTagsToReplace, "\n\n");

        return translation;
    }

    private processLabel(layer: Layers, label: BitmapText | Text | HTMLText, buttonName: string, state: string, toggleState?: string) {
        const button = layer.getButton(buttonName) ?? layer.getToggle(buttonName)?.getButtonElement(toggleState);
        if (button) {
            button.addLabelForState(label, ButtonState.FromString(state));
        }
    }

    private colourStringToHex(color: string): number {
        return parseInt("0x" + color.substr(1));
    }

}

export const textPopulator = new TextPopulator();
