import { PopScatterResult } from "model/pop-scatter-result";
import { SlingoReelSpinResultBuilder } from "slingo/integration/builders/results/slingo-reel-spin-result-builder";
import { SlingoResponse } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";

export class PopScatterResultBuilder extends SlingoReelSpinResultBuilder {

    // I know this is horrible, but the server is completely unfit for purpose; the scatter win is reported on every wild pick,
    // so there is no way of telling based on a server response when a scatter has already been awarded or not, so we have to keep track of it client side
    public static scatterWinsAdded = 0;

    public build(record: SlingoRecord, data: SlingoResponse): SlingoReelSpinResult[] {
        const results = [];

        while (data?.spinResult?.symbolWins?.length > PopScatterResultBuilder.scatterWinsAdded) {
            PopScatterResultBuilder.scatterWinsAdded++;
            const symbolWin = data.spinResult.symbolWins[data.spinResult.symbolWins.length - PopScatterResultBuilder.scatterWinsAdded];

            const result = new PopScatterResult();

            result.cashWon = symbolWin.amount * 100;
            result.matches = symbolWin.numberOfSymbols;

            results.push(result);
        }

        return results;
    }
}
