import { Layers } from "appworks/graphics/layers/layers";
import { SoundToggleControl } from "appworks/ui/controls/standard/toggle/sound-toggle-control";
import { ControlFactory } from "../control-factory";

export class SoundToggleControlFactory implements ControlFactory {
    public filter(layer: Layers) {
        if (layer.getFlexiButton("sound_toggle_on") &&
            layer.getFlexiButton("sound_toggle_off")) {
            return true;
        }

        return false;
    }

    public build(layer: Layers) {
        return [new SoundToggleControl(
            layer.getFlexiButton("sound_toggle_on"),
            layer.getFlexiButton("sound_toggle_off"),
            layer.getFlexiSprite("sound_toggle_loading"),
            layer.getFlexiButton("sound_music_off")
        )];
    }
}
