import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { State } from "appworks/state-machine/states/state";
import { Contract } from "appworks/utils/contracts/contract";
import { SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";

export class SlingoFullHouseState extends State {
    public onEnter(cascadeSkip?: boolean): void {
        if (!this.isFullHouse()) { return this.complete(); }
        this.showFullHouseAnimation().then(() => this.complete());
    }

    protected showFullHouseAnimation(): Contract {
        return Components.get(SlingoTicketMatrixComponent).fullHouseAnim();
    }

    protected isFullHouse(): boolean {
        const level = gameState.getCurrentGame().getLatestResultOfType(SlingoLadderResult).total;
        return level === 12;
    }
}