export class Point {
    public static from(source: { x: number, y: number }): Point {
        return new Point(source.x, source.y);
    }

    public x: number;
    public y: number;

    constructor(x = 0, y?: number) {
        this.x = x;
        this.y = (typeof y === "number") ? y : x;
    }

    public set(x: number, y?: number) {
        this.x = x;
        this.y = (typeof y === "number") ? y : x;
    }

    public equals(point: Point) {
        return this.x === point.x && this.y === point.y;
    }

    public clone(): Point {
        return new Point(this.x, this.y);
    }

    public toString(): string {
        return this.x.toString() + "," + this.y.toString();
    }

    public distanceTo(point: Point): number {
        return Math.sqrt(
            Math.pow(this.x - point.x, 2) +
            Math.pow(this.y - point.y, 2)
        );
    }

    public add(point: Point): Point {
        return new Point(this.x + point.x, this.y + point.y);
    }

    public subtract(point: Point): Point {
        return new Point(this.x - point.x, this.y - point.y);
    }

    public multiply(n: number): Point {
        return new Point(this.x * n, this.y * n);
    }

    public divide(n: number): Point {
        return new Point(this.x / n, this.y / n);
    }

    public normalize() {
        const magnitude = this.getMagnitude();
        return new Point(this.x / magnitude, this.y / magnitude);
    }

    public getMagnitude(): number {
        return Math.sqrt((this.x * this.x) + (this.y * this.y));
    }
}
