import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { stateMachine } from "appworks/state-machine/state-machine";
import { AbstractControl } from "../../abstract-control";

export class SkipAreaControl extends AbstractControl {
    constructor(protected area: SmartShape) {
        super();

        this.area.renderable = false;
        this.area.interactive = true;
        this.area.addListener(ButtonEvent.CLICK.getPIXIEventString() as any, () => {
            stateMachine.skip();
        });
    }

    public setEnabled(enabled: boolean): void {
        super.setEnabled(enabled);

        this.area.interactive = enabled;
    }
}
