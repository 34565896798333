import { gameLoop } from "appworks/core/game-loop";
import { Model } from "appworks/model/model";
import { commsManager } from "appworks/server/comms-manager";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { stateMachine } from "appworks/state-machine/state-machine";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Timer } from "appworks/utils/timer";
import { DebugTab } from "slotworks/debug/menu/tabs/debug-tab";
import { debugSpinTimer } from "slotworks/debug/spin-timer";
import { slotModel } from "slotworks/model/slot-model";
import { AutoplayService } from "slotworks/services/autoplay/autoplay-service";

export class UtilTab extends DebugTab {
    private static crashTimer?: number;

    protected root: HTMLElement;
    protected hide: Function;

    public render(root: HTMLElement, hide: Function): void {
        this.root = root;
        this.hide = hide;
        this.addFramerateDebugger();
        this.addSpeedDebugger();
        this.addNetworkDelay();
        this.addFPSToggle();
        this.addMusicToggle();
        this.addAutoTurbo();
        this.addSpinTimer();
        this.addCrash();
    }

    // Add a custom button which calls a method when pressed
    public addCustomButton(label: string, method: Function) {
        const inputBtn = document.createElement("button");

        inputBtn.style.fontSize = "3vw";
        inputBtn.style.margin = "1vmax 1vw";
        inputBtn.style.borderRadius = "10px";
        inputBtn.style.width = "72vw";
        inputBtn.style.height = "10vmax";
        inputBtn.style.backgroundColor = "#ffffff";
        inputBtn.innerText = label;
        inputBtn.style.outline = "none";
        inputBtn.style.cursor = "pointer";

        inputBtn.onclick = () => {
            method();
            this.hide();
        };
        this.root.appendChild(inputBtn);
    }

    private addFramerateDebugger() {
        const inputText = document.createElement("input");
        inputText.type = "text";
        inputText.style.fontSize = "3vw";
        inputText.style.margin = "1vmax 1vw";
        inputText.style.borderRadius = "10px";
        inputText.style.width = "30vw";
        inputText.style.height = "10vmax";
        inputText.style.backgroundColor = "#ffffff";

        const inputBtn = document.createElement("button");

        inputBtn.style.fontSize = "3vw";
        inputBtn.style.margin = "1vmax 1vw";
        inputBtn.style.borderRadius = "10px";
        inputBtn.style.width = "30vw";
        inputBtn.style.height = "10vmax";
        inputBtn.style.backgroundColor = "#ffffff";
        inputBtn.innerText = "SET FPS";
        inputBtn.style.outline = "none";
        inputBtn.style.cursor = "pointer";

        inputBtn.onclick = () => {
            gameLoop.fakeFps = parseInt(inputText.value);
            this.hide();
        };

        inputText.value = gameLoop.fakeFps.toString();

        this.root.appendChild(inputText);
        this.root.appendChild(inputBtn);
    }

    private addSpeedDebugger() {
        const inputText = document.createElement("input");
        inputText.type = "text";
        inputText.style.fontSize = "3vw";
        inputText.style.margin = "1vmax 1vw";
        inputText.style.borderRadius = "10px";
        inputText.style.width = "30vw";
        inputText.style.height = "10vmax";
        inputText.style.backgroundColor = "#ffffff";

        const inputBtn = document.createElement("button");

        inputBtn.style.fontSize = "3vw";
        inputBtn.style.margin = "1vmax 1vw";
        inputBtn.style.borderRadius = "10px";
        inputBtn.style.width = "30vw";
        inputBtn.style.height = "10vmax";
        inputBtn.style.backgroundColor = "#ffffff";
        inputBtn.innerText = "SET SPEED";
        inputBtn.style.outline = "none";
        inputBtn.style.cursor = "pointer";

        inputBtn.onclick = () => {
            gameLoop.setDebugSpeed(parseFloat(inputText.value));
            this.hide();
        };

        inputText.value = gameLoop.getSpeed().toString();

        this.root.appendChild(inputText);
        this.root.appendChild(inputBtn);
    }

    private addNetworkDelay() {
        const inputText = document.createElement("input");
        inputText.type = "text";
        inputText.style.fontSize = "3vw";
        inputText.style.margin = "1vmax 1vw";
        inputText.style.borderRadius = "10px";
        inputText.style.width = "30vw";
        inputText.style.height = "10vmax";
        inputText.style.backgroundColor = "#ffffff";
        inputText.value = commsManager.getDelay().toString();

        const inputBtn = document.createElement("button");
        inputBtn.style.fontSize = "3vw";
        inputBtn.style.margin = "1vmax 1vw";
        inputBtn.style.borderRadius = "10px";
        inputBtn.style.width = "30vw";
        inputBtn.style.height = "10vmax";
        inputBtn.style.backgroundColor = "#ffffff";
        inputBtn.style.outline = "none";
        inputBtn.style.cursor = "pointer";
        inputBtn.innerText = "SET NETWORK DELAY";

        inputBtn.onclick = () => {
            commsManager.setDelay(parseInt(inputText.value));
            this.hide();
        };

        this.root.appendChild(inputText);
        this.root.appendChild(inputBtn);
    }

    private addFPSToggle() {
        const inputBtn = document.createElement("button");

        inputBtn.style.fontSize = "3vw";
        inputBtn.style.margin = "1vmax 1vw";
        inputBtn.style.borderRadius = "10px";
        inputBtn.style.width = "72vw";
        inputBtn.style.height = "10vmax";
        inputBtn.style.backgroundColor = "#ffffff";
        inputBtn.innerText = "TOGGLE FPS";
        inputBtn.style.outline = "none";
        inputBtn.style.cursor = "pointer";

        inputBtn.onclick = () => {
            gameLoop.toggleStats();
        };
        this.root.appendChild(inputBtn);
    }

    private addAutoTurbo() {
        const inputBtn = document.createElement("button");

        inputBtn.style.fontSize = "3vw";
        inputBtn.style.margin = "1vmax 1vw";
        inputBtn.style.borderRadius = "10px";
        inputBtn.style.width = "72vw";
        inputBtn.style.height = "10vmax";
        inputBtn.style.backgroundColor = "#ffffff";
        inputBtn.innerText = "AUTO TURBO";
        inputBtn.style.outline = "none";
        inputBtn.style.cursor = "pointer";

        inputBtn.onclick = () => {

            const autoplayModel = slotModel.read().autoplay;
            autoplayModel.autoplays.currentValue = Number.POSITIVE_INFINITY;
            autoplayModel.lossLimit.currentValue = Number.POSITIVE_INFINITY;
            autoplayModel.singleWinLimit.currentValue = Number.POSITIVE_INFINITY;
            autoplayModel.stopOnFeatureWin = true;

            Services.get(AutoplayService).setAutoplays(Number.POSITIVE_INFINITY);
            Model.write({
                settings: {
                    quickSpin: false,
                    turboSpin: true
                }
            });
            slotModel.write({
                autoplay: {
                    lossLimit: autoplayModel.lossLimit,
                    singleWinLimit: autoplayModel.singleWinLimit,
                    stopOnFeatureWin: autoplayModel.stopOnFeatureWin
                }
            });

            uiFlags.set(UIFlag.AUTO_SPIN, true);

            Services.get(AutoplayService).start();

            stateMachine.skip();

            this.hide();
        };
        this.root.appendChild(inputBtn);
    }

    private addSpinTimer() {
        const inputBtn = document.createElement("button");

        inputBtn.style.fontSize = "3vw";
        inputBtn.style.margin = "1vmax 1vw";
        inputBtn.style.borderRadius = "10px";
        inputBtn.style.width = "72vw";
        inputBtn.style.height = "10vmax";
        inputBtn.style.backgroundColor = "#ffffff";
        inputBtn.innerText = "SPIN TIMER";
        inputBtn.style.outline = "none";
        inputBtn.style.cursor = "pointer";

        inputBtn.onclick = () => debugSpinTimer.toggle();
        this.root.appendChild(inputBtn);
    }

    private addCrash() {
        const inputBtn = document.createElement("button");

        inputBtn.style.fontSize = "3vw";
        inputBtn.style.margin = "1vmax 1vw";
        inputBtn.style.borderRadius = "10px";
        inputBtn.style.width = "72vw";
        inputBtn.style.height = "10vmax";
        inputBtn.style.backgroundColor = "#ffffff";
        inputBtn.innerText = "CRASH GAME (2s)";
        inputBtn.style.outline = "none";
        inputBtn.style.cursor = "pointer";

        inputBtn.onclick = () => {
            this.hide();

            if (UtilTab.crashTimer) {
                Timer.clearTimeout(UtilTab.crashTimer);
                UtilTab.crashTimer = undefined;
            }
            UtilTab.crashTimer = Timer.setTimeout(() => {
                UtilTab.crashTimer = undefined;
                throw new Error("Game has crashed due to debug tools.");
            }, 2000);
        };
        this.root.appendChild(inputBtn);
    }

    private addMusicToggle() {
        const inputBtn = document.createElement("button");

        inputBtn.style.fontSize = "3vw";
        inputBtn.style.margin = "1vmax 1vw";
        inputBtn.style.borderRadius = "10px";
        inputBtn.style.width = "72vw";
        inputBtn.style.height = "10vmax";
        inputBtn.style.backgroundColor = "#ffffff";
        inputBtn.innerText = "TOGGLE MUSIC";
        inputBtn.style.outline = "none";
        inputBtn.style.cursor = "pointer";

        inputBtn.onclick = () => {
            Services.get(SoundService).setMusicMute(!Services.get(SoundService).getMusicMuted());
        };
        this.root.appendChild(inputBtn);
    }
}
