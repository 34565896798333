import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Record } from "appworks/model/gameplay/records/record";
import { CloseRequestPayload } from "appworks/model/gameplay/requests/close-request-payload";
import { RequestPayload } from "appworks/model/gameplay/requests/request-payload";
import { Services } from "appworks/services/services";
import { clearElement } from "appworks/utils/dom-utils";
import { Signal } from "signals";
import { FreespinRequestPayload } from "slotworks/model/gameplay/requests/freespin-request-payload";
import { LockAndSpinRequestPayload } from "slotworks/model/gameplay/requests/lockandspin-request-payload";
import { PickRequestPayload } from "slotworks/model/gameplay/requests/pick-request-payload";
import { SpinRequestPayload } from "slotworks/model/gameplay/requests/spin-request-payload";
import { TumbleRequestPayload } from "slotworks/model/gameplay/requests/tumble-request-payload";

export class RequestButtons {

    public select: Signal = new Signal();

    private element: HTMLDivElement;

    constructor(customRequests: RequestButton[], useDefaultButtons: boolean) {
        this.draw();

        this.createRequestButtons(customRequests, useDefaultButtons);
    }

    public setEnabled(enabled: boolean) {
        if (enabled) {
            this.element.style.pointerEvents = "all";
            this.element.style.opacity = "1";
        } else {
            this.element.style.pointerEvents = "none";
            this.element.style.opacity = "0.2";
        }
    }

    public toggle() {
        if (this.element.parentElement) {
            Services.get(CanvasService).rootNode.removeChild(this.element);
        } else {
            Services.get(CanvasService).rootNode.appendChild(this.element);
        }
    }

    public updateButtonEnabled(req: RequestPayload, rec: Record, conditions: Map<string, { button: string, condition: (req: RequestPayload, rec: Record) => boolean }>): void {
        conditions.forEach((cond: { button: string, condition: (req: RequestPayload, rec: Record) => boolean }) => {
            const buttonId = `#reqBtn-${cond.button.replace(/\ /g, "")}`;
            const buttonElem: HTMLButtonElement = document.querySelector(buttonId);
            if (!buttonElem) {
                return;
            }
            if (cond.condition(req, rec)) {
                buttonElem.style.pointerEvents = "inherit";
                buttonElem.style.opacity = "1";
            } else {
                buttonElem.style.pointerEvents = "none";
                buttonElem.style.opacity = "0.2";
            }
        });
    }

    private draw() {
        this.element = document.createElement("div");
        this.element.id = "integrationDebugger_resultTree";

        this.element.style.position = "fixed";
        this.element.style.top = this.element.style.right = "0";
        this.element.style.zIndex = "900";
        this.element.style.width = "20vw";
        this.element.style.height = "100vh";
        this.element.style.paddingTop = "5vh";
        this.element.style.pointerEvents = "all";

        this.element.style.backgroundColor = "#222";
        this.element.style.border = "solid 1px white";

        this.element.style.color = "#fff";
        this.element.style.fontSize = "20px";

        Services.get(CanvasService).rootNode.appendChild(this.element);
    }

    private createRequestButtons(customRequests: RequestButton[], useDefaultButtons: boolean) {
        clearElement(this.element);

        let requests: RequestButton[] = [
            { label: "Spin", requestType: SpinRequestPayload },
            { label: "Free Spin", requestType: FreespinRequestPayload },
            { label: "Pick", requestType: PickRequestPayload, parameters: ["pick"] },
            { label: "Lock and Spin", requestType: LockAndSpinRequestPayload },
            { label: "End Round", requestType: CloseRequestPayload },
            { label: "Tumble", requestType: TumbleRequestPayload },
            { label: "Spin and End", requestType: SpinRequestPayload }
        ];

        if (useDefaultButtons) {
            requests = requests.concat(customRequests);
        } else {
            requests = customRequests;
        }

        requests.forEach((requestButton) => {
            const options = requestButton.parameters;
            const values = requestButton.values;
            const requestType = requestButton.requestType;
            const requestElement = document.createElement("button");

            requestElement.innerHTML = requestButton.label;

            requestElement.id = `reqBtn-${requestButton.label.replace(/\ /g, "")}`;
            requestElement.style.display = "block";
            requestElement.style.marginLeft = "2.5vw";
            requestElement.style.marginTop = "2.5vw";
            requestElement.style.width = "15vw";
            requestElement.style.height = "5vw";
            requestElement.style.backgroundColor = "#555";
            requestElement.style.color = "#fff";

            requestElement.onclick = () => {
                const requestPayload = new requestType();
                if (values) {
                    for (const key in values) {
                        if (values[key] !== undefined) {
                            (requestPayload as any)[key] = values[key];
                        }
                    }
                }
                if (options) {
                    options.forEach((option) => {
                        const optionChoice = prompt(`Enter value for '${option}'`);
                        if (optionChoice === "false") {
                            (requestPayload as any)[option] = false;
                        } else if (optionChoice === "true") {
                            (requestPayload as any)[option] = true;
                        } else if (!isNaN(parseFloat(optionChoice))) {
                            (requestPayload as any)[option] = parseFloat(optionChoice);
                        } else {
                            (requestPayload as any)[option] = optionChoice;
                        }
                    });
                }
                this.select.dispatch(requestPayload, requestButton);
            };

            this.element.appendChild(requestElement);
        });
    }
}

export interface RequestButton {
    label: string;
    requestType: new (...args: any[]) => RequestPayload;
    parameters?: string[];
    values?: { [key: string]: any };
}
