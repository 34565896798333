import { AbstractComponent } from "appworks/components/abstract-component";
import { Layers } from "appworks/graphics/layers/layers";
import { Result } from "appworks/model/gameplay/records/results/result";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Point } from "appworks/utils/geom/point";
import { modulo } from "appworks/utils/math/modulo";
import { Filter } from "pixi.js";
import { Signal } from "signals";
import { AnimateSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/animate-symbol-behaviour";
import { BaseAnimateSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/base-animate-symbol-behaviour";
import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { ReelSubcomponent } from "./reel/reel-subcomponent";
import { AbstractReelTransition } from "./reel/transition-behaviours/abstract-reel-transition";
import { AbstractSymbolBehaviour } from "./symbol/behaviours/abstract-symbol-behaviour";
import { SymbolComponentType, SymbolSubcomponent } from "./symbol/symbol-subcomponent";

export class AbstractMatrixComponent<T extends SymbolSubcomponent = SymbolSubcomponent> extends AbstractComponent {
    public startSignal: Signal;
    public stopSignal: Signal;
    public landSignal: Signal;
    public skipSignal: Signal;
    public onAnticipationStart: Signal;
    public onAnticipationReel: Signal;
    public onAnticipationEnd: Signal;

    public zSortEnabled: boolean;
    public zSortLeftToRight: boolean;
    public zSortTopToBottom: boolean;
    public anticipationLayer: Layers;
    public stickyLayer: Layers;
    public lockedReels: number[][];
    public readonly matrixGrid: number[];

    constructor(
        grid: number[],
        stops: number[],
        reelset: string[][],
        matrixLayer?: Layers,
        animationLayer?: Layers,
        public readonly symbolType: SymbolComponentType<T> = SymbolSubcomponent as any
    ) {
        super();

        this.matrixGrid = grid;
    }

    public addDefaultSymbolBehaviour(behaviourGenerator: (symbol: SymbolSubcomponent) => AbstractSymbolBehaviour, validSymbols?: string[], invalidSymbols?: string[]): void {
        //
    }

    public reset(): void {
        //
    }
    public flush(): void {
        //
    }

    public getTransition(): AbstractReelTransition {
        throw new Error("Not implemented.");
    }
    public setTransition(reelTransition: AbstractReelTransition): void {
        //
    }
    public startTransition(jumpStart: boolean = false, quickSpin: boolean = false): Contract<void> {
        throw new Error("Not implemented.");
    }
    public stopTransition(spinRecord: SpinRecord, quickSpin: boolean): Contract<void> {
        throw new Error("Not implemented.");
    }
    public skipTransition(spinRecord: SpinRecord): void {
        //
    }

    public jumpToGrid(grid: string[][]): void {
        //
    }
    public jump(stops: number[], reelset: string[][]): void {
        //
    }

    public getReels(): ReelSubcomponent[] {
        throw new Error("Not implemented.");
    }
    public getSymbol(x: number, y: number): T {
        throw new Error("Not implemented.");
    }

    public getSymbolsFromPositions(positions: Point[]): T[] {
        throw new Error("Not implemented.");
    }
    public getAllSymbols(): T[] {
        throw new Error("Not implemented.");
    }
    public getGridSymbols(): T[] {
        throw new Error("Not implemented.");
    }
    public getHiddenSymbols(): T[] {
        throw new Error("Not implemented.");
    }
    public getBaseGridSymbols(): T[] {
        throw new Error("Not implemented.");
    }

    public resetSymbolPositions(): void {
        //
    }

    public addHiddenSymbol(symbol: T): void {
        //
    }

    public resetSymbols(): void {
        //
    }

    public addStuckSymbol(symbol: T): T {
        throw new Error("Not implemented.");
    }
    public addStuckSymbolById(gridPosition: Point, symbolId: string): T {
        throw new Error("Not implemented.");
    }
    public getStuckSymbol(gridPosition: Point): T {
        throw new Error("Not implemented.");
    }
    public getStuckSymbols(): Map<string, T> {
        throw new Error("Not implemented.");
    }
    public removeStuckSymbol(gridPosition: Point): void {
        //
    }
    public clearStuckSymbols(replaceBase: boolean = false): void {
        //
    }

    public hold(reelIndexes?: number[]): void {
        //
    }
    public change(scene: string, grid: number[], stops?: number[], reelset?: string[][]): void {
        //
    }

    public setCustomReelset(reelset: string[][]): void {
        //
    }

    public resetReelsetToDefault(): void {
        //
    }

    public getDefaultReelset(): string[][] {
        throw new Error("Not implemented.");
    }

    public getCurrentReelset(): string[][] {
        throw new Error("Not implemented.");
    }

    public showWinningSymbols(symbols: T[], result: SymbolWinResult, cycling: boolean = false) {
        const winningSymbolWinMethods: Array<() => Contract<void>> = [];

        for (const symbol of symbols) {
            winningSymbolWinMethods.push(() => cycling ? symbol.cycle(result) : symbol.win(result));
        }

        return new Contract<void>((resolve) => {
            new Parallel(winningSymbolWinMethods).then(() => {
                resolve(null);
            });
        });
    }

    public setAllSymbolEffects(tint: number, filters: Filter[], behaviourNames: string[]) {
        const symbols = this.getAllSymbols();
        this.setSymbolEffects(symbols, tint, filters, behaviourNames);
    }

    public setSymbolEffects(symbols: T[], color: number, filters: Filter[], behaviourNames: string[]) {
        symbols.forEach((symbol) => {
            behaviourNames.forEach((behaviourName) => {
                const behaviour = symbol.getBehaviourByName<BaseAnimateSymbolBehaviour>(behaviourName);

                if (behaviour) {
                    if (filters.length) {
                        behaviour.filters = filters;
                    } else {
                        behaviour.tintColor = color;
                    }
                }
            });
        });
    }

    public setContinuousAnimate(symbols: T[], value: boolean) {
        symbols.forEach((symbol) => {
            const behaviour = symbol.getBehaviourByName<AnimateSymbolBehaviour>("Animate");
            if (behaviour) {
                behaviour.continuous = value;
            }
        });
    }

    public highlightSymbols(symbols: T[], result?: SymbolWinResult, autoReset?: boolean): Contract<void> {
        const winningSymbolHighlightMethods = symbols.map((symbol) => this.highlightSymbol(symbol, result, autoReset));

        return new Parallel(winningSymbolHighlightMethods);
    }

    public highlightSymbol(symbol: T, result?: SymbolWinResult, autoReset?: boolean) {
        symbol.static();
        return () => new Contract((resolve) => {
            symbol.highlight(result).then(() => {
                if (autoReset) {
                    symbol.static();
                }
                resolve();
            });
        });
    }

    public getWinningSymbols(results: Result[]): T[] {
        let winningPositions: Point[] = [];

        for (const win of results) {
            if (win instanceof SymbolWinResult) {
                winningPositions = winningPositions.concat(win.positions);
            }
        }

        const winningSymbols = [];

        for (const position of winningPositions) {
            const symbol = this.getSymbol(position.x, position.y);
            winningSymbols.push(symbol);
        }

        return winningSymbols;
    }

    public dimSymbols(symbols: T[], type: DimType, result?: SymbolWinResult): Contract<void> {
        const dimSymbolMethods: Array<() => Contract<void>> = [];

        for (const symbol of symbols) {
            dimSymbolMethods.push(this.getDimAction(symbol, type, result));
        }

        return new Parallel(dimSymbolMethods);
    }

    // TODO: I think this isn't an ideal place for this to live (it's been moved here from a command)
    public playSymbolSound(symbolType: string, matches: string, currentCycle: string) {
        const cycleInt = parseInt(currentCycle);
        const mod2Cycle = modulo(cycleInt, 2).toString();
        const mod3Cycle = modulo(cycleInt, 3).toString();

        Services.get(SoundService).event(SoundEvent.symbol_ID_win, symbolType);
        Services.get(SoundService).event(SoundEvent.symbol_ID_win_CYCLE, symbolType, currentCycle);
        Services.get(SoundService).event(SoundEvent.symbol_ID_win_mod2_CYCLE, symbolType, mod2Cycle);
        Services.get(SoundService).event(SoundEvent.symbol_ID_win_mod3_CYCLE, symbolType, mod3Cycle);
        Services.get(SoundService).event(SoundEvent.symbol_ID_MATCHES_win, symbolType, matches);
        Services.get(SoundService).event(SoundEvent.symbol_ID_win_MATCHES_CYCLE, symbolType, matches, currentCycle);
        Services.get(SoundService).event(SoundEvent.symbol_ID_win_MATCHES_mod2_CYCLE, symbolType, matches, mod2Cycle);
        Services.get(SoundService).event(SoundEvent.symbol_ID_win_MATCHES_mod3_CYCLE, symbolType, matches, mod3Cycle);
        Services.get(SoundService).event(SoundEvent.symbol_any_win);
        Services.get(SoundService).event(SoundEvent.symbol_any_win_CYCLE, currentCycle);
        Services.get(SoundService).event(SoundEvent.symbol_any_win_mod2_CYCLE, mod2Cycle);
        Services.get(SoundService).event(SoundEvent.symbol_any_win_mod3_CYCLE, mod3Cycle);
        Services.get(SoundService).event(SoundEvent.symbol_any_MATCHES_win, matches);
        Services.get(SoundService).event(SoundEvent.symbol_any_win_MATCHES_CYCLE, matches, currentCycle);
        Services.get(SoundService).event(SoundEvent.symbol_any_win_MATCHES_mod2_CYCLE, matches, mod2Cycle);
        Services.get(SoundService).event(SoundEvent.symbol_any_win_MATCHES_mod3_CYCLE, matches, mod3Cycle);
    }

    public destroy(): void {
        //
    }

    protected getDimAction(symbol: T, type: DimType, result?: SymbolWinResult) {
        switch (type) {
            case DimType.Highlight:
                return () => symbol.highlightDim(result);
            case DimType.Win:
                return () => symbol.winDim(result);
            case DimType.Cycle:
                return () => symbol.cycleDim(result);
            default:
                throw new Error(`Invalid symbol dim type requested: "${DimType}"`);
        }
    }
}

export enum DimType {
    Highlight,
    Win,
    Cycle
}
