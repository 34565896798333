import { Components } from "appworks/components/components";
import { PromptComponent } from "appworks/components/prompt/prompt-component";
import { PromptContentSubcomponent } from "appworks/components/prompt/prompt-content-subcomponent";
import { Layers } from "appworks/graphics/layers/layers";
import { gameState } from "appworks/model/game-state";
import { InitRequestPayload } from "appworks/model/gameplay/requests/init-request-payload";
import { commsManager } from "appworks/server/comms-manager";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { fadeOut } from "appworks/utils/animation/fade";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { SlingoCoinHopperComponent } from "slingo/components/slingo-coin-hopper-component";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoCompletionReason, SlingoSpinType } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { slingoModel } from "slingo/model/slingo-model";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";
import { BigWinComponent } from "slotworks/components/bigwin/big-win-component";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { BonusResult } from "slotworks/model/gameplay/records/results/bonus-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";

export class SlingoGameOverState extends State {
    public onEnter(cascadeSkip?: boolean): void {
        const summaryContract = (gameState.getCurrentGame().getTotalWin() > 0) ? () => this.showWinSummary() : () => this.showNoWinSummary();

        this.playSounds();

        new Sequence([
            summaryContract,
            () => Contract.wrap(() => uiFlags.set(UIFlag.GAME_IN_PROGRESS, false)),
            () => this.sendInitRequest()
        ]).then(() => this.complete());
    }

    public onExit(): void {
        Components.get(SlingoSpinsCounterComponent).setType(SlingoSpinType.STANDARD).execute();
        Components.get(SlingoSpinsCounterComponent).setValue(slingoModel.read().gameConfig.standardSpins).execute();

        Components.get(SlingoCoinHopperComponent)?.set(slingoModel.read().gameConfig.standardSpins, 0, 0);
        Components.get(SlingoLadderComponent).stepToLevel(0, 0).execute();

        slingoModel.write({ gameInstanceId: null });

        // They want the value in game to hide, but the value in the wrapper to remain, which means I can't actually
        // reset the winnings value in TransactionService here - so I'm just hiding the text
        const totalWinText = Layers.get("BetBar").getText("total_win_value");
        if (totalWinText) { fadeOut(totalWinText, 200).execute(); }
    }
    protected sendInitRequest(): Contract<void> {
        gameState.newGame();

        return new Sequence([
            () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, true)),
            () => commsManager.request(new InitRequestPayload()),
            () => Contract.wrap(() => {
                uiFlags.set(UIFlag.AWAITING_RESPONSE, false);

                const gameplay = gameState.getCurrentGame();
                gameplay.setToLatestRecord();
                const record = gameplay.getLatestRecord() as SlingoRecord;

                Components.get(SlingoTicketMatrixComponent).setGrid(record.ticketGrid);
                Components.get(MatrixComponent).jumpToGrid([["blank"], ["blank"], ["blank"], ["blank"], ["blank"]]);
            })
        ]);
    }

    protected showNoWinSummary(): Contract {
        Services.get(SoundService).customEvent(SlingoSoundEvent.end_game_no_win);

        const summaryPrompt = new PromptContentSubcomponent(["gamesummary_nowin"], 3000);
        return Components.get(PromptComponent).showPrompt(summaryPrompt);
    }

    protected meetsWinRequirement(): boolean {
        const totalWager = gameState.getCurrentGame().getTotalWagered();
        const totalWin = gameState.getCurrentGame().getTotalWin();
        const winThreshold = slingoModel.read().gameConfig.celebrateWinThreshold; // percentage, i.e. 100 for a 1x minimum

        return ((totalWin / totalWager) * 100) >= winThreshold;
    }

    protected showWinSummary(): Contract {
        Services.get(SoundService).customEvent(SlingoSoundEvent.end_game_win);

        const summaryPrompt = new PromptContentSubcomponent(["gamesummary_win"], 6000);

        const translations = Services.get(TranslationsService);
        const currency = Services.get(CurrencyService);

        const gameplay = gameState.getCurrentGame();
        const totalWin = gameplay.getTotalWin();
        const totalStake = gameplay.getTotalWagered();

        const isBigWin = Components.get(BigWinComponent).isBigWin(totalWin, totalStake);
        const isFullHouse = gameplay.getLatestResultOfType(SlingoGameProgressResult).completionReason === SlingoCompletionReason.TICKET_MATCHED;

        /** Title */
        let titleKey = "results";
        if (this.meetsWinRequirement()) {
            if (isFullHouse) {
                titleKey = "full_house";
            } else if (isBigWin) {
                titleKey = "big_win";
            } else {
                titleKey = "win";
            }
        }
        summaryPrompt.setText("title", translations.get(titleKey));
        summaryPrompt.setText("win_breakdown", this.getWinBreakdown());
        summaryPrompt.setText("total_win", `${translations.get("total_win")}: ${currency.format(totalWin)}`);
        summaryPrompt.setText("total_win_title", `${translations.get("total_win")}`);
        summaryPrompt.setText("total_win_value", `${currency.format(totalWin)}`);

        return Components.get(PromptComponent).showPrompt(summaryPrompt);
    }

    // Seems likely to change between games
    protected getWinBreakdown(): string {
        const translations = Services.get(TranslationsService);
        const currency = Services.get(CurrencyService);

        const recordFamily = gameState.getCurrentGame().getFamily(gameState.getCurrentGame().getRootRecord());

        const bonusRecords = recordFamily.filter(record => record instanceof SpinRecord);
        const bonusWin = (bonusRecords.length > 0) ?
            bonusRecords.map(record => record.cashWon).reduce((total, current) => total += current) : 0;

        const scatterWin = recordFamily.map(record => {
            let win = 0;
            record.getResultsOfType(BonusResult).forEach(result => win += result.cashWon);
            return win;
        }).reduce((total, current) => total += current);

        const slingoWin = gameState.getCurrentGame().getTotalWin() - bonusWin - scatterWin;

        let text = `${translations.get("slingos")}: ${currency.format(slingoWin)}`;
        if (scatterWin > 0) { text += "\n" + translations.get("scatters") + ": " + currency.format(scatterWin) };
        if (bonusWin > 0) { text += "\n" + translations.get("bonus") + ": " + currency.format(bonusWin) };

        return text;
    }

    protected playSounds() {
        // Sounds
        const gameplay = gameState.getCurrentGame();
        const totalWin = gameplay.getTotalWin();
        const totalStake = gameState.getCurrentGame().getTotalWagered();
        const isBigWin = Components.get(BigWinComponent).isBigWin(totalWin, totalStake);
        const isFullHouse = gameplay.getLatestResultOfType(SlingoGameProgressResult)?.completionReason === SlingoCompletionReason.TICKET_MATCHED;

        if (totalWin === 0) {
            Services.get(SoundService).customEvent(SlingoSoundEvent.result_no_win);
        } else if (totalWin < totalStake) {
            Services.get(SoundService).customEvent(SlingoSoundEvent.result_substake_win);
        } else if (isFullHouse) {
            Services.get(SoundService).customEvent(SlingoSoundEvent.result_full_house_win);
        } else if (isBigWin) {
            Services.get(SoundService).customEvent(SlingoSoundEvent.result_big_win);
        } else {
            Services.get(SoundService).customEvent(SlingoSoundEvent.result_normal_win);
        }
    }
}