import { Layers } from "appworks/graphics/layers/layers";
import { TurboControl } from "appworks/ui/controls/standard/button/turbo-control";
import { ControlFactory } from "../control-factory";

export class TurboControlFactory implements ControlFactory {
    public filter(layer: Layers) {
        if (layer.getFlexiToggle("turbo_toggle")) {
            return true;
        }

        return false;
    }

    public build(layer: Layers) {
        return [new TurboControl(layer.getFlexiToggle("turbo_toggle"))];
    }
}
