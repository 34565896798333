import { AbstractComponent } from "appworks/components/abstract-component";
import { Components } from "appworks/components/components";
import { Layers } from "appworks/graphics/layers/layers";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";
import { SlingoFooterComponent } from "./slingo-footer-component";

export class SlingoCelebrationComponent extends AbstractComponent {
    protected layer: Layers;

    constructor(protected layerName: string) {
        super();
    }

    public init(): void {
        this.layer = Layers.get(this.layerName);
    }

    public showCelebration(lines: number, autoReset = true): Contract {
        // If higher than 4 lines, just fall back to basic animation
        if (lines > 4) { lines = 1; }

        Components.get(SlingoFooterComponent)?.showSlingoCelebration(lines);

        let spine: SpineContainer;
        return new Sequence([
            () => this.layer.setScene("slingo"),
            () => Contract.wrap(() => {
                spine = this.layer.getSpine("slingo_spine");
            }),
            () => new Parallel([
                () => spine.playOnce(`slingo${lines}`),
                () => Contract.wrap(() => {
                    Services.get(SoundService).customEvent(SlingoSoundEvent.slingo);
                    Services.get(SoundService).event(SlingoSoundEvent.slingo_N as any, lines.toString());
                })
            ]),
            () => autoReset ? this.layer.defaultScene() : Contract.empty()
        ]);
    }

    public reset(): Contract<void> {
        return this.layer.defaultScene();
    }
}
