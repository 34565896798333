import { Flexi } from "appworks/graphics/elements/abstract-flexi";
import { ToggleElement } from "appworks/graphics/elements/toggle-element";

/**
 * Toggle events work with the philisophy that the boolean which has been dispatched
 * is the state of the button that has just been clicked
 * ie, if the toggle is 'off' and pressed, 'false' will be dispatched
 */
export class FlexiToggle extends Flexi<HTMLElement | ToggleElement> {

    private sharedValue: boolean = false;
    private eventListeners: Map<string, (toggleEvent: Event) => void> = new Map();

    public addTarget(target: HTMLElement | ToggleElement) {
        super.addTarget(target);

        if (target) {
            if (target instanceof ToggleElement) {
                const checkBox = target as ToggleElement;
                checkBox.onChanged.add(() => this.setValue(checkBox.getChecked()));
            }
        }
    }

    public on(event: ToggleEvent, handler: (value: boolean) => void) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                const listener = (toggleEvent: Event) => {
                    const value = (toggleEvent.currentTarget as HTMLInputElement).checked;
                    handler(value);
                    this.setValue(!value);
                };
                this.eventListeners.set(event.id, listener);
                target.addEventListener(event.id, listener);
            } else if (target instanceof ToggleElement) {
                if (event.id === ToggleEvent.CHANGE.id) {
                    target.onChanged.add(handler);
                }
            }
        }
    }

    public off(event: ToggleEvent, handler: Function) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.removeEventListener(event.id, this.eventListeners.get(event.id));
            } else if (target instanceof ToggleElement) {
                if (event.id === ToggleEvent.CHANGE.id) {
                    target.onChanged.remove(handler);
                }
            }
        }
    }

    public setValue(value: boolean) {
        this.sharedValue = value;

        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                (target as HTMLInputElement).checked = !value;
            } else if (target instanceof ToggleElement) {
                target.setChecked(value);
            }
        }
    }

    public getValue(): boolean {
        return this.sharedValue;
    }

    public setVisible(visible: boolean) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle("visible", visible);
                target.classList.toggle("hidden", !visible);
            } else if (target instanceof ToggleElement) {
                target.setVisible(visible);
            }
        }
    }

    public setEnabled(enabled: boolean) {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                target.classList.toggle("enabled", enabled);
                target.classList.toggle("disabled", !enabled);
            } else if (target instanceof ToggleElement) {
                target.setEnabled(enabled);
            }
        }
    }

    public destroy() {
        for (const target of this.targets) {
            if (target instanceof HTMLElement) {
                this.eventListeners.forEach((listener: (event: Event) => void, name: string) => {
                    target.removeEventListener(name, listener);
                });
            } else if (target instanceof ToggleElement) {
                target.destroy();
            }
        }
    }
}

export class ToggleEvent {
    public static CHANGE: ToggleEvent = new ToggleEvent("click");

    public id: string;

    constructor(id: string) {
        this.id = id;
    }
}
