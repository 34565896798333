import { FlexiToggle, ToggleEvent } from "appworks/graphics/elements/flexi-toggle";
import { Model } from "appworks/model/model";
import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { slotModel } from "slotworks/model/slot-model";

export class TurboControl extends AbstractControl {
    constructor(protected toggle: FlexiToggle) {
        super();

        const turboAllowed = slotModel.read().regulations.turboAllowed;

        if (!turboAllowed) {
            this.toggle.setVisible(false);
            return;
        }

        const { settings } = Model.read();
        this.toggle.setValue(settings.turboSpin);

        this.toggle.on(ToggleEvent.CHANGE, () => this.onClick());
    }

    protected onClick(): void {
        const { settings } = Model.read();

        settings.turboSpin = this.toggle.getValue();

        Model.write({ settings });
    }
}
