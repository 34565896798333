import { UIFlagState } from "appworks/ui/flags/ui-flag-state";
import { Signal } from "signals";

export class UIHook {
    public readonly flagState: UIFlagState;
    public on: Signal;
    public off: Signal;
    public active: boolean;

    constructor(flagState: UIFlagState) {
        this.flagState = flagState;
        this.on = new Signal();
        this.off = new Signal();
        this.active = false;
    }
}
