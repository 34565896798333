import { Layers } from "appworks/graphics/layers/layers";
import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { ControlFactory } from "appworks/ui/factories/control-factory";
import { SlingoGameInstanceIdControl } from "../controls/slingo-game-instance-id-control";

export class SlingoGameInstanceIdControlFactory implements ControlFactory {
    public filter(layer: Layers): boolean {
        return Boolean(layer.getText("gameId"));
    }

    public build(layer: Layers): AbstractControl[] {
        return [new SlingoGameInstanceIdControl(layer)];
    }

}