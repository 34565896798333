import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { GameNameControlFactory } from "appworks/ui/factories/autoplay/game-name-control-factory";
import { TimeControlFactory } from "appworks/ui/factories/autoplay/time-control-factory";
import { GameJuriInfoControlFactory } from "appworks/ui/factories/standard/game-juri-info-control-factory";
import { UIToggleControlFactory } from "appworks/ui/factories/standard/ui-toggle-control-factory";
import { CoverControl } from "../controls/standard/shape/cover-control";
import { GenericControlFactory } from "./generic-control-factory";
import { BackgroundMusicToggleControlFactory } from "./standard/background-music-toggle-control-factory";
import { SkipAreaControlFactory } from "./standard/skip-area-control-factory";
import { SoundEffectsToggleControlFactory } from "./standard/sound-effects-toggle-control-factory";
import { SoundToggleControlFactory } from "./standard/sound-toggle-control-factory";
import { SpinControlFactory } from "./standard/spin-control-factory";
import { StakeListRadioControlFactory } from "./standard/stake-list-radio-control-factory";
import { TotalStakeStepperControlFactory } from "./standard/total-stake-stepper-control-factory";
import { TotalStakeTextControlFactory } from "./standard/total-stake-text-control-factory";
import { TurboControlFactory } from "./standard/turbo-control-factory";
import { UIButtonControlFactory } from "./standard/ui-button-control-factory";
import { VolumeSliderControlFactory } from "./standard/volume-slider-control-factory";

// TODO: V6: Should be GetStandardControlFactories
export function GetStandardFactories() {
    return [
        new SpinControlFactory(),
        new SkipAreaControlFactory(),
        new TotalStakeStepperControlFactory(),
        new TotalStakeTextControlFactory(),
        new StakeListRadioControlFactory(),
        new SoundToggleControlFactory(),
        new SoundEffectsToggleControlFactory(),
        new VolumeSliderControlFactory(),
        new BackgroundMusicToggleControlFactory(),
        new UIButtonControlFactory(),
        new UIToggleControlFactory(),
        new TurboControlFactory(),
        new TimeControlFactory(),
        new GameNameControlFactory(),
        new GameJuriInfoControlFactory(),
        new GenericControlFactory("cover", SmartShape, CoverControl)
    ];
}
