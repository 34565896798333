import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Layers } from "appworks/graphics/layers/layers";
import { Services } from "appworks/services/services";
import { UIFlag, uiFlags, UIMenuFlag } from "appworks/ui/flags/ui-flags";
import { UI } from "appworks/ui/ui";
import { KeyInput } from "appworks/utils/key-input";
import { KeyboardCode } from "appworks/utils/keyboard";

export class UIDebugger {

    private flagElement: HTMLDivElement;
    private groupElement: HTMLDivElement;
    private colorElement: HTMLDivElement;
    private checkboxes: UIFlagCheckbox[] = [];

    constructor() {
        const keyInput = new KeyInput();
        keyInput.onKeyUp.add((key: string, e: KeyboardEvent) => {
            if (e.shiftKey) {
                if (key === KeyboardCode.U) {
                    this.toggle();
                }
            }
        });
    }

    public start() {
        this.draw();
        this.populateFlagToggles();
        this.updateGroups();
        this.updateCheckboxes();

        uiFlags.onFlagsUpdated.add(() => {
            this.updateCheckboxes();
        });

        Layers.onEnterScene.add(() => {
            this.updateGroups();
        });
    }

    public toggle() {
        if (!this.flagElement || !this.groupElement) {
            this.start();
        } else {
            if (this.flagElement.parentElement) {
                Services.get(CanvasService).rootNode.removeChild(this.flagElement);
            } else {
                Services.get(CanvasService).rootNode.appendChild(this.flagElement);
            }

            if (this.groupElement.parentElement) {
                Services.get(CanvasService).rootNode.removeChild(this.groupElement);
            } else {
                Services.get(CanvasService).rootNode.appendChild(this.groupElement);
            }
        }
    }

    private draw() {

        this.flagElement = document.createElement("div");
        this.flagElement.id = "UIDebugger-flags";

        this.flagElement.style.position = "fixed";
        this.flagElement.style.top = this.flagElement.style.right = "0";
        this.flagElement.style.zIndex = "900";
        this.flagElement.style.width = "15vw";
        this.flagElement.style.paddingTop = "3vh";
        this.flagElement.style.paddingLeft = "3vw";
        this.flagElement.style.pointerEvents = "all";

        this.flagElement.style.backgroundColor = "rgba(50,50,50,.5)";
        this.flagElement.style.border = "solid 1px white";

        this.flagElement.style.color = "#fff";
        this.flagElement.style.fontSize = "16px";

        Services.get(CanvasService).rootNode.appendChild(this.flagElement);

        this.groupElement = document.createElement("div");
        this.groupElement.id = "UIDebugger-groups";

        this.groupElement.style.position = "fixed";
        this.groupElement.style.top = "0";
        this.groupElement.style.right = "18vw";
        this.groupElement.style.zIndex = "900";
        this.groupElement.style.width = "15vw";
        this.groupElement.style.paddingTop = "3vh";
        this.groupElement.style.paddingLeft = "3vw";
        this.groupElement.style.pointerEvents = "all";

        this.groupElement.style.backgroundColor = "rgba(50,50,50,.5)";
        this.groupElement.style.border = "solid 1px white";

        this.groupElement.style.color = "#fff";
        this.groupElement.style.fontSize = "16px";

        Services.get(CanvasService).rootNode.appendChild(this.groupElement);

        this.colorElement = document.createElement("div");
        this.colorElement.id = "UIDebugger-colors";

        this.colorElement.style.position = "fixed";
        this.colorElement.style.top = "0";
        this.colorElement.style.right = "36vw";
        this.colorElement.style.zIndex = "900";
        this.colorElement.style.width = "15vw";
        this.colorElement.style.paddingTop = "3vh";
        this.colorElement.style.paddingLeft = "3vw";
        this.colorElement.style.pointerEvents = "all";

        this.colorElement.style.backgroundColor = "rgba(50,50,50,.5)";
        this.colorElement.style.border = "solid 1px white";

        this.colorElement.style.color = "#fff";
        this.colorElement.style.fontSize = "16px";

        Services.get(CanvasService).rootNode.appendChild(this.colorElement);
    }

    private updateGroups() {
        while (this.groupElement.firstChild) {
            this.groupElement.removeChild(this.groupElement.firstChild);
        }

        UI.getCurrentActiveScenes().forEach((layerScene) => {
            const label = document.createElement("div");

            label.innerText = `${layerScene.layer} => ${layerScene.scene}`;

            label.style.color = layerScene.enabled ? "white" : "grey";

            this.groupElement.appendChild(label);
        });
    }

   

    private populateFlagToggles() {
        const flags = [...Object.keys(UIFlag).filter((key) => isNaN(Number(key))), ...Object.keys(UIMenuFlag).filter((key) => isNaN(Number(key)))];

        flags.forEach((key, index) => {

            const flag = Number(UIFlag[key as any] || UIMenuFlag[key as any]);

            const label = document.createElement("span");

            label.innerText = key;

            const checkbox = document.createElement("input");
            this.checkboxes.push(new UIFlagCheckbox(label, checkbox));

            checkbox.type = "checkbox";

            if (UIMenuFlag[key as any]) {
                checkbox.onchange = (e) => this.setMenuFlag(flag, (e.target as any).checked);
            } else {
                checkbox.onchange = (e) => this.setFlag(flag, (e.target as any).checked);
            }

            const br = document.createElement("br");

            this.flagElement.appendChild(label);
            this.flagElement.appendChild(checkbox);
            this.flagElement.appendChild(br);
        });
    }

    private updateCheckboxes() {
        const flags = [...Object.keys(UIFlag).filter((key) => isNaN(Number(key))), ...Object.keys(UIMenuFlag).filter((key) => isNaN(Number(key)))];

        flags.forEach((key, index) => {

            const flag = Number(UIFlag[key as any] || UIMenuFlag[key as any]);

            this.checkboxes[index].label.style.color = uiFlags.has(flag) ? "red" : "white";
            this.checkboxes[index].checkbox.checked = uiFlags.has(flag);
        });
    }

    private setFlag(flag: UIFlag, value: boolean) {
        uiFlags.set(flag, value);
    }

    private setMenuFlag(flag: UIMenuFlag, value: boolean) {
        uiFlags.menu(flag, value);
    }
}

class UIFlagCheckbox {
    constructor(public label: HTMLSpanElement, public checkbox: HTMLInputElement) { }
}
