import { ResultBuilder } from "appworks/server/builders/result-builder";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoJokerResult } from "slingo/model/results/slingo-joker-result";
import { SlingoResponse } from "slingo/integration/slingo-schema";

export class SlingoJokerResultBuilder implements ResultBuilder {
    public filter(data: SlingoResponse, record?: SlingoRecord): boolean {
        return data.spinResult && (data.spinResult.jokerCells.length > 0 || data.spinResult.superJokerCells.length > 0);
    }

    public build(record: SlingoRecord, data: SlingoResponse): SlingoJokerResult[] {
        const result = new SlingoJokerResult();

        result.jokerCells = data.spinResult.jokerCells;
        result.superJokerCells = data.spinResult.superJokerCells;

        return [result];
    }

}
