import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Gameplay } from "appworks/model/gameplay/gameplay";
import { State } from "appworks/state-machine/states/state";
import { Contract } from "appworks/utils/contracts/contract";
import { Timer } from "appworks/utils/timer";
import { BubbleSpineState } from "components/pop-bubble-spine-component";
import { PopReelMatrixComponent } from "components/pop-reel-matrix-component";
import { PopCascadeReelTransition } from "components/reel-transitions/pop-reel-cascade-transition";
import { SignalBinding } from "signals";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";
import { SlingoHighlightType, SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoCascadeResult } from "slingo/model/results/slingo-cascade-result";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { AbstractReelTransition } from "slotworks/components/matrix/reel/transition-behaviours/abstract-reel-transition";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { slotDefinition } from "slotworks/model/slot-definition";
import { getDabContracts } from "./get-dab-contracts";
import { Sequence } from "appworks/utils/contracts/sequence";
import { SlingoCoinHopperComponent } from "slingo/components/slingo-coin-hopper-component";
import { SlingoSpinType } from "slingo/integration/slingo-schema";

export class PopCascadeState extends State {

    // TODO: I don't like that this class has so much copy pasted funcitonality from spin state

    protected cascadeTransition = new PopCascadeReelTransition();
    protected baseTransition: AbstractReelTransition;

    protected result: SlingoCascadeResult;

    protected landBinding: SignalBinding;

    public onEnter(cascadeSkip?: boolean): void {
        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord() as SpinRecord;
        this.result = record.getResultsOfType(SlingoCascadeResult).find((result) => !result.played);

        const matrix = Components.get(MatrixComponent);
        this.baseTransition = matrix.getTransition();
        matrix.setTransition(this.cascadeTransition);

        const ticketComponent = Components.get(SlingoTicketMatrixComponent);
        ticketComponent.resetStreak();

        this.cancelGroup.sequence([
            () => this.cascade(),
            () => getDabContracts(this.result.matches),
            () => Components.get(SlingoLadderComponent).stepToLevel( // todo - is already going to have jumped right up
                gameplay.getLatestResultOfType(SlingoLadderResult).total
            ),
            () => this.addFreespins()
        ]).then(() => this.complete());

    }

    protected addFreespins() {
        const hopper = Components.get(SlingoCoinHopperComponent);
        if (hopper) {
            const sequence: Array<() => Contract> = [];

            Components.get(MatrixComponent).getAllSymbols().forEach((symbol, reelIndex) => {
                if (symbol.symbolId === "FS" && this.result.newSymbols[reelIndex]) {
                    sequence.push(() => hopper.addCoinFromReels(reelIndex, SlingoSpinType.FREESPIN));
                }
            })

            return new Sequence(sequence);
        } else {
            return Contract.empty();
        }
    }

    public onExit(): void {
        this.landBinding.detach();
        this.landBinding = null;

        const matrix = Components.get(MatrixComponent);
        const symbols = matrix.getGridSymbols().map((sym) => [sym.symbolId]);

        matrix.setTransition(this.baseTransition);
        matrix.jumpToGrid(symbols);

        this.result.played = true;
        this.result = null;
    }

    public complete(): void {
        Timer.setTimeout(() => super.complete(), 0);
    }

    protected cascade(): Contract {
        const matrix = Components.get(MatrixComponent);

        const fakeRecord = new SpinRecord();
        fakeRecord.grid = this.result.symbols.map((sym) => {
            return [isNaN(parseInt(sym)) ? sym : `numbers/${sym}`];
        });
        fakeRecord.reelset = slotDefinition.reelsets.get("slingo");
        Gameplay.dataProcessorSupplements.get().forEach((supplement) => supplement.process(fakeRecord));

        this.landBinding = matrix.landSignal.add((reelIndex: number) => this.onReelLand(
            reelIndex, this.result.matches.find((match) => match.reelIndex === reelIndex)
        ));

        return matrix.stopTransition(fakeRecord);
    }

    protected onReelLand(reelIndex: number, match?: { matchedValue: number, reelIndex: number }) {
        if (match) {
            if (!Components.get(SlingoTicketMatrixComponent).isValueDabbed(match.matchedValue)) {
                Components.get(SlingoTicketMatrixComponent).highlightSymbol(match.matchedValue, SlingoHighlightType.MATCHED).execute();

                const reelSymbol = Components.get(MatrixComponent).getBaseGridSymbols().find(
                    (symbol) => symbol.symbolId.replace("numbers/", "") === match.matchedValue.toString()
                );
                Components.get(PopReelMatrixComponent).getBubble(reelSymbol.gridPosition.x).setState(BubbleSpineState.MATCH).execute();
            }
        }
    }
}
