import { Record } from "appworks/model/gameplay/records/record";
import RespinResult from "slotworks/model/gameplay/records/results/respin-result";

export function respinResultDisplay(record: Record, result: RespinResult) {
    const container = document.createElement("div");

    container.innerHTML = `totalRespinsAwarded: ${result.totalRespinsAwarded}<br>respinsAwarded: ${result.respinsAwarded}<br>respinsRemaining: ${result.respinsRemaining}`;

    return container;
}
