import { gameState } from 'appworks/model/game-state';
import { Services } from 'appworks/services/services';
import { SoundService } from 'appworks/services/sound/sound-service';
import { SlingoGameProgressResult } from 'slingo/model/results/slingo-game-progress-result';
import { slingoModel } from 'slingo/model/slingo-model';
import { SlotBetModel } from 'slotworks/model/bets/slot-bet-model';
import { slotModel } from 'slotworks/model/slot-model';
import { SocialInitState } from 'states/social-init-state';

export enum BlastworksClientEvent {
  START_GAME,
  PURCHASE_SPIN,
  SPIN_RESULT,
  SLINGO,
  SLINGO_2,
  SLINGO_3,
  SLINGO_4,
  REEL_STOP_JOKER,
  REEL_STOP_SUPER_JOKER,
  REEL_STOP_FREE_SPIN,
  REEL_STOP_DEVIL,
  GAME_OVER,
  BONUS_START,
  BONUS_COMPLETE,
  RECOVERING,
}

/**
 * Handles events between game and client.
 *
 * @class ClientController
 * @typedef {ClientController}
 * @extends {AbstractGameController}
 */
export class ClientController {
  protected static instance: ClientController = null;

  public betStakes: number[];
  private isRecovering: boolean = false;
  private initState: SocialInitState;

  constructor() {
    if (ClientController.instance) {
      throw new Error('ClientController Singleton Already Initialised');
    }
    ClientController.instance = this;
    window.addEventListener('message', this.handleWindowMessage.bind(this), false);
  }

  public static getInstance(): ClientController {
    if (ClientController.instance === null) {
      ClientController.instance = new ClientController();
    }
    return ClientController.instance;
  }

  /**
   * Return numbered slingo client event
   *
   * @public
   * @static
   * @param {number} slingoCount
   * @returns {BlastworksClientEvent}
   */
  public static getSlingoEvent(slingoCount: number) {
    let slingoEvent: BlastworksClientEvent;
    switch (slingoCount) {
      case 1:
        slingoEvent = BlastworksClientEvent.SLINGO;
        break;
      case 2:
        slingoEvent = BlastworksClientEvent.SLINGO_2;
        break;
      case 3:
        slingoEvent = BlastworksClientEvent.SLINGO_3;
        break;
      case 4:
        slingoEvent = BlastworksClientEvent.SLINGO_4;
        break;
      default:
        break;
    }

    return slingoEvent;
  }

  /**
   * Sends client messages based on event
   *
   * @override
   * @public
   */
  public raiseEvent(event: BlastworksClientEvent): void {
    switch (event) {
      case BlastworksClientEvent.START_GAME:
        this.handleGameStart();
        break;
      case BlastworksClientEvent.PURCHASE_SPIN:
        this.handlePurchaseSpin();
        break;
      case BlastworksClientEvent.SPIN_RESULT:
        if (this.isRecovering) this.isRecovering = false;
        this.handleSpinStart();
        break;
      case BlastworksClientEvent.SLINGO:
        if (!this.isRecovering) this.handleSlingo(1);
        break;
      case BlastworksClientEvent.SLINGO_2:
        if (!this.isRecovering) this.handleSlingo(2);
        break;
      case BlastworksClientEvent.SLINGO_3:
        if (!this.isRecovering) this.handleSlingo(3);
        break;
      case BlastworksClientEvent.SLINGO_4:
        if (!this.isRecovering) this.handleSlingo(4);
        break;
      case BlastworksClientEvent.REEL_STOP_JOKER:
        if (!this.isRecovering) this.handleReelStrikeJoker();
        break;
      case BlastworksClientEvent.REEL_STOP_SUPER_JOKER:
        if (!this.isRecovering) this.handleReelStrikeSuperJoker();
        break;
      case BlastworksClientEvent.REEL_STOP_FREE_SPIN:
        if (!this.isRecovering) this.handleReelStrikeFreeSpin();
        break;
      case BlastworksClientEvent.REEL_STOP_DEVIL:
        if (!this.isRecovering) this.handleReelBlocker();
        break; // This is never triggered as this game has no blockers
      case BlastworksClientEvent.GAME_OVER:
        this.handleGameComplete();
        break;
      case BlastworksClientEvent.BONUS_START:
        this.handleBonusStart();
        break;
      case BlastworksClientEvent.BONUS_COMPLETE:
        this.handleBonusComplete();
        break;
      case BlastworksClientEvent.RECOVERING:
        this.isRecovering = true;
      default:
        break;
    }
  }

  /**
   * Used by init state to send game ready to client and receive bet stakes
   *
   * @public
   * @param {SocialInitState} initState
   */
  public sendGameReady(initState: SocialInitState) {
    this.initState = initState;
    ClientController.handleGameReady();
  }

  /**
   * Get url param
   *
   * @private
   * @static
   * @param {string} param
   * @returns {boolean}
   */
  private static getURLParam(param: string): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get(param);
    return paramValue !== null && paramValue.toLowerCase() === 'true';
  }

  /**
   * Post event message to parent window.
   *
   * @public
   * @static
   * @param {string} name
   * @param {Object} [data={}]
   */
  public static postParentMessage(name: string, data: Object = {}) {
    let message: string = JSON.stringify({
      name: name,
      data: data,
    });

    if (this.getURLParam('test')) {
      console.log(message);
    }

    window.parent.postMessage(message, '*');
  }

  /**
   * Sends block balance update
   *
   * @protected
   */
  protected handleBonusStart(): void {
    ClientController.postParentMessage('block_balance_update');
  }

  /**
   * Sends unblock balance update
   *
   * @protected
   */
  protected handleBonusComplete(): void {
    window.setTimeout(() => ClientController.postParentMessage('unblock_balance_update'), 500);
  }

  /**
   * Handle game complete.
   *
   * @private
   */
  private handleGameComplete(): void {
    this.handleBalanceUpdate();
    const m = slingoModel.read();
    const progressData = gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult);
    const paidSpins = progressData.purchaseSpinsUsed;

    let fullHouse = 0;
    let matchedPatterns = progressData.matchedPatterns;
    if (matchedPatterns == 12) {
      fullHouse = 1;
    }

    ClientController.postParentMessage('gameComplete', {
      totalWinAmount: (progressData.totalWin ?? 0) / 100,
      amountBet: (progressData.standardStake ?? 0) / 100,
      extraSpinsCoins: ((progressData.totalStake ?? 0) - (progressData.standardStake ?? 0)) / 100,
      total_spins:
        (progressData.standardSpins ?? 0) + (paidSpins ?? 0) + (progressData.freeSpinsUsed ?? 0),
      paidSpins: paidSpins ?? 0,
      full_card: fullHouse ?? 0,
      slingos: matchedPatterns ?? 0,
      purchSpinsAvail: progressData.purchaseSpins ?? 0,
      purchSpinsRemain: progressData.purchaseSpinsRemaining ?? 0,
      freeSpinsAwarded: progressData.freeSpins ?? 0,
      game_instance_id: m.gameInstanceId ?? 0,
    });
  }

  /**
   * Handles balance update.
   *
   * @protected
   */
  protected handleBalanceUpdate(): void {
    window.setTimeout(() => ClientController.postParentMessage('update_balance_only'), 500);
  }

  /**
   * Send slingo events
   *
   * @protected
   * @param {number} amount
   */
  protected handleSlingo(amount: number): void {
    ClientController.postParentMessage('slingo', { slingos: amount });
  }

  /**
   * Send game start message
   *
   * @protected
   */
  protected handleGameStart(): void {
    const data = gameState.getCurrentGame();

    ClientController.postParentMessage('gameStart', {
      amountBet: (data.getTotalWagered() ?? 0) / 100,
      game_instance_id: slingoModel.read().gameInstanceId ?? 0,
    });
  }

  /**
   * Handles purchased spin.
   *
   * @protected
   */
  protected handlePurchaseSpin(): void {
    const m = slingoModel.read();
    const progressData = gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult);

    ClientController.postParentMessage('purchasedSpin', {
      amountBet: (progressData.standardStake ?? 0) / 100,
      spinCost: {
        stakeAmount: (progressData.nextPurchaseStake ?? 0) / 100,
      },
      game_instance_id: m.gameInstanceId ?? 0,
    });
  }

  /**
   * Handles spin start.
   *
   * @protected
   */
  protected handleSpinStart(): void {
    const m = slingoModel.read();
    const progressData = gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult);

    ClientController.postParentMessage('spinStart', {
      amountBet: (progressData.standardStake ?? 0) / 100,
      game_instance_id: m.gameInstanceId ?? 0,
    });
  }

  /**
   * Handles reel strike free spin.
   *
   * @protected
   */
  protected handleReelStrikeFreeSpin(): void {
    this.handleReelStrike({ freeSpins: 1 });
  }

  /**
   * Handles reel strike joker.
   *
   * @protected
   */
  protected handleReelStrikeJoker(): void {
    this.handleReelStrike({ jokers: 1 });
  }

  /**
   * Handles reel strike super joker.
   *
   * @protected
   */
  protected handleReelStrikeSuperJoker(): void {
    this.handleReelStrike({ superJokers: 1 });
  }

  /**
   * Handles reel blocker.
   *
   * @protected
   */
  protected handleReelBlocker(): void {
    this.handleReelStrike({ devils: 1 });
  }

  /**
   * General reel strike message.
   *
   * @protected
   * @param {Object} data
   */
  protected handleReelStrike(data: Object): void {
    ClientController.postParentMessage('reelStrike', data);
  }

  /**
   * Handle insufficient funds.
   *
   * @protected
   */
  public handleInsufficientFunds(): void {
    const progressData = gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult);
    const totalStake = gameState.getCurrentGame().getTotalWagered() / 100;
    const betModel: SlotBetModel = slotModel.read().bet;
    const stake: number = betModel.creditSizes.currentValue / 100;

    ClientController.postParentMessage('outOfCoins', {
      amountBet: (progressData?.standardStake ?? totalStake > 0) ? totalStake : stake,
      game_instance_id: slingoModel.read().gameInstanceId ?? 0,
    });
  }

  /**
   * Handles messages sent by client window.
   *
   * @protected
   * @param {*} event
   */
  protected handleWindowMessage(event: any): void {
    if (ClientController.isValid(event)) {
      if (event && event.data && event.data._pixiInspector) {
        return;
      }
      let json: any;

      try {
        json = JSON.parse(event.data);
      } catch (error) {
        return;
      }

      switch (json.name) {
        case 'betStops':
          this.handleBetStops(json);
          break;

        case 'volume':
          this.handleVolume(json);
          break;
      }
    }
  }

  /**
   * Handle bet stops changes.
   *
   * @protected
   * @param {*} json
   */
  protected handleBetStops(json: any): void {
    try {
      if (!json || typeof json !== 'object' || !json.data || !Array.isArray(json.data.coins)) {
        throw new Error("Invalid data structure: 'data.coins' should be an array.");
      }
      const betStakes = json.data.coins;
      if (!betStakes.every((stake: any) => typeof stake === 'number')) {
        throw new Error("Invalid data: 'coins' array must contain only numbers.");
      }
      this.betStakes = betStakes;
      this.initState.clientBetsReceived();
    } catch (error) {
      console.error('Error in ClientController.handleBetStops:', error.message);
    }
  }

  /**
   * Handles volume changes from client.
   *
   * @protected
   * @param {*} json
   */
  protected handleVolume(json: any): void {
    if (json.data.volume === 0) {
      window.setTimeout(() => Services.get(SoundService).mute(), 100);
    } else {
      window.setTimeout(() => Services.get(SoundService).unmute(), 100);
    }
  }

  /**
   * TODO: Game sends game ready state to wrapper in gaming-realms.
   * Our wrapper could handle this event if bet stakes is reworked.
   *
   * Sends game read message to client.
   *
   * @protected
   */
  protected static handleGameReady(): void {
    ClientController.postParentMessage('gameReady', { isReady: true });
  }

  /**
   * Sends error message to client.
   *
   * @public
   * @static
   * @param {GameModel} model
   */
  public static somethingWentWrong(errorId: string): void {
    ClientController.postParentMessage('something_went_wrong', {
      game_instance_id: slingoModel.read().gameInstanceId ?? 0,
      response: this.getErrorCode(errorId) ?? 0,
    });
  }

  private static getErrorCode(errorId: string): number {
    const errorType = so.ErrorType.getErrorById(errorId);
    return errorType.getCode();
  }

  public static handleReload(): void {
    ClientController.postParentMessage('reload');
  }

  /**
   * anyone can broadcast window messages. Verify if origin is correct.
   * For Chrome, the origin property is in the event.originalEvent object.
   *
   * @private
   * @static
   * @param {*} event
   * @returns {boolean}
   */
  private static isValid(event: any): boolean {
    const origin: string = event.origin || event.originalEvent.origin;

    //TODO: Put into config?
    return checkOrigins(origin, [
      'localhost',
      'https://c2dev.slingo.com',
      'slingocasino.ca',
      'slingoarcade.com',
    ]);

    function checkOrigins(incomingOrigin: string, acceptableOrigins: string[]): boolean {
      for (const acceptedOrigins of acceptableOrigins) {
        if (incomingOrigin.toLowerCase().indexOf(acceptedOrigins.toLowerCase()) >= 0) {
          return true;
        }
      }
      return false;
    }
  }
}
