import _ = require("lodash");
import { Point } from "./geom/point";

export function wrapIndex<T>(index: number, array: T[]): T {
    return array[normalizeIndex(index, array)];
}

export function normalizeIndex<T>(index: number, arrayOrLength: T[] | number): number {
    const length = Array.isArray(arrayOrLength) ? arrayOrLength.length : arrayOrLength;

    if (!length) {
        return null;
    }

    while (index < 0) {
        index += length;
    }
    while (index >= length) {
        index -= length;
    }

    return index;
}

export function contains<T>(arr: T[], value: T): boolean {
    for (const v of arr) {
        if (v === value) {
            return true;
        }
    }
    return false;
}

/**
 * Search an array for the closest value to a target
 */
export function nearest(target: number, arr: number[]) {
    let nearestValue: number = -1;
    let dist = Number.MAX_VALUE;

    for (const n of arr) {
        if (Math.abs(n - target) < dist) {
            dist = Math.abs(n - target);
            nearestValue = n;
        }
    }

    return nearestValue;
}

/**
 * Search an array for the closest value to a target, without ever going over it
 */
export function nearestUnder(target: number, arr: number[]) {
    let nearestValue: number = -1;
    let dist = Number.MAX_VALUE;

    for (const n of arr) {
        if (n > target) {
            continue;
        }
        if (Math.abs(n - target) < dist) {
            dist = Math.abs(n - target);
            nearestValue = n;
        }
    }

    return nearestValue;
}

export function unique<T>(arr: T[]): T[] {
    const uniqueArr: T[] = [];
    for (const v of arr) {
        if (!contains(uniqueArr, v)) {
            uniqueArr.push(v);
        }
    }
    return uniqueArr;
}

/**
 * Removes any element which rejectArr contains from sourceArr
 * @param sourceArr {T[]}
 * @param rejectArr {T[]}
 */
export function reject<T>(sourceArr: T[], rejectArr: T[]): T[] {
    const rejectedArr: T[] = [];
    for (const v of sourceArr) {
        if (!contains(rejectArr, v)) {
            rejectedArr.push(v);
        }
    }
    return rejectedArr;
}

/**
 * Pushes the same value to the array 'count' number of times
 * @param arr {T[]}
 * @param value {T}
 * @param count {number}
 */
export function addRepeated<T>(arr: T[], value: T, count: number) {
    while (count > 0) {
        count--;
        arr.push(value);
    }
}

export function clearMap<T>(map: Map<string, T>) {
    if (map) {
        map.forEach((obj: any) => {
            if (obj.destroy) {
                obj.destroy();
            }
        });

        map.clear();
    }
}

export function lowerCaseMapKeys(map: Map<string, any>) {
    map.forEach((value: any, key: string) => {
        const lowerCaseKey = key.toLowerCase();
        if (lowerCaseKey !== key) {
            map.set(key.toLowerCase(), value);
            map.delete(key);
        }
    });
}

export function lowerCaseMapValues(map: Map<any, string>) {
    map.forEach((value: string, key: any) => {
        map.set(key, map.get(key).toLowerCase());
    });
}

// Random sorts an array (mutates original array)
export function shuffle<T>(a: T[]) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export function asArray<T>(target: T | T[]): T[] {
    if (Array.isArray(target)) {
        return target;
    } else {
        return [target];
    }
}

export function lastInArray<T>(target: T | T[]): T {
    if (Array.isArray(target)) {
        return target[target.length - 1];
    } else {
        return target;
    }
}

export function firstInArray<T>(target: T | T[]): T {
    if (Array.isArray(target)) {
        return target[0];
    } else {
        return target;
    }
}

/**
 * Create an array of given length with an optional default value
 *
 * @param length
 * @param defaultValue
 * @returns An array of indices, or an array of the default value
 */
export function arrayOfValues<T>(length: number, defaultValue?: T): T[] {
    const arr: T[] = Array.apply(null, { length });
    if (defaultValue !== undefined) {
        return arr.map(() => JSON.parse(JSON.stringify(defaultValue)));
    }
    return arr.map(Number.call, Number);
}

/**
 * Count the occurances of a certain value inside an array
 */
export function countOccurances<T>(target: T, arr: T[]): number {
    return arr.reduce((count: number, item: T) => (count += Number(item === target)), 0);
}

/**
 * Finds all indexes where the pattern needle matches in haystack. firstOnly will return only first match (faster)
 * E.g: findPatterns([1,2,3,4,5,6,1,2,3,4,5,6], [1,2,3]); // [0, 6]
 * E.g: findPatterns([1,2,3,4,5,6,1,2,3,4,5,6], [1,2,3], true); // [0]
 */
export function findPatterns<T>(haystack: T[], needle: T[], firstOnly: boolean = false): number[] {
    const matchPositions: number[] = [];
    let searchIndex = 0;

    while (searchIndex > -1) {
        searchIndex = haystack.indexOf(needle[0], searchIndex + 1);
        if (searchIndex === -1) {
            break;
        }
        let patternFound = true;
        for (let matchIndex = 0; matchIndex < needle.length; matchIndex++) {
            if (wrapIndex(searchIndex + matchIndex, haystack) !== needle[matchIndex]) {
                patternFound = false;
                break;
            }
        }
        if (patternFound) {
            matchPositions.push(searchIndex);
            if (firstOnly) {
                break;
            }
        }
    }

    return matchPositions;
}

// Create an object with a specified structure, creating empty objects along the way if they don't already exist
// For example, createStructure({}, "foo", "bar", "baz") creates { foo: { bar: { baz: {}}}}
export function createStructure(obj: any, ...path: string[]) {
    for (const node of path) {
        if (!obj[node]) {
            obj[node] = {};
        }
        obj = obj[node];
    }

    return obj;
}

// Converts a map to an object with key value pairs
export function mapToObject<T>(map: Map<string, T>): { [key: string]: T } {
    const obj: { [key: string]: T } = {};

    map.forEach((value, key) => {
        obj[key] = value;
    });

    return obj;
}

// Iterates over an object containing a specified type
export function forIn<T>(obj: { [key: string]: T }, iterator: (value: T, key: string) => void) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            iterator(obj[key], key);
        }
    }
}

export function removeFromArray<T>(haystack: T[], needle: T) {
    const index = haystack.indexOf(needle);
    if (index > -1) {
        haystack.splice(index, 1);
    }
}

export function deepClone<T>(obj: T): T {
    return _.cloneDeep(obj);
}

// Converts [[0,1,2],[3,4,5]] to [[new Point(0,0), new Point(1,1), new Point(2,2)], [new Point(0,3), new Point(1,4), new Point(2,5)]]
export function arrayToPoints(arr: number[][]): Point[][] {
    const output: Point[][] = [];

    arr.forEach(entry => {
        const outputEntry: Point[] = [];
        entry.forEach((y, x) => {
            outputEntry.push(new Point(x, y));
        });
        output.push(outputEntry);
    });

    return output;
}

export function arrayEquals<T>(array1: T[], array2: T[]): boolean {
    return JSON.stringify(array1) === JSON.stringify(array2);
}

export function flatten<T>(arr: T[][]) {
    const output: T[] = [];
    arr.forEach(childArr => {
        output.push(...childArr);
    });

    return output;
}

export function average(arr: number[]) {
    return sum(arr) / arr.length;
}

export function sum(arr: number[]) {
    return arr.reduce((total, n) => total + n, 0);
}

/** Mutates given array */
export function rotateArray<T extends any[]>(arr: T, times: number): void {
    for (let i = 0; i < times; i++) {
        arr.unshift(arr.pop());
    }
}

/** Mutates given array */
export function swapElements<T>(arr: T[], index1: number, index2: number): void {
    if (index1 < 0 || index1 >= arr.length || index2 < 0 || index2 >= arr.length) {
        throw new Error("Invalid indices provided. Please make sure indices are within the array bounds.");
    }

    const temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
}

// Because we still aren't using Object.values for some reason
export function objectValues<T>(obj: { [key: string]: T }): T[] {
    return Object.keys(obj).map(key => obj[key]);
}
