import { Orientation } from "../canvas/orientation";

export class RenderOrientation {

    public static orientation: Orientation;

    public static isLandscape() {
        return this.orientation === Orientation.LANDSCAPE;
    }

}
