import { LoadbarSubcomponent } from "appworks/components/preloader/loadbar-subcomponent";
import { LoadType, PromptContentSubcomponent } from "appworks/components/prompt/prompt-content-subcomponent";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { LoaderService } from "appworks/loader/loader-service";
import { Model } from "appworks/model/model";
import { Services } from "appworks/services/services";
import { Contract } from "appworks/utils/contracts/contract";
import { KeyboardCode } from "appworks/utils/keyboard";
import { Timer } from "appworks/utils/timer";
import { DisplayObject, LoaderResource } from "pixi.js";
import { AbstractComponent } from "../abstract-component";

export interface PromptComponentConfig {
    spaceToContinue: boolean;
    enterToContinue: boolean;
    usePromptLayerAsClickListener: boolean;
}

export class PromptComponent extends AbstractComponent {

    protected config: PromptComponentConfig = {
        spaceToContinue: true,
        enterToContinue: false,
        usePromptLayerAsClickListener: false
    };

    constructor(config?: Partial<PromptComponentConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }
    }

    protected keyListener: EventListenerOrEventListenerObject;
    // Lambda set up when a prompt opens, used to clean up and close (race handled)
    protected onContinue: Function;

    protected KEY_UP_EVENT: string = "keyup";

    // Externally force the prompt to complete, for example when spin button (skip) is used to close prompt
    public forceComplete() {
        if (this.onContinue) { this.onContinue(); }
    }

    public showPrompt(prompt: PromptContentSubcomponent, loadStage: number = null): Contract<void> {

        return new Contract<void>((resolve) => {

            prompt.show().then(() => {
                let onContinue = () => {
                    document.removeEventListener(this.KEY_UP_EVENT, this.keyListener);
                    Services.get(CanvasService).rootNode.removeEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), onPointerDown);
                    Services.get(CanvasService).rootNode.removeEventListener(ButtonEvent.POINTER_UP.getDOMEventString(), onClickToSkip);
                    prompt.layer.container.removeAllListeners();

                    if (prompt.getLoadRaceType() === LoadType.LOADING_SCENE && Layers.get("Prompts").hasScene("loading")) {
                        Layers.get("Prompts").setScene("loading").then(() => raceHandler());
                    } else {
                        raceHandler();
                    }
                };
                this.onContinue = onContinue;

                let raceStages = 1;

                const raceHandler = () => {
                    raceStages--;
                    if (raceStages === 0) {
                        this.onContinue = onContinue = null;
                        prompt.hide().then(() => resolve(null)).execute();
                    }
                };

                let pointerDown = false;

                const onPointerDown = () => {
                    pointerDown = true;
                };

                const onClickToSkip = () => {
                    if (pointerDown || this.config.usePromptLayerAsClickListener) {
                        if (onContinue) { onContinue(); }
                    }
                };

                const setupClickToSkip = () => {
                    if (this.config.usePromptLayerAsClickListener) {
                        prompt.layer.container.interactive = true;
                        prompt.layer.container.once(ButtonEvent.CLICK.getPIXIEventString(), onClickToSkip);
                    } else {
                        Services.get(CanvasService).rootNode.addEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), onPointerDown);
                        Services.get(CanvasService).rootNode.addEventListener(ButtonEvent.CLICK.getDOMEventString(), onClickToSkip);
                    }
                }

                prompt.enableContinueButton();

                if (loadStage) {
                    raceStages++;
                    if (prompt.getLoadRaceType() === LoadType.DISABLE_BUTTON) {
                        prompt.disableContinueButton();
                    } else if (prompt.getLoadRaceType() === LoadType.HIDE_BUTTON) {
                        prompt.hideContinueButton();
                    }

                    this.loadStage(loadStage).then(() => {
                        prompt.enableContinueButton();

                        if (prompt.getClickToSkip()) {
                            setupClickToSkip();
                        }

                        raceHandler();
                    });
                } else if (prompt.getClickToSkip()) {
                    setupClickToSkip();
                }

                if (this.keyListener) {
                    document.removeEventListener(this.KEY_UP_EVENT, this.keyListener);
                }
                this.keyListener = (event: any) => {
                    const spaceKeyPressed = (this.config.spaceToContinue && event.key === KeyboardCode.SPACE);
                    const enterKeyPressed = (this.config.enterToContinue && event.key === KeyboardCode.ENTER);
                    const skipKeyPressed = spaceKeyPressed || enterKeyPressed;
                    if (Model.read().settings.spaceToSpin && skipKeyPressed) {
                        if (onContinue) onContinue();
                    }
                };



                if (prompt.hasContinueButton()) {
                    document.addEventListener(this.KEY_UP_EVENT, this.keyListener);
                    prompt.onHide.add(() => this.removeKeyListener());
                    prompt.addContinueListener(() => {
                        if (onContinue) { onContinue(); }
                    });
                }

                if (prompt.dontBlockTimeouts || !prompt.hasContinueButton()) {
                    Timer.setTimeout(() => {
                        if (onContinue) { onContinue(); }
                    }, prompt.timeOutTime);
                }
            });
        });
    }

    // Function should only be called internally by prompt sub component (horrible design has led to this hack being necessary)
    public promptClosed() {
        this.onContinue = null;
    }

    protected removeKeyListener() {
        document.removeEventListener(this.KEY_UP_EVENT, this.keyListener);
    }

    protected loadStage(stageId: number): Contract<void> {

        const bar = Layers.get("Prompts").getSprite("loadbar");

        const loadBar = new LoadbarSubcomponent({ files: Services.get(LoaderService).getStageFiles(stageId), bar });

        let loadSprite: DisplayObject = Layers.get("Prompts").getSprite("loading_sprite");
        if (!loadSprite) {
            const loadButton = Layers.get("Prompts").getButton("loading_sprite");
            if (loadButton) {
                loadSprite = loadButton;
            }
        }

        loadBar.addLoadingText(Layers.get("Prompts").getText("loading_label"), Layers.get("Prompts").getText("loading_percentage"), loadSprite);

        return new Contract<void>((resolve) => {
            Services.get(LoaderService).loadStage(stageId, (stage: number, progress: number, resource: LoaderResource) => {
                loadBar.fileLoaded(resource);
            }).then(() => loadBar.loadComplete().then(() => resolve(null)));
        });
    }
}
