import { EmitterConfig, OldEmitterConfig } from "pixi-particles";


// List of all language codes supported by slotworks
export const supportedIETFCodes: string[] = [
    "en-SC", "en-GB", "en-US", "en-CA", "bg-BG", "zh-CN", "zh-TW", "zh-Hant", "hr-HR", "cs-CZ", "da-DK", "nl-BE", "nl-NL", "et-EE", "fi-FI", "fr-CA", "fr-FR", "ka-GE", "de-AT", "de-DE", "gr-EL", "hu-HU", "is-IS", "id-ID", "it-IT", "ja-JP", "km-KH", "ko-KR", "lv-LV", "lt-LT", "ms-MY", "nb-NO", "nn-NO", "pl-PL", "pt-BR", "pt-PT", "ro-RO", "ru-RU", "sk-SK", "es-41", "es-LA", "es-MX", "es-ES", "sv-SE", "th-TH", "tr-TR", "uk-UA", "vi-VN"
];

export interface LayersConfigSchema {
    default: string[];

    [key: string]: string[];
}

export interface AssetConfigSchema {
    layouts: {
        mobile: LayoutConfigSchema;
        desktop: LayoutConfigSchema;
        all: SharedLayoutConfigSchema;
    };
}

export interface SharedLayoutConfigSchema {
    animations: AnimationConfig[];
    spines: SpineConfig;
    particles: { [id: string]: ParticleConfig };
    manifest: SharedManifestSchema;
}

export interface LayoutConfigSchema {
    stage: StageConfigSchema;
    graphics: { [layerScene: string]: SceneConfig };
    manifest: ManifestSchema;
}

export interface StageConfigSchema {
    viewport: DualPositionConfig;
    nativePixelRatio: number;
    scales: number[];
}

export interface SceneConfig {
    bounds: DualPositionConfig;
    positions: {
        [id: string]: DualPositionConfig;
    };
    text: {
        [id: string]: DualTextConfig;
    };
    sprites: {
        [id: string]: DualPositionConfig;
    };
}
export interface DualPositionConfig {
    landscape?: PositionConfig;
    portrait?: PositionConfig;
}

export interface DualTextConfig {
    landscape?: TextConfig;
    portrait?: TextConfig;
}

export interface ManifestSchema {
    graphics: DeviceManifest;
    DOMElements: DOMManifest;
}

export interface SharedManifestSchema {
    fonts: string[];
    backgrounds: string[];
    languages: string[];
    sound: SoundManifest | { [spriteName: string]: SoundManifest };
    files: FileLocation[];
    videos: FileLocation[];
}

export type ResolutionDefinitions = "hd" | "md" | "ld";

export interface SoundManifest {
    src: string[];
    sprite: IHowlSoundSpriteDefinition;
}

export interface DeviceManifest {
    ld: FileLocation[][];
    md: FileLocation[][];
    hd: FileLocation[][];
}

export interface DOMManifest {
    ld: string[];
    md: string[];
    hd: string[];
}

export interface FileLocation {
    name: string;
    url: string;
    size?: number;
}

export interface AnimationConfig {
    id: string;

    fps: number;

    prefix: string;
    numFrames: number;
    suffix: string;
    zeroPad: number;

    repeat: number;
    loop: boolean;
    loopFrame: number;
    reverse: boolean;
}

export interface SpineConfig {
    [key: string]: any;
}

export interface PositionConfig {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface TextConfig extends PositionConfig {
    fontFamily: string;
    fontVariant: string;
    color: string;
    content: string;
    align: string;
    size: number;
    letterSpacing?: number;

    stroke?: string;
    strokeThickness?: number;

    fill?: string[];
    fillGradientStops?: number[];
    fillGradientType?: number;

    dropShadowAlpha?: number;
    dropShadowBlur?: number;
    dropShadowAngle?: number;
    dropShadowDistance?: number;
    dropShadowColor?: string;

    outerGlow?: OuterGlow;
    innerShadow?: InnerShadow;
}

export interface OuterGlow {
    color: string;
    alpha: number;
    blur: number;
    noise: number;
    spread: number;
}

export interface InnerShadow {
    color: string;
    alpha: number;
    rotation: number;
    thickness: number;
}

export interface FXParticleBundleConfig extends EmitterConfig {
    revoltFX: string;
}

export interface ParticleConfig {
    textures: Array<string | AnimatedParticleConfig>;
    config: EmitterConfig | OldEmitterConfig | FXParticleBundleConfig;
}

export interface AnimatedParticleConfig {
    textures: string[];
    framerate: number;
    loop: boolean;
}

export function CreateEmptyAssetConfig(): AssetConfigSchema {
    return {
        layouts: {
            all: {
                manifest: {
                    fonts: [],
                    backgrounds: [],
                    sound: {},
                    files: [],
                    videos: [],
                    languages: []
                },
                animations: [],
                particles: {},
                spines: {}
            },
            mobile: {
                manifest: {
                    graphics: {
                        hd: [],
                        md: [],
                        ld: []
                    },
                    DOMElements: {
                        hd: [],
                        md: [],
                        ld: []
                    }
                },
                stage: {
                    viewport: {
                        landscape: { x: 0, y: 0, width: 0, height: 0 },
                        portrait: { x: 0, y: 0, width: 0, height: 0 }
                    },
                    nativePixelRatio: 0,
                    scales: []
                },
                graphics: {}
            },
            desktop: {
                graphics: {},
                stage: {
                    viewport: {
                        landscape: { x: 0, y: 0, width: 0, height: 0 },
                        portrait: { x: 0, y: 0, width: 0, height: 0 }
                    },
                    nativePixelRatio: 0,
                    scales: []
                },
                manifest: {
                    graphics: {
                        hd: [],
                        md: [],
                        ld: []
                    },
                    DOMElements: {
                        hd: [],
                        md: [],
                        ld: []
                    }
                }
            }
        }
    };
}
