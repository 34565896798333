import { Contract } from "appworks/utils/contracts/contract";
import { PopReelMatrixComponent } from "components/pop-reel-matrix-component";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { PopReelTransition } from "./pop-reel-transition";

export class PopCascadeReelTransition extends PopReelTransition {
    public stop(spinRecord: SpinRecord): Contract<void> {
        const matrix = this.matrix as PopReelMatrixComponent;
        const symbols = matrix.getBaseGridSymbols();

        spinRecord.grid.forEach((reel, x) => {
            this.symbolHeld[x] = reel[0] === symbols[x].symbolId;
        });

        return super.stop(spinRecord);
    }
}
