import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { SignalBinding } from "signals";
import { HideAllLinesCommand } from "slotworks/commands/lines/hide-all-lines-command";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";

export interface IdleConfig {
    holdSpinDelay: number;
    /** Change this if using this state with a secondary matrix component */
    matrixComponentType: { new (...args: any[]): AbstractMatrixComponent };
}

export class IdleState extends State {
    protected signalBindings: SignalBinding[] = [];

    protected config: IdleConfig = {
        holdSpinDelay: 0,
        matrixComponentType: AbstractMatrixComponent
    };

    protected holdSkipTimer: number;

    constructor(config?: Partial<IdleConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }
    }

    public onEnter() {
        uiFlags.set(UIFlag.IDLE, true);
        uiFlags.set(UIFlag.GAME_IN_PROGRESS, false);
        uiFlags.set(UIFlag.SPINNING, false);

        this.signalBindings.push(Services.get(SlotBetService).onBetChange.add(() => this.onBetChange()));

        Services.get(TransactionService).setBalance(gameState.getCurrentGame().balance);

        this.startIdles();
    }

    public onSkip() {
        if (!this.holdSkipTimer) {
            this.skip();
        }
    }

    public onExit() {
        Components.get(AbstractMatrixComponent)?.resetSymbols();

        HideAllLinesCommand().execute();

        uiFlags.set(UIFlag.IDLE, false);
        uiFlags.set(UIFlag.GAME_IN_PROGRESS, true);
        uiFlags.closeMenus();
    }

    public skip(): void {
        this.clearSignalBindings();
        this.clearIdles();
        super.skip();
    }

    public complete(): void {
        this.clearSignalBindings();
        this.clearIdles();
        super.complete();
    }

    protected clearSignalBindings(): void {
        for (const binding of this.signalBindings) {
            binding.detach();
        }

        this.signalBindings = [];
    }

    protected onBetChange(): void {
        Services.get(TransactionService).setTotalWin(0);
    }

    protected startIdles(): void {
        Components.get(this.config.matrixComponentType)?.getGridSymbols().forEach((symbol) => symbol.idle());
    }

    protected clearIdles(): void {
        Components.get(this.config.matrixComponentType)?.getGridSymbols().forEach((symbol) => symbol.static());
    }
}
