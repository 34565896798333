import { BlastworksClientEvent, ClientController } from 'client-controller';
import { SlingoRecoveryState } from 'slingo/states/slingo-recovery-state';
import { PopRecoveryState } from 'state-machine/states/pop-recovery-state';

/**
 * Social recovery state, sends recovery message
 *
 * @class SocialRecoveryState
 * @typedef {SocialRecoveryState}
 * @extends {SlingoRecoveryState}
 */
export class SocialRecoveryState extends PopRecoveryState {
  /**
   * Send recovery message
   *
   * @public
   * @override
   */
  public onEnter(): void {
    ClientController.getInstance().raiseEvent(BlastworksClientEvent.RECOVERING);
    super.onEnter();
  }
}
