import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { Layers } from "appworks/graphics/layers/layers";
import { Timer } from "appworks/utils/timer";

export class ClockSubcomponent {
    protected clockText: FlexiText;

    protected timer: number;

    constructor(clockName: string = "clock") {
        this.clockText = Layers.get("Header").getFlexiText(clockName);
        if (this.clockText != null) {
            this.clockText.setVisible(true);

            const millisecondsLeft = 60000 - new Date().getSeconds() * 1000;

            this.updateClock(millisecondsLeft);
        }
    }

    public destroy() {
        this.clockText?.setVisible(false);
        Timer.clearTimeout(this.timer);
    }

    public setVisible(visible: boolean) {
        if (this.clockText != null) {
            this.clockText.setVisible(visible);
        }
    }
    
    protected updateClock(milliseconds: number = 60000) {
        this.clockText.setText(this.getTime());

        this.timer = Timer.setTimeout(() => {
            this.updateClock();
        }, milliseconds);
    }

    protected getTime() {
        const today = new Date();
        const hours = today.getHours();
        const minutes = today.getMinutes();

        return this.leadZeroes(hours) + ":" + this.leadZeroes(minutes);
    }

    protected leadZeroes(n: number) {
        if (n < 10) {
            return "0" + n.toString();
        } else {
            return n.toString();
        }
    }
}
