import { Components } from "appworks/components/components";
import { HTTPConnector } from "appworks/server/connectors/http-connector";
import { Contract } from "appworks/utils/contracts/contract";
import { AxiosError, AxiosRequestConfig } from "axios";
import { GMRAlertComponent } from "gaming-realms/components/gmr-alert-component";
import { GamingRealms } from "gaming-realms/gaming-realms";

export class GMRHTTPConnector extends HTTPConnector {
    public sendRequest(request: any): Contract<{}> {
        const axiosRequest = request as AxiosRequestConfig;

        // Prepend base url to request url
        if (!axiosRequest.baseURL) {
            axiosRequest.baseURL = this.getBaseUrl();
        }
        axiosRequest.url = axiosRequest.url || "";

        return super.sendRequest(axiosRequest);
    }

    protected processError(error: AxiosError, resolve: (value?: any) => void) {
        const errorType = (error.response?.data as any)?.status;
        const nonFatalErrors = [
            "ACCOUNT_LIMIT_EXCEEDED",
            "WAGER_CASH",
            "RESPONSIBLE_GAMING_TIME_LIMIT_MESSAGE"
        ];

        if (errorType) {
            if (errorType === "INVALID_TOKEN") {
                GamingRealms.wrapperInstance.error(so.ErrorType.SESSION_EXPIRED);
            } else {
                GamingRealms.wrapperInstance.error(so.ErrorType.getErrorById(errorType));
            }

            const responseData = error?.response?.data as any;
            const wrapperData = responseData?.wrapper;
            if (wrapperData) { GamingRealms.wrapperInstance.updateWrapper(wrapperData); }

            if (errorType.includes("THIRD_PARTY_EXCEPTION")) {
                // Wrapper should be showing a popup
                GamingRealms.waitForErrorComplete().then(() => resolve(null));
            } else if (errorType.includes("INSUFFICIENT_FUNDS")) {
                Components.get(GMRAlertComponent).insufficientFunds().then(() => resolve(null));
            } else {
                Components.get(GMRAlertComponent).error(errorType, nonFatalErrors.indexOf(errorType) === -1).then(() => resolve(null));
            }
        } else {
            super.processError(error, resolve);
        }
    }

    protected getBaseUrl() {
        const operatorParameters = GamingRealms.wrapperConfig.getOperatorParameters();
        return operatorParameters.getGameServer() + GamingRealms.gameUrl + operatorParameters.getGameId();
    }
}
