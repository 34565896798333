import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { PopSoundEvents } from "setup/pop-sound-events";
import { SlingoCoinHopperComponent } from "slingo/components/slingo-coin-hopper-component";
import { SlingoSpinType } from "slingo/integration/slingo-schema";

export class PopCoinHopperComponent extends SlingoCoinHopperComponent {
    public addCoinFromReels(reelIndex: number, spinType: SlingoSpinType): Contract {
        const ss = Services.get(SoundService);
        ss.customEvent(PopSoundEvents.coin_flip);
        ss.customEvent(PopSoundEvents.free_spin);
        return super.addCoinFromReels(reelIndex, spinType);
    }

    public addCoin(spinType: SlingoSpinType): Contract {
        Services.get(SoundService).customEvent(PopSoundEvents.coin_insert);
        return super.addCoin(spinType);
    }

    public consumeCoin(): Contract<void> {
        Services.get(SoundService).customEvent(PopSoundEvents.coin_nudge);
        return super.consumeCoin();
    }
}
