import { SlingoCascadeResult } from "slingo/model/results/slingo-cascade-result";
import { SlingoReelSpinResultBuilder } from "slingo/integration/builders/results/slingo-reel-spin-result-builder";
import { SlingoResponse } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";

export class SlingoCascadeResultBuilder extends SlingoReelSpinResultBuilder {
    public build(record: SlingoRecord, data: SlingoResponse): SlingoCascadeResult[] {
        const results: SlingoCascadeResult[] = [];
        const symbols = [...data.spinResult.reelSequence.symbols].splice(5, Infinity);

        while (symbols.length) {
            const result = new SlingoCascadeResult();
            result.symbols = symbols.splice(0, 5);

            result.matches = [];
            for (const match of data.spinResult.matchedNumbers) {
                if (result.symbols.indexOf(match.toString()) !== -1) {
                    result.matches.push({
                        matchedValue: match,
                        reelIndex: result.symbols.indexOf(match.toString())
                    });
                }
            }

            results.push(result);
        }

        let previousSymbols = data.spinResult.reelSequence.symbols.slice(0, 5);
        results.forEach((result) => {
            result.newSymbols = result.symbols.map((symbol, index) => previousSymbols[index] === symbol ? false : true);
            previousSymbols = result.symbols;
        });

        // Only include cascades after the last joker (not including if there is one in current cascade), as we must have seen everything up until then
        let unseenCascadesIndex = 0;
        for (let i = 0; i < results.length - 1; i++) {
            if (results[i].symbols.find((symbol) => symbol.indexOf("J") > -1)) {
                unseenCascadesIndex = i + 1;
            }
        }

        return results.slice(unseenCascadesIndex, results.length);
    }
}
