import { Components } from "appworks/components/components";
import { Timer } from "appworks/utils/timer";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";

class DebugSpinTimer {
    private timer: HTMLDivElement;
    private startTime: number;
    private interval?: number;

    private initialised: boolean;

    constructor() {
        this.timer = document.createElement("div");

        this.timer.style.fontSize = "2vw";
        this.timer.style.color = "white";
        this.timer.style.position = "fixed";
        this.timer.style.top = "1vh";
        this.timer.style.right = "2vw";
        this.timer.style.width = "auto";
        this.timer.style.height = "auto";
        this.timer.innerText = "0:00";
        this.timer.style.display = "none";

        this.initialised = false;
    }

    public toggle(): void {
        const show: boolean = this.timer.style.display === "none";
        this.timer.style.display = (show) ? "block" : "none";
        if (show) {
            this.init();
        } else {
            this.stop();
        }
    }

    private init(): void {
        if (!this.initialised) {
            document.body.appendChild(this.timer);

            Components.get(AbstractMatrixComponent).startSignal.add(() => this.start());
            Components.get(AbstractMatrixComponent).stopSignal.add(() => this.stop());

            this.initialised = true;
        }

        this.timer.innerText = "0:00";
    }

    private start(): void {
        this.startTime = Date.now();
        this.timer.innerText = "0:00";
        this.interval = Timer.setInterval(() => this.update(), 0);
    }

    private stop(): void {
        if (this.interval) {
            Timer.clearInterval(this.interval);
            this.interval = undefined;
        }
        this.update();
    }

    private update(): void {
        const time = (Date.now() - this.startTime) / 1000;
        this.timer.innerText = time.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/\./, ":");
    }
}

export const debugSpinTimer = new DebugSpinTimer();
