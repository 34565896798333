import { Gameplay } from "appworks/model/gameplay/gameplay";
import { CloseRequestPayload } from "appworks/model/gameplay/requests/close-request-payload";
import { RequestBuilder } from "appworks/server/builders/request-builder";
import { AxiosRequestConfig } from "axios";

export class GMRCollectRequestBuilder implements RequestBuilder {

    constructor(protected httpMethod:string = "put") {

    }

    public filter(data: any): boolean {
        return data instanceof CloseRequestPayload;
    }

    public build(gameplay: Gameplay, data: any) {
        return {
            method: this.httpMethod,
            url: "/actions/COLLECT",
            data: {}
        } as AxiosRequestConfig;
    }
}
