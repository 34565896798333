import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Contract } from "appworks/utils/contracts/contract";
import { BubbleSpineState } from "components/pop-bubble-spine-component";
import { PopReelMatrixComponent } from "components/pop-reel-matrix-component";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { SlingoSpinState } from "slingo/states/slingo-spin-state";
import { getDabContracts } from "./get-dab-contracts";

export class PopSpinState extends SlingoSpinState {
    protected onReelLand(reelIndex: number, match?: { matchedValue: number, reelIndex: number }) {
        super.onReelLand(reelIndex, match);

        if (match) {
            Components.get(PopReelMatrixComponent).getBubble(match.reelIndex).setState(BubbleSpineState.MATCH).execute();
        }
    }

    protected getDabContracts(): Contract {
        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord();
        const spinResult = record.getFirstResultOfType(SlingoReelSpinResult);
        return getDabContracts(spinResult.matches);
    }

    protected updateTotalWin(tickTime: number = 500): Contract {
        return super.updateTotalWin(100);
    }
}
