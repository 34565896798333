import { Result } from "appworks/model/gameplay/records/results/result";
import { SlingoCompletionReason, SlingoGame } from "slingo/integration/slingo-schema";

export class SlingoGameProgressResult extends Result implements SlingoGame {
    public resultType: string = "SlingoGameProgressResult";

    public completionReason: SlingoCompletionReason;
    public totalWin: number;
    public totalStake: number;
    public standardSpins: number;
    public standardSpinsUsed: number;
    public standardSpinsRemaining: number;
    public purchaseSpins: number;
    public purchaseSpinsUsed: number;
    public purchaseSpinsRemaining: number;
    public hasFirstPurchaseSpin: boolean;
    public freeSpins: number;
    public freeSpinsUsed: number;
    public freeSpinsRemaining: number;
    public matchedNumbers: number[];
    public matchedPatterns: number;
    public patternWin: number;
    public symbolWin: number;
    public standardWin: number;
    public purchaseWin: number;
    public jackpotWin: number;
    public standardStake: number;
    public purchaseStake: number;
    public nextPurchaseStake: number;
    public nextStakeToSpin: number;
    public nextStakeToSpinChance: number;
    public currencyCode: string;
}
