import { Components } from "appworks/components/components";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Services } from "appworks/services/services";
import { normalizeIndex, wrapIndex } from "appworks/utils/collection-utils";
import { KeyInput } from "appworks/utils/key-input";
import { KeyboardCode } from "appworks/utils/keyboard";
import { SignalBinding } from "signals";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { slotDefinition } from "slotworks/model/slot-definition";

export class ReelsetControlDebugger {
    protected containerEl: HTMLDivElement;
    protected selectReelsetEl: HTMLSelectElement;
    protected reelstopsEl: HTMLInputElement;
    protected reelsLandedBinding: SignalBinding;
    protected matrix: MatrixComponent;

    constructor() {
        const keyInput = new KeyInput();
        keyInput.onKeyUp.add((key: string, e: KeyboardEvent) => {
            if (e.shiftKey) {
                if (key === KeyboardCode.R) {
                    this.toggle();
                }
            }
        });
    }

    public start() {
        this.draw();
    }

    public toggle() {
        this.matrix = Components.get(MatrixComponent);

        const reelsets = this.getReelsets();
        const firstReelsetId = Array.from(reelsets.keys())[0];

        if (!this.containerEl) {
            this.start();
            this.setReelset(firstReelsetId);
        } else {
            if (this.containerEl.parentElement) {
                Services.get(CanvasService).rootNode.removeChild(this.containerEl);
            } else {
                Services.get(CanvasService).rootNode.appendChild(this.containerEl);
                this.setReelset(firstReelsetId);
            }
        }
    }

    protected draw() {
        this.containerEl = document.createElement("div");
        this.containerEl.classList.add("reelset-control");

        this.selectReelsetEl = document.createElement("select");
        this.selectReelsetEl.classList.add("reelset-control__reelset-selector");
        this.containerEl.appendChild(this.selectReelsetEl);

        const reelsets = this.getReelsets();
        
        for (const reelsetId of Array.from(reelsets.keys())) {
            const option = document.createElement("option");
            option.value = reelsetId;
            option.innerText = reelsetId;
            this.selectReelsetEl.appendChild(option);
        }

        this.selectReelsetEl.addEventListener("change", () => this.setReelset(this.selectReelsetEl.value));

        const upRowEl = document.createElement("div");
        upRowEl.classList.add("reelset-control__row");
        this.containerEl.appendChild(upRowEl);

        const downRowEl = document.createElement("div");
        downRowEl.classList.add("reelset-control__row");
        this.containerEl.appendChild(downRowEl);

        for (let i = 0; i < slotDefinition.matrixGrid.length; i++) {
            const btn = document.createElement("button");
            btn.innerHTML = "▲";
            btn.addEventListener("pointerdown", () => this.reelUp(i));
            upRowEl.appendChild(btn);
        }

        for (let i = 0; i < slotDefinition.matrixGrid.length; i++) {
            const btn = document.createElement("button");
            btn.innerHTML = "▼";
            btn.addEventListener("pointerdown", () => this.reelDown(i));
            downRowEl.appendChild(btn);
        }

        this.reelstopsEl = document.createElement("input");
        this.reelstopsEl.classList.add("reelset-control__reelstops");
        this.containerEl.appendChild(this.reelstopsEl);

        Services.get(CanvasService).rootNode.appendChild(this.containerEl);

        if (!this.reelsLandedBinding) {
            this.reelsLandedBinding = Components.get(AbstractMatrixComponent).getTransition().onReelLand.add((reelIndex) => {
                if (reelIndex === slotDefinition.matrixGrid.length - 1) {
                    setTimeout(() => this.updateReelstops(), 100);
                }
            });
        }

        this.updateReelstops();
    }

    protected reelDown(reelIndex: number) {
        const matrix = Components.get(MatrixComponent);
        const stops = matrix.getCurrentStops();
        stops[reelIndex] = normalizeIndex(stops[reelIndex] + 1, matrix.getCurrentReelset()[reelIndex]);
        matrix.jump(stops, matrix.getCurrentReelset());

        this.updateReelstops();
    }

    protected reelUp(reelIndex: number) {
        const matrix = Components.get(MatrixComponent);
        const stops = matrix.getCurrentStops();
        stops[reelIndex] = normalizeIndex(stops[reelIndex] - 1, matrix.getCurrentReelset()[reelIndex]);
        matrix.jump(stops, matrix.getCurrentReelset());

        this.updateReelstops();
    }

    protected setReelset(reelsetId: string) {
        const reelsets = this.getReelsets();
        const reelset = reelsets.get(reelsetId);
        this.matrix.setCustomReelset(reelset);
        this.matrix.jump(this.matrix.getCurrentStops(), reelset);
    }

    protected updateReelstops() {
        this.reelstopsEl.value = this.matrix.getCurrentStops().join(", ");
    }

    protected getReelsets() {
        return slotDefinition.reelsets;
    }
}
