import { Setup } from "appworks/boot/setup";
import { Components } from "appworks/components/components";
import { PreloaderComponent } from "appworks/components/preloader/preloader-component";
import { gameLoop } from "appworks/core/game-loop";
import { LoaderService } from "appworks/loader/loader-service";
import { Model } from "appworks/model/model";
import { commsManager } from "appworks/server/comms-manager";
import { HTTPConnector } from "appworks/server/connectors/http-connector";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { UIFlagState } from "appworks/ui/flags/ui-flag-state";
import { UIFlag, uiFlags, UIMenuFlag } from "appworks/ui/flags/ui-flags";
import { appendCacheBustStringToUrl } from "appworks/utils/browser-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { logger } from "appworks/utils/logger";
import { ValueList } from "appworks/utils/value-list";
import Axios, { AxiosResponse } from "axios";
import { slotModel } from "slotworks/model/slot-model";
import { AutoplayService } from "slotworks/services/autoplay/autoplay-service";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { Jurisdiction, JurisdictionService } from "slotworks/services/jurisdiction/jurisdiction-service";
import { SlotWorks } from "slotworks/slotworks";
import { GMRAlertComponent } from "./components/gmr-alert-component";
import { gameState } from "appworks/model/game-state";
import { Layers } from "appworks/graphics/layers/layers";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Signal } from "signals";
import { fakeConfig } from "gaming-realms/wrapper/fake-config";

declare const __DEBUG__: boolean;

export interface GamingRealmsConfig {
    isSlot: boolean;
}

export interface FreeRoundsData {
    uuid: string,
    stake: number
}

export class GamingRealms implements so.IGame {
    public static get wrapperInstance(): so.IGameWrapper {
        return so.Wrapper.getInstance();
    }

    public static wrapperConfig: so.IWrapperConfig;
    public static gameUrl: string;
    public static freeRoundsNewGameRequest: Signal = new Signal();

    protected static config: GamingRealmsConfig;

    protected queuedAlerts: Map<string, Function> = new Map();
    protected pendingErrorCompletes: Function[] = [];
    protected freeRoundsData: FreeRoundsData;
    protected startSlotworks: Function;

    public static go(
        setup: Setup | Setup[],
        assetworksData: any,
        gameUrl: string,
        config: Partial<GamingRealmsConfig> = {}
    ) {
        GamingRealms.config = { isSlot: false, ...config };

        const setups: Setup[] = (Array.isArray(setup)) ? setup : [setup];

        if (GamingRealms.instance) {
            throw new Error("GamingRealms integration already instantiated");
        } else {
            GamingRealms.instance = new GamingRealms();
        }

        GamingRealms.instance.startSlotworks = () => SlotWorks.go(setups, assetworksData);

        GamingRealms.gameUrl = gameUrl;

        GamingRealms.instance.loadWrapper();
    }

    // When displaying errors is handled by the wrapper, this will return a contract that will resolve when the wrapper is finished handling the error
    public static waitForErrorComplete() {
        return new Contract<void>((resolve) => {
            this.instance.pendingErrorCompletes.push(resolve);
        });
    }

    public static isHistory() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const historyId = urlParams.get("encryptedId");

        return !!historyId;
    }

    public static isArcade() {
        return GamingRealms.wrapperConfig.getGame().getId().slice(-2).toLowerCase() === "-a";
    }

    public static getLocale() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (GamingRealms.isHistory()) {
            return urlParams.get("locale") || "en-GB";
        } else {
            if (GamingRealms.wrapperConfig.getOperatorConfig().hasLangOverrides()) {
                if ((GamingRealms.wrapperConfig.getOperatorConfig() as any).getLangOverrideType() === "LangOverride.SOCIAL") {
                    return "en-SC";
                }
            }

            return GamingRealms.wrapperConfig?.getOperatorParameters()?.getLocale() ?? urlParams.get("locale") ?? "en-GB";
        }
    }

    // This makes debugging wrapper state issues much easier
    // than calling wrapperInstance.updateState directly
    public static setWrapperGameState(state: so.GameState) {
        logger.log("Setting GMR wrapper state: " + (state as any).id);
        GamingRealms.wrapperInstance.updateState(state);
    }

    public static getFreeRoundsData(): FreeRoundsData {
        return GamingRealms.instance.freeRoundsData;
    }

    protected static instance: GamingRealms;

    protected realityCheckPending: so.IRealityCheck;

    protected constructor() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const accountId = urlParams.get("accountId");

        if (accountId === "rndacc") {
            const rndString = (Math.random() + 1).toString(36).substring(4);
            const newHref = window.location.href.replace("rndacc", rndString);
            window.location.href = newHref;
        }
    }

    public wrapperReady(config: so.IWrapperConfig): void {

        // Force IOS for Native App version
        if (so.Wrapper.NATIVE) {
            (window as any).forceIOS = true;
        }

        GamingRealms.wrapperConfig = config;
        GamingRealms.instance.startSlotworks();

        (commsManager.connector as HTTPConnector).defaultHeaders.set(
            "Authorization",
            config.getLogin().getToken()
        );

        this.setupClientEvents();

        this.setupClientConfig();

        this.setupRulesiFrame();
    }

    public wrapperFailed(config: so.IWrapperConfig, type: so.ErrorType): void {
        logger.error("wrapperFailed: " + type.getId(), config, type);

/////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////
/////////
//////////////////

        GamingRealms.wrapperConfig = config;
        GamingRealms.instance.startSlotworks();

        const preloader = Components.get(PreloaderComponent);
        if (preloader && !preloader.isComplete()) {
            preloader.loadComplete().then(() => this.error(type));
        } else {
            this.error(type);
        }
    }

    public updateAction(action: so.WrapperFreeRoundsAction, data?: any): void {
        logger.debug(`GMR Wrapper Action: ${(action as any).id}`);

        const soundService = Services.get(SoundService);

        switch (action) {
            case so.WrapperAction.AUTO_PLAY_STOP:
                Services.get(AutoplayService).stop();
                return;

            case so.WrapperAction.BALANCE_REFRESH:
                GamingRealms.wrapperInstance.getBalance();
                return;

            case so.WrapperAction.ERROR_COMPLETE:
                this.pendingErrorCompletes.forEach((resolve) => resolve());
                this.pendingErrorCompletes = [];
                gameLoop.setPaused("GMRwrapper", false);
                return;

            case so.WrapperAction.HELP_CLOSE:
                uiFlags.menu(UIMenuFlag.RULES, false);
                return;

            case so.WrapperAction.HELP_OPEN:
                uiFlags.menu(UIMenuFlag.RULES, true);
                return;

            case so.WrapperAction.PAYTABLE_OPEN:
                uiFlags.menu(UIMenuFlag.PAYTABLE, true);
                return;

            case so.WrapperAction.PAYTABLE_CLOSE:
                uiFlags.menu(UIMenuFlag.PAYTABLE, false);
                return;

            case so.WrapperAction.PAUSE:
                gameLoop.setPaused("GMRwrapper", true);
                return;

            case so.WrapperAction.RESUME:
                gameLoop.setPaused("GMRwrapper", false);
                return;

            case so.WrapperAction.SOUND_OFF:
                soundService.mute();
                soundService.setMusicMute(true);
                soundService.setSoundFxMute(true);
                return;

            case so.WrapperAction.SOUND_ON:
                soundService.unmute();
                soundService.setMusicMute(false);
                soundService.setSoundFxMute(false);
                return;

            case so.WrapperFreeRoundsAction.FREE_ROUNDS_IN:
                this.freeRoundsData = {
                    uuid: data.uuid,
                    stake: data.stake * 100
                };
                uiFlags.set(UIFlag.FREE_BETS, true);

                const betModel = slotModel.read().bet;
                betModel.creditSizes.currentValue = this.freeRoundsData.stake;
                slotModel.write({ bet: betModel });

                this.updateFreeRoundsBanner();

                break;

            case so.WrapperFreeRoundsAction.FREE_ROUNDS_OUT:
                this.freeRoundsData = undefined;
                uiFlags.set(UIFlag.FREE_BETS, false);
                break;

            case so.WrapperFreeRoundsAction.FREE_ROUNDS_NEW_GAME_REQUEST:
                GamingRealms.freeRoundsNewGameRequest.dispatch();
                break;
        }
    }

    public error(type: so.ErrorType, data?: any): void {
        const alert = Components.get(GMRAlertComponent);

        const loader = Services.get(LoaderService);
        if (!alert && !loader.allStagesLoaded()) {
            loader.onStageLoad.addOnce(() => this.error(type, data));
            return;
        }

        switch (type) {
            case so.ErrorType.REALITY_CHECK:
                this.realityCheckPending = data;
                if (!uiFlags.has(UIFlag.GAME_IN_PROGRESS)) {
                    this.realityCheck();
                }
                break;

            case so.ErrorType.INSUFFICIENT_FUNDS:
            case so.ErrorType.INSUFFICIENT_BALANCE:
                alert.insufficientFunds().execute();
                break;

            case so.ErrorType.GAME_ACCESS_DENIED:
                alert.error("GAME_DISABLED", true).execute();
                break;

            case so.ErrorType.SESSION_EXPIRED:
                alert.error("INVALID_TOKEN", true).execute();
                break;

            case so.ErrorType.UNKNOWN_PROMOTION_CODE:
                alert.error("INVALID_STAKE_AMOUNT", true).execute();
                break;

            case so.ErrorType.REGULATORY_MESSAGE:
                alert.error("REGULATORY_MESSAGE", true).execute();
                break;

            case so.ErrorType.WAGER_BONUS_MESSAGE:
                if (GamingRealms.config.isSlot) {
                    this.queueAlert(() => alert.bonusMoney().execute());
                } else {
                    alert.bonusMoney().execute();
                }
                break;

            case so.ErrorType.WAGER_CASH_MESSAGE:
                if (GamingRealms.config.isSlot) {
                    this.queueAlert(() => alert.error("WAGER_CASH", false).execute());
                } else {
                    alert.error("WAGER_CASH", false).execute();
                }
                break;

            case so.ErrorType.ACCOUNT_LIMIT_EXCEEDED:
                alert.error("ACCOUNT_LIMIT_EXCEEDED", true).execute();
                break;

            case so.ErrorType.CURRENCY_NOT_SUPPORTED:
                alert.error("WAGER_CURRENCY_NOT_MATCHED", true).execute();
                break;

            case so.ErrorType.ACCOUNT_LIMIT_EXCEEDED_GENERIC:
                alert.error("SPENDING_BUDGET_EXCEEDED", true).execute();
                break;

            case so.ErrorType.RESPONSIBLE_GAMING_TIME_LIMIT:
                alert.error("RESPONSIBLE_GAMING_TIME_LIMIT_MESSAGE", true).execute();
                break;

            case so.ErrorType.JACKPOT_ALREADY_WON:
                alert.error("JACKPOT_ALREADY_WON", true).execute();
                break;

            case so.ErrorType.RESPONSIBLE_GAMING_TURNOVER_LIMIT:
                alert.error("RESPONSIBLE_GAMING_TURNOVER_LIMIT", true).execute();
                break;

            case so.ErrorType.USER_NOT_AUTHENTICATED:
            case so.ErrorType.RETRIABLE_WIN_OCCURRED:
            case so.ErrorType.EXTERNAL_MESSAGE:
            case so.ErrorType.GENERIC_ERROR:
            case so.ErrorType.CONNECTION_ERROR:
                alert.error("SERVER_ERROR", true).execute();
                break;
        }
    }

    public updateBalance(balanceData: so.IBalances) {
        const gameplay = gameState.getCurrentGame();
        if (gameplay) {
            gameplay.balance = balanceData.getTotal() * 100;
        }

        Services.get(TransactionService).setBalance(balanceData.getTotal() * 100);
        GamingRealms.wrapperInstance.updateBalance(balanceData);
    }

    protected loadWrapper() {
        if (GamingRealms.isHistory()) {
            Model.write({ settings: { language: GamingRealms.getLocale() } });
            GamingRealms.instance.startSlotworks();
            return;
        }

        const wrapperScript: HTMLScriptElement = document.createElement("script");
        wrapperScript.type = 'text/javascript';
        document.head.appendChild(wrapperScript);

        const wrapperURLs = [
            `${location.origin}/rgs/wrapper/so-wrapper.min.js`, // for web
            `${location.origin}${location.pathname.substring(0, location.pathname.lastIndexOf("/"))}/bin/so-wrapper.min.js`, // for iOS bundles,
            `https://alchemy.gamingrealms.net/rgs/wrapper/so-wrapper.min.js`
        ];

        // Fallback for local environments with no copy of the wrapper, fallback to the staging wrapper
        const fallbackEnvs = ["localhost", "slot.works"];
        fallbackEnvs.forEach((env) => {
            if (location.href.includes(env)) {
                wrapperURLs.push("https://alchemy-sta.gamingrealms.net/rgs/wrapper/so-wrapper.min.js");
            }
        });

        const tryNextWrapperURL = () => {
            const url = wrapperURLs.shift();
            if (url) {
                Axios.get(appendCacheBustStringToUrl(url))
                    .then((response: AxiosResponse) => {
                        wrapperScript.text = response.data;
                        logger.log("GMR wrapper loaded from: " + url);
                        GamingRealms.wrapperInstance.initialise(GamingRealms.instance);
                    })
                    .catch(tryNextWrapperURL);
            } else {
                throw new Error("Couldn't find GMR wrapper");
            }
        };
        tryNextWrapperURL();
    }

    protected queueAlert(callback: Function, id?: string): void {
        const promptLayer: Layers = Layers.get("Prompts");

        const progress = (): void => {
            if (this.queuedAlerts.size === 0) {
                return;
            }

            const [id, callback] = Array.from(this.queuedAlerts.entries())[0];
            callback();

            promptLayer.onSceneEnter.addOnce((scene: string) => {
                if (scene === "default") {
                    this.queuedAlerts.delete(id);
                    progress();
                }
            });
        }

        this.queuedAlerts.set(id ?? Date.now().toString(), callback);

        const gameInProgress: boolean = !uiFlags.has(UIFlag.IDLE);
        const isShowingPrompt: boolean = !promptLayer.currentSceneIs("default");

        if (gameInProgress && this.queuedAlerts.size > 1) {
            return;
        }

        if (gameInProgress) {
            uiFlags.hook(new UIFlagState(UIFlag.IDLE)).on.addOnce(() => {
                progress();
            });
            return;
        }

        if (!isShowingPrompt) {
            progress();
        }
    }

    protected setupClientConfig() {
        const config = GamingRealms.wrapperConfig.getOperatorConfig();

        Model.write({ settings: { language: GamingRealms.getLocale() } });

        slotModel.write({ regulations: { showClock: config.isClockEnabled() } });

        Services.get(JurisdictionService).setAutoplayEnabled(config.isAutoPlayEnabled());

        if (GamingRealms.wrapperConfig.getOperator().getJurisdiction()?.isGermany()) {
            Services.get(JurisdictionService).setJurisdiction(Jurisdiction.Germany);
        }

        Services.get(SlotBetService).onBetChange.add(() => this.setAutoplayLimits());

        Services.get(JurisdictionService).setBuyBonusEnabled(config.isBuyFeatureEnabled());

        Services.get(JurisdictionService).setSpinSkippingEnabled(config.isForceStopEnabled());

        Services.get(CanvasService).onResize.add(() => this.updateFreeRoundsBanner());
    }

    protected setupRulesiFrame() {
        const iFrame: HTMLIFrameElement = document.getElementById("rules_iframe") as HTMLIFrameElement;
        if (iFrame) {
            const locale = GamingRealms.getLocale();
            const url = appendCacheBustStringToUrl(`./rules/${locale}.html`);
            const fallbackUrl = appendCacheBustStringToUrl(`./rules/en-GB.html`);

            Axios.get(url)
                .then(() => { // url is valid
                    iFrame.src = url;
                })
                .catch(() => { // url invalid, use fallback
                    logger.warn(`No rules page found for locale ${locale}, falling back to en-GB`);
                    iFrame.src = fallbackUrl;
                });
        }
    }

    protected setAutoplayLimits() {
        const bet = Services.get(SlotBetService).getTotalStake();

        const autoplayModel = slotModel.read().autoplay;

        const config = GamingRealms.wrapperConfig.getOperatorConfig();
        const maxAutoplays = config.getMaxAutoSpins();
        const autoplayIncrement = Math.floor(maxAutoplays / 5);

        autoplayModel.autoplays = new ValueList([1, 2, 3, 4, 5].map((n) => autoplayIncrement * n));
        autoplayModel.lossLimit.values = [5, 10, 20, 50, 100].map((n) => n * bet);
        autoplayModel.lossLimit.defaultValue = bet * 100;
        autoplayModel.singleWinLimit.values = [...[10, 20, 100, 200].map((n) => n * bet), Number.MAX_VALUE];
        autoplayModel.singleWinLimit.defaultValue = Number.MAX_VALUE;

        slotModel.write({ autoplay: autoplayModel });
    }

    /**
     * This method will set up events the client is supposed to report to the wrapper
     * This is possible because everything the wrapper needs is possible to determine from various service, component, model and ui flag events
     */
    protected setupClientEvents() {
        // updateProgress
        const preloaderComponent = Components.get(PreloaderComponent);
        if (preloaderComponent) {
            preloaderComponent.onProgress.add((percent: number) => GamingRealms.wrapperInstance.updateProgress(percent));
        }

        // ====== updateState ====== //
        // GameState.PRELOAD_COMPLETE The game has completed its preload sequence
        // Immediately set preload complete, since it's unclear what this is compared to load complete
        GamingRealms.setWrapperGameState(so.GameState.PRELOAD_COMPLETE);

        // GameState.LOAD_COMPLETE The game has completed its load sequence
        if (preloaderComponent) {
            preloaderComponent.onComplete.addOnce(() => GamingRealms.setWrapperGameState(so.GameState.LOAD_COMPLETE));
        }

        // GameState.GAME_READY The game is first ready to play
        uiFlags.hook(new UIFlagState(UIFlag.GAME_STARTED)).on.addOnce(() => {
            GamingRealms.setWrapperGameState(so.GameState.GAME_READY);
            GamingRealms.wrapperInstance.updateStake(Services.get(SlotBetService).getTotalStake() / 100);
            this.updateMute();
        });

        // GameState.GAME_IDLE The game enters an idle state
        const idleHook = uiFlags.hook(UIFlagState.IDLE);
        idleHook.on.add(() => GamingRealms.setWrapperGameState(so.GameState.GAME_IDLE));
        // GameState.GAME_BUSY The game enters a busy state
        idleHook.off.add(() => GamingRealms.setWrapperGameState(so.GameState.GAME_BUSY));

        // GameState.GAME_COMPLETE The active game is complete
        const gameInProgressHook = uiFlags.hook(new UIFlagState(UIFlag.GAME_IN_PROGRESS));
        gameInProgressHook.off.add(() => {
            GamingRealms.setWrapperGameState(so.GameState.GAME_COMPLETE);
            if (this.realityCheckPending) {
                this.realityCheck();
            }
        });
        // GameState.START_GAME The player starts a new game - handled in request builder
        gameInProgressHook.on.add(() => GamingRealms.setWrapperGameState(so.GameState.START_GAME));

        // ====== updateAction ====== //
        // GameAction.EXIT_GAME - See UI setup
        // GameAction.DEPOSIT - See UI setup; May not be included, TBC
        // GameAction.BET_HISTORY - See UI setup; May not be included, TBC
        // GameAction.BONUS - See UI setup; May not be included, TBC
        // GameAction.REFRESH - See UI setup; May not be included, TBC

        // GameAction.SOUND_ON
        // GameAction.SOUND_OFF
        const soundService = Services.get(SoundService);
        soundService.onMuteChange.add(() => this.updateMute());
        soundService.onFXMuteChange.add(() => this.updateMute());
        soundService.onMusicMuteChange.add(() => this.updateMute());
        this.updateMute();

        // TODO: Controls

        // updateStake
        Services.get(SlotBetService).onBetChange.add(() => {
            GamingRealms.wrapperInstance.updateStake(Services.get(SlotBetService).getTotalStake() / 100);
        });

        // updateWin
        Services.get(TransactionService).totalWinUpdate.add((win, ticking) => {
            if (!ticking) {
                GamingRealms.wrapperInstance.updateWin(win / 100);
            }
        });
    }

    protected updateMute() {
        const soundService = Services.get(SoundService);
        if (soundService.getMuted() || (soundService.getMusicMuted() && soundService.getSoundFxMuted())) {
            GamingRealms.wrapperInstance.updateAction(so.GameAction.SOUND_OFF);
        } else {
            GamingRealms.wrapperInstance.updateAction(so.GameAction.SOUND_ON);
        }
    }

    protected realityCheck(): void {
        if (this.realityCheckPending == null) {
            return;
        }

        const pendingRealityCheck = this.realityCheckPending;

        this.queueAlert(() => {
            Components.get(GMRAlertComponent).realitycheck(
                pendingRealityCheck.getDetails().getTotalSessionTime(),
                pendingRealityCheck.getWinLoss()?.getTotalLoss() * 100 || 0,
                pendingRealityCheck.getWinLoss()?.getTotalWin() * 100 || 0
            ).execute();
            this.realityCheckPending = null;
        }, "realitycheck");
    }

    protected updateFreeRoundsBanner() {
        GamingRealms.wrapperInstance.updateFreeRoundsPositions({
            getScale: () => { return 1; }, // 0-1
            getInfoBannerType: () => { return "LEFT"; }, // "BOTTOM" or "LEFT"
            getInfoBannerLocation: () => { return { x: 0, y: window.innerHeight / 2 }; },
            getInfoBannerColors: () => { return ["#ffffff", "#00b1b6", "#ffffff", "#00b1b6"]; }
        });
    }
}