import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { ButtonElement, ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { Services } from "appworks/services/services";
import { GenericActionButtonControl } from "appworks/ui/controls/standard/button/generic-action-button-control";
import { GenericControlFactory } from "appworks/ui/factories/generic-control-factory";
import { GetStandardFactories } from "appworks/ui/factories/get-standard-factories";
import { ButtonOrganiserControlFactory } from "appworks/ui/factories/standard/button-organiser-control-factory";
import { UIFlagState } from "appworks/ui/flags/ui-flag-state";
import { UIMenuFlag, uiFlags, UIFlag } from "appworks/ui/flags/ui-flags";
import { UI } from "appworks/ui/ui";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { SlingoGameInstanceIdControlFactory } from "slingo/ui/factories/slingo-game-instance-id-control-factory";
import { SlingoResponsibleGamingMenuFactory } from "slingo/ui/factories/slingo-responsible-gaming-menu-factory";
import { SlingoStakeTextControlFactory } from "slingo/ui/factories/slingo-stake-text-control-factory";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";

export function SlingoUISetup() {
    UI.layer(Layers.get("SlingoLadder"), [
        {
            scene: "base",
            show: new UIFlagState(UIFlag.ALWAYS, UIMenuFlag.PAYTABLE),
            enable: new UIFlagState(UIFlag.ALWAYS, UIFlag.SPINNING)
        },
        {
            scene: "paytable",
            show: new UIFlagState(UIMenuFlag.PAYTABLE)
        }
    ]);
    UI.button(Layers.get("SlingoLadder"), "ui_paytable", UIMenuFlag.PAYTABLE);
    UI.button(Layers.get("SlingoLadder"), "ui_ladder", UIMenuFlag.PAYTABLE);
    Services.get(CanvasService).onOrientationChange.add(() => {
        if (uiFlags.has(UIMenuFlag.PAYTABLE)) {
            uiFlags.menu(UIMenuFlag.PAYTABLE, false);
            Layers.get("SlingoLadder").jumpToScene("base");
        }
    });

    UI.layer(Layers.get("SpinButton"), [
        {
            scene: "base",
            show: UIFlagState.ALWAYS,
            enable: new UIFlagState(UIFlag.IDLE, UIFlag.NO_SKIP)
        }
    ]);

    UI.layer(Layers.get("BetBar"), [
        {
            scene: "base",
            show: UIFlagState.ALWAYS,
            enable: new UIFlagState(UIFlag.IDLE, UIFlag.FREE_BETS)
        }
    ]);

    UI.layer(Layers.get("Menus"), [
        {
            scene: "stake",
            show: new UIFlagState(UIMenuFlag.STAKE)
        }, {
            scene: "settings",
            show: new UIFlagState(UIMenuFlag.MENU)
        }, {
            scene: "play_controls",
            show: new UIFlagState(UIMenuFlag.RESPONSIBLE_GAMING)
        }, {
            scene: "rules",
            show: new UIFlagState(UIMenuFlag.RULES)
        }
    ]);

    UI.layer(Layers.get("MenuButton"), [
        {
            scene: "base",
            show: new UIFlagState(UIFlag.ALWAYS),
            enable: new UIFlagState(0, UIFlag.SPINNING)
        }
    ]);

    UI.button(Layers.get("BetBar"), "ui_stake_menu", UIMenuFlag.STAKE);
    UI.button(Layers.get("MenuButton"), "ui_settings_menu", UIMenuFlag.MENU);
    UI.button(Layers.get("Menus"), "ui_play_controls", UIMenuFlag.RESPONSIBLE_GAMING, null, new UIFlagState(UIFlag.ALWAYS, UIFlag.GAME_IN_PROGRESS));
    UI.button(Layers.get("Menus"), "ui_help", UIMenuFlag.RULES);
    UI.button(Layers.get("Menus"), "close", null);
    Services.get(SlotBetService).onBetChange.add(() => uiFlags.menu(UIMenuFlag.STAKE, false));

    for (const layer of [Layers.get("Menus"), Layers.get("SlingoLadder")]) {
        layer.onSceneEnter.add(() => {
            const cover = layer.getShape("cover");
            if (cover) {
                cover.interactive = true;
                cover.once(ButtonEvent.CLICK.getPIXIEventString(), () => uiFlags.closeMenus());
                layer.add(cover, false, true);
            }
        });
    }

    UI.register(
        ...GetStandardFactories(),
        new SlingoResponsibleGamingMenuFactory(),
        new SlingoStakeTextControlFactory(),
        new SlingoGameInstanceIdControlFactory(),
        new GenericControlFactory("deposit", ButtonElement, GenericActionButtonControl, [() => GamingRealms.wrapperInstance.updateAction(so.GameAction.DEPOSIT)]),
        new GenericControlFactory("lobby", ButtonElement, GenericActionButtonControl, [() => GamingRealms.wrapperInstance.updateAction(so.GameAction.EXIT_GAME)]),
        new GenericControlFactory("history", ButtonElement, GenericActionButtonControl, [() => GamingRealms.wrapperInstance.updateAction(so.GameAction.DGH), new UIFlagState(UIFlag.ALWAYS, UIFlag.GAME_IN_PROGRESS)]),
        new ButtonOrganiserControlFactory(new Map<string, boolean>([
            ["deposit", GamingRealms.wrapperConfig.getOperatorConfig().isDepositButtonEnabled()],
            ["ui_help", GamingRealms.wrapperConfig.getOperatorConfig().isHelpButtonEnabled()],
            ["ui_play_controls", GamingRealms.getLocale().toLowerCase().includes("gb")],
            ["lobby", GamingRealms.wrapperConfig.getOperatorConfig().isHomeButtonEnabled()],
            ["history", GamingRealms.wrapperConfig.getOperatorConfig().isDetailedGameHistoryEnabled()]
        ]))
    );
    UI.init();
}