import { Jurisdiction } from "slotworks/services/jurisdiction/jurisdiction-service";

export class RegulationsModel {
    public jurisdiction: Jurisdiction | null = null;
    public autoplayAllowed: boolean = true;
    public buyBonusAllowed: boolean = true;
    public turboAllowed: boolean = true;
    public skipAllowed: boolean = true;
    public sessionTimerEnabled: boolean = false;
    public unlimitedAutoplaySpins: boolean = false;
    public autoplayLimits: number[] = null;
    public minPreloaderTime: number = 0;
    public preloaderMessageKey: string = "";
    public minSpinTimes: Array<{ minBet: number, delayMs: number }> = [{ minBet: 0, delayMs: 0 }];
    public netPosition: number = 0;
    public rtp: number;
    public showClock: boolean = true;
    // Whether or not to celebrate wins less than stake
    public celebrateSubWins: boolean = true;
}
