import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { Layers } from "appworks/graphics/layers/layers";
import { Text } from "appworks/graphics/pixi/text";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";

export class BetBarValueText {

    protected currencyValueText: FlexiText;
    protected creditsValueText: FlexiText;
    protected valueText: FlexiText;

    constructor(protected layer: Layers, protected name: string, protected hideNillValue = false, protected wrapText = false) {

    }

    public refresh() {
        this.currencyValueText = this.layer.getFlexiText(this.name + "_currency_value");
        this.creditsValueText = this.layer.getFlexiText(this.name + "_credits_value");
        this.valueText = this.layer.getFlexiText(this.name + "_value");

        for (const flexiText of [this.currencyValueText, this.creditsValueText, this.valueText]) {
            const targets = flexiText?.getTargets() ?? [];
            for (const target of targets) {
                if (target instanceof Text) {
                    target.style.wordWrap = this.wrapText;
                }
            }
        }
    }

    public update(value: number) {
        if (this.currencyValueText) {
            if (value <= 0 && this.hideNillValue) {
                this.currencyValueText.setText("");
            } else {
                this.currencyValueText.setText(Services.get(CurrencyService).format(value, true, false));
            }
        }

        if (this.creditsValueText) {
            if (value <= 0 && this.hideNillValue) {
                this.creditsValueText.setText("");
            } else {
                this.creditsValueText.setText(Services.get(CurrencyService).format(value, true, true));
            }
        }

        if (this.valueText) {
            if (value <= 0 && this.hideNillValue) {
                this.valueText.setText("");
            } else {
                this.valueText.setText(Services.get(CurrencyService).format(value));
            }
        }
    }
}
