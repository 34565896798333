import { Result } from "appworks/model/gameplay/records/results/result";
import { ReelModifierResult } from "slotworks/model/gameplay/records/results/reel-modifier-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { getSymbolImageData, gridDisplay } from "../general-displays/grid-display";

export function reelModifierResultDisplay(record: SpinRecord, result: Result) {
    const container = document.createElement("div");
    container.style.width = "10vw";

    const reelDisplay = gridDisplay(record.grid, record.overlayGrid);

    const symbols = reelDisplay.querySelectorAll(`[id*=symbol_]`);
    [].forEach.call(symbols, (symbol: HTMLElement) => {
        symbol.style.opacity = ".1";
    });

    const reelModifierResult = (result as ReelModifierResult);

    reelModifierResult.expansions.forEach((position) => {
        const symbol = reelDisplay.querySelector(`#symbol_${position.x}_${position.y}`) as HTMLElement;
        symbol.style.opacity = "1";
        if (reelModifierResult.targetSymbolID) {
            symbol.style.backgroundImage = "url(" + getSymbolImageData(reelModifierResult.targetSymbolID) + ")";
        }
    });

    if (reelModifierResult.source) {
        const symbol = reelDisplay.querySelector(`#symbol_${reelModifierResult.source.x}_${reelModifierResult.source.y}`) as HTMLElement;
        symbol.style.opacity = "0.5";
        symbol.style.borderColor = "#f00";
    }

    const infoDisplay = document.createElement("div");
    infoDisplay.style.width = "20vw";
    infoDisplay.innerText = `sourceSymbolId: ${reelModifierResult.sourceSymbolID}`;
    if (reelModifierResult.targetSymbolID) {
        infoDisplay.innerText += `\ntargetSymbolID: ${reelModifierResult.targetSymbolID}`;
    }

    if (reelModifierResult.modifierMeta) {
        infoDisplay.innerText = reelModifierResult.modifierMeta;
    }

    const reelDisplayContainer = document.createElement("div");

    reelDisplayContainer.appendChild(reelDisplay);
    container.appendChild(reelDisplayContainer);
    container.appendChild(infoDisplay);

    return container;
}
