import { Layers } from "appworks/graphics/layers/layers";
import { TotalStakeTextControl } from "appworks/ui/controls/standard/text/total-stake-text-control";
import { ControlFactory } from "../control-factory";

export class TotalStakeTextControlFactory implements ControlFactory {
    public filter(layer: Layers) {
        if (layer.getFlexiText("total_stake")) {
            return true;
        }

        return false;
    }

    public build(layer: Layers) {
        return [new TotalStakeTextControl(layer.getFlexiText("total_stake"))];
    }
}
