import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { AbstractControl } from "../../abstract-control";

export class CoverControl extends AbstractControl {
    constructor(protected area: SmartShape) {
        super();

        this.area.interactive = true;
    }
}
