import { Gameplay } from "appworks/model/gameplay/gameplay";
import { InitRequestPayload } from "appworks/model/gameplay/requests/init-request-payload";
import { PickRequestPayload } from "slotworks/model/gameplay/requests/pick-request-payload";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoResponse, SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecordBuilder } from "./slingo-record-builder";

export class SlingoPickRecordBuilder extends SlingoRecordBuilder {
    public id: string = "SlingoPickRecordBuilder";

    public filter(request: InitRequestPayload, response: any): boolean {
        return request instanceof PickRequestPayload;
    }

    public build(gameplay: Gameplay, request: any, response: any): SlingoRecord[] {
        const record = super.build(gameplay, request, response)[0];
        const responseData = response.data as SlingoResponse;

        if (responseData.state === SlingoState.COMPLETE && !record.hasUnplayedBonuses()) {
            record.lastChild = true;
        }

        return [record];
    }
}
