import { Contract } from 'appworks/utils/contracts/contract';
import { ClientController } from 'client-controller';
import { GMRAlertComponent } from 'gaming-realms/components/gmr-alert-component';

/**
 * Social version of alert component.
 *
 * @class SocialGMRAlertComponent
 * @typedef {SocialGMRAlertComponent}
 * @extends {GMRAlertComponent}
 */
export class SocialGMRAlertComponent extends GMRAlertComponent {
  /**
   * Message client on error
   *
   * @public
   * @override
   * @param {string} messageId
   * @param {?boolean} [fatal]
   * @returns {Contract<void>}
   */
  public error(messageId: string, fatal?: boolean): Contract<void> {
    ClientController.somethingWentWrong(messageId);

    return super.error(messageId, fatal);
  }

  /**
   * Message client for add funds and resolve game dialog.
   *
   * @public
   * @override
   * @returns {Contract}
   */
  public insufficientFunds(): Contract {
    ClientController.getInstance().handleInsufficientFunds();

    return new Contract(resolve => resolve(null));
  }
}
