import { Layers } from "appworks/graphics/layers/layers";
import { Text } from "appworks/graphics/pixi/text";
import { DisplayObject } from "pixi.js";
import { AbstractControl } from "../controls/abstract-control";
import { ControlFactory } from "./control-factory";

export class GenericControlFactory implements ControlFactory {

    constructor(
        protected elementName: string,
        protected elementType: typeof DisplayObject | typeof Text,
        protected controlType: typeof AbstractControl,
        protected controlArgs: any[] = []
    ) {
    }

    public filter(layer: Layers) {
        if (layer.getByReference(this.elementName, this.elementType)) {
            return true;
        }

        return false;
    }

    public build(layer: Layers) {
        const args = [layer.getByReference(this.elementName, this.elementType), ...this.controlArgs];
        return [new this.controlType(...args)];
    }
}
