import { AbstractComponent } from "appworks/components/abstract-component";
import { Layers } from "appworks/graphics/layers/layers";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { AbstractBetService } from "appworks/services/bet/abstract-bet-service";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Contract } from "appworks/utils/contracts/contract";
import { slingoModel } from "slingo/model/slingo-model";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";

export class SlingoLadderComponent extends AbstractComponent {
    protected static MAX_SLINGOS = 12;

    protected layer: Layers;

    protected fillSprites: Map<number, Sprite[]> = new Map();
    protected activeSprites: Map<number, Sprite[]> = new Map();
    protected highlightSprites: Map<number, Sprite[]> = new Map();

    protected currentLevel: number = 0;
    protected cancelGroup = new CancelGroup();

    constructor(protected showTrailingDecimalsOnCashPrizes: boolean = false) {
        super();
        Services.get(AbstractBetService)?.onBetChange.add(() => this.updateCashPrizeValues());
    }

    public init(): void {
        this.layer = Layers.get("SlingoLadder");
        this.updateCashPrizeValues();

        this.fillSprites.clear();
        this.activeSprites.clear();
        this.highlightSprites.clear();
        for (let i = 0; i <= SlingoLadderComponent.MAX_SLINGOS; i++) {
            const fills = this.layer.getSprites([`fill_${i}`, `p_fill_${i}`])
                .filter(sprite => sprite);
            this.fillSprites.set(i, fills);
            fills.forEach(sprite => sprite.visible = false);

            const actives = this.layer.getSprites([`active_${i}`, `p_active_${i}`, `portrait_active_${i}`])
                .filter(sprite => sprite);
            this.activeSprites.set(i, actives);
            actives.forEach(sprite => sprite.visible = false);

            const highlights = this.layer.getSprites([`highlight_${i}`, `p_highlight_${i}`, `portrait_highlight_${i}`])
                .filter(sprite => sprite);
            this.highlightSprites.set(i, highlights);
            highlights.forEach(sprite => sprite.visible = false);
        }

        const lvl = this.currentLevel;
        this.currentLevel = -1;
        this.setValue(lvl).execute();

        this.layer.onSceneEnter.addOnce(() => this.init());
    }

    public stepToLevel(level: number, stepTime: number = 200): Contract {
        this.cancelGroup.skip();

        if (!stepTime) {
            return this.setValue(level);
        }

        const contracts: Array<() => Contract> = [];

        for (let i = this.currentLevel; i <= level; i++) {
            contracts.push(() => this.setValue(i, true, 200));
            contracts.push(() => Contract.getTimeoutContract(stepTime));
        }

        return this.cancelGroup.sequence(contracts);
    }

    public highlightLevel(level: number) {
        const highlights = this.highlightSprites.get(level);
        highlights.forEach(sprite => sprite.visible = true);
    }

    public clearHighlights() {
        return this.setValue(this.currentLevel);
    }

    public getCurrentLevel(): number {
        return this.currentLevel;
    }

    protected setValue(level: number, playSound: boolean = false, durationMs = 0): Contract {
        if (playSound && level > this.currentLevel) {
            Services.get(SoundService).customEvent(SlingoSoundEvent.ladder_level_up);
            Services.get(SoundService).event(SlingoSoundEvent.ladder_level_up_N as any, level.toString());
        }

        this.currentLevel = level;

        this.activeSprites.forEach((sprites, value) => {
            sprites.forEach(sprite => sprite.visible = value === level);
        });

        this.fillSprites.forEach((sprites, value) => {
            sprites.forEach(sprite => sprite.visible = value <= level);
        });

        this.highlightSprites.forEach((sprites, value) => {
            sprites.forEach(sprite => sprite.visible = false);
        });

        return Contract.empty();
    }

    protected updateCashPrizeValues() {
        const currencyService = Services.get(CurrencyService);

        const betMultiplier = Services.get(AbstractBetService).getTotalStake() / 100;
        const payouts = slingoModel.read().payoutConfig.patternPayouts;

        for (let i = 0; i <= SlingoLadderComponent.MAX_SLINGOS; i++) {
            const text = this.layer?.getText("cashprize_" + i);
            if (text) {
                const prize = payouts[i - 1] * betMultiplier;
                text.text = currencyService.format(prize, this.showTrailingDecimalsOnCashPrizes);
            }
        }
    }
}