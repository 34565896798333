import { Result } from "appworks/model/gameplay/records/results/result";
import { SlingoStakeToSpinConfig } from "slingo/integration/slingo-schema";

export class SlingoStakeToSpinResult extends Result {
    public resultType: string = "SlingoStakeToSpinResult";

    public rng: {
        result: number;
        bound: number;
        weight: number;
        isWin: boolean;
    }
}