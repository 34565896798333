import { Result } from "appworks/model/gameplay/records/results/result";
import { SlingoSpinType } from "slingo/integration/slingo-schema";

export class SlingoReelSpinResult extends Result {
    public resultType: string = "SlingoReelSpinResult";
    public symbols: string[];
    public matches: { matchedValue: number, reelIndex: number }[];
    public patterns: number;
    public spinType: SlingoSpinType;
}
