
export enum SoundNames {
    wheel_button_press = "wheel_button_press",
    wheel_entry = "wheel_entry",
    wheel_exit = "wheel_exit",
    wheel_lose = "wheel_lose",
    wheel_spin = "wheel_spin",
    wheel_win = "wheel_win",
    sp_anticipation = "sp_anticipation",
    sp_bet_value_decrease = "sp_bet_value_decrease",
    sp_bet_value_increase = "sp_bet_value_increase",
    sp_board_appears = "sp_board_appears",
    sp_cascade_in_1 = "sp_cascade_in_1",
    sp_cascade_in_2 = "sp_cascade_in_2",
    sp_cascade_in_3 = "sp_cascade_in_3",
    sp_cascade_in_4 = "sp_cascade_in_4",
    sp_coin_flip = "sp_coin_flip",
    sp_coin_insert = "sp_coin_insert",
    sp_coin_nudge = "sp_coin_nudge",
    sp_dab_1 = "sp_dab_1",
    sp_extra_spin_click = "sp_extra_spin_click",
    sp_free_spin_added = "sp_free_spin_added",
    sp_free_spin_chink = "sp_free_spin_chink",
    sp_game_over_no_results = "sp_game_over_no_results",
    sp_launch_game = "sp_launch_game",
    sp_level_up_cashprize = "sp_level_up_cashprize",
    sp_level_up_featureprize = "sp_level_up_featureprize",
    sp_level_up_noprize = "sp_level_up_noprize",
    sp_prize_countup_loop = "sp_prize_countup_loop",
    sp_reel_appear = "sp_reel_appear",
    sp_reel_drop_superwild = "sp_reel_drop_superwild",
    sp_reel_drop_wild = "sp_reel_drop_wild",
    sp_reel_remove = "sp_reel_remove",
    sp_results_big_win = "sp_results_big_win",
    sp_results_full_house = "sp_results_full_house",
    sp_results_normal_win = "sp_results_normal_win",
    sp_results_substake_win = "sp_results_substake_win",
    sp_sinkplug_waterdrain = "sp_sinkplug_waterdrain",
    sp_slingo_1 = "sp_slingo_1",
    sp_slingo_2 = "sp_slingo_2",
    sp_slingo_3 = "sp_slingo_3",
    sp_slingo_4 = "sp_slingo_4",
    sp_slingo_logo = "sp_slingo_logo",
    sp_spin_click = "sp_spin_click",
    sp_theme_1_loop = "sp_theme_1_loop",
    sp_theme_2_loop = "sp_theme_2_loop",
    sp_vanish_number = "sp_vanish_number",
    sp_vanish_wild = "sp_vanish_wild",
    sp_win_big = "sp_win_big",
    sp_win_full_house = "sp_win_full_house",
    sp_win_mega = "sp_win_mega",
    sp_win_scatter_high = "sp_win_scatter_high",
    sp_win_scatter_low = "sp_win_scatter_low",
    sp_win_super = "sp_win_super"
}
