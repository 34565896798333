import { gameState } from "appworks/model/game-state";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";
import { SlingoCascadeResult } from "slingo/model/results/slingo-cascade-result";

export class SlingoHasCascadesDecision extends Decision {
    public evaluate(): Exit {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const results = record.getResultsOfType(SlingoCascadeResult);

        const hasCascades = results.some((result) => !result.played);

        return hasCascades ? Exit.True : Exit.False;
    }
}
