export enum KeyboardCode {
    SPACE = " ",
    ENTER = "Enter",
    LEFT = "ArrowLeft",
    UP = "ArrowUp",
    RIGHT = "ArrowRight",
    DOWN = "ArrowDown",
    EQUAL = "=",
    MINUS = "-",
    PLUS = "+",
    DIGIT0 = "0",
    DIGIT9 = "9",
    DIGIT8 = "8",
    DIGIT7 = "7",
    PERIOD = ".",
    OPEN_BRACKET = "(",
    F = "F",
    I = "I",
    L = "L",
    R = "R",
    U = "U",
    O = "O",
    f = "f"
}
