import { AbstractComponent } from "appworks/components/abstract-component";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Layers } from "appworks/graphics/layers/layers";
import { Container } from "appworks/graphics/pixi/container";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { arrayOfValues } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { Easing, Tween } from "appworks/utils/tween";
import { SlingoSpinType } from "slingo/integration/slingo-schema";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";

interface SlingoCoinHopperConfig {
    layerName: string;
    hopperCoinStandard: string;
    hopperCoinFreespin: string;
    hopperCoinPurchase: string;
}

export class SlingoCoinHopperComponent extends AbstractComponent {
    protected coinContainer: Container;
    protected coinSprites: Sprite[];
    protected spins: SlingoSpinType[];

    protected layer: Layers;

    protected config: SlingoCoinHopperConfig = {
        layerName: "CoinHopper",
        hopperCoinStandard: "hopper_coin_standard",
        hopperCoinFreespin: "hopper_coin_freespin",
        hopperCoinPurchase: "hopper_coin_purchase",
    };

    constructor(config?: Partial<SlingoCoinHopperConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }
    }

    public init(): void {
        this.layer = Layers.get(this.config.layerName);

        this.coinContainer = new Container();
        this.layer.add(this.coinContainer);

        const mask = this.layer.getSprite("hopper_mask") ?? this.layer.getShape("hopper_mask");
        if (mask) {
            this.coinContainer.mask = mask;
        }

        this.coinSprites = [];
        this.spins = [];
    }

    /**
     * @param totalSpins Standard spins + free spins remaining
     * @param freeSpins Total freespins awarded
     * @param purchaseSpins
     */
    public set(totalSpins: number, freeSpins: number, purchaseSpins: number) {
        this.cleanup();

        // Get spin queue
        this.spins.push(...arrayOfValues(totalSpins - freeSpins, SlingoSpinType.STANDARD));
        this.spins.push(...arrayOfValues(totalSpins < freeSpins ? totalSpins : freeSpins, SlingoSpinType.FREESPIN));
        this.spins.push(...arrayOfValues(purchaseSpins, SlingoSpinType.PURCHASE));

        this.spins.forEach((spin: SlingoSpinType, index: number) => {
            const position = this.layer.getPosition("hopper_" + index);
            if (position) {
                const coin = this.getSpriteForSpinType(spin);
                this.coinSprites.push(coin);
                this.coinContainer.addChild(coin);
                coin.setDualPosition(position);
            }
        });
    }

    public addCoinFromReels(reelIndex: number, spinType: SlingoSpinType): Contract {
        const sequence: Array<() => Contract> = [];

        const spine = this.layer.getSpine("free_spin_flying_coin");
        if (spine) {
            sequence.push(() => Contract.wrap(() => Services.get(SoundService).event((SlingoSoundEvent as any).add_coin_from_reels)));
            sequence.push(() => spine.playOnce(`${reelIndex + 1}_coin_flight`, true));
        }

        sequence.push(() => this.addCoin(spinType));

        return new Sequence(sequence);
    }

    public addCoin(spinType: SlingoSpinType): Contract {
        this.spins.push(spinType);

        const coin = this.getSpriteForSpinType(spinType);
        this.coinSprites.push(coin);
        this.coinContainer.addChild(coin);

        coin.setDualPosition(this.layer.getPosition("hopper_top"));

        const positions: DualPosition[] = [];
        for (let i = this.coinSprites.length - 1; i < 1000; i++) {
            const pos = this.layer.getPosition("hopper_" + i);
            if (pos) {
                positions.unshift(pos);
            } else break;
        }

        return new Parallel([
            () =>
                TweenContract.wrapTween(
                    new Tween(coin.landscape)
                        .easing(Easing.Cubic.Out)
                        .to({ x: positions.map(pos => pos.landscape.x), y: positions.map(pos => pos.landscape.y) }, 200 + positions.length * 50)
                ),
            () =>
                TweenContract.wrapTween(
                    new Tween(coin.portrait)
                        .easing(Easing.Cubic.Out)
                        .to({ x: positions.map(pos => pos.portrait.x), y: positions.map(pos => pos.portrait.y) }, 200 + positions.length * 50)
                ),
        ]);
    }

    public consumeCoin(): Contract {
        const tweens: Tween[] = [];

        const positions = [this.layer.getPosition("hopper_bottom")];
        for (let i = 0; i < 1000; i++) {
            const pos = this.layer.getPosition("hopper_" + i);
            if (pos) {
                positions.push(pos);
            } else break;
        }

        positions.forEach((position: DualPosition, index: number) => {
            const coin = this.coinSprites[index];
            if (coin) {
                tweens.push(
                    new Tween(coin.landscape).easing(Easing.Cubic.Out).to({ x: position.landscape.x, y: position.landscape.y }, 200),
                    new Tween(coin.portrait).easing(Easing.Cubic.Out).to({ x: position.portrait.x, y: position.portrait.y }, 200)
                );
            }
        });

        return new Sequence([
            () => new Parallel(tweens.map(tween => () => TweenContract.wrapTween(tween))),
            () =>
                Contract.wrap(() => {
                    this.spins.shift();
                    this.coinSprites[0].destroy();
                    this.coinSprites.shift();
                }),
        ]);
    }

    protected getSpriteForSpinType(spinType: SlingoSpinType): Sprite {
        switch (spinType) {
            case SlingoSpinType.STANDARD:
                return Services.get(GraphicsService).createSprite(this.config.hopperCoinStandard);

            case SlingoSpinType.FREESPIN:
                return Services.get(GraphicsService).createSprite(this.config.hopperCoinFreespin);

            case SlingoSpinType.PURCHASE:
                return Services.get(GraphicsService).createSprite(this.config.hopperCoinPurchase);
        }
    }

    protected cleanup() {
        this.coinSprites.forEach((coin: Sprite) => coin.destroy());
        this.coinSprites.length = 0;
        this.spins.length = 0;
    }
}
