import { PickMatchRecord } from "slotworks/model/gameplay/records/pick-match-record";

export function pickMatchRecordDisplay(record: PickMatchRecord) {
    const containerElement = document.createElement("div");
    containerElement.style.width = "100%";
    containerElement.style.height = "90%";

    const dataElement = document.createElement("div");
    dataElement.innerHTML += "<br>index: " + record.index;
    dataElement.innerHTML += "<br>value: " + record.value;
    dataElement.innerHTML += "<br>isPrize: " + record.isPrize;
    dataElement.innerHTML += "<br>prizeType: " + record.prizeType;
    dataElement.innerHTML += "<br>complete: " + record.complete;

    containerElement.appendChild(dataElement);

    return containerElement;
}
