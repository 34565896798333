export function getAbbreviated(n: number): string {
    const abbreviations = ["b", "m", "k"];
    for (let i = 0; i < abbreviations.length; i++) {
        let factoredN = (n / Math.pow(10, 9 - (i * 3)));
        if (factoredN >= 1) {
            return factoredN + abbreviations[i];
        }
    }

    return n.toString();
}

/**
 * @example precisionRound(1639.9999999999998, 1) === 1640
 * @source https://stackoverflow.com/a/49729715/1864403
 */
export function precisionRound(number: number, precision: number) {
    var factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
}

/**
 * like precisionRound but actually works, e.g. precisionRound(69.652, 10) === 69.652 but roundToMultiple(69.652, 10) === 70
 */
export function roundToMultiple(num: number, multiple: number) {
    return Number((Math.round(num / multiple) * multiple).toFixed(2));
}

// If a number is smaller than min, or larger than max, it is clamped to min or max respectively
export function clamp(value:number, min:number, max:number) {
    return Math.min(Math.max(min, value), max);
}

export function asPercent(value: number, precision = 0.01): string {
    return `${roundToMultiple(value * 100, precision)}%`;
}