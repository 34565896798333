import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SignalBinding } from "signals";
import { slotModel } from "slotworks/model/slot-model";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { AbstractControl } from "../../abstract-control";

export class TotalStakeTextControl extends AbstractControl {
    protected updateStakeBinding: SignalBinding;

    constructor(protected label: FlexiText) {
        super();

        this.updateStakeBinding = slotModel.onUpdate.add(() => this.updateStake());
        this.updateStake();
    }

    public destroy(): void {
        this.updateStakeBinding.detach();
    }

    protected updateStake(): void {
        this.label.setText(Services.get(CurrencyService).format(Services.get(SlotBetService).getTotalStake()));
    }
}
