import { AbstractComponent } from "appworks/components/abstract-component";
import { Components } from "appworks/components/components";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { deepClone } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Timer } from "appworks/utils/timer";
import * as TweenJS from "appworks/utils/tween";
import { Signal } from "signals";
import { FooterComponent } from "../footer/footer-component";

export interface BigWinTierConfig {
    threshold: number;
    waitTime: number;
    countTime: number;
    scene: string;
}

export interface BigWinComponentConfig {
    backgroundSceneName: string;
    foregroundSceneName: string;
    hangTime: number;
    clickToSkip: boolean;
    spaceToSkip: boolean;
    instantUpgrade: boolean;
}

export class BigWinComponent extends AbstractComponent {

    public contentLayerName: string = "CelebrationContent";
    public backgroundLayerName: string = "CelebrationBackground";
    public foregroundLayerName: string = "CelebrationForeground";

    public countupMode: CountMode;

    public upgrade: boolean;
    public skipTier?: boolean;

    public totalReached: Signal;
    public totalReachedSkip: Signal;
    public onTierStart: Signal;
    public onUpdate: Signal;

    protected tiers: BigWinTierConfig[] = [];
    protected currentTiers: BigWinTierConfig[];
    protected valueTweenObject: { value: number };

    protected autoHide: boolean;

    protected currentAmount: number = 0;
    protected tierIndex: number = 0;

    protected waitTimer: number;
    protected countTimer: number;

    protected userSkipListener: EventListener;
    protected showWinResolve: Function;

    protected config: BigWinComponentConfig = {
        backgroundSceneName: "bigWin",
        foregroundSceneName: "bigWin",
        hangTime: 3000,
        clickToSkip: true,
        spaceToSkip: false,
        instantUpgrade: true
    };

    constructor(config?: Partial<BigWinComponentConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }

        this.totalReached = new Signal();
        this.totalReachedSkip = new Signal();
        this.onTierStart = new Signal();
        this.onUpdate = new Signal();
    }

    public format: (value: number) => string = (value) => Services.get(CurrencyService).format(value);

    public addWinTier(config: BigWinTierConfig) {

        this.tiers.push(config);

        this.tiers.sort((a, b) => a.threshold - b.threshold);
    }

    public upgradePending() {
        return this.currentTiers.length > 1;
    }

    public showWin(amount: number, stake: number, autoHide: boolean = true): Contract<boolean> {

        this.autoHide = autoHide;

        return new Contract<boolean>((resolve) => {

            if (this.isBigWin(amount, stake)) {
                this.showWinResolve = resolve;
                this.currentTiers = this.getSelectedTiers(amount, stake);

                if (this.config.clickToSkip) {
                    document.body.removeEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), this.userSkipListener, true);
                    this.userSkipListener = () => this.userSkip(amount, stake);
                    document.body.addEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), this.userSkipListener, true);
                }

                if (this.config.spaceToSkip) {
                    window.removeEventListener("keydown", this.userSkipListener);
                    window.addEventListener("keydown", this.userSkipListener);
                }

                // If resuming a countup skip already traversed tiers
                for (let i = 0; i < this.tierIndex - 1; i++) {
                    this.currentTiers.shift();
                }
                // Resuming from a previous countup, so adjust timings to be proportionate
                if (this.currentAmount > 0) {
                    this.currentTiers[0] = deepClone(this.currentTiers[0]);
                    // Whacky formula for deciding how long a "resumed" count should take
                    const relativeTime = Math.min(1, ((amount - this.currentAmount) - (this.currentTiers[0].threshold)) / (this.currentAmount - (this.currentTiers[0].threshold)));
                    this.currentTiers[0].countTime *= relativeTime;
                }

                this.startCountup(amount, stake);
                this.nextTier(amount, stake, false, this.currentAmount > 0);
            } else {
                resolve(false);
            }

        });
    }

    public isBigWin(amount: number, stake: number): boolean {
        const multiplier = amount / stake;

        const selectedLevel = this.getTier(amount, stake);

        return selectedLevel !== null && selectedLevel.threshold <= multiplier;
    }

    public getTier(amount: number, stake: number): BigWinTierConfig {
        const levels = this.getSelectedTiers(amount, stake);

        return levels.length ? levels[levels.length - 1] : null;
    }

    public hide(): Contract<void> {
        this.tierIndex = 0;
        this.currentAmount = 0;
        document.body.removeEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), this.userSkipListener, true);
        window.removeEventListener("keydown", this.userSkipListener);
        return new Parallel([
            () => Layers.get(this.contentLayerName)?.defaultScene() || Contract.empty(),
            () => Layers.get(this.foregroundLayerName)?.defaultScene() || Contract.empty(),
            () => Layers.get(this.backgroundLayerName)?.defaultScene() || Contract.empty()
        ]);
    }

    public skip(amount: number, stake: number) {
        this.userSkip(amount, stake);
    }

    protected nextTier(amount: number, stake: number, skip?: boolean, resume?: boolean) {
        const currentTier = this.currentTiers[0];
        Services.get(SoundService).event(SoundEvent.big_win_tier_id, currentTier.scene);

        const footer = Components.get(FooterComponent);
        if (footer) {
            footer.showBigWinMessage(this.tierIndex);
        }

        if (resume !== true) {
            this.tierIndex++;
            if (this.config.instantUpgrade || skip) {
                Layers.get(this.contentLayerName).jumpToScene("default");
            }

            Layers.get(this.contentLayerName).setScene(currentTier.scene).execute();

            if (Layers.get(this.foregroundLayerName)?.hasScene(currentTier.scene)) {
                Layers.get(this.foregroundLayerName).setScene(currentTier.scene).execute();
            }

            if (Layers.get(this.backgroundLayerName)?.hasScene(currentTier.scene)) {
                Layers.get(this.backgroundLayerName).setScene(currentTier.scene).execute();
            }

            Services.get(SoundService).event(SoundEvent.big_win_tier_id, currentTier.scene);

            this.onTierStart.dispatch(currentTier.scene);
        }

        const countTime = this.getTierCountTime(currentTier, amount, stake);
        this.countTimer = Timer.setTimeout(() => {
            if (this.upgrade) {
                this.currentTiers.shift();
            }

            if (this.currentTiers.length && this.upgrade) {
                this.nextTier(amount, stake);
            } else if (this.autoHide) {
                this.waitTimer = Timer.setTimeout(() => this.complete(amount), currentTier.waitTime);
            } else {
                this.complete(amount);
            }
        }, skip ? 0 : countTime);
    }

    protected getTierCountTime(currentTier: BigWinTierConfig, amount: number, stake: number) {
        // Don't tick up when you've hit exactly the threshold
        if (currentTier.threshold * stake === amount) {
            return 0;
        }
        return currentTier.countTime;
    }

    protected getLinearCountTime(amount: number, stake: number) {
        let countTime = 0;
        for (const level of this.currentTiers) {
            countTime += this.getTierCountTime(level, amount, stake);
        }

        return countTime;
    }

    protected userSkip(amount: number, stake: number) {
        TweenJS.removeAll(this.valueTweenObject);
        Timer.clearTimeout(this.waitTimer);
        Timer.clearTimeout(this.countTimer);
        Services.get(SoundService).event(SoundEvent.celebration_skip);

        if (this.skipTier === true) {
            if (this.currentTiers?.length > 1) {
                this.currentTiers.shift();
                this.currentAmount = this.currentTiers[0].threshold * stake;

                this.nextTier(amount, stake);

                Layers.get(this.contentLayerName).skipTransition();

                this.startCountup(amount, stake);
            } else {
                this.complete(amount);
                this.updateValue(amount);

                if (this.valueTweenObject) {
                    Services.get(SoundService).event(SoundEvent.big_win_countup_end);
                    this.valueTweenObject = null;
                }

                this.totalReachedSkip.dispatch();
            }

            Layers.get(this.contentLayerName).skipTransition();
        } else {
            if (this.currentTiers?.length > 1) {
                this.currentTiers = [this.currentTiers[this.currentTiers.length - 1]];

                this.nextTier(amount, stake, true);
            } else if ((!this.currentTiers || this.currentTiers.length === 0) || !this.autoHide) {
                this.complete(amount);
            } else {
                this.waitTimer = Timer.setTimeout(() => this.complete(amount), this.currentTiers[0].waitTime);
                this.currentTiers.shift();
            }

            this.updateValue(amount);

            Layers.get(this.contentLayerName).skipTransition();

            if (this.valueTweenObject) {
                Services.get(SoundService).event(SoundEvent.big_win_countup_end);
                this.valueTweenObject = null;
            }

            this.totalReachedSkip.dispatch();
        }
    }

    protected complete(amount: number) {
        TweenJS.removeAll(this.valueTweenObject);
        document.body.removeEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), this.userSkipListener, true);
        window.removeEventListener("keydown", this.userSkipListener);
        Services.get(SoundService).event(SoundEvent.celebration_end);

        this.currentAmount = amount;
        this.updateValue(amount);

        if (this.autoHide) {
            this.hide().then(() => {
                if (this.showWinResolve) {
                    this.showWinResolve(true);
                    this.showWinResolve = null;
                }
            });
        } else {
            if (this.showWinResolve) {
                this.showWinResolve(true);
                this.showWinResolve = null;
            }
        }
    }

    protected updateValue(value: number): void {
        const valueText = this.getValueText();
        if (valueText) {
            valueText.text = this.format(value);
        }
        this.onUpdate.dispatch(value);
    }

    protected clearValue(): void {
        const valueText = this.getValueText();
        if (valueText) {
            valueText.text = "";
        }
    }

    protected updateCountup(amount: number, stake: number) {
        if (this.valueTweenObject) {
            this.updateValue(this.valueTweenObject.value);
        }
    }

    protected startCountup(amount: number, stake: number) {

        this.valueTweenObject = { value: this.currentAmount };

        const countTween: TweenJS.Tween = new TweenJS.Tween(this.valueTweenObject);

        const complete = () => {
            this.updateValue(amount);

            this.valueTweenObject = null;

            Services.get(SoundService).event(SoundEvent.big_win_countup_end);
            Services.get(SoundService).event(SoundEvent.big_win_linear_countup_end);

            this.totalReached.dispatch();
        };

        if (this.countupMode === CountMode.LINEAR) {

            const countTime = this.getLinearCountTime(amount, stake);
            countTween.to({ value: amount }, countTime);
            countTween.onStart(() => Services.get(SoundService).event(SoundEvent.big_win_linear_countup_start));
            countTween.onUpdate(() => this.updateCountup(amount, stake));
            countTween.onComplete(complete);

        } else {
            let tween = countTween;
            countTween.to({ value: this.currentAmount }, 0);
            countTween.onStart(() => Services.get(SoundService).event(SoundEvent.big_win_nonlinear_countup_start));
            countTween.onComplete(() => Services.get(SoundService).event(SoundEvent.big_win_nonlinear_countup_end));

            this.currentTiers.forEach((level, levelIndex) => {
                let toValue;
                const isLastLevel = levelIndex + 1 >= this.currentTiers.length;
                if (!isLastLevel) {
                    const nextLevel = this.currentTiers[levelIndex + 1];
                    toValue = nextLevel.threshold * stake;
                } else {
                    toValue = amount;
                }

                const countTime = this.getTierCountTime(level, amount, stake);

                const levelTween = new TweenJS.Tween(this.valueTweenObject).to({ value: toValue }, countTime).onUpdate(() => this.updateCountup(amount, stake));
                tween.chain(levelTween);
                tween = levelTween;

                if (isLastLevel) {
                    levelTween.onComplete(complete);
                }
            });
        }

        const setup = () => {
            this.clearValue();
            Services.get(SoundService).event(SoundEvent.big_win_countup_start);
        };

        if (Layers.get(this.backgroundLayerName)?.hasScene(this.config.backgroundSceneName)) {
            Layers.get(this.backgroundLayerName).setScene(this.config.backgroundSceneName).execute();
        }
        if (Layers.get(this.foregroundLayerName)?.hasScene(this.config.foregroundSceneName)) {
            Layers.get(this.foregroundLayerName).setScene(this.config.foregroundSceneName, setup).execute();
        }

        countTween.start();
    }

    protected getSelectedTiers(amount: number, stake: number): BigWinTierConfig[] {
        const multiplier = amount / stake;

        let selectedLevels: BigWinTierConfig[] = [];

        for (const level of this.tiers) {
            const shouldBreak = (this.countupMode === CountMode.LINEAR) ? level.threshold > multiplier : level.threshold >= multiplier;
            if (shouldBreak) {
                break;
            } else {
                if (this.upgrade) {
                    selectedLevels.push(level);
                } else {
                    selectedLevels = [level];
                }
            }
        }

        return selectedLevels;
    }

    protected getValueText() {
        return Layers.get(this.contentLayerName).getText("value")
            || Layers.get(this.contentLayerName).getBitmapText("value")
            || Layers.get(this.foregroundLayerName)?.getText("value")
            || Layers.get(this.foregroundLayerName)?.getBitmapText("value")
            || Layers.get(this.backgroundLayerName)?.getText("value")
            || Layers.get(this.backgroundLayerName)?.getBitmapText("value")
            || null;
    }
}

export enum CountMode {
    LINEAR = "linear",
    TIERED = "tiered"
}
