import { SlingoRecord } from "../../../model/records/slingo-record";
import { SlingoJokerResult } from "../../../model/results/slingo-joker-result";

export function SlingoJokerResultDisplay(record: SlingoRecord, result: SlingoJokerResult) {
    const container = document.createElement("div");

    container.innerHTML += "Joker Cells: ";
    container.innerHTML += result.jokerCells.toString() || "-";
    container.innerHTML += "<br>";
    container.innerHTML += "Super Joker Cells: ";
    container.innerHTML += result.superJokerCells.toString() || "-";

    return container;
}
