/////////////////////////
// tslint:disable-next-line
(global as any).PIXI = require("pixi.js");
//////////
import { AssetConfigSchema, SpineConfig } from "appworks/config/asset-config-schema";
import { LoaderService } from "appworks/loader/loader-service";
import { Service } from "appworks/services/service";
import { Services } from "appworks/services/services";
import { logger } from "appworks/utils/logger";
import { Container, LoaderResource, Point, Texture } from "pixi.js";
import { GraphicsService } from "../graphics-service";
import { DualPosition } from "../pixi/dual-position";
import { SpineContainer } from "../pixi/spine-container";
import { Spine, ISkeletonData, TextureAtlas, SpineParser } from "pixi-spine";


export class SpineService extends Service {

    // TODO: V6 remove this or make 0 default
    // This is the time in the first animation of a spine used as a "reference" when positioning it
    // See https://epicindustries.atlassian.net/wiki/spaces/AW/pages/586809359/Spine for details
    public static REFERENCE_TIME: number = 10;

    public spineData: Map<string, ISkeletonData> = new Map<string, ISkeletonData>();
    public spineConfig: SpineConfig;

    public init(): void {
        // Do nothing. Handled in setup due to assetConfig requirements.
    }

    public setup(config: AssetConfigSchema) {
        const layoutConfig = config.layouts.all;
        this.spineConfig = layoutConfig.spines;

        this.initAllSpines();

        Services.get(LoaderService).onStageLoad.add(() => {
            this.initAllSpines();
        });
    }

    public info() {
        let output = "-------- Spine Info --------\n";

        this.spineData.forEach((spineData, index) => {
            output += "--------------------------------\n";
            output += `         ${index}\n`;
            output += "--------------------------------\n";

            output += "\nSkins:\n";
            spineData.skins.forEach((skin) => {
                output += "\t" + skin.name + "\n";
            });

            output += "\nAnimations:\n";
            spineData.animations.forEach((animation) => {
                const duration = Math.floor(animation.duration * 1000);
                output += "\t" + animation.name + " [" + duration + "]\n";
            });

            output += "\nEvents:\n";
            spineData.events.forEach((event) => {
                output += "\t" + event.name + "\n";
            });
        });

        logger.info(output);
    }

    /**
     * Helper method which will display all the "reference" frames of each spine. Copy them to photoshop and use them for positioning
     */
    public extractFirstFrames(drawMetaInfo = false, drawAllAnims: boolean = false) {
        logger.info("-------- Spine First Frames --------\n");

        const elements = [];

        this.spineData.forEach((spineData, spineName) => {
            const spineObj = this.createSpine(spineName);

            const anims = drawAllAnims ? spineObj.spineData.animations : [spineObj.spineData.animations[0]];

            anims.forEach((anim) => {
                let spineNameRef = spineName;
                let bounds = spineObj.getBounds();

                if (bounds.width === 0 && bounds.height === 0 || drawAllAnims) {
                    const animName = anim.name;
                    spineNameRef += ` :: (${animName})`;
                    spineObj.state.setAnimation(0, animName, true);
                    // jump into the anim because the bounds can still be 0,0 at frame 0
                    // WARNING: spine bounds are not consistent when at a time beyond 0, so it's recommended to use 0 for this if possible
                    // See https://epicindustries.atlassian.net/wiki/spaces/AW/pages/586809359/Spine for details
                    spineObj.state.tracks[0].trackTime = SpineService.REFERENCE_TIME;

                    spineObj.update(0);

                    bounds = spineObj.getBounds();
                }

                const canvas = PIXI.autoDetectRenderer().extract.canvas(spineObj);

                if (drawMetaInfo) {
                    // Draw pivot point
                    var ctx = canvas.getContext("2d");
                    const pivot = new Point(-bounds.x, -bounds.y);
                    ctx.fillStyle = "black";
                    ctx.fillRect(pivot.x - 7, pivot.y - 7, 14, 14);
                    ctx.fillStyle = "grey";
                    ctx.fillRect(pivot.x - 5, pivot.y - 5, 10, 10);
                    ctx.fillStyle = "white";
                    ctx.fillRect(pivot.x, pivot.y, 1, 1);

                    ctx.fillStyle = "red";
                    ctx.fillText(spineNameRef, 10, 20);
                    ctx.fillText(`Dimensions: { width: ${Math.round(bounds.width)}, height: ${Math.round(bounds.height)} }`, 10, 30);
                    ctx.fillText(`Pivot: { x: ${Math.round(bounds.x)}, y: ${Math.round(bounds.y)} }`, 10, 40);
                }

                canvas.id = spineNameRef;
                elements.push(canvas);
            });

            spineObj.destroy();
        });

        document.body.innerHTML = "";
        elements.forEach((element) => {
            const title = document.createElement("div");
            title.innerText = element.id;
            document.body.appendChild(title);
            document.body.appendChild(element);
        });
        document.body.style.setProperty("overflow", "scroll", "important");
        document.body.style.setProperty("background-color", "#ffffff", "important");
        document.body.style.setProperty("font-size", "50px", "important");
        document.body.style.setProperty("color", "#000000", "important");
    }

    public update(time: number) {
        //
    }

    public createSpineContainer(name: string | string[], position: DualPosition): SpineContainer | undefined {
        const spineAnim = this.createSpine(name);
        if (spineAnim) {
            const container = new SpineContainer(spineAnim.name, position);
            container.landscape.x = position.landscape.x;
            container.landscape.y = position.landscape.y;
            container.portrait.x = position.portrait.x;
            container.portrait.y = position.portrait.y;
            container.setupSpine();
            return container;
        } else {
            return undefined;
        }
    }

    public createSpine(name: string | string[]): Spine & Container | undefined {
        if (typeof (name) === "string") {
            if (!this.spineData.get(name)) {
                throw new Error("Spine `" + name + "` does not exist in spine cache");
            }

            const spineData = this.spineData.get(name);
            const spineObj = new Spine(spineData);
            // NOTE: since pixi-spine has peerDependencies for pixi@^6.0.0, and slotworks is built on top of pixi v5,
            // TS won't pick up the fact that Spine extends PIXI.Container.
            // Cast as unknown and then to Container so that a name can be assigned
            (spineObj as unknown as Container).name = name;
            return spineObj as Spine & Container;
        } else {
            for (const entry of name) {
                if (this.spineData.has(entry)) {
                    const spineData = this.spineData.get(entry);
                    const spineObj = new Spine(spineData);
                    // See above comment
                    (spineObj as unknown as Container).name = entry;
                    return spineObj as Spine & Container;
                }
            }

            return undefined;
        }
    }

    private initAllSpines() {
        for (const name in this.spineConfig) {
            if (this.spineConfig.hasOwnProperty(name)) {
                const spineJSON = this.spineConfig[name];
                if (this.spineData.get(name) === undefined) {
                    try {
                        this.spineData.set(name, this.generateSpineData(name, spineJSON));
                    } catch (e) {
                        (window as any).testms = () => Services.get(LoaderService).allStagesLoaded();
                        if (Services.get(LoaderService).allStagesLoaded()) {
                            logger.error(e);
                        } else {
                            continue;
                        }
                    }
                }
            }
        }
    }

    /**
     * Generates a SkeletonData object.
     *
     * @param name - Spine name
     * @param data - Spine data (either bytecode or JSON)
     */
    private generateSpineData(name: string, data: any): ISkeletonData {
        // Grab the texture atlas
        const spineAtlas = this.generateSpineAtlas(name, data);
        // NOTE: the SkeletonData we need gets saved into an object rather than being returned by the parser.
        // Bit of a hack but it works
        const resource = {} as LoaderResource;
        // Need to use the `SpineParser`, which figures out which spine runtime to use (3.7, 3.8, 4.0, 4.1)
        const spineParser = new SpineParser();
        const skeletonParser = data instanceof Uint8Array ? spineParser.createBinaryParser() : spineParser.createJsonParser()
        spineParser.parseData(resource, skeletonParser, spineAtlas, data);
        return resource.spineData;
    }

    /**
     * Generates a TextureAtlas from JSON data which is used to create a SkeletonData object.
     *
     * @param name - Spine name
     * @param data - Spine data (either bytecode or JSON)
     */
    private generateSpineAtlas(name: string, spineData: any) {
        const spineAtlas = new TextureAtlas();
        const allTextures: { [key: string]: Texture } = {};

        const skins = spineData.skins;

        for (const skin of skins) {
            for (const attachmentKey in skin.attachments) {
                if (skin.attachments.hasOwnProperty(attachmentKey)) {
                    const slots = skin.attachments[attachmentKey];
                    for (const slotKey in slots) {

                        if (slots.hasOwnProperty(slotKey)) {
                            const slot = slots[slotKey];
                            const textureId = slot.name || slot.path || slotKey;
                            const textureName = name + "/" + (textureId);
                            const altTextureName = name + "/images/" + (textureId);

                            let texture: Texture;
                            if (Services.get(GraphicsService).hasTexture(textureName)) {
                                texture = Services.get(GraphicsService).getTexture(textureName);
                            }
                            if (Services.get(GraphicsService).hasTexture(altTextureName)) {
                                texture = Services.get(GraphicsService).getTexture(altTextureName);
                            }

                            if (texture) {
                                allTextures[textureId] = texture;
                            } else {
                                logger.debug(`Spine texture not found: ${name} - ${textureId}`);
                            }
                        }
                    }
                }
            }
        }

        spineAtlas.addTextureHash(allTextures, true);
        return spineAtlas;
    }
}
