import { Services } from "appworks/services/services";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { gridDisplay } from "../general-displays/grid-display";

export function spinRecordDisplay(record: SpinRecord) {
    const containerElement = document.createElement("div");
    containerElement.style.width = "100%";
    containerElement.style.height = "80%";

    if (record.grid) {
        const gridElement = gridDisplay(record.grid, record.overlayGrid);
        containerElement.appendChild(gridElement);
    }

    const dataElement = document.createElement("div");
    if (record.reelsetId) {
        dataElement.innerHTML += `Reelset: ${record.reelsetId}<br>`;
    }

    if (record.stops) {
        dataElement.innerHTML += `Stops: ${record.stops.join(",")}<br>`;
    }

    dataElement.innerHTML += "RTP: " + (record.cashWon / Services.get(SlotBetService).getTotalStake());
    dataElement.innerHTML += "";

    containerElement.appendChild(dataElement);

    return containerElement;
}
