import { GraphicsService } from "appworks/graphics/graphics-service";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Services } from "appworks/services/services";
import { Contract } from "appworks/utils/contracts/contract";
import { SlingoHighlightType, SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { BubbleSpineState, PopBubbleSpineComponent } from "./pop-bubble-spine-component";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Point } from "appworks/utils/geom/point";
import { Easing } from "appworks/utils/tween";
import { pulse } from "appworks/utils/animation/scale";
import { Sequence } from "appworks/utils/contracts/sequence";

export class PopSlingoTicketMatrixComponent extends SlingoTicketMatrixComponent {
    protected bubbles: PopBubbleSpineComponent[][] = [];

    public setGrid(grid: number[][]): void {
        super.setGrid(grid);

        const bubblePos = this.layer.getPosition("bubble");
        const symbol00Pos = this.layer.getPosition("symbol_0_0");

        grid.forEach((col: number[], x: number) => {
            this.bubbles[x] = [];

            col.forEach((value: number, y: number) => {
                const bubble = new PopBubbleSpineComponent(this.layer, bubblePos);
                this.bubbles[x][y] = bubble;

                const symbolPosition = this.layer.getPosition(`symbol_${x}_${y}`);
                const offset = symbolPosition.subtractPosition(symbol00Pos);
                bubble.setPosition(bubble.getPosition().addPosition(offset));

                bubble.setState(BubbleSpineState.IDLE).execute();
            });
        });
    }

    public dabSymbol(symbolValue: number, instant?: boolean): Contract<void> {
        const pos = this.getSymbolPositionFromValue(symbolValue);
        const bubble = this.bubbles[pos.x][pos.y];

        return new Parallel([
            () => super.dabSymbol(symbolValue, instant),
            () => bubble.setState(instant ? BubbleSpineState.DAB_INSTANT : BubbleSpineState.DAB)
        ]);
    }

    public highlightSymbol(symbolValue: number, highlightType: SlingoHighlightType, onClick?: () => void): Contract<void> {
        const pos = this.getSymbolPositionFromValue(symbolValue);
        const bubble = this.bubbles[pos.x][pos.y];

        switch (highlightType) {
            case SlingoHighlightType.MATCHED:
                bubble.setState(BubbleSpineState.MATCH).execute();
                break;

            case SlingoHighlightType.JOKER:
                bubble.setState(BubbleSpineState.WILD).execute();
                break;

            case SlingoHighlightType.SUPER_JOKER:
                bubble.setState(BubbleSpineState.SUPERWILD).execute();
                break;
        }

        return super.highlightSymbol(symbolValue, highlightType, onClick);
    }

    public clearHighlights(): Contract<void> {
        this.bubbles.forEach((col: PopBubbleSpineComponent[]) => {
            col.forEach((bubble: PopBubbleSpineComponent) => {
                if (bubble.getState() !== BubbleSpineState.DAB) {
                    bubble.setState(BubbleSpineState.IDLE).execute();
                }
            });
        });

        return super.clearHighlights();
    }

    protected animateLine(pattern: string): Contract {
        const values = this.getValuesForWinlinePattern(pattern);
        const positions = values.map(val => this.getSymbolPositionFromValue(val));

        const isVertical = positions.every(pos => pos.x === positions[0].x);
        positions.sort((a, b) => isVertical ? (a.y - b.y) : (a.x - b.x));

        const delay = 75;
        const contracts = positions.map((pos: Point, index: number) => {
            return () => Contract.getDelayedContract(delay * index, () => {
                const dab = this.bubbles[pos.x][pos.y];
                if (!dab) { return Contract.empty(); }
                return new Sequence([
                    () => dab.setState(BubbleSpineState.PULSE),
                    () => dab.setState(BubbleSpineState.DAB_INSTANT)
                ]);
            });
        })

        return new Parallel(contracts);
    }

    protected reset(): void {
        super.reset();

        this.bubbles.forEach((col: PopBubbleSpineComponent[]) => {
            col.forEach((bubble) => bubble.destroy());
        });
        this.bubbles = [];
    }

    protected getNumberSprite(value: number): Sprite {
        return Services.get(GraphicsService).createSprite("numbers_blue/" + value);
    }

    protected getDabSprite(): Sprite {
        return Services.get(GraphicsService).createSprite("blank");
    }

    protected getHighlightSprite(highlightType: SlingoHighlightType): Sprite {
        return Services.get(GraphicsService).createSprite("blank");
    }
}
