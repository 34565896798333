import { UIFlagState } from "appworks/ui/flags/ui-flag-state";
import { UIHook } from "appworks/ui/flags/ui-hooks";
import { logger } from "appworks/utils/logger";
import { Signal } from "signals";

// TODO: rewrite the internal implementation to just use a map of booleans
// TODO: ability for games to add custom UI flags
class UIFlags {

    public onFlagsUpdated: Signal = new Signal();
    public bitmask: number = 0;

    protected hooks: UIHook[] = [];
    protected previousBitMask: number = -1;

    public init() {
        this.update();
    }

    public set(flag: UIFlag, value: boolean) {
        if (value) {
            this.bitmask |= flag;
        } else {
            this.bitmask &= ~flag;
        }

        this.update();
    }

    // Toggles specific menu flag, and turns off all other menu flags
    public menu(flag: UIMenuFlag, value?: boolean) {
        // Toggle menu flag
        if (value === undefined) {
            value = (this.bitmask & flag) !== 0;
        } else {
            value = !value;
        }

        this.closeMenus();

        if (value) {
            this.bitmask &= ~flag;
        } else {
            this.bitmask |= flag;
        }

        if (logger.DEBUG) {
            const flags = { ...UIFlag, ...UIMenuFlag };
        }

        this.update();
    }

    public closeMenus() {
        const smartFlags = Object.keys(UIMenuFlag).filter((key) => isNaN(Number(key)));

        smartFlags.forEach((key) => {
            const menuFlag = Number(UIMenuFlag[key as any]);
            if (this.bitmask & menuFlag) {
                this.bitmask -= menuFlag;
            }
        });

        this.update();
    }

    public has(flags: UIFlag | UIMenuFlag | Array<UIFlag | UIMenuFlag>): boolean {
        if (Array.isArray(flags)) {
            flags = (flags as Array<UIFlag | UIMenuFlag>).reduce((all, one) => all |= one, 0);
        }
        return (this.bitmask & flags) === flags;
    }

    public maskToString(mask: number) {
        const flags: string[] = [];

        const smartFlags = Object.keys(UIFlag).filter((key) => isNaN(Number(key)));

        smartFlags.forEach((key: string) => {
            const flag = (UIFlag as any)[key] as number;

            if ((mask & flag) === flag) {
                flags.push(key);
            }
        });

        return flags.toString();
    }

    /**
     * Add a hook which can be used to trigger a signal when the flag state becomes true (on) or false (off)
     */
    public hook(flagState: UIFlagState) {
        const hook = new UIHook(flagState);
        this.hooks.push(hook);
        return hook;
    }

    protected update() {
        if (this.previousBitMask !== this.bitmask) {
            this.log();
            this.onFlagsUpdated.dispatch(this.bitmask);

            for (const hook of this.hooks) {
                if (hook.flagState.test() && !hook.active) {
                    hook.on.dispatch();
                    hook.active = true;
                } else if (!hook.flagState.test() && hook.active) {
                    hook.off.dispatch();
                    hook.active = false;
                }
            }
        }

        this.previousBitMask = this.bitmask;
    }

    protected log() {
        // if (logger.getLevel() === logger.DEBUG) {
        //     const previousFlagState = this.getFlags(this.previousBitMask);
        //     const currentFlagState = this.getFlags(this.bitmask);
        //     for (const flag in previousFlagState) {
        //         if (previousFlagState[flag] !== currentFlagState[flag] && this.previousBitMask !== -1) {
        //             console.groupCollapsed("%c UIFlags ", "background: #ffccff; color: #333", `${flag} changed to ${currentFlagState[flag]}`); // tslint:disable-line
        //             console.table(currentFlagState); // tslint:disable-line
        //             console.trace("Stacktrace"); // tslint:disable-line
        //             console.groupEnd(); // tslint:disable-line
        //         }
        //     }
        // }
    }

    protected getFlags(bitmask: number) {
        const flags = { ...UIFlag, ...UIMenuFlag };
        const keys = Object.keys(flags).map((k) => flags[k as any]).filter((val) => typeof val === "string");
        const flagState: { [key: string]: boolean } = {};
        for (const uiFlag of keys) {
            const flagEnabled = (bitmask & UIFlag[uiFlag]) | (bitmask & UIMenuFlag[uiFlag]);
            flagState[uiFlag] = flagEnabled !== 0;
        }
        return flagState;
    }
}

export const uiFlags = new UIFlags();

export enum UIFlag {
    ALWAYS = 0, // Always true
    IDLE = 1, // Game is idle
    NO_SKIP = 1 << 1, // Skip is disabled
    MIN_SPIN_COOLDOWN = 1 << 2, // Waiting for min spin time
    FREE_SPINS = 1 << 3, // Game is freespinning
    BONUS = 1 << 4, // Bonus is playing
    AWAITING_RESPONSE = 1 << 5, // Awaiting server response
    SPINNING = 1 << 6, // Spinning
    AUTO_SPIN = 1 << 7, // Autospinning
    REPLAY = 1 << 8, // Not used
    PROMPT = 1 << 9, // Prompt is open
    FREE_BETS = 1 << 10, // In a free bet promotion
    GAMBLE = 1 << 11, // Gamble is enabled
    GAMBLE_UI = 1 << 12, // Gamble is open
    EXTERNAL_DISABLE = 1 << 13, // Wrapper or similar is trying to disable the game
    GAME_IN_PROGRESS = 1 << 14, // When a game is playing out, anything between a spin being requested and a close being requested
    RESPINS = 1 << 15, // Respins are being played
    NEVER = 1 << 16, // Never true
    SPECIAL = 1 << 17, // Used for unique UI modes within an individual game
    GAME_STARTED = 1 << 18 // The game has finished all loading as it now ready to play
}

export enum UIMenuFlag {
    MENU = 1 << 20, // Generic menu is open
    AUTO = 1 << 21, // Autospin menu is open
    STAKE = 1 << 22, // Stake menu is open
    SOUND = 1 << 23, // Sound menu is open
    PROMO = 1 << 24, // Promo menu is open
    PAYTABLE = 1 << 25, // Paytable menu is open
    RULES = 1 << 26, // Rules menu is open
    BONUS_BUY = 1 << 27, // Bonus buy menu is open
    MORE_RULES = 1 << 28, // Additional Rules menu is open
    RESPONSIBLE_GAMING = 1 << 29 // Responsible gaming / spend limits menu is open
}
