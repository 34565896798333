import { logger } from "appworks/utils/logger";
import { ForceList } from "slotworks/debug/menu/force-list";
import { DebugTab } from "slotworks/debug/menu/tabs/debug-tab";

export class LogTab extends DebugTab {
    public maxLogs: number;

    protected logs: any[][];
    protected table: HTMLElement;

    constructor(name: string, resultList?: ForceList, maxLogs: number = 100) {
        super(name, resultList);

        this.maxLogs = maxLogs;

        this.logs = [];
        const handler = logger.createDefaultHandler();
        logger.setHandler((messages, context) => {
            this.logs.push(messages);
            if (this.logs.length > this.maxLogs) {
                this.logs.shift();
            }
            handler(messages, context);
        });
    }

    public render(root: HTMLElement, hide: Function): void {
        this.table = document.createElement("table");
        this.table.style.width = "100%";
        this.table.style.height = "100%";
        this.table.style.backgroundColor = "#222";
        this.table.style.color = "#CCC";
        this.table.style.fontFamily = "monospace";
        this.table.style.fontSize = "2vmax";
        this.table.style.overflowY = "auto";

        root.appendChild(this.table);
    }

    public refresh(): void {
        this.clearLogs();
        this.logs.forEach((log) => {
            this.addLog(log);
        });
    }

    private clearLogs() {
        this.table.innerHTML = "";
    }

    private addLog(message: any[]) {
        try {
            if (message.length >= 3 && typeof (message[0]) === "string" && typeof (message[1]) === "string" && message[0].indexOf("%c") !== -1) {
                const head = document.createElement("td");
                head.innerText = message[0].replace("%c", "");
                head.style.width = "10vw";

                for (const entry of message[1].split(";")) {
                    const keyvalue = entry.trim().split(":");
                    head.style.setProperty(keyvalue[0].trim(), keyvalue[1].trim());
                }

                let finalBody: string = "";

                for (let i = 2; i < message.length; i++) {
                    if (typeof (message[i]) === "object") {
                        finalBody += "  " + JSON.stringify(message[i]);
                    } else {
                        finalBody += "  " + String(message[i]);
                    }
                }

                const body = document.createElement("td");
                body.innerText = finalBody;

                const row = document.createElement("tr");
                row.appendChild(head);
                row.appendChild(body);
                this.table.appendChild(row);
            } else {
                let finalBody: string = "";

                for (const entry of message) {
                    if (typeof (entry) === "object") {
                        finalBody += "  " + JSON.stringify(entry);
                    } else {
                        finalBody += "  " + String(entry);
                    }
                }

                const body = document.createElement("td");
                body.colSpan = 2;
                body.innerText = finalBody;

                const row = document.createElement("tr");
                row.appendChild(body);
                this.table.appendChild(row);
            }
        } catch (e) {
            // Do nothing. This avoid a loopback in the console.
        }
    }
}
