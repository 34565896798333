import { Components } from 'appworks/components/components';
import { PromptComponent } from 'appworks/components/prompt/prompt-component';
import { SpineContainer } from 'appworks/graphics/pixi/spine-container';
import { PopReelMatrixFactory } from 'components/factories/pop-reel-matrix-factory';
import { PopCoinHopperComponent } from 'components/pop-coin-hopper-component';
import { PopLadderComponent } from 'components/pop-ladder-component';
import { PopScatterInfoComponent } from 'components/pop-scatter-info-component';
import { PopSlingoCelebrationComponent } from 'components/pop-slingo-celebration-component';
import { PopSlingoTicketMatrixComponent } from 'components/pop-slingo-ticket-matrix-component';
import { PopSpinsCounterComponent } from 'components/pop-spins-counter-component';
import { SocialBetBarComponent } from 'components/social-bet-bar-component';
import { SocialGMRAlertComponent } from 'components/social-gmr-alert-component';
import { SocialPreloaderComponent } from 'components/social-preloader-component';
import { SocialSlingoCelebrationComponent } from 'components/social-slingo-celebration-component';
import { LayerNames } from 'game-layers';
import { GMRAlertComponent } from 'gaming-realms/components/gmr-alert-component';
import { GMRPreloaderComponent } from 'gaming-realms/components/gmr-preloader-component';
import { GMRSuperSpinWheelComponent } from 'gaming-realms/components/gmr-super-spin-wheel-component';
import { SlingoFooterComponent } from 'slingo/components/slingo-footer-component';
import { SlingoReelHighlightComponent } from 'slingo/components/slingo-reel-highlight-component';
import { SlingoSpinsCounterComponent } from 'slingo/components/slingo-spins-counter-component';
import { BetBarComponent } from 'slotworks/components/betbar/bet-bar-component';
import { CountMode } from 'slotworks/components/bigwin/big-win-component';
import { CelebrateWinComponent } from 'slotworks/components/celebrate-win/celebrate-win-component';
import { BigWinFactory } from 'slotworks/components/factories/big-win-factory';
import { GenericComponentFactory } from 'slotworks/components/factories/generic-component-factory';

/**
 * Replacement for PopComponentSetup. Replacements commented out.
 */
export function SocialPopComponentSetup() {
  SpineContainer.AUTO_UPDATE_SPEED = true;

  Components.register(new GenericComponentFactory(PromptComponent), 0);
  // Components.register(new GenericComponentFactory(BetBarComponent));
  Components.register(new GenericComponentFactory(SocialBetBarComponent));
  Components.register(new GenericComponentFactory(CelebrateWinComponent));
  Components.register(new BigWinFactory(CountMode.LINEAR, false, [
      {
          threshold: 20,
          countTime: 4000,
          waitTime: 4000,
          scene: "bigwin"
      },
      {
          threshold: 50,
          countTime: 5500,
          waitTime: 4000,
          scene: "superbigwin"
      },
      {
          threshold: 100,
          countTime: 9500,
          waitTime: 4000,
          scene: "megawin"
      }
  ]));

  // Components.register(new GenericComponentFactory(GMRPreloaderComponent), null);
  Components.register(new GenericComponentFactory(SocialPreloaderComponent), null);
  // Components.register(new GenericComponentFactory(GMRAlertComponent), 0);
  Components.register(new GenericComponentFactory(SocialGMRAlertComponent), 0);
  Components.register(new GenericComponentFactory(SocialPreloaderComponent), null);
  Components.register(new GenericComponentFactory(GMRSuperSpinWheelComponent));

  Components.register(new PopReelMatrixFactory(), 3);

  Components.register(new GenericComponentFactory(SlingoFooterComponent));
  // Components.register(new GenericComponentFactory(PopSlingoCelebrationComponent, LayerNames.CelebrationContent));
  Components.register(
    new GenericComponentFactory(SocialSlingoCelebrationComponent, LayerNames.CelebrationContent),
  );
  Components.register(new GenericComponentFactory(PopSpinsCounterComponent, LayerNames.SpinCounter));
  Components.register(new GenericComponentFactory(PopCoinHopperComponent));
  Components.register(new GenericComponentFactory(PopLadderComponent));
  Components.register(new GenericComponentFactory(PopScatterInfoComponent));

  Components.register(new GenericComponentFactory(PopSlingoTicketMatrixComponent), 3);
}
