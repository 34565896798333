import { LayersConfigSchema as LayerOrderSchema, SceneConfig } from "appworks/config/asset-config-schema";
import { LayerEvent } from "appworks/debug/ci";
import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { ScriptedAnimation } from "appworks/graphics/animation/scripted-animation";
import { ButtonElement } from "appworks/graphics/elements/button-element";
import { FlexiButton } from "appworks/graphics/elements/flexi-button";
import { FlexiInputText } from "appworks/graphics/elements/flexi-input-text";
import { FlexiSlider } from "appworks/graphics/elements/flexi-slider";
import { FlexiSprite } from "appworks/graphics/elements/flexi-sprite";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { FlexiToggle } from "appworks/graphics/elements/flexi-toggle";
import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { ToggleElement } from "appworks/graphics/elements/toggle-element";
import { DOMLayer } from "appworks/graphics/layers/dom-layer";
import { InvalidSceneError } from "appworks/graphics/layers/invalid-scene-error";
import { spritePopulator } from "appworks/graphics/layers/populators/populate-sprites";
import { textPopulator } from "appworks/graphics/layers/populators/populate-text";
import { Scene } from "appworks/graphics/layers/scene";
import { SceneTransition } from "appworks/graphics/layers/scene-transitions/scene-transition";
import { BitmapText } from "appworks/graphics/pixi/bitmap-text";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { Services } from "appworks/services/services";
import { clearMap, createStructure, mapToObject } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Point } from "appworks/utils/geom/point";
import { Rectangle } from "appworks/utils/geom/rectangle";
import { parseJSONMap } from "appworks/utils/json-utils";
import { logger } from "appworks/utils/logger";
import { Timer } from "appworks/utils/timer";
import * as TweenJS from "appworks/utils/tween";
import { DualPositionObject, isDualPositionObject } from "appworks/utils/type-utils";
import * as Logger from "js-logger";
import * as particles from "pixi-particles";
import { DisplayObject, Graphics, Point as PIXIPoint } from "pixi.js";
import { Signal } from "signals";
import { AnimationService } from "../animation/animation-service";
import { CanvasService } from "../canvas/canvas-service";
import { FlexiVideo } from "../elements/flexi-video";
import { ParticleService } from "../particles/particle-service";
import { Container } from "../pixi/container";
import { DualAnchor } from "../pixi/dual-anchor";
import { HTMLText } from "../pixi/html-text";
import { ParticleContainer } from "../pixi/particle-container";
import { Position } from "../pixi/position";
import { SpineContainer } from "../pixi/spine-container";

export class Layers {

    public static onEnterScene: Signal = new Signal();
    public static onExitScene: Signal = new Signal();

    public static gameLayers: any;
    public static layerScales: Map<string, number> = new Map();

    public static FromString(id: string): Layers {
        return Layers.IdMap.get(id);
    }

    /**
     * Creates a pixi container for every layer, and stores the initial config from assetworks in each layer.
     * Then automatically calls resetLayer on each layer to populate their initial graphics
     *
     * @param config { Map<string, SceneConfig> }
     */
    public static init(config: { [layerScene: string]: SceneConfig }, layerNameEnum: any) {

        const layerNames: string[] = [];
        for (const layerName in layerNameEnum) {
            if (layerNameEnum.hasOwnProperty(layerName)) {
                layerNames.push(layerName);
            }
        }

        for (const layerName of layerNames) {
            const layer = new Layers(layerName);
            Layers.layers.set(layerName.toLowerCase(), layer);
        }

        const configMap = parseJSONMap<SceneConfig>(config);

        const stage = Services.get(CanvasService).stage;

        let insertIndex = 0;

        configMap.forEach((sceneConfig: SceneConfig, layerSceneName: string) => {
            const layerName = layerSceneName.split("-")[0];

            if (layerName === "DOM") {
                return;
            }

            if (layerNames.indexOf(layerName) < 0) {
                const layer = new Layers(layerName, insertIndex);
                layerNames.splice(insertIndex, 0, layerName);
                Layers.layers.set(layerName.toLowerCase(), layer);
            }

            insertIndex = layerNames.indexOf(layerName);
        });

        stage.addChild(Layers.container);

        // Create all layers
        for (const layer of Layers.allLayers) {
            const layerContainer = new Container();

            layerContainer.name = layer.id;
            layer.container = layerContainer;

            Layers.container.addChild(layerContainer);

            // Set up layers with information about their scenes

            const sceneConfigs: Map<string, SceneConfig> = new Map<string, SceneConfig>();

            configMap.forEach((sceneConfig: SceneConfig, layerSceneName: string) => {
                const sceneName = (layerSceneName.split("-").length > 1 ? layerSceneName.split("-")[1] : "default");

                if (layerSceneName.split("-")[0] === layer.id) {
                    sceneConfigs.set(sceneName.toLowerCase(), sceneConfig);
                }
            });

            layer.init(sceneConfigs);
        }
    }

    public static setLayerOrderConfig(layerOrder: LayerOrderSchema, layerNames: any): void {

        const validLayerNames = [];
        for (const layerKey in layerNames) {
            if (layerNames.hasOwnProperty(layerKey)) {
                validLayerNames.push(layerNames[layerKey]);
            }
        }

        for (const layerOrderName in layerOrder) {
            if (layerOrder.hasOwnProperty(layerOrderName)) {
                const configLayers = layerOrder[layerOrderName];

                validLayerNames.forEach((layerName) => {
                    const count = configLayers.filter((filterLayer) => filterLayer === layerName).length;

                    if (count === 0) {
                        throw new Error(`Layer "${layerName}" missing from layer order "${layerOrderName}"`);
                    } else if (count > 1) {
                        throw new Error(`Layer "${layerName}" duplicated in layer order "${layerOrderName}" x${count}`);
                    }
                });

                configLayers.forEach((configLayerName) => {
                    if (validLayerNames.indexOf(configLayerName) === -1) {
                        throw new Error(`Unknown layer "${configLayerName}" found in layer order "${layerOrderName}"`);
                    }
                });
            }
        }

        this.layerOrderConfig = layerOrder;

        this.resetLayerOrder();
    }

    public static get(layer: string): Layers | null {
        layer = layer.toLowerCase();
        if (Layers.layers.has(layer)) {
            return Layers.layers.get(layer);
        }

        return null;
    }

    public static resetLayerOrder(): void {
        Layers.setLayerOrder("default");
    }

    public static getCurrentLayerOrderId(): string {
        return this.currentLayerOrderId;
    }

    public static setLayerOrder(id: string): void {
        const layerOrder = Layers.layerOrderConfig[id];

        if (layerOrder) {
            layerOrder.forEach((layerName: string, layerIndex: number) => {
                const layer = Layers.get(layerName);

                if (layer) {
                    layer.container.zIndex = layerIndex;
                } else {
                    logger.warn(`Unknown layer "${layerName}" found in layer order "${id}". The layer is present in the layer order, but missing from the display list`);
                }
            });

            Layers.container.sortChildren();
            this.currentLayerOrderId = id;
        } else {
            throw new Error("No layers order found for \"" + id + "\".");
        }
    }

    /**
     * Calls set scene on all layers
     *
     * @param scene {string}
     */
    public static jumpToScene(scene: string) {
        for (const layer of Layers.allLayers) {
            if (!layer.getCurrentScene()) {
                layer.jumpToScene(scene);
            }
        }
    }

    /**
     * Purely syntactic sugar. Calls set scene with no args
     */
    public static jumpToDefaultScene() {
        this.jumpToScene("default");
    }

    /**
     * Clears all layers of all contents, auto generated or otherwise
     */
    public static clearAllLayers() {
        for (const layer of Layers.allLayers) {
            layer.clear();
        }
    }

    public static hideAll() {
        for (const layer of Layers.allLayers) {
            layer.hide();
        }
    }

    public static showAll() {
        for (const layer of Layers.allLayers) {
            layer.show();
        }
    }

    public static listLayers() {
        Logger.info(Layers.container.children.map((layer) => layer.name));
    }

    public static refreshAllLayers() {
        for (const layer of Layers.allLayers) {
            layer.refreshAnchors();
        }
    }

    public static getAllLayers() {
        return Layers.allLayers;
    }

    public static getContainer() {
        return Layers.container;
    }

    private static layers: Map<string, Layers> = new Map<string, Layers>();
    private static layerOrderConfig: LayerOrderSchema;
    private static currentLayerOrderId: string;

    private static allLayers: Layers[] = [];
    private static container: Container = new Container();

    private static IdMap: Map<string, Layers>;

    public id: string;
    public container: Container;
    public bounds: DualPosition;

    // Object containing all elements possibly in this layer (same as gameLayers.layer.scene.active.contents)
    public readonly allElements: { [name: string]: any } = {};

    // Signal dispatched before elements are automatically moved on an orientation change
    public preOrientationChange: Signal = new Signal();
    public onSceneEnter: Signal = new Signal(); // TODO: rename to beforeSceneEnter
    public afterSceneEnter: Signal = new Signal();
    public beforeSceneExit: Signal = new Signal();
    public onSceneExit: Signal = new Signal(); // TODO: rename to afterSceneExit

    private boundsMask: SmartShape;
    private customMask: DualPosition;
    private customMaskGraphic: Graphics | Sprite;

    private cachingAsBitmap: boolean;
    private cacheAsBitmapTimer: number;

    private currentScene: Scene;
    private scenes: Map<string, Scene> = new Map<string, Scene>();
    private positions: Map<string, DualPosition>;
    private particles: Map<string, number> = new Map<string, number>();
    private scriptedAnimations: Map<string, ScriptedAnimation> = new Map<string, ScriptedAnimation>();

    private buttonElements: Map<string, ButtonElement> = new Map<string, ButtonElement>();
    private toggleElements: Map<string, ToggleElement> = new Map<string, ToggleElement>();
    private flexiButtons: Map<string, FlexiButton> = new Map<string, FlexiButton>();
    private flexiTexts: Map<string, FlexiText> = new Map<string, FlexiText>();
    private flexiSprites: Map<string, FlexiSprite> = new Map<string, FlexiSprite>();
    private flexiToggles: Map<string, FlexiToggle> = new Map<string, FlexiToggle>();
    private flexiInputTexts: Map<string, FlexiInputText> = new Map<string, FlexiInputText>();
    private flexiSliders: Map<string, FlexiSlider> = new Map<string, FlexiSlider>();
    private flexiVideos: Map<string, FlexiVideo> = new Map<string, FlexiVideo>();

    private references: Map<string, DisplayObject> = new Map<string, DisplayObject>();

    private anchoredElement: Map<string, DualAnchor> = new Map<string, DualAnchor>();

    constructor(id: string, depth: number = -1) {
        this.id = id;

        if (depth === -1) {
            Layers.allLayers.push(this);
        } else {
            Layers.allLayers.splice(depth, 0, this);
        }

        if (!Layers.IdMap) {
            Layers.IdMap = new Map<string, Layers>();
        }
        Layers.IdMap.set(id, this);
    }

    public init(scenes: Map<string, SceneConfig>) {
        if (Layers.gameLayers) {
            Layers.gameLayers[this.id] = this;
        }

        scenes.forEach((config, name) => {
            const scene = new Scene(name, this.id, config);
            this.scenes.set(name.toLowerCase(), scene);
            createStructure(Layers.gameLayers[this.id], "scene")[name] = scene;
        });

        this.onSceneEnter.add(() => this.refreshAnchors());
        Services.get(CanvasService).onResize.add(() => this.refreshAnchors());

        this.hide();
    }

    public addScriptedAnimation(name: string, anim: ScriptedAnimation) {
        this.scriptedAnimations.set(name, anim);
    }

    public getScriptedAnimation(name: string) {
        return this.scriptedAnimations.get(name);
    }

    public addParticle(name: string, id: number) {
        this.particles.set(name, id);
    }

    public getParticle(id: string): particles.Emitter {
        return this.getByReference(id, particles.Emitter) || Services.get(ParticleService).get(this.particles.get(id));
    }

    public getParticleContainer(id: string): ParticleContainer {
        return this.getByReference(id, ParticleContainer);
    }

    public addButton(id: string, button: ButtonElement) {
        this.buttonElements.set(id, button);
    }

    public getButton(id: string): ButtonElement {
        return this.buttonElements.get(id);
    }

    public getSprite(id: string): Sprite {
        return this.getByReference(id, Sprite);
    }

    public getSprites(ids: string[]): Sprite[] {
        const sprites = [];
        for (const id of ids) {
            sprites.push(this.getSprite(id));
        }
        return sprites;
    }

    public getSpine(id: string): SpineContainer {
        return this.getByReference(id, SpineContainer);
    }

    public getAnimatedSprite(id: string): AnimatedSprite {
        return this.getByReference(id, AnimatedSprite);
    }

    public getShape(id: string): SmartShape {
        return this.getByReference(id, SmartShape);
    }

    public getText(id: string): Text {
        return this.getByReference(id, Text);
    }

    public getHTMLText(id: string): HTMLText {
        return this.getByReference(id, HTMLText);
    }

    public getBitmapText(id: string): BitmapText {
        return this.getByReference(id, BitmapText);
    }

    public getAllElements(): DisplayObject[] {
        const elements: DisplayObject[] = [];
        for (const key in this.allElements) {
            elements.push(this.allElements[key]);
        }
        return elements;
    }

    public hasAssetOfName(name: string): boolean {
        if (this.getDOMElements(name).length > 0) {
            return true;
        }

        for (const child of this.container.children) {
            if (child.name === name) {
                return true;
            }
        }

        return false;
    }

    public getFlexiSprite(id: string): FlexiSprite {

        if (this.flexiSprites.get(id)) {
            return this.flexiSprites.get(id);
        }

        const domElements = this.getDOMElements(id);
        const sprite = this.getSprite(id);

        const flexiSprite = new FlexiSprite();

        flexiSprite.addTargets(domElements);
        if (sprite) {
            flexiSprite.addTarget(sprite);
        }

        if (flexiSprite.hasTargets()) {
            this.flexiSprites.set(id, flexiSprite);
            return flexiSprite;
        }
        return null;
    }

    public addToggle(id: string, toggle: ToggleElement) {
        this.toggleElements.set(id, toggle);
        createStructure(Layers.gameLayers[this.id], "scene", this.currentScene.name, "contents")[id] = toggle;
        createStructure(Layers.gameLayers[this.id], "scene", "active", "contents")[id] = toggle;
        this.allElements[id] = toggle;
    }

    public getToggle(id: string): ToggleElement {
        return this.toggleElements.get(id);
    }

    public getFlexiButton(id: string): FlexiButton {

        if (this.flexiButtons.get(id)) {
            return this.flexiButtons.get(id);
        }

        const domElements = this.getDOMElements(id);
        const sprite = this.getSprite(id);
        const button = this.getButton(id);

        const flexiButton = new FlexiButton();

        flexiButton.addTargets(domElements);
        flexiButton.addTarget(button);
        flexiButton.addTarget(sprite);

        if (flexiButton.hasTargets()) {
            flexiButton.setEnabled(true);
            flexiButton.setVisible(true);

            this.flexiButtons.set(id, flexiButton);
            return flexiButton;
        }
        return null;
    }

    public getFlexiText(id: string): FlexiText {

        if (this.flexiTexts.get(id)) {
            return this.flexiTexts.get(id);
        }

        const domElements = this.getDOMElements(id);
        const text = this.getText(id) ?? this.getHTMLText(id) ?? this.getBitmapText(id);

        const flexiText = new FlexiText();

        flexiText.addTargets(domElements);
        if (text) {
            flexiText.addTarget(text);
        }

        if (flexiText.hasTargets()) {
            this.flexiTexts.set(id, flexiText);
            return flexiText;
        }
        return null;
    }

    public getFlexiInputText(id: string): FlexiInputText {

        if (this.flexiInputTexts.get(id)) {
            return this.flexiInputTexts.get(id);
        }

        const domElements = this.getDOMElements(id);

        const flexiInputText = new FlexiInputText();

        flexiInputText.addTargets(domElements);

        if (flexiInputText.hasTargets()) {
            this.flexiInputTexts.set(id, flexiInputText);
            return flexiInputText;
        }
        return null;
    }

    public getFlexiToggle(id: string) {

        if (this.flexiToggles.get(id)) {
            return this.flexiToggles.get(id);
        }

        const domElements = this.getDOMElements(id);
        const toggle = this.getToggle(id);

        const flexiToggle = new FlexiToggle();

        flexiToggle.addTargets(domElements);
        flexiToggle.addTarget(toggle);

        if (flexiToggle.hasTargets()) {
            this.flexiToggles.set(id, flexiToggle);
            return flexiToggle;
        }
        return null;
    }

    public getFlexiSlider(id: string) {

        if (this.flexiSliders.get(id)) {
            return this.flexiSliders.get(id);
        }

        const domElements = this.getDOMElements(id);

        const flexiSlider = new FlexiSlider();

        flexiSlider.addTargets(domElements);

        if (flexiSlider.hasTargets()) {
            this.flexiSliders.set(id, flexiSlider);
            return flexiSlider;
        }
        return null;
    }

    public getFlexiVideo(id: string): FlexiVideo {
        if (this.flexiVideos.get(id)) {
            return this.flexiVideos.get(id);
        }

        const domElements = this.getDOMElements(id) as HTMLVideoElement[];
        const flexiVideo = new FlexiVideo();
        flexiVideo.addTargets(domElements);

        if (flexiVideo.hasTargets()) {
            this.flexiVideos.set(id, flexiVideo);
            return flexiVideo;
        }

        return null;
    }

    public getDOMElements(id: string): HTMLElement[] {
        return Array.from(document.querySelectorAll("#" + this.id + " #" + this.currentScene?.name + " #" + id)) as HTMLElement[];
    }

    public getPosition(id: string, layer?: Layers): DualPosition {
        const position = this.positions?.get(id);
        if (!position) {
            return position;
        }

        const clonedPosition: DualPosition = new DualPosition();
        if (position.landscape) {
            clonedPosition.landscape = position.landscape.clone();
        }
        if (position.portrait) {
            clonedPosition.portrait = position.portrait.clone();
        }

        if (layer) {
            clonedPosition.landscape = this.localToLocal(layer, clonedPosition.landscape);
            clonedPosition.portrait = this.localToLocal(layer, clonedPosition.portrait);
        }

        return clonedPosition;
    }

    public has(target: DualPositionObject | DisplayObject) {
        return this.container.children.indexOf(target) !== -1;
    }

    /**
     *
     * @param target DisplayObject to add
     * @param globalToLocal convert from "stage" coords to layer coords
     * @param sendToBack Add to beginning of display list instead of end
     * @param reference Optional. Adds DisplayObject to layer's register so even if it's container is changed, it can still be accessed via getSprite etc
     */
    public add(target: DualPositionObject | DisplayObject, globalToLocal: boolean = false, sendToBack: boolean = false, reference?: string) {
        if (Layers.gameLayers && target.name !== null) {
            createStructure(Layers.gameLayers[this.id], "scene", this.currentScene.name, "contents")[target.name] = target;
            createStructure(Layers.gameLayers[this.id], "scene", "active", "contents")[target.name] = target;
            this.allElements[target.name] = target;
        }

        if (target.parent) {
            const currentLayer = Layers.FromString(target.parent.name).container;

            if (isDualPositionObject(target)) {
                target.landscape.x += currentLayer.landscape.x - this.container.landscape.x;
                target.landscape.y += currentLayer.landscape.y - this.container.landscape.y;
                target.portrait.x += currentLayer.portrait.x - this.container.portrait.x;
                target.portrait.y += currentLayer.portrait.y - this.container.portrait.y;
            } else {
                Logger.warn(`A native pixi object is being added to a layer (${this.id}). Use DualPositionObjects or the position will not change between orientations`);
                target.x += currentLayer.landscape.x - this.container.landscape.x;
                target.y += currentLayer.landscape.y - this.container.landscape.y;
            }
        }

        if (globalToLocal) {
            if (isDualPositionObject(target)) {
                target.landscape.x -= this.container.landscape.x;
                target.landscape.y -= this.container.landscape.y;
                target.portrait.x -= this.container.portrait.x;
                target.portrait.y -= this.container.portrait.y;
            } else {
                Logger.warn(`A native pixi object is being added to a layer (${this.id}). Use DualPositionObjects or the position will not change between orientations`);
                target.x -= this.container.landscape.x;
                target.y -= this.container.landscape.y;
            }
        }

        if (sendToBack) {
            this.container.addChildAt(target, 0);
        } else {
            this.container.addChild(target);
        }

        if (reference) {
            if (this.references.has(reference)) {
                Logger.warn(`Element '${reference}' already exists in layer '${this.id}'`);
            }
            this.references.set(reference, target);
        }
    }

    public remove(target: DisplayObject, reference?: string) {
        this.container.removeChild(target);

        if (reference) {
            if (this.references.has(reference) && this.references.get(reference) === target) {
                this.references.delete(reference);
            }
        }
    }

    public setTransition(transition: SceneTransition) {
        for (const [sceneName, scene] of this.scenes) {
            this.setTransitions(sceneName, transition);
        }
    }

    public setTransitions(scene: string, transition: SceneTransition, targetScene: string = "*") {
        this.getScene(scene).setTransitions(transition, targetScene);
    }

    public addTransition(scene: string, transition: SceneTransition, targetScene: string = "*") {
        this.getScene(scene).addTransition(transition, targetScene);
    }

    public hasScene(name: string) {
        name = name.toLowerCase();
        return this.scenes.has(name) || DOMLayer.hasDOMScene(name, this.id);
    }

    public hasAnyScene(names: string[]) {

        for (const name of names) {
            if (this.hasScene(name)) {
                return true;
            }
        }

        return false;
    }

    public hasScenes(names: string[]) {
        for (const name of names) {
            if (!this.hasScene(name)) {
                return false;
            }
        }

        return true;
    }

    public getScene(scene: string | Scene): Scene {
        if (!(scene instanceof Scene)) {
            return this.getSceneByName(scene);
        }
        return scene;
    }

    public getFirstValidScene(scenes: string[]): Scene {
        for (const scene of scenes) {
            if (this.hasScene(scene)) {
                return this.getScene(scene);
            }
        }

        return null;
    }

    /**
     * @param scene Scene name to jump to
     * @param autoClear Whether to clear previous scene
     * @param reset If false, calling jumpToScene on a scene you're already in will do nothing, if true, it will clear and restart the scene
     * @returns
     */
    public jumpToScene(scene: string | Scene, autoClear: boolean = true, reset: boolean = false) {
        const nextScene = this.getScene(scene);

        if (this.currentScene === nextScene && !reset) {
            return;
        }

        if (this.currentScene) {
            if (autoClear) {
                this.clear();
            }

            this.currentScene.clearDOM();
            this.currentScene.destroyTransitions();
            Layers.onExitScene.dispatch(this.id, this.currentScene.name);
        }

        this.currentScene = nextScene;

        this.currentScene.showDOM();

        Layers.onEnterScene.dispatch(this.id, this.currentScene.name);

        if (this.currentScene.hasCanvas()) {
            this.updateBounds();
            spritePopulator.populateSprites(this.currentScene, this);
            textPopulator.populateText(this.currentScene, this);
            this.updatePositions(this.currentScene);
        }

/////////////////////////////////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////////////
//////////////////

        this.resetContainer();

        this.onSceneEnter.dispatch(nextScene.name);
    }

    public resetScene() {
        if (!this.currentScene) {
            Logger.warn(`resetScene() called when no scene is active (${this.id})`);
        } else {
            const currentSceneName = this.currentScene.name;
            this.jumpToScene(currentSceneName, true, true);
        }
    }

    public setScene(scene: string | Scene, setup?: () => void, force: boolean = false): Contract<void> {
        const nextScene = this.getScene(scene);

        const currentSceneName = this.currentScene ? this.currentScene.name : "*";

        if (this.currentScene === nextScene && !force) {
            if (setup) {
                setup();
            }
            return Contract.empty();
        }

        return new Contract<void>((resolve) => {
            const enterNextScene = () => {
                this.jumpToScene(nextScene);

                if (setup) {
                    setup();
                }

                this.currentScene.in(currentSceneName).then(() => {
                    this.afterSceneEnter.dispatch(nextScene.name);
                    resolve();
                });
            };

            if (this.currentScene) {
                this.beforeSceneExit.dispatch(currentSceneName);
                this.currentScene.out(nextScene.name).then(() => {
                    this.onSceneExit.dispatch(currentSceneName);
                    enterNextScene();
                });
            } else {
                enterNextScene();
            }
        });
    }

    public skipTransition() {
        this.currentScene.skipTransition();
    }

    public defaultScene(): Contract<void> {
        return this.setScene("default");
    }

    public getCurrentScene(): Scene {
        return this.currentScene;
    }

    public currentSceneIs(name: string): boolean {
        return this.currentScene && this.currentScene.name === name;
    }

    public hide() {
        this.container.visible = false;
        DOMLayer.setVisibleDOMLayer(this.id, false);
    }

    public show() {
        this.container.visible = true;
        DOMLayer.setVisibleDOMLayer(this.id, true);
    }

    /**
     * Immediately turns off caching, or turns it on with a delay
     */
    public cacheAsBitmap(cache: boolean) {
        this.cachingAsBitmap = cache;

        this.updateCacheAsBitmap(cache);
    }

    public refreshBitmapCache() {
        if (this.cachingAsBitmap) {
            this.updateCacheAsBitmap(false);
            this.updateCacheAsBitmap(true);
        }
    }

    public clear() {
        // Clear bitmap cache if applicable
        this.refreshBitmapCache();

        // If applicable, remove bounds mask from container
        if (this.boundsMask && this.boundsMask.parent === this.container) {
            this.container.removeChild(this.boundsMask);
        }

        // Clean up any auto generated elements
        clearMap(this.buttonElements);
        clearMap(this.toggleElements);

        // Clean up any generated flexis
        clearMap(this.flexiButtons);
        clearMap(this.flexiTexts);
        clearMap(this.flexiSprites);
        clearMap(this.flexiInputTexts);
        clearMap(this.flexiToggles);
        clearMap(this.flexiSliders);

        // Kill all particles
        this.particles.forEach((particleId: number) => {
            Services.get(ParticleService).remove(particleId);
        });
        this.particles.clear();

        // Remove all scripted animations
        this.scriptedAnimations.forEach((scriptedAnimation: ScriptedAnimation) => {
            Services.get(AnimationService).removeScriptedAnimation(scriptedAnimation);
        });
        this.scriptedAnimations.clear();

        // Clear bounds
        this.bounds = null;

        // Clear custom masks
        if (this.customMask) {
            this.container.mask = null;
            this.customMask = null;
        }
        if (this.customMaskGraphic) {
            this.container.mask = null;
            this.customMaskGraphic = null;
        }

        // Clear all references to DisplayObjects
        this.references.clear();

        // Clear gameLayers references
        if (Layers.gameLayers) {
            createStructure(Layers.gameLayers[this.id], "scene", this.currentScene.name).contents = null;
        }

        // Remove all children and kill any tweens that may exist
        while (this.container.children.length) {
            const child = this.container.children[0];
            if (child) {
                if (child.scale) {
                    TweenJS.removeAll(child.scale);
                }
                TweenJS.removeAll(child);
                if (child.destroy) {
                    child.destroy();
                }
            }
        }
    }

    public localToGlobal<T extends Point | Rectangle | Position | DualPosition>(input: T, container?: Position): T {
        if (input instanceof DualPosition) {
            return new DualPosition(this.localToGlobal(input.landscape, this.container.landscape), this.localToGlobal(input.portrait, this.container.portrait)) as T;
        } else if (input && container instanceof Position) {
            const limitedInput = input as Point | Rectangle | Position;
            const globalPoint = new Point(limitedInput.x + container.x, limitedInput.y + container.y);

            if (input instanceof Position) {
                const globalPosition: Position = input.clone() as Position;
                globalPosition.x = globalPoint.x;
                globalPosition.y = globalPoint.y;
                return globalPosition as T;
            } else if (input instanceof Rectangle) {
                return new Rectangle(globalPoint.x, globalPoint.y, input.width, input.height) as T;
            } else {
                return globalPoint as T;
            }
        }
    }

    public globalToLocal<T extends Point | Rectangle | Position | DualPosition>(input: T, container?: Position): T {
        if (input instanceof DualPosition) {
            return new DualPosition(this.globalToLocal(input.landscape, this.container.landscape), this.globalToLocal(input.portrait, this.container.portrait)) as T;
        } else if (input && container instanceof Position) {
            const limitedInput = input as Point | Rectangle | Position;
            const localPoint = new Point(limitedInput.x - container.x, limitedInput.y - container.y);

            if (input instanceof Position) {
                const globalPosition = input.clone() as Position;
                globalPosition.x = localPoint.x;
                globalPosition.y = localPoint.y;
                return globalPosition as T;
            } else if (input instanceof Rectangle) {
                return new Rectangle(localPoint.x, localPoint.y, input.width, input.height) as T;

            } else {
                return localPoint as T;
            }
        }
    }

    public localToLocal<T extends Point | Rectangle | Position | DualPosition>(layer: Layers, point: T): T {
        return layer.globalToLocal(this.localToGlobal(point));
    }

    /** @deprecated */
    public moveBehind(targetLayer: Layers) {
        throw new Error("Use the layer-config.json to create a new layer order, then switch to it using Layers.setLayerOrder");
    }

    /** @deprecated */
    public moveAbove(targetLayer: Layers) {
        throw new Error("Use the layer-config.json to create a new layer order, then switch to it using Layers.setLayerOrder");
    }

    public enableBoundsMask(enable: boolean) {
        if (this.customMaskGraphic) {
            this.container.mask = this.customMaskGraphic;
        } else {
            if (enable) {
                this.boundsMask.visible = true;
                this.container.mask = this.boundsMask;
            } else if (!this.customMask) {
                this.boundsMask.visible = false;
                this.container.mask = null;
            }
        }
    }

    public getScenes() {
        return this.scenes;
    }

    public getDOMContainers() {
        if (this.currentScene) {
            return Array.from(document.querySelectorAll("#" + this.id + " #" + this.currentScene.name)) as HTMLElement[];
        }

        return [];
    }

    public setCustomMask(mask: DualPosition): void;
    public setCustomMask(mask: Sprite | Graphics): void;

    public setCustomMask(mask: DualPosition | Sprite | Graphics): void {
        if (mask instanceof DualPosition) {
            this.customMask = this.localToGlobal(mask);
            this.container.mask = this.boundsMask;
        } else {
            this.customMaskGraphic = mask;
        }

        this.updateMask();
    }

    public removeMask(): void {
        this.container.mask = null;
        this.customMask = null;
        this.customMaskGraphic = null;
    }

    public addAnchor(element: string, alignment: DualAnchor): void {
        this.anchoredElement.set(element, alignment);
        this.refreshAnchors();
    }

    public removeAnchor(element: string): void {
        if (this.anchoredElement.has(element)) {
            this.anchoredElement.delete(element);
        }
    }

    public refreshAnchors(): void {
        const titleSafeArea = Services.get(CanvasService).getSafeArea();
        this.anchoredElement.forEach((alignment, name) => {
            const element = this.getByReference(name, DisplayObject);
            if (element && (element instanceof Container || element instanceof Sprite || element instanceof ButtonElement || element instanceof Text)) {
                let offset: number;
                if (alignment.portrait) {
                    if (alignment.portrait.horizontal) {
                        offset = (alignment.portrait.horizontal.offset - (alignment.portrait.horizontal.offset * 2)) +
                            ((alignment.portrait.horizontal.offset * 2) * (1 - alignment.portrait.horizontal.alignment));
                        element.portrait.x = titleSafeArea.portrait.x + (titleSafeArea.portrait.width * alignment.portrait.horizontal.alignment) -
                            (element.getBounds().width * alignment.portrait.horizontal.alignment) + offset;
                    }
                    if (alignment.portrait.vertical) {
                        offset = (alignment.portrait.vertical.offset - (alignment.portrait.vertical.offset * 2)) +
                            ((alignment.portrait.vertical.offset * 2) * (1 - alignment.portrait.vertical.alignment));
                        element.portrait.y = titleSafeArea.portrait.y + (titleSafeArea.portrait.width * alignment.portrait.vertical.alignment) -
                            (element.getBounds().width * alignment.portrait.vertical.alignment) + offset;
                    }
                }
                if (alignment.landscape) {
                    if (alignment.landscape.horizontal) {
                        offset = (alignment.landscape.horizontal.offset - (alignment.landscape.horizontal.offset * 2)) +
                            ((alignment.landscape.horizontal.offset * 2) * (1 - alignment.landscape.horizontal.alignment));
                        element.landscape.x = titleSafeArea.landscape.x + (titleSafeArea.landscape.width * alignment.landscape.horizontal.alignment) -
                            (element.getBounds().width * alignment.landscape.horizontal.alignment) + offset;
                    }
                    if (alignment.landscape.vertical) {
                        offset = (alignment.landscape.vertical.offset - (alignment.landscape.vertical.offset * 2)) +
                            ((alignment.landscape.vertical.offset * 2) * (1 - alignment.landscape.vertical.alignment));
                        element.landscape.y = titleSafeArea.landscape.y + (titleSafeArea.landscape.width * alignment.landscape.vertical.alignment) -
                            (element.getBounds().width * alignment.landscape.vertical.alignment) + offset;
                    }
                }
            }
        });
    }

    public getByReference<T>(reference: string, type: { new(...args: any[]): T }) {
        const element = this.references.get(reference);
        if (element instanceof type) {
            return element;
        }
        return null;
    }

    public getByReferences<T>(reference: string[], type: { new(...args: any[]): T }) {
        return reference.map((ref) => this.getByReference(ref, type));
    }

    public get(id: string) {
        return this.references.get(id);
    }

    private updateCacheAsBitmap(cache: boolean) {
        Timer.clearTimeout(this.cacheAsBitmapTimer);
        if (cache) {
            this.cacheAsBitmapTimer = Timer.setTimeout(() => {
                this.container.cacheAsBitmap = true;
            }, 100);
        } else {
            this.container.cacheAsBitmap = false;
        }
    }

    private updateMask() {
        let bounds = this.bounds;

        if (this.customMaskGraphic) {
            this.customMaskGraphic.renderable = false;
            this.container.addChild(this.customMaskGraphic);
            this.container.mask = this.customMaskGraphic;
        } else {
            if (this.customMask) {
                bounds = this.customMask;
            }

            if (!this.boundsMask) {
                this.boundsMask = new SmartShape();
                this.boundsMask.name = "boundsMask";
            }

            this.boundsMask.shapeFillColor = 0xff0000;
            if (bounds) {
                this.boundsMask.landscape = new Position(0, 0, bounds.landscape.width, bounds.landscape.height);
            }
            if (!bounds.portrait.unavailable) {
                this.boundsMask.portrait = new Position(0, 0, bounds.portrait.width, bounds.portrait.height);
            }

            this.boundsMask.renderable = false;
            this.container.addChild(this.boundsMask);
        }
    }

    private getSceneByName(name: string = "default"): Scene {
        let scene = this.scenes.get(name.toLowerCase());

        if (!scene && (DOMLayer.hasDOMScene(name, this.id) || name === "default")) {
            scene = new Scene(name, this.id, null);
        }

        if (!scene) {
            throw new InvalidSceneError("Invalid scene: '" + name + "' for " + this.id);
        }

        return scene;
    }

    private updateBounds() {
        this.bounds = DualPosition.fromConfig(this.currentScene.config.bounds);

        // Set layer position / bounds
        this.container.landscape.x = this.container.portrait.x = this.bounds.landscape.x;
        this.container.landscape.y = this.container.portrait.y = this.bounds.landscape.y;

        if (!this.bounds.portrait.unavailable) {
            this.container.portrait.x = this.bounds.portrait.x;
            this.container.portrait.y = this.bounds.portrait.y;
        }

        this.updateMask();
    }

    private updatePositions(scene: Scene) {
        if (scene.positionMap) {
            this.positions = scene.positionMap;
            if (Layers.gameLayers) {
                createStructure(Layers.gameLayers[this.id], "scene", scene.name, "contents").positions = mapToObject(scene.positionMap);
                createStructure(Layers.gameLayers[this.id], "scene", "active", "contents").positions = mapToObject(scene.positionMap);
                const mergedPositions = { ...Layers.gameLayers[this.id].scene[scene.name].contents.positions, ...Layers.gameLayers[this.id].scene.active.contents.positions };
                Layers.gameLayers[this.id].scene.active.contents.positions = mergedPositions;
            }
        }
    }

    private resetContainer() {
        this.container.pivot = new PIXIPoint(0, 0);

        const layerScale = Layers.layerScales.get(this.id.toLowerCase());
        if (layerScale) {
            this.container.landscape.scale.set(layerScale);
            this.container.portrait.scale.set(layerScale);
        } else {
            this.container.scale.x = 1;
            this.container.scale.y = 1;
        }

        this.container.alpha = 1;
    }
}
