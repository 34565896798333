import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { PopReelMatrixComponent } from "components/pop-reel-matrix-component";
import { SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";

export function getDabContracts(matches: { matchedValue: number, reelIndex: number }[]): Contract<void> {
    const ticketComponent = Components.get(SlingoTicketMatrixComponent);

    const dabContracts: Array<() => Contract> = [
        () => Contract.getTimeoutContract(0)
    ];

    matches.forEach((match, index) => {
        if (!Components.get(SlingoTicketMatrixComponent).isValueDabbed(match.matchedValue)) {
            dabContracts.push(() => new Sequence([
                () => Contract.getTimeoutContract(250 * index),
                () => new Contract((resolve) => {
                    const dab = new Parallel([
                        () => ticketComponent.dabSymbol(match.matchedValue, false),
                        () => Components.get(PopReelMatrixComponent).popSymbol(match.reelIndex)
                    ]);

                    if (index === matches.length - 1) {
                        dab.then(resolve);
                    } else {
                        dab.execute();
                        resolve();
                    }
                }),
                () => ticketComponent.checkWinlines()
            ]));
        }
    });

    return new Sequence(dabContracts);
}