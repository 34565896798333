import { Contract } from 'appworks/utils/contracts/contract';
import { ClientController } from 'client-controller';
import { PopSlingoCelebrationComponent } from 'components/pop-slingo-celebration-component';

/**
 * Description placeholder
 *
 * @class SocialSlingoCelebrationComponent
 * @typedef {SocialSlingoCelebrationComponent}
 * @extends {SlingoCelebrationComponent}
 */
export class SocialSlingoCelebrationComponent extends PopSlingoCelebrationComponent {
  public showCelebration(lines: number, autoReset?: boolean): Contract {
    ClientController.getInstance().raiseEvent(ClientController.getSlingoEvent(lines));
    return super.showCelebration(lines, autoReset);
  }
}
